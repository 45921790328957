<template>
    <v-dialog v-model="show" content-class="popin-dialog-container" overlay-opacity="0.85">

        <v-icon
            class="close-popin-dialog"
            @click="show=false"
        >
            mdi-close
        </v-icon>

        <component-container
            :card-title="`${$t('component_linkingReferralBacklinksPositions_title')} <em>${elementName}</em>`"
            :card-subtitle="$t('component_linkingReferralBacklinksPositions_subtitle')"
            :table-menu="{csv:true}"
            :component-data="{response:linkingReferralBacklinksPositions, hasData:linkingReferralBacklinksPositions.data}"
            @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"

            :table-density-selector="true"
        >
            <template v-slot:content>
                <ag-grid-table
                   v-if="linkingReferralBacklinksPositions.data"
                   :with-fullscreen="false"
                   :show-pagination="false"
                   :show-floating-filter="false"
                   :table-data="{columnDefs:columnDefs,rowData:linkingReferralBacklinksPositions.data}"
                   ref="agGridTable"
                />
            </template>
        </component-container>
    </v-dialog>
</template>


<script>

import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable.vue'

export default {
    data() {
        return {
            show:false,
            linkingReferralBacklinksPositions:{  isLoaded:false, error:null,data: null },
            elementName:null,
            popinStateName:'linkingReferralBacklinksPositions',
            columnDefs:[
                {headerName: this.$t('component_linkingReferralBacklinksPositions_table_header_keyword'),filter: true, field: 'keyword'},
                {headerName: this.$t('component_linkingReferralBacklinksPositions_table_header_position'),filter: false, field: 'position', sort:'asc', sortingOrder: ['asc','desc'], width: 70, cellRendererFramework: 'CellRendererFormatedNumber', cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},
                {headerName: this.$t('component_linkingReferralBacklinksPositions_table_header_searchVolume'),filter: false, field: 'searchVolume',  sortingOrder: ['desc','asc'], width: 70, cellRendererFramework: 'CellRendererFormatedNumber', cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},
                {headerName: this.$t('component_linkingReferralBacklinksPositions_table_header_estimatedTraffic'),filter: false, field: 'estimatedTraffic',  sortingOrder: ['desc','asc'], width: 70, cellRendererFramework: 'CellRendererFormatedNumber', cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},

            ]
        }
    },
    components: {
        AgGridTable,
    },
    watch:{
        getShowPopin(){
            if(!this.show && this.getShowPopin){
                if(this.getShowPopin.componentName === this.popinStateName){
                    this.show = true
                    this.elementName = this.getShowPopin.elementName
                    this.getComponentData({
                        componentName:'linkingReferralBacklinksPositions',
                        promise: this.getShowPopin.promise(),
                        dataFormatter: this.formatData
                    })
                }
            }
        },
        show(){
            if(!this.show){
                this.showAgGridPopin(null)
            }
        }


    },
    methods:{
        formatData(jsonData){
            if(jsonData.content.length === 0){return null}
            let rowData = []
            jsonData.content.forEach(data => {
                rowData.push({
                    keyword:data.string,
                    position:data.rank,
                    searchVolume:Math.round(data.searchCount),
                    estimatedTraffic:data.visibility
                })
            })

            return rowData
        },

    },
}
</script>
