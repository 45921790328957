
import axios from '@/api/axios.js'

export default {

    /**
     * @param projectId int
     * @param filters
     * @returns Promise
     */
    readEvolutionConversion (projectId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/dashboard/traffic/evolution-conversion`,filters)
    },


    /**
     * @param projectId int
     * @returns Promise
     */
    readAcquisitionChannel (projectId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/dashboard/traffic/acquisition-channel`,filters)
    },

    /**
     * @param projectId int
     * @returns Promise
     */
    readTopCompetitor (projectId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/dashboard/competition/top-competitor`, filters)
    },


    /**
     * @param projectId int
     * @returns Promise
     */
    readEvolution (projectId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/dashboard/market-segment/evolution`, filters)
    },

    /**
     * @param projectId int
     * @returns Promise
     */
    readProgressionPotential (projectId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/dashboard/market-segment/progression-potential`,filters)
    },


    /**
     * @param projectId int
     * @returns Promise
     */
    readPerformance (projectId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/dashboard/advertising/performance`, filters)
    },

    /**
     * @param projectId int
     * @returns Promise
     */
    readByLocation (projectId) {
        return axios.get(`/report/data/projects/${projectId}/dashboard/local-visibility/by-location`)
    },

    /**
     * @param projectId int
     * @returns Promise
     */
    readSize (projectId) {
        return axios.get(`/report/data/projects/${projectId}/dashboard/market-segment/size`)
    },
};
