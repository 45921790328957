<template>
    <v-dialog v-model="show" :content-class="`popin-dialog-container ${filterOpen ? 'filter-open' : ''}`" overlay-opacity="0.85">

        <v-icon
            class="close-popin-dialog"
            @click="show=false"
        >
            mdi-close
        </v-icon>
            <component-container
                :card-title="`${$t('component_competitionMarketLeaderPages_title')} ${elementName}`"
                :card-subtitle="$t('component_competitionMarketLeaderPages_subtitle')"


                :component-data="{response:competitionMarketLeaderPages, hasData:competitionMarketLeaderPages.data}"

                :table-menu="{csv:true}"
                :table-density-selector="true"
                @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"
            >
                <template v-slot:filters>
                    <component-filters
                        class="mt-10"
                        :market-segments="true"
                        @applyFilter="initComponentData"
                        @filterPanelStatus="displayFilterOpen"
                        :default-select-value="lastFilters"
                    />
                </template>

                <template v-slot:content>
                    <ag-grid-table v-if="competitionMarketLeaderPages.data"
                           :with-fullscreen="false"
                           class="table-with-filter"
                           :show-pagination="false"
                           :show-floating-filter="false"
                           :table-data="{columnDefs:columnDefs,rowData:competitionMarketLeaderPages.data}"
                            ref="agGridTable"
                    />
                </template>
            </component-container>
    </v-dialog>
</template>


<script>

import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable'


import apiCalls from "@/api/axios/dataReport/competition";
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";
import ComponentFilters from "@/components/yooda-components/ComponentFilters";

export default {
    data() {
        return {
            show:false,
            competitionMarketLeaderPages:{  isLoaded:false, error:null,data: null },
            elementName:null,
            filterOpen:false,
            lastFilters:null,
            popinStateName:'competitionMarketLeaderPages',
            columnDefs:[
                {headerName: this.$t('component_competitionMarketLeaderPages_table_header_rank'), field: 'rank', sort:'asc',width: 60, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},
                {headerName: this.$t('component_competitionMarketLeaderPages_table_header_page'), field: 'page',filter: true},
                {headerName: this.$t('component_competitionMarketLeaderPages_table_header_estimatedTraffic'), field: 'estimatedTraffic', cellRendererFramework: 'CellRendererFormatedNumber', width: 70, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellTraffic},
                {headerName: this.$t('component_competitionMarketLeaderPages_table_header_performance'), field: 'performance', cellRendererFramework: 'CellRendererFormatedNumber', width: 70, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellTraffic},
                {headerName: this.$t('component_competitionMarketLeaderPages_table_header_keywordCount'), field: 'keywordCount', cellRendererFramework: 'CellRendererFormatedNumber', width: 70, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellGeneric},
            ]
        }
    },
    components: {
        AgGridTable,
        ComponentFilters,
    },
    computed:{

    },
    watch:{
        getShowPopin(){
            if(!this.show && this.getShowPopin){
                if(this.getShowPopin.componentName === this.popinStateName){
                    this.show = true
                    this.elementName = this.getShowPopin.elementName
                    this.getComponentData({
                        componentName:'competitionMarketLeaderPages',
                        promise: this.getShowPopin.promise(),
                        dataFormatter: this.formatData
                    })
                }
            }
        },
        show(){
            if(!this.show){
                this.showAgGridPopin(null)
            }
        }
    },
    methods:{
        displayFilterOpen(status){ this.filterOpen = status === 'open'; },
        formatData(jsonData){
            let rowData = []
            jsonData.content.forEach(data => {
                rowData.push({
                    rank: data.rank,
                    page: data.url,
                    estimatedTraffic: (data.cumulatedVisibility ? data.cumulatedVisibility : '-'),
                    performance: (data.performance ? data.performance : '-'),
                    keywordCount: data.rankedKeywordCount,
                })
            })
            return rowData
        },
        initComponentData(filters){
            filters.params['domain-name'] = this.elementName
            filters.params['date-range-type'] = this.globalFilterFrequency
            filters.params['search-geolocation-id'] = this.globalFilterLocation

            this.getComponentData( {
                componentName:'competitionMarketLeaderPages',
                promise: apiCalls.readMarketLeaderPages(this.$router.currentRoute.params.projectId,filters),
                dataFormatter:  this.formatData
            })
            this.lastFilters = filters
        },
    },
}
</script>
