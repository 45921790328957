var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{attrs:{"card-title":_vm.$t('component_marketSegmentSize_title'),"card-subtitle":_vm.$t('component_marketSegmentSize_subtitle'),"height":495,"information-tooltip":{
        title : _vm.$t('component_marketSegmentSize_informationTooltip_title'),
        text:_vm.$t('component_marketSegmentSize_informationTooltip_text')
    },"component-data":{
        response: _vm.marketSegmentSize,
        hasData: true
    }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"text-center pt-10 pb-12 mt-10 mb-12 v-application"},[_c('div',{staticClass:"p-6"},[_c('yooda-icon',{staticClass:"p-3",attrs:{"name":"users","color":"warning","background":"rounded","size":30}})],1),_c('h3',{staticClass:"text-h3 font-weight-bold mt-6 mb-6"},[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.marketSegmentSize.data)))]),_c('p',{staticClass:"text-body-1 mb-8"},[_vm._v(_vm._s(_vm.$t('component_marketSegmentSize_legend')))])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }