<template>
    <v-dialog v-model="show" :content-class="`popin-dialog-container ${filterOpen ? 'filter-open' : ''}`" overlay-opacity="0.85">

        <v-icon
            class="close-popin-dialog"
            @click="show=false"
        >
            mdi-close
        </v-icon>
        <component-container
            :card-title="`${$t('component_websitePagesBestPositionedPagePositions_title')} ${elementName}`"
            :card-subtitle="$t('component_websitePagesBestPositionedPagePositions_subtitle')"



            :component-data="{response:websitePagesBestPositionedPagePositions, hasData:websitePagesBestPositionedPagePositions.data}"

            :table-menu="{csv:true}"
            :table-density-selector="true"
            @setTableMenuExportCsv="exportCsvTable = true"
        >

            <template v-slot:filters>
                <component-filters
                    class="mt-10"
                    :market-segments="true"
                    @applyFilter="initComponentData"
                    @filterPanelStatus="displayFilterOpen"
                    :default-select-value="lastFilters"
                />
            </template>

            <template v-slot:content>
                <ag-grid-table v-if="websitePagesBestPositionedPagePositions.data"
                   :with-fullscreen="false"
                   class="table-with-filter"
                   :show-pagination="false"
                   :show-floating-filter="false"
                   :table-data="{columnDefs:columnDefs,rowData:websitePagesBestPositionedPagePositions.data}"
                   :set-export-csv-table="exportCsvTable"
                />
            </template>
        </component-container>


    </v-dialog>
</template>


<script>

import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable'


import apiCalls from "@/api/axios/dataReport/websitePages";
import ComponentFilters from "@/components/yooda-components/ComponentFilters";
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";

export default {
    data() {
        return {
            show:false,
            websitePagesBestPositionedPagePositions:{  isLoaded:false, error:null,data: null },
            elementName:null,
            filterOpen:false,
            exportCsvTable:false,
            lastFilters:null,
            popinStateName:'websitePagesBestPositionedPagePositions',
            columnDefs:[
                {headerName: this.$t('component_websitePagesBestPositionedPagePositions_table_header_keyword'), field: 'keyword',filter: true},
                {headerName: this.$t('component_websitePagesBestPositionedPagePositions_table_header_searchCount'), field: 'searchCount', cellRendererFramework: 'CellRendererFormatedNumber', width: 100, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},
                {headerName: this.$t('component_websitePagesBestPositionedPagePositions_table_header_position'), field: 'position', sort:'asc', width: 90, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},
                {headerName: this.$t('component_websitePagesBestPositionedPagePositions_table_header_evolution'), field: 'evolution', cellRendererFramework: 'CellRendererProgression', width: 80, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', comparator: cellSort.agGridTableSortCellRankEvolution},
                {headerName: this.$t('component_websitePagesBestPositionedPagePositions_table_header_positionType'), field: 'positionType'},
            ]
        }
    },
    components: {
        AgGridTable,
        ComponentFilters,
    },
    computed:{

    },
    watch:{
        getShowPopin(){
            if(!this.show && this.getShowPopin){
                if(this.getShowPopin.componentName === this.popinStateName){
                    this.show = true
                    this.elementName = this.getShowPopin.elementName
                    this.getComponentData({
                        componentName:'websitePagesBestPositionedPagePositions',
                        promise: this.getShowPopin.promise(),
                        dataFormatter: this.formatData
                    })
                }
            }
        },
        show(){
            if(!this.show){
                this.showAgGridPopin(null)
            }
        }
    },
    methods:{
        displayFilterOpen(status){ this.filterOpen = status === 'open'; },
        formatData(jsonData){

            let rowData = []
            jsonData.content.forEach(data => {
                rowData.push({
                    keyword: data.string,
                    searchCount: data.searchCount !== null ? data.searchCount : '-',
                    position: data.rank,
                    evolution: {rankEvolution:(data.rankEvolution === null ? 'New' : data.rankEvolution*-1)},
                    positionType: this.$t(`common_google_block_label_${data.positionType}`),
                })
            })
            return rowData
        },
        initComponentData(filters){
            filters.params['date-range-type'] = this.globalFilterFrequency
            filters.params['search-geolocation-id'] = this.globalFilterLocation

            this.getComponentData( {
                componentName:'websitePagesBestPositionedPagePositions',
                promise: apiCalls.readBestPositionedPagePositions(
                    this.$router.currentRoute.params.projectId,
                    this.getShowPopin.elementId,
                    filters
                ),
                dataFormatter: this.formatData
            })
            this.lastFilters = filters


        },
    }
}
</script>
