<template>

    <component-container
        :card-title="$t('component_websitePagesPositionedCountEvolution_title')"
        :card-subtitle="$t('component_websitePagesPositionedCountEvolution_subtitle')"

        :height="495"

        :information-tooltip="{
            title : $t('component_websitePagesPositionedCountEvolution_informationTooltip_title'),
            text:$t('component_websitePagesPositionedCountEvolution_informationTooltip_text')
        }"

        :component-data="{response:websitePagesPositionedCountEvolution, hasData:(websitePagesPositionedCountEvolution.data && websitePagesPositionedCountEvolution.data.options)}"
    >
        <template v-slot:content>
            <div v-if="websitePagesPositionedCountEvolution.data" class="vx-row">
                <v-row>
                    <v-col :cols="10">
                        <div class="v-application pl-5 text-center" style="display: inline-block">
                            <div class="p-3">
                                <yooda-icon name="file-text-o" class="p-3" color="primary" background="rounded" :size="30"></yooda-icon>
                            </div>
                            <h3 class="text-h3 font-weight-bold">{{ websitePagesPositionedCountEvolution.data.labelValue|formatNumber}}</h3>
                            <p class="text-body-1 mb-0">{{$t('component_websitePagesPositionedCountEvolution_label')}}</p>
                        </div>

                        <yooda-chart
                            autoresize
                            :options="websitePagesPositionedCountEvolution.data.options"
                            chart-type="line"
                            chart-style="height: 285px"
                        />
                    </v-col>
                    <v-col :cols="2">
                        <div v-for="(rank, index) in websitePagesPositionedCountEvolution.data.ranks" v-bind:key="index" class="mb-1" >
                            <p class="text-body-1 mb-1">{{ $t('common_google_block_label_'+rank.positionType) }} : <strong>{{ rank.distinctPageCount }} {{ $t('common_rank_pages') }}</strong></p>
                            <vs-progress :percent="rank.percent" :color="getComponentColor().getGoogleResultTypeColor(rank.positionType)" :height="16"></vs-progress>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </template>
    </component-container>

</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/websitePages.js'


export default {
    data() {
        return {
            websitePagesPositionedCountEvolution:null
        }
    },
    components: {
        YoodaChart
    },
    computed:{

    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData()
        },
        globalFilterLocation(){
            this.initComponentData()
        }
    },
    methods:{
        formatData(jsonData){

            if(jsonData.content.length === 0){return {}}
            let self = this
            let serieLabel = []
            let serieData = []
            let lastValue = 0

            //**** Faire varier les jauge au survol du graphe
            let lastRepartition = []

            jsonData.content.forEach((data)=>{
                serieLabel.push(this.$options.filters.displayDateLocale(data.date))
                serieData.push({
                    date:this.$options.filters.displayDateLocale(data.date),
                    value:data.cumulatedDistinctPageCount,
                    expandedDistinctPageCounts:data.expandedDistinctPageCounts

                })
                lastValue = data.cumulatedDistinctPageCount

                lastRepartition = []
                lastRepartition['natural'] = {distinctPageCount : 0,percent:0, positionType:'natural'}
                lastRepartition['adwords'] = {distinctPageCount : 0,percent:0, positionType:'adwords'}
                lastRepartition['video'] = {distinctPageCount : 0,percent:0, positionType:'video'}
                lastRepartition['map'] = {distinctPageCount : 0,percent:0, positionType:'map'}
                lastRepartition['snippet'] = {distinctPageCount : 0,percent:0, positionType:'snippet'}
                lastRepartition['shopping'] = {distinctPageCount : 0,percent:0, positionType:'shopping'}
                lastRepartition['actuality'] = {distinctPageCount : 0,percent:0, positionType:'actuality'}
                lastRepartition['image'] = {distinctPageCount : 0,percent:0, positionType:'image'}


                data.expandedDistinctPageCounts.forEach(repartitionData=>{
                    if(repartitionData.positionType === 'zero'){repartitionData.positionType = 'snippet'}
                    if(lastRepartition[repartitionData.positionType]){
                        lastRepartition[repartitionData.positionType].percent = (data.cumulatedDistinctPageCount > 0 ? ((repartitionData.distinctPageCount*100)/data.cumulatedDistinctPageCount) : 0)
                        lastRepartition[repartitionData.positionType].distinctPageCount = repartitionData.distinctPageCount
                    }
                })
            })

            let cardLineData = {labels:serieLabel,data: serieData,labelValue: lastValue}

            let tooltipFormatter = params => {
                let displayPageCount = ''
                params[0].data.expandedDistinctPageCounts.forEach(data=>{
                    if(data.positionType){
                        displayPageCount +=self.$t('common_google_block_label_'+data.positionType)+" : "+data.distinctPageCount+"<br>"
                    }
                })
                return `
                    <p>
                        ${params[0].axisValue}
                        <br>
                        <span class="toolTipColorSpan" style="background-color:${params[0].color}"></span>
                        ${params[0].seriesName} : ${this.$options.filters.formatNumber(params[0].value)}
                        <br>
                        ${displayPageCount}
                    </p>`
            }


            let formatedData = this.genericCardLineOptions(
                cardLineData,
                this.$t('component_websitePagesPositionedCountEvolution_series_name_1'),
                'primary',
                'axis',
                tooltipFormatter
            )
            formatedData.options.xAxis.show = true
            formatedData.options.grid.bottom = 20
            formatedData.options.grid.left = 30
            formatedData.options.grid.right = 30



            formatedData.ranks = lastRepartition
            formatedData.ranks = [
                lastRepartition["natural"],
                lastRepartition["adwords"],
                lastRepartition["video"],
                lastRepartition["map"],
                lastRepartition["snippet"],
                lastRepartition["shopping"],
                lastRepartition["actuality"],
                lastRepartition["image"]
            ]
            return formatedData
        },
        initComponentData(){
            this.getComponentData({
                componentName:'websitePagesPositionedCountEvolution',
                promise: !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readPositionedCountEvolution(this.$router.currentRoute.params.projectId,{params:{
                    'date-range-type':this.globalFilterFrequency,
                    'search-geolocation-id':this.globalFilterLocation
                }}) : false,
                dataFormatter: this.formatData
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
