
import axios from '@/api/axios.js'

export default {

    /**
     * @param projectId int
     * @param dateRange integer
     * @returns Promise
     */
    readCompletedReports (projectId, dateRange=1) {
        return axios.get(`/report/configuration/projects/${projectId}/reports/complete`,{params:{"date-range-type":dateRange}})
    },



};
