<template>

    <v-dialog v-model="show" content-class="popin-dialog-container" overlay-opacity="0.85">

        <v-icon
            class="close-popin-dialog"
            @click="show=false"
        >
            mdi-close
        </v-icon>
        <component-container
            :card-title="`${$t('component_progressionAxisImprovementPotentialKeywords_title')} ${elementName}`"
            :card-subtitle="`${$t('component_progressionAxisImprovementPotentialKeywords_subtitle')} ${elementName}`"

            :table-menu="{csv:true}"
            :table-density-selector="true"

            :component-data="{response:progressionAxisImprovementPotentialKeywords, hasData:progressionAxisImprovementPotentialKeywords.data}"

            @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"
        >

            <template v-slot:content>
                <ag-grid-table v-if="progressionAxisImprovementPotentialKeywords.data"
                               :with-fullscreen="false"
                               :show-pagination="false"
                               :show-floating-filter="false"
                               :table-data="{columnDefs:columnDefs,rowData:progressionAxisImprovementPotentialKeywords.data}"
                               ref="agGridTable"
                               class="mt-10"
                >
                </ag-grid-table>
            </template>

        </component-container>

    </v-dialog>


</template>


<script>

import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable'
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";

export default {
    data() {
        return {
            show:false,
            progressionAxisImprovementPotentialKeywords:{  isLoaded:false, error:null,data: null },
            elementName:null,
            popinStateName:'progressionAxisImprovementPotentialKeywords',
            columnDefs:[
                {headerName: this.$t('component_progressionAxisImprovementPotentialKeywords_table_header_keyword'), field: 'keyword'},
                {headerName: this.$t('component_progressionAxisImprovementPotentialKeywords_table_header_position'), field: 'position', width: 70, cellClass:'ag-grid-cell-center', headerClass:'ag-grid-header-center',comparator: cellSort.agGridTableSortCellGeneric},
                {headerName: this.$t('component_progressionAxisImprovementPotentialKeywords_table_header_searchCount'), field: 'searchCount',cellRendererFramework: 'CellRendererFormatedNumber', width: 70, cellClass:'ag-grid-cell-center', headerClass:'ag-grid-header-center',comparator: cellSort.agGridTableSortCellGeneric},
                {headerName: this.$t('component_progressionAxisImprovementPotentialKeywords_table_header_potentialGain'), field: 'potentialGain',sort:'desc', cellRendererFramework: 'CellRendererFormatedNumber', width: 70, cellClass:'ag-grid-cell-center', headerClass:'ag-grid-header-center',comparator: cellSort.agGridTableSortCellGeneric},
            ]
        }
    },
    components: {
        AgGridTable
    },
    computed:{

    },
    watch:{
        getShowPopin(){
            if(!this.show && this.getShowPopin){
                if(this.getShowPopin.componentName === this.popinStateName){
                    this.show = true
                    this.elementName = this.getShowPopin.elementName
                    this.getComponentData({
                        componentName:'progressionAxisImprovementPotentialKeywords',
                        promise: this.getShowPopin.promise(),
                        dataFormatter: this.formatData
                    })
                }
            }
        },
        show(){
            if(!this.show){
                this.showAgGridPopin(null)
            }
        }

    },
    methods:{

        formatData(jsonData){
            let rowData = []
            jsonData.content.forEach(data => {
                rowData.push({
                    keyword: data.string,
                    position: (data.rank>0?data.rank:'-'),
                    searchCount: data.searchCount !== null ? data.searchCount : '-',
                    potentialGain: (data.potentialTrafficGain?data.potentialTrafficGain:0)
                })
            })
            return rowData
        },

    },
}
</script>
