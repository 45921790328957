
<template>
    <component-container
        :height="320"

        :information-tooltip="{
            title : $t('component_trafficQualityTotalTurnover_informationTooltip_title'),
            text:$t('component_trafficQualityTotalTurnover_informationTooltip_text')
        }"

        :component-config="{
            hasConfig:analyticsViewConfiguration,
            hideOnNoConfig:hideOnNoConfig,
            redirectRoute:'analytics-project-configuration',
            placeholderTitle:$t('component_componentContainer_noAnalytics_title'),
            placeholderText:$t('component_componentContainer_noAnalytics_text')
        }"

        :component-data="{response:trafficQualityTotalTurnover, hasData:(trafficQualityTotalTurnover.data && trafficQualityTotalTurnover.data.options)}"

        :no-padding="true"
    >
        <template v-slot:content>
            <div v-if="trafficQualityTotalTurnover.data">
                <div class="v-application pl-5" style="display: inline-block">
                    <div class="py-3">
                        <yooda-icon name="shopping-cart" class="p-3" color="success" background="rounded" :size="26"></yooda-icon>
                    </div>
                    <h3 class="text-h3 font-weight-bold">{{ trafficQualityTotalTurnover.data.labelValue|formatNumber }} €</h3>
                    <p class="text-body-1 mb-0">{{$t('component_trafficQualityTotalTurnover_title')}} {{displayKpiLabel}}</p>
                </div>

                <yooda-chart
                    :options="trafficQualityTotalTurnover.data.options"
                    chart-type="line"
                    chart-style="height:150px;"
                />

                <v-row v-if="trafficQualityTotalTurnover.data.displayedDate">
                    <v-col cols="6" class="pl-6">{{trafficQualityTotalTurnover.data.displayedDate.start}}</v-col>
                    <v-col cols="6" class="text-right pr-6">{{trafficQualityTotalTurnover.data.displayedDate.end}}</v-col>
                </v-row>
            </div>
        </template>
    </component-container>
</template>

<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/traffic.js'
import { useComponentStore } from '@/stores/ComponentStore'
import {mapState} from "pinia";

export default {
    props: {
        hideOnNoConfig:{type: Boolean, default: false}
    },
    data() {
        return {
            trafficQualityTotalTurnover:null,
            displayKpiLabel:''
        }
    },
    components:{
        YoodaChart
    },
    computed:{
        ...mapState(useComponentStore, {
            globalFilterFrequency: 'getGlobalFilterFrequency',
        }),
    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData()
        }
    },
    methods:{
        formatData(jsonData){

            if(!jsonData.content.turnovers){return {}}
            let tooltipFormatter = params => {
                return `
                    <p>
                        ${this.globalFilterFrequency !== "1" ? '<p>'+params[0].data.comparisonDates+'<br>' : '<p>'+params[0].axisValue+'<br>'}
                        <span class="toolTipColorSpan" style="background-color:${params[0].color}"></span>
                        ${params[0].seriesName} : ${params[0].value}
                    </p>`
            }

            let serieLabel = []
            let serieData = []
            jsonData.content.turnovers.forEach((data)=>{
                serieLabel.push(this.$options.filters.displayDateLocale(data.endDate))
                serieData.push({
                    date:this.$options.filters.displayDateLocale(data.endDate),
                    value:data.turnover,
                    comparisonDates: this.$t('common_tooltip_date_comparison').replace('[*STARTDATE*]',this.$options.filters.displayDateLocale(data.startDate)).replace('[*ENDDATE*]', this.$options.filters.displayDateLocale(data.endDate)),
                })
            })
            let cardLineData = {
                labels:serieLabel,
                data: serieData,
                labelValue: jsonData.content.currentTurnover
            }

            let dataFormated = this.genericCardLineOptions(
                cardLineData,
                this.$t('component_trafficQualityTotalTurnover_label'),
                'success',
                'axis',
                tooltipFormatter
            )

            let displayedDate = { start: '', end:'' }
            if(serieLabel[0]){displayedDate.start = serieLabel[0]}
            if(serieLabel.slice(-1)[0]){displayedDate.end = serieLabel.slice(-1)[0]}

            if(jsonData.context.inputs){
                this.displayKpiLabel = this.$t(`common_periodPicker_kpiLabel_${jsonData.context.inputs.dateRangeType}`)
            }

            return {displayedDate:displayedDate, ...dataFormated}
        },
        initComponentData(){
            this.getComponentData({
                componentName:'trafficQualityTotalTurnover',
                promise: (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  && this.analyticsViewConfiguration) ? apiCalls.readQualityTotalTurnover(this.$router.currentRoute.params.projectId,{params:{'date-range-type':this.globalFilterFrequency}}) : false,
                dataFormatter: this.formatData
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
