<template>

    <component-container
        :card-title="$t('component_localVisibilityByLocation_title')"
        :card-subtitle="$t('component_localVisibilityByLocation_subtitle')"

        :height="(searchGeolocationsConfiguration.filter(data=> data.targetType !== 'country').length ? 495 : 471)"

        :information-tooltip="{
            title : $t('component_localVisibilityByLocation_informationTooltip_title'),
            text:$t('component_localVisibilityByLocation_informationTooltip_text')
        }"

        :component-config="{
            hasConfig: searchGeolocationsConfiguration.filter(data=> data.targetType !== 'country').length,
            hideOnNoConfig:hideOnNoConfig,
            redirectRoute: displayPlaceholderConfigButton ? 'keywords-configuration' : null,
            placeholderLocation: true,
            placeholderTitle: $t('component_componentContainer_noLocation_title'),
            placeholderText: $t('component_componentContainer_noLocation_text')
        }"

        :component-data="{response:localVisibilityByLocation, hasData:(localVisibilityByLocation.data && localVisibilityByLocation.data.series)}"
    >
        <template v-slot:content>
            <yooda-chart
                :options="localVisibilityByLocation.data"
                chart-type="bar"
                chart-style="height:350px;"
            />
            <div class="v-application" v-if="displayLinkButton">
                <v-btn
                    outlined
                    :to="{name:'local_visibility'}"
                    color="primary"
                    style="width: calc(100% - 35px); position: absolute; bottom: 18px"
                >
                    {{ $t('component_localVisibilityByLocation_detailButton') }}<v-icon right>mdi-chevron-double-right</v-icon>
                </v-btn>
            </div>
        </template>
    </component-container>
</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/dashboard.js'

export default {
    props: {
        displayLinkButton:{type: Boolean, default: false},
        displayPlaceholderConfigButton:{type: Boolean, default: true},
        hideOnNoConfig:{type: Boolean, default: false}
    },
    data() {
        return {
            localVisibilityByLocation:null
        }
    },
    components: {
        YoodaChart
    },
    computed:{

    },
    methods:{
        formatData(jsonData){
            if(jsonData.content.length === 0){ return {} }
            let seriesData = []

            let tooltipFormatter = params => {
                return `
                    <p>
                        <span class="toolTipColorSpan" style="background-color:${params.color}"></span>
                        ${params.seriesName} : ${this.$options.filters.formatNumber(params.value)}
                    </p>`
            }

            let labelFormatter = params => { return  params.seriesName+': '+this.$options.filters.formatNumber(params.value) }

            jsonData.content.forEach((data)=>{
                let serie = {
                    type: 'bar',
                    name:data.name,
                    data: [data.cumulatedVisibility],
                    showBackground: true,
                    itemStyle: { barBorderRadius: [0,10,10,0], color:this.getThemeColorByName('primary',0.6) },
                    emphasis:{ itemStyle: { color:this.getThemeColorByName('primary') } },
                    label: { show: true, position: 'insideLeft', distance:20,formatter:labelFormatter, fontWeight:600 },
                }
                seriesData.push(serie)
            })

            return {
                grid: { left: 0, top: 15, right: 0, bottom: 10 },
                tooltip: this.genericTooltipConfiguration('item', tooltipFormatter),
                xAxis: { type: 'value', show: false },
                yAxis: { show: true,  type: 'category', position:'left',axisTicks: {show: false} },
                series: seriesData
            }
        },
        initComponentData(){
            this.getComponentData({
                componentName:'localVisibilityByLocation',
                promise:(!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  && this.searchGeolocationsConfiguration) ? apiCalls.readByLocation(this.$router.currentRoute.params.projectId) : false,
                dataFormatter: this.formatData,
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
