<template>
    <component-container
        :card-title="$t('component_linkingAuthorityCompare_title')"
        :card-subtitle="$t('component_linkingAuthorityCompare_subtitle')"

        :height="630"
        :information-tooltip="{
            title : $t('component_linkingAuthorityCompare_informationTooltip_title'),
            text:$t('component_linkingAuthorityCompare_informationTooltip_text')
        }"

        :component-data="{response:linkingAuthorityCompare, hasData:linkingAuthorityCompare.data && linkingAuthorityCompare.data.hasData, noDataMessage:noDataMessage}"

    >

        <template v-slot:filters>
            <v-row class="mt-1 v-application">
                <v-col :cols="5">
                    <v-autocomplete
                        v-model="selectedCompetitor"
                        :items="competitorsList"
                        outlined
                        prepend-inner-icon="mdi-web"
                        dense
                        hide-details
                        item-text="name"
                        item-value="name"
                        class="header-nav-select"
                        :no-data-text="$t('component_linkingAuthorityCompare_noSearchResult')"
                        @change="initComponentData"
                    >

                        <template v-slot:item="data">
                            <template>
                                <v-list-item-content>
                                    <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
        </template>

        <template v-slot:content>
            <v-row>
                <v-col  :cols="12" class="text-center">
                    <yooda-chart
                        :options="linkingAuthorityCompare.data"
                        chart-type="radar"
                        chart-style="height:450px"
                    />
                </v-col>
            </v-row>
        </template>
    </component-container>
</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/linking.js'
import ComponentFilters from "@/components/yooda-components/ComponentFilters";
import { useComponentStore } from '@/stores/ComponentStore'
import {mapState} from "pinia";

export default {
    data() {
        return {
            initComplete:false,
            linkingAuthorityCompare:null,
            competitorsList:[],
            selectedCompetitor:null,
            noDataMessage: ''
        }
    },
    components: {
        YoodaChart,
        ComponentFilters,
    },
    computed:{
        ...mapState(useComponentStore, {
            rivalsConfiguration: 'getRivalsConfiguration',
            globalFilterFrequency: 'getGlobalFilterFrequency',
        }),
    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData()
        }
    },
    methods:{

        explodeTopic(topic){
            let parsedTopic = {
                topLevel:'',
                other:''
            }

            let splited = topic.split('/')
            if(splited.length >0){
                let otherTopic = []
                splited.forEach((data, index)=>{
                    if(index === 0){
                        parsedTopic.topLevel = data
                    }
                    else{
                        otherTopic.push(data)
                    }
                })
                parsedTopic.other = otherTopic.join(' / ')
            }
            return parsedTopic
        },

        formatData(jsonData){

            let trackedCompetitors = []
            let otherCompetitor = []
            jsonData.context.computed.competitors.forEach((competitor)=>{
                if(this.rivalsConfiguration.map((rival)=>{return rival.url}).includes(competitor)){
                    trackedCompetitors.push(competitor)
                }
                else{
                    otherCompetitor.push(competitor)
                }
            })

            this.competitorsList = []
            this.competitorsList.push({ header: this.$t('component_linkingAuthorityCompare_rivalLabel') })
            trackedCompetitors.forEach((competitor)=>{
                this.competitorsList.push({name:competitor, group:this.$t('component_linkingAuthorityCompare_rivalLabel')})
            })
            this.competitorsList.push({ divider: true })

            this.competitorsList.push({ header: this.$t('component_linkingAuthorityCompare_competitorLabel') })
            otherCompetitor.forEach((competitor)=>{
                this.competitorsList.push({name:competitor, group:this.$t('component_linkingAuthorityCompare_competitorLabel')})
            })

            this.competitorsList.forEach((data)=>{
                if(data.name && data.name === jsonData.content.competitorDomainName){
                    this.selectedCompetitor = {name:data.name}
                }
            })


            // this.selectedCompetitor = jsonData.content.competitorDomainName

            let tooltipFormatter = params => {

                let toolTip = `<div class="text-left">
                                  <p class="text-body-1 mb-0 text-center"><strong class="d-block mb-2">${params.name}</strong></p>
                                  <ul style="list-style: disc; padding: 0 0 0 12px">`
                params.data.value.forEach((param,index)=>{
                    let topic = this.explodeTopic(params.data.tooltipValue[index].name)
                    toolTip += `<li class="mb-1"><strong>${topic.topLevel}</strong> / ${topic.other} : ${this.$options.filters.formatNumber(param)}</li>`
                })
                toolTip +=`       </ul>
                                </div>`

                return  toolTip
            }

            let maxIndicatorValue = 0
            jsonData.content.topicals.forEach((data)=>{
                if(data.competitorAuthority > maxIndicatorValue){maxIndicatorValue = data.competitorAuthority}
                if(data.projectAuthority > maxIndicatorValue){maxIndicatorValue = data.projectAuthority}
            })

            let hasData = true
            if(jsonData.content.topicals.length === 0){
                this.noDataMessage = this.$t('component_linkingAuthorityCompare_noData_text')
                hasData = false
            }else{
                if(jsonData.content.topicals.length <=2){
                    this.noDataMessage = this.$t('component_linkingAuthorityCompare_noEnoughData_text')
                    hasData = false
                }
            }

            if(!hasData){
                return {}
            }

            return {
                hasData: maxIndicatorValue > 0,
                tooltip: this.genericTooltipConfiguration('item', tooltipFormatter),
                legend: {
                    data: [jsonData.content.projectDomainName,jsonData.content.competitorDomainName],
                    orient: 'vertical',
                    left: -5,
                    bottom:2
                },
                radar: {
                    splitArea:{ show:false },
                    splitLine:{ lineStyle:{ type:'dotted' } },
                    name: {
                        formatter:function(value, indicator){
                            if(indicator.otherThematic){
                                return `{a|${value}}\r\n{b|${indicator.otherThematic}}`
                            }
                            return `{a|${value}}`



                        },
                        textStyle: {
                            color: '#5F5F6E',
                            backgroundColor: '#fff',
                            borderRadius: 6,
                            padding: [3, 5],
                            borderWidth:1,
                            borderColor:'#bbbbbb',
                            lineHeight:16,
                            rich:{
                                a:{
                                    fontWeight:'bold',
                                    align:'center'
                                },
                                b:{
                                    align:'center'
                                },
                            }
                        }
                    },
                    indicator:  jsonData.content.topicals.map((data)=>{
                        let topics = this.explodeTopic(data.topic)
                        return {
                            name:topics.topLevel,
                            otherThematic: topics.other,
                            max: maxIndicatorValue
                        }
                    })
                },
                series: [
                    {
                        type: 'radar',
                        areaStyle: { opacity:0.2 },
                        data: [
                            {
                                tooltipValue: jsonData.content.topicals.map((data)=>{ return {name:data.topic, value:data.projectAuthority}}),
                                value: jsonData.content.topicals.map((data)=>{ return data.projectAuthority}),
                                name: jsonData.content.projectDomainName,
                                itemStyle:{ color:this.getThemeColorByName('primary') }
                            },
                            {
                                tooltipValue: jsonData.content.topicals.map((data)=>{ return {name:data.topic, value:data.competitorAuthority}}),
                                value: jsonData.content.topicals.map((data)=>{ return data.competitorAuthority}),
                                name: jsonData.content.competitorDomainName,
                                itemStyle:{ color:this.getThemeColorByName('linkingLinkCompetitorCount') }
                            }
                        ]
                    }
                ]
            }
        },
        initComponentData(){
            let filters = {'date-range-type':this.globalFilterFrequency}
            if(this.selectedCompetitor){
                filters['domain-name'] = this.selectedCompetitor
            }
            this.getComponentData({
                componentName:'linkingAuthorityCompare',
                promise: (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId) ? apiCalls.readAuthorityCompare(this.$router.currentRoute.params.projectId,{params:filters}) : false,
                dataFormatter: this.formatData
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>

