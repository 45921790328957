var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{attrs:{"card-title":_vm.$t('component_marketSegmentCompetitorCompare_title'),"card-subtitle":_vm.$t('component_marketSegmentCompetitorCompare_subtitle'),"height":(_vm.filterOpen ? 595 : 495),"information-tooltip":{
        title : _vm.$t('component_marketSegmentCompetitorCompare_informationTooltip_title'),
        text:_vm.$t('component_marketSegmentCompetitorCompare_informationTooltip_text')
    },"component-config":{
        hasConfig:_vm.keywordGroupsConfiguration.length,
        hideOnNoConfig:_vm.hideOnNoConfig,
        redirectRoute:_vm.displayPlaceholderConfigButton ? 'segments-configuration' : null,
        placeholderTitle:_vm.$t('component_componentContainer_noMarketSegment_title'),
        placeholderText:_vm.$t('component_componentContainer_noMarketSegment_text')
    },"component-data":{
        response: _vm.marketSegmentCompetitorCompare,
        hasData: (_vm.marketSegmentCompetitorCompare.data && _vm.marketSegmentCompetitorCompare.data.series)
    }},scopedSlots:_vm._u([{key:"filters",fn:function(){return [(!_vm.projectCreationWaiting)?_c('component-filters',{attrs:{"open-default":_vm.filterOpen,"compare-two-domains":true,"default-select-value":_vm.lastFilters},on:{"applyFilter":_vm.initComponentData,"filterPanelStatus":_vm.displayFilterOpen}}):_vm._e()]},proxy:true},{key:"content",fn:function(){return [_c('yooda-chart',{attrs:{"options":_vm.marketSegmentCompetitorCompare.data,"chart-type":"radar","chart-style":"height:360px;margin-bottom: -28px"}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }