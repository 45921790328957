var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{attrs:{"card-title":_vm.$t('component_localVisibilityCompetitor_title'),"card-subtitle":_vm.$t('component_localVisibilityCompetitor_subtitle'),"height":(_vm.filterOpen ? 625 : 525),"information-tooltip":{
        title : _vm.$t('component_localVisibilityCompetitor_informationTooltip_title'),
        text:_vm.$t('component_localVisibilityCompetitor_informationTooltip_text')
    },"component-config":{
        hasConfig: _vm.searchGeolocationsConfiguration.filter(data=> data.targetType !== 'country').length,
        hideOnNoConfig:_vm.hideOnNoConfig,
        redirectRoute: 'keywords-configuration',
        placeholderLocation: true,
        placeholderTitle: _vm.$t('component_componentContainer_noLocation_title'),
        placeholderText: _vm.$t('component_componentContainer_noLocation_text')
    },"component-data":{response:_vm.localVisibilityCompetitor, hasData:_vm.localVisibilityCompetitor.data}},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('component-filters',{attrs:{"market-segments":true,"result-type":true,"default-select-value":_vm.lastFilters},on:{"applyFilter":_vm.initComponentData,"filterPanelStatus":_vm.displayFilterOpen}})]},proxy:true},{key:"content",fn:function(){return [_c('yooda-chart',{attrs:{"options":_vm.localVisibilityCompetitor.data,"chart-type":"bar"}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }