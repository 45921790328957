<template>
    <v-dialog v-model="show" content-class="popin-dialog-container" overlay-opacity="0.85">

        <v-icon
            class="close-popin-dialog"
            @click="show=false"
        >
            mdi-close
        </v-icon>
        <component-container
            :card-title="`${$t('component_advertisingAdwordsKeywordsAds_title')} ${elementName}`"
            :card-subtitle="$t('component_advertisingAdwordsKeywordsAds_subtitle')"

            :table-menu="{csv:true}"

            :component-data="{response:advertisingAdwordsKeywordsAds, hasData:advertisingAdwordsKeywordsAds.data}"

            @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"
        >
            <template v-slot:content>
                <ag-grid-table v-if="advertisingAdwordsKeywordsAds.data"
                   :with-fullscreen="false"
                   :show-pagination="false"
                   :show-floating-filter="false"
                   :table-data="{columnDefs:columnDefs,rowData:advertisingAdwordsKeywordsAds.data}"
                   :ag-grid-options="{ rowHeight: 160 }"
                   class="mt-10"
                    ref="agGridTable"
                />
            </template>
        </component-container>
    </v-dialog>
</template>


<script>

import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable'


export default {
    data() {
        return {
            show:false,
            advertisingAdwordsKeywordsAds:{  isLoaded:false, error:null,data: null },
            elementName:null,
            popinStateName:'advertisingAdwordsKeywordsAds',
            columnDefs:[
                {headerName: this.$t('component_advertisingAdwordsKeywordsAds_table_header_rank'), field: 'rank',  sort:'asc', width:80, cellRendererFramework: 'CellRendererFormatedNumber',cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},
                {headerName: this.$t('component_advertisingAdwordsKeywordsAds_table_header_ad'), field: 'ad', sortable: false, width:600, cellRendererFramework: 'CellRendererAdwordAd'},
            ]
        }
    },
    components: {
        AgGridTable
    },
    watch:{
        getShowPopin(){
            if(!this.show && this.getShowPopin){
                if(this.getShowPopin.componentName === this.popinStateName){
                    this.show = true
                    this.elementName = this.getShowPopin.elementName
                    this.getComponentData({
                        componentName:'advertisingAdwordsKeywordsAds',
                        promise: this.getShowPopin.promise(),
                        dataFormatter: this.formatData
                    })
                }
            }
        },
        show(){
            if(!this.show){
                this.showAgGridPopin(null)
            }
        }
    },


    methods:{
        formatData(jsonData){
            let rowData = []
            jsonData.content.forEach(data => {
                rowData.push({
                    rank: data.rank,
                    ad: {...data.info, ...{url:data.url, title:data.title, isAdwordsRenderCell:true}},
                })
            })
            return rowData
        }
    },
}
</script>
