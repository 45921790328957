<template>


    <component-container
        :card-title="$t('component_seoVisibilityPresenceEvolution_title')"

        :height="(displayLinkButton ? 495 : 400)"

        :information-tooltip="{
            title : $t('component_seoVisibilityPresenceEvolution_informationTooltip_title'),
            text:$t('component_seoVisibilityPresenceEvolution_informationTooltip_text')
        }"

        :component-data="{
            response:seoVisibilityPresenceEvolution,
            hasData:(seoVisibilityPresenceEvolution.data && seoVisibilityPresenceEvolution.data.options)
        }"
        :no-padding="true"
    >
        <template v-slot:content>
            <div v-if="seoVisibilityPresenceEvolution.data">
                <div class="v-application px-5 mt-8">
                    <h3 class="text-h3 font-weight-bold">{{seoVisibilityPresenceEvolution.data.labelValue}}%</h3>
                    <p class="text-body-1 mb-16" v-if="seoVisibilityPresenceEvolution.data.displayedDate">{{$t('component_seoVisibilityPresenceEvolution_legend')}} ({{seoVisibilityPresenceEvolution.data.displayedDate.end}})</p>
                </div>

                <yooda-chart
                    :options="seoVisibilityPresenceEvolution.data.options"
                    chart-type="line"
                    :chart-style="(displayLinkButton ? 'height:190px;' : 'height:150px;')"
                />

                <v-row v-if="seoVisibilityPresenceEvolution.data.displayedDate">
                    <v-col cols="6" class="pl-6">
                        {{seoVisibilityPresenceEvolution.data.displayedDate.start}}
                    </v-col>
                    <v-col cols="6" class="text-right pr-6">
                        {{seoVisibilityPresenceEvolution.data.displayedDate.end}}
                    </v-col>
                </v-row>


                <div class="v-application" v-if="displayLinkButton">
                    <v-btn
                        outlined
                        :to="{name:'seo_visibility'}"
                        color="primary"
                        style="width: calc(100% - 35px); position: absolute; bottom: 18px; left:18px"
                    >
                        {{ $t('component_seoVisibilityPresenceEvolution_detailButton') }}<v-icon right>mdi-chevron-double-right</v-icon>
                    </v-btn>
                </div>

            </div>
        </template>

    </component-container>

</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/seoVisbility.js'

export default {
    props: {
        displayLinkButton:{ type: Boolean, default: false}
    },
    data() {
        return {
            seoVisibilityPresenceEvolution:null
        }
    },
    components: {
        YoodaChart
    },
    computed:{
    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData()
        },
        globalFilterLocation(){
            this.initComponentData()
        }
    },
    methods:{
        formatData(jsonData){
            if(jsonData.content.length === 0){ return {} }
            let tooltipLabel = this.$t('component_seoVisibilityPresenceEvolution_label')
            let tooltipFormatter = params => {
                return `
                    <p>
                        ${params[0].axisValue}
                        <br>
                        <span class="toolTipColorSpan" style="background-color:${params[0].color}"></span>
                        ${params[0].seriesName} :
                        <br>
                        ${this.$options.filters.formatNumber(params[0].data.distinctRankedKeywordCount)} ${tooltipLabel} (${params[0].value}%)
                    </p>`
            }

            let serieLabel = []
            let serieData = []
            let lastValue = 0

            jsonData.content.forEach((data)=>{
                let percentValue = 0
                if(data.distinctTotalKeywordCount >0){
                    percentValue = Math.round((data.distinctRankedKeywordCount*100)/data.distinctTotalKeywordCount)
                }
                serieLabel.push(this.$options.filters.displayDateLocale(data.date))
                serieData.push({date:this.$options.filters.displayDateLocale(data.date), value:percentValue, distinctRankedKeywordCount:data.distinctRankedKeywordCount})
                lastValue = percentValue
            })
            let cardLineData = {
                labels:serieLabel,
                data: serieData,
                labelValue: lastValue
            }



            let dataFormated = this.genericCardLineOptions(
                cardLineData,
                this.$t('component_seoVisibilityPresenceEvolution_series_name'),
                'primary',
                'axis',
                tooltipFormatter
            )

            let displayedDate = { start: '', end:'' }
            if(serieLabel[0]){displayedDate.start = serieLabel[0]}
            if(serieLabel.slice(-1)[0]){displayedDate.end = serieLabel.slice(-1)[0]}

            return {displayedDate:displayedDate, ...dataFormated}
        },
        initComponentData(){
            this.getComponentData({
                componentName:'seoVisibilityPresenceEvolution',
                promise:!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readPresenceEvolution(this.$router.currentRoute.params.projectId, {params:{
                        'date-range-type':this.globalFilterFrequency,
                        'search-geolocation-id':this.globalFilterLocation
                }}) : false,
                dataFormatter: this.formatData,
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
