<template>
    <div>
        <component-container
            :card-title="$t('component_linkingAnchors_title')"
            :card-subtitle="$t('component_linkingAnchors_subtitle')"

            :custom-style="`padding-top:15px;border:none !important;${displayNoBorder ? 'border-radius:0!important;': 'box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.15) !important;'}`"

            :no-shadow="true"
            :information-tooltip="{
                title : $t('component_linkingAnchorBacklinks_informationTooltip_title'),
                text:$t('component_linkingAnchorBacklinks_informationTooltip_text')
            }"

            :table-menu="{csv:true, fullscreen:true}"
            :table-density-selector="true"

            :component-data="{response:linkingAnchorBacklinks, hasData:linkingAnchorBacklinks.data, noDataMessage:$t('component_linkingMyThematicAuthority_no_data_text')}"

            @setTableMenuFullscreen="cardContainerFullscreen = !cardContainerFullscreen"
            @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"
        >
            <template v-slot:filters>
                <linking-thematic-selector class="mb-8" :selected-thematic-name="selectedThematic" @selectValue="selectThematic"/>
            </template>

            <template v-slot:content>
                <ag-grid-table
                    :show-floating-filter="false"
                    :table-data="{columnDefs:columnDefs,rowData:linkingAnchorBacklinks.data}"
                    :agGridStyle="`height:${tableHeightByRowCount(linkingAnchorBacklinks.data)}px;`"
                    :is-card-container-fullscreen="cardContainerFullscreen"
                    ref="agGridTable"
                />
            </template>

        </component-container>

        <linking-anchors-links/>
        <linking-anchors-domains/>
    </div>

</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/linking.js'
import { useComponentStore } from '@/stores/ComponentStore'
import {mapState} from "pinia";
import AgGridTable from "@/components/yooda-components/ag-grid-table/AgGridTable.vue";
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";
import LinkingThematicSelector from "@/components/yooda-components/app/linking/LinkingThematicSelector.vue";
import LinkingAnchorsLinks from "@/components/yooda-components/app/linking/popin/LinkingAnchorsLinks.vue";
import LinkingAnchorsDomains from "@/components/yooda-components/app/linking/popin/LinkingAnchorsDomains.vue";

export default {
    data() {
        return {
            linkingAnchorBacklinks:null,

            selectedThematic:this.$t('linking_thematic_label_all'),
            cardContainerFullscreen:false,
            columnDefs:[
                {headerName: this.$t('component_linkingAnchorBacklinks_table_header_anchor'),filter: true, field: 'anchor', width: 120, cellRendererFramework: 'CellRendererTextEllipsis',headerTooltip: this.$t('component_linkingAnchorBacklinks_table_header_anchor_tooltip')},
                {headerName: this.$t('component_linkingAnchorBacklinks_table_header_popularity'),filter: false, field: 'popularity', sortingOrder: ['desc','asc'], cellRendererFramework: 'CellRendererLinkingInfoChips', cellClass:'ag-grid-cell-left', width: 85, headerTooltip: this.$t('component_linkingAnchorBacklinks_table_header_popularity_tooltip'), comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_linkingAnchorBacklinks_table_header_authority'),filter: false, field: 'authority',  sort:'desc', sortingOrder: ['desc','asc'], cellRendererFramework: 'CellRendererLinkingInfoChips',cellClass:'ag-grid-cell-left', width: 90, headerTooltip: this.$t('component_linkingAnchorBacklinks_table_header_popularity_tooltip'), comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_linkingAnchorBacklinks_table_header_thematic'),filter: false, field: 'thematic',  sortingOrder: ['desc','asc'], cellRendererFramework: 'CellRendererLinkingThematic',headerTooltip: this.$t('component_linkingAnchorBacklinks_table_header_thematic_tooltip'), comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_linkingAnchorBacklinks_table_header_linkCount'),filter: false, field: 'linkCount', sortingOrder: ['desc','asc'], cellRendererFramework: 'CellRendererPopinTableLink', width: 60, cellClass:'ag-grid-cell-right',headerTooltip: this.$t('component_linkingAnchorBacklinks_table_header_linkCount_tooltip'), headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_linkingAnchorBacklinks_table_header_referralCount'),filter: false, field: 'referralCount', sortingOrder: ['desc','asc'], cellRendererFramework: 'CellRendererPopinTableLink', width: 60, cellClass:'ag-grid-cell-right',headerTooltip: this.$t('component_linkingAnchorBacklinks_table_header_referralCount_tooltip'), headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellValue},
            ]
        }
    },
    components: {
        AgGridTable,
        LinkingThematicSelector,
        LinkingAnchorsLinks,
        LinkingAnchorsDomains,
        YoodaChart
    },
    computed:{
        ...mapState(useComponentStore, {
            globalFilterFrequency: 'getGlobalFilterFrequency',
        }),
        displayNoBorder(){
            return this.$parent.$options._componentTag !== 'custom-report-model' && !this.cardContainerFullscreen
        }
    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData()
        }
    },
    methods:{

        selectThematic(selectThematic){
            this.selectedThematic = selectThematic
            this.initComponentData()
        },

        formatData(jsonData){
            if(jsonData.content.length === 0){return null}

            let rowData = []
            jsonData.content.forEach(data => {
                let thematicData = this.getThematicData(data)

                let filters = {
                    params: {
                        'date-range-type':this.globalFilterFrequency,
                        "search-geolocation-id": this.globalFilterLocation
                    }
                }

                rowData.push({
                    anchor:data.text ? data.text : this.$t('component_linkingBestBacklinks_no_anchor_text'),


                    linkCount:(data.linkCount ? {
                        componentName:!this.isReportingRoute ? 'linkingAnchorsLinks' : null,
                        value:data.linkCount,
                        displayedValue:this.$options.filters.formatNumber(data.linkCount),
                        promise: () => !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ?  apiCalls.readAnchorBacklinksPopinLink(this.$router.currentRoute.params.projectId, data.id, filters) : false,
                        elementName:data.text
                    } :  {value:0}),

                    referralCount:(data.refDomainCount ? {
                        componentName:!this.isReportingRoute ? 'linkingAnchorsDomains' : null,
                        value:data.refDomainCount,
                        displayedValue:this.$options.filters.formatNumber(data.refDomainCount),
                        promise: () => !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ?  apiCalls.readAnchorBacklinksPopinDomains(this.$router.currentRoute.params.projectId, data.id, filters) : false,
                        elementName:data.text
                    } :  {value:0}),



                    popularity: { value: data.popularity, color:this.getThemeColorByName('primary') },
                    authority: { value: data.authority, color:this.getThemeColorByName('primary') },
                    thematic:{
                        value:thematicData.mainThematic.value,
                        color:thematicData.mainThematic.color,
                        categories:thematicData.mainThematic.categories,
                        otherThematic:thematicData.otherThematic
                    },
                })
            })


            return rowData
        },
        initComponentData(){

            let filters = {'date-range-type':this.globalFilterFrequency}
            if(this.selectedThematic !== this.$t('linking_thematic_label_all')){
                filters['thematic'] = this.selectedThematic
            }

            this.getComponentData({
                componentName:'linkingAnchorBacklinks',
                promise: (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId ) ? apiCalls.readAnchorBacklinks(this.$router.currentRoute.params.projectId,{params:filters}) : false,
                dataFormatter: this.formatData
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
