<template>

    <component-container
        :class="{filterOpen:filterOpen}"
        :card-title="$t('component_localVisibilityMyBusinessKeywordsPositions_title')"
        :card-subtitle="$t('component_localVisibilityMyBusinessKeywordsPositions_subtitle')"

        :information-tooltip="{
            title : $t('component_localVisibilityMyBusinessKeywordsPositions_informationTooltip_title'),
            text:$t('component_localVisibilityMyBusinessKeywordsPositions_informationTooltip_text')
        }"

        :table-menu="{csv:true, fullscreen:true}"
        :table-density-selector="true"

        :component-config="{
            hasConfig: searchGeolocationsConfiguration.filter(data=> data.targetType !== 'country').length,
            hideOnNoConfig:hideOnNoConfig,
            redirectRoute: 'keywords-configuration',
            placeholderLocation: true,
            placeholderTitle: $t('component_componentContainer_noLocation_title'),
            placeholderText: $t('component_componentContainer_noLocation_text')
        }"

        :component-data="{response:localVisibilityMyBusinessKeywordsPositions, hasData:localVisibilityMyBusinessKeywordsPositions.data}"

        @setTableMenuFullscreen="cardContainerFullscreen = !cardContainerFullscreen"
        @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"
    >
        <template v-slot:filters>
            <component-filters
                :market-segments="true"

                :competitors="true"
                :competitors-with-project-website="true"
                :competitors-with-select-all="false"
                @applyFilter="initComponentData"
                @filterPanelStatus="displayFilterOpen"
                :default-select-value="lastFilters"
            />
        </template>

        <template v-slot:content>
            <ag-grid-table
                :show-floating-filter="false"
                :table-data="{columnDefs:columnDefs,rowData:localVisibilityMyBusinessKeywordsPositions.data}"
                :agGridStyle="`height:${tableHeightByRowCount(localVisibilityMyBusinessKeywordsPositions.data)}px;`"
                :is-card-container-fullscreen="cardContainerFullscreen"
                ref="agGridTable"
            />
        </template>
    </component-container>
</template>


<script>

import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable'
import apiCalls from '@/api/axios/dataReport/localVisbility.js'
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";
import ComponentFilters from "@/components/yooda-components/ComponentFilters";

export default {
    props: {
        hideOnNoConfig:{type: Boolean, default: false}
    },
    data() {
        return {
            localVisibilityMyBusinessKeywordsPositions:null,
            filterOpen:false,
            cardContainerFullscreen:false,
            lastFilters:null,
            columnDefs:[
                {headerName: this.$t('component_localVisibilityMyBusinessKeywordsPositions_table_header_keyword'),filter: true, field: 'keyword'},
                {headerName: this.$t('component_localVisibilityMyBusinessKeywordsPositions_table_header_position'), field: 'position', sort:'asc', width: 90, cellClass:'ag-grid-cell-right', cellRendererFramework: 'CellRendererFormatedNumber',headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellGeneric},
                {headerName: this.$t('component_localVisibilityMyBusinessKeywordsPositions_table_header_geoloc'), field: 'geoloc'},
                {headerName: this.$t('component_localVisibilityMyBusinessKeywordsPositions_table_header_website'), field: 'website'},
            ]
        }
    },
    components: {
        AgGridTable,
        ComponentFilters,
    },
    computed:{

    },
    watch:{
        globalFilterLocation(){
            this.initComponentData(this.lastFilters)
        }
    },
    methods:{
        displayFilterOpen(status){ this.filterOpen = status === 'open'; },
        formatData(jsonData){
            if(jsonData.content.length === 0){return []}
            let rowData = []
            jsonData.content.forEach(data => {

                rowData.push({
                    keyword: data.string,
                    position: data.rank !== null ? data.rank : '-',
                    geoloc: data.name,
                    website: data.domainName
                })
            })
            return rowData
        },
        initComponentData(filters){
            filters.params['search-geolocation-id'] = this.globalFilterLocation
            this.getComponentData({
                componentName:'localVisibilityMyBusinessKeywordsPositions',
                promise: !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readMyBusinessKeywordsPositions(this.$router.currentRoute.params.projectId,filters) : false,
                dataFormatter: this.formatData
            })
            this.lastFilters = filters
        },
    },
    beforeMount() {
         this.initComponentData({params:{"domain-name":this.projectUrl}})
    },

}
</script>
