var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{attrs:{"card-title":_vm.$t('component_competitionNewCompetitor_title'),"card-subtitle":_vm.$t('component_competitionNewCompetitor_subtitle'),"height":(!_vm.projectCreationWaiting ? 495 : 471),"information-tooltip":{
        title : _vm.$t('component_competitionNewCompetitor_informationTooltip_title'),
        text:_vm.$t('component_competitionNewCompetitor_informationTooltip_text')
    },"component-data":{
        response:_vm.competitionNewCompetitor,
        hasData:(_vm.competitionNewCompetitor.data && _vm.competitionNewCompetitor.data.length > 0),
        noDataMessage:_vm.$t('component_competitionNewCompetitor_noDataMessage')
    }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',_vm._l((_vm.competitionNewCompetitor.data),function(rank,index){return _c('div',{key:index,staticClass:"mt-8"},[_c('competition-rank-progress-bar',{attrs:{"rank":rank}})],1)}),0)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }