var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{attrs:{"card-title":_vm.$t('component_localVisibilityByLocation_title'),"card-subtitle":_vm.$t('component_localVisibilityByLocation_subtitle'),"height":(_vm.searchGeolocationsConfiguration.filter(data=> data.targetType !== 'country').length ? 495 : 471),"information-tooltip":{
        title : _vm.$t('component_localVisibilityByLocation_informationTooltip_title'),
        text:_vm.$t('component_localVisibilityByLocation_informationTooltip_text')
    },"component-config":{
        hasConfig: _vm.searchGeolocationsConfiguration.filter(data=> data.targetType !== 'country').length,
        hideOnNoConfig:_vm.hideOnNoConfig,
        redirectRoute: _vm.displayPlaceholderConfigButton ? 'keywords-configuration' : null,
        placeholderLocation: true,
        placeholderTitle: _vm.$t('component_componentContainer_noLocation_title'),
        placeholderText: _vm.$t('component_componentContainer_noLocation_text')
    },"component-data":{response:_vm.localVisibilityByLocation, hasData:(_vm.localVisibilityByLocation.data && _vm.localVisibilityByLocation.data.series)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('yooda-chart',{attrs:{"options":_vm.localVisibilityByLocation.data,"chart-type":"bar","chart-style":"height:350px;"}}),(_vm.displayLinkButton)?_c('div',{staticClass:"v-application"},[_c('v-btn',{staticStyle:{"width":"calc(100% - 35px)","position":"absolute","bottom":"18px"},attrs:{"outlined":"","to":{name:'local_visibility'},"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('component_localVisibilityByLocation_detailButton'))),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-double-right")])],1)],1):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }