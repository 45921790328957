<template>

    <component-container
        :card-title="$t('component_competitionTopCompetitor_title')"
        :card-subtitle="$t('component_competitionTopCompetitor_subtitle')"

        :height="(forceFullHeight ? 620 : 495 )"
        :information-tooltip="{
            title : $t('component_competitionTopCompetitor_informationTooltip_title'),
            text:$t('component_competitionTopCompetitor_informationTooltip_text')
        }"

        :component-config="{
            hasConfig:rivalsConfiguration.length,
            redirectRoute:displayPlaceholderConfigButton ? 'competitors-configuration' : null,
            placeholderTitle:$t('component_componentContainer_noCompetitor_title'),
            placeholderText:$t('component_componentContainer_noCompetitor_text'),
        }"

        :component-data="{
            response:competitionTopCompetitor,
            hasData:(competitionTopCompetitor.data),
            noDataMessage:$t('component_competitionTopCompetitor_noDataMessage')
        }"
    >
        <template v-slot:content>

            <div
                class="text-body-1 top-competitor-rank-display"
                :class="{
                    'first-rank':website.myProject,
                    'with-link-button':displayLinkButton
                }"
                v-for="website in competitionTopCompetitor.data" :key="website.websiteId"
            >
                <span :style="'background-color:'+getComponentColor().getCompetitorColor(website.pos)"></span>
                <p><strong>{{ website.rank }}</strong> : {{ website.name }} ({{ website.performance }}%)</p>
            </div>
            <div class="v-application" v-if="displayLinkButton">
                <v-btn
                    outlined
                    :to="{name:'competitor'}"
                    color="primary"
                    style="width: calc(100% - 35px); position: absolute; bottom: 18px"
                >
                    {{ $t('component_competitionTopCompetitor_detailButton') }}<v-icon right>mdi-chevron-double-right</v-icon>
                </v-btn>
            </div>
        </template>
    </component-container>

</template>


<script>

import CompetitionRankProgressBar from "@/components/yooda-components/app/competition/CompetitionRankProgressBar";
import apiCalls from '@/api/axios/dataReport/dashboard.js'

export default {
    props: {
        displayLinkButton:{ type: Boolean, default: false },
        displayPlaceholderConfigButton:{
            type: Boolean,
            default: true
        },
        forceFullHeight:{
            type: Boolean,
            default: true
        }
    },
    data(){
        return {
            competitionTopCompetitor:null
        }
    },
    components:{
        CompetitionRankProgressBar,
    },
    computed:{

        marginClass(){
            if(this.displayLinkButton){return 'mt-5'}
            return 'mt-8'
        },
    },
    watch:{
        globalFilterLocation(){
            this.initComponentData()
        }
    },
    methods:{
        formatData(jsonData){
            jsonData.content.sort((a,b)=>{
                if(a.rank === null || b.rank === null) { return 0}
                return (a.rank < b.rank) ? -1 : (a.rank > b.rank) ? 1 : 0
            })
            return jsonData.content.map((data, index)=>{
                return {
                    myProject:data.competitorType === 'project',
                    name:data.domainName,
                    performance:data.performance,
                    pos:index,
                    rank: this.getRankPositionLabel(data.rank)
                }
            })
        },

        initComponentData(){
            this.getComponentData({
                componentName:'competitionTopCompetitor',
                promise:(!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  && this.rivalsConfiguration.length>0) ? apiCalls.readTopCompetitor(this.$router.currentRoute.params.projectId,{params:{'search-geolocation-id':this.globalFilterLocation}}) : false,
                dataFormatter: this.formatData,
            })
        }
    },
    beforeMount() {
        this.initComponentData()

    },
}
</script>

<style lang="scss">
.top-competitor-rank-display{
    font-size: 16px;
    margin: 24px 0 24px 6px !important;

    >span{
        border-radius:100%;
        width: 20px;
        height:20px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 12px
    }
    >p{
        display: inline-block;
        vertical-align: middle;
    }
    >i{
        margin-right: 10px;
    }

    &.first-rank{
        //font-size: 20px;
        font-weight: 600;
    }
    &.with-link-button{

        margin: 12px 0 10px 6px !important;
    }
}
</style>
