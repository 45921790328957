<template>
    <v-dialog v-model="show" content-class="popin-dialog-container" overlay-opacity="0.85">

        <v-icon
            class="close-popin-dialog"
            @click="show=false"
        >
            mdi-close
        </v-icon>
        <component-container
            :card-title="`${$t('component_marketSegmentBestProgressionPotentialKeywords_title')} ${elementName}`"
            :card-subtitle="`${$t('component_marketSegmentBestProgressionPotentialKeywords_subtitle')} : ${elementName}`"



            :component-config="{
                hasConfig:keywordGroupsConfiguration.length,
                hideOnNoConfig:hideOnNoConfig,
                redirectRoute:'segments-configuration',
                placeholderText:$t('component_componentContainer_noMarketSegment_text')
            }"
            :table-menu="{csv:true}"
            :table-density-selector="true"

            :component-data="{response:marketSegmentBestProgressionPotentialKeywords, hasData:marketSegmentBestProgressionPotentialKeywords.data}"

            @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"
        >
            <template v-slot:content>
                <div v-if="!marketSegmentBestProgressionPotentialKeywords.isLoaded" style="padding-top: 80px">
                    <component-loader/>
                </div>
                <ag-grid-table v-else-if="marketSegmentBestProgressionPotentialKeywords.data"
                               :show-pagination="false"
                               :show-floating-filter="false"
                               :with-fullscreen="false"
                               :table-data="{columnDefs:columnDefs,rowData:marketSegmentBestProgressionPotentialKeywords.data}"
                               :headerHeight="45"
                               class="mt-16"
                               ref="agGridTable"
                >
                </ag-grid-table>
            </template>
        </component-container>

    </v-dialog>
</template>


<script>

import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable'
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";

export default {
    props: {
        hideOnNoConfig:{type: Boolean, default: false}
    },
    data() {
        return {
            show:false,
            marketSegmentBestProgressionPotentialKeywords:{  isLoaded:false, error:null,data: null },
            elementName:null,
            popinStateName:'marketSegmentBestProgressionPotentialKeywords',
            columnDefs:[
                {headerName: this.$t('component_marketSegmentBestProgressionPotentialKeywords_table_header_keyword'), field: 'keyword',cellStyle: { fontWeight:500} },
                {headerName: this.$t('component_marketSegmentBestProgressionPotentialKeywords_table_header_searchCount'), field: 'searchCount',  width:200, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', cellRendererFramework: 'CellRendererFormatedNumber',comparator: cellSort.agGridTableSortCellTraffic},
                {headerName: this.$t('component_marketSegmentBestProgressionPotentialKeywords_table_header_totalPotentialTraffic'), field: 'totalPotentialTraffic', width:250, sort:'desc', cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', cellRendererFramework: 'CellRendererFormatedNumber',comparator: cellSort.agGridTableSortCellTraffic},
                {
                    headerName:this.$t('component_marketSegmentBestProgressionPotentialKeywords_table_header_potentialTraffic'),
                    headerClass:'ag-grid-header-center with-background',
                    children:[
                        {headerName: this.$t('component_marketSegmentBestProgressionPotentialKeywords_table_header_potentialSeoTraffic'), field: 'potentialSeoTraffic', width:100, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', cellRendererFramework: 'CellRendererFormatedNumber',comparator: cellSort.agGridTableSortCellTraffic},
                        {headerName: this.$t('component_marketSegmentBestProgressionPotentialKeywords_table_header_potentialAdwordsTraffic'), field: 'potentialAdwordsTraffic', width:140, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', cellRendererFramework: 'CellRendererFormatedNumber',comparator: cellSort.agGridTableSortCellTraffic},
                        {headerName: this.$t('component_marketSegmentBestProgressionPotentialKeywords_table_header_potentialGoogleBusinessTraffic'), field: 'potentialGoogleBusinessTraffic', width:200, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', cellRendererFramework: 'CellRendererFormatedNumber',comparator: cellSort.agGridTableSortCellTraffic},
                        {headerName: this.$t('component_marketSegmentBestProgressionPotentialKeywords_table_header_potentialZeroTraffic'), field: 'potentialZeroTraffic', width:150,  cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', cellRendererFramework: 'CellRendererFormatedNumber',comparator: cellSort.agGridTableSortCellTraffic},
                        {headerName: this.$t('component_marketSegmentBestProgressionPotentialKeywords_table_header_potentialShoppingTraffic'), field: 'potentialShoppingTraffic',  width:130,  cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', cellRendererFramework: 'CellRendererFormatedNumber',comparator: cellSort.agGridTableSortCellTraffic},
                        {headerName: this.$t('component_marketSegmentBestProgressionPotentialKeywords_table_header_potentialActualityTraffic'), field: 'potentialActualityTraffic',  width:130, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', cellRendererFramework: 'CellRendererFormatedNumber',comparator: cellSort.agGridTableSortCellTraffic},
                        {headerName: this.$t('component_marketSegmentBestProgressionPotentialKeywords_table_header_potentialImageTraffic'), field: 'potentialImageTraffic',  width:100, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', cellRendererFramework: 'CellRendererFormatedNumber',comparator: cellSort.agGridTableSortCellTraffic},
                        {headerName: this.$t('component_marketSegmentBestProgressionPotentialKeywords_table_header_potentialVideoTraffic'), field: 'potentialVideoTraffic',  width:100,  cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', cellRendererFramework: 'CellRendererFormatedNumber',comparator: cellSort.agGridTableSortCellTraffic},
                    ]
                },


            ]
        }
    },
    components: {
        AgGridTable
    },
    computed:{
    },
    watch:{
        getShowPopin(){
            if(!this.show && this.getShowPopin){
                if(this.getShowPopin.componentName === this.popinStateName){
                    this.show = true
                    this.elementName = this.getShowPopin.elementName
                    this.getComponentData({
                        componentName:'marketSegmentBestProgressionPotentialKeywords',
                        promise: this.getShowPopin.promise(),
                        dataFormatter: this.formatData
                    })
                }
            }
        },
        show(){
            if(!this.show){
                this.showAgGridPopin(null)
            }
        }
    },
    methods:{
        formatData(jsonData){
            let rowData = []
            jsonData.content.forEach(data => {
                rowData.push({
                    keyword: data.string,
                    potentialSeoTraffic: (data.naturalPotentialTrafficGain !== null ? data.naturalPotentialTrafficGain : '-'),
                    potentialAdwordsTraffic: (data.adwordsPotentialTrafficGain !== null ? data.adwordsPotentialTrafficGain : '-'),
                    potentialGoogleBusinessTraffic: (data.mapPotentialTrafficGain !== null ? data.mapPotentialTrafficGain : '-'),
                    potentialZeroTraffic: (data.zeroPotentialTrafficGain !== null ? data.zeroPotentialTrafficGain : '-'),
                    potentialShoppingTraffic: (data.shoppingPotentialTrafficGain !== null ? data.shoppingPotentialTrafficGain : '-'),
                    potentialActualityTraffic: (data.actualityPotentialTrafficGain !== null ? data.actualityPotentialTrafficGain : '-'),
                    potentialImageTraffic:(data.imagePotentialTrafficGain !== null ? data.imagePotentialTrafficGain : '-'),
                    potentialVideoTraffic:(data.videoPotentialTrafficGain !== null ? data.videoPotentialTrafficGain : '-'),
                    searchCount: (data.searchCount !== null ? data.searchCount : '-'),
                    totalPotentialTraffic: (data.cumulatedPotentialTrafficGain !== null ? data.cumulatedPotentialTrafficGain : '-'),
                })
            })
            return rowData
        }
    },
}
</script>
