<template>
    <div>
        <component-container
            :card-title="$t('component_linkingRefDomains_title')"
            :card-subtitle="$t('component_linkingRefDomains_subtitle')"


            :custom-style="`padding-top:15px;border:none !important;${displayNoBorder ? 'border-radius:0!important;': 'box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.15) !important;'}`"

            :no-shadow="true"

            :information-tooltip="{
                title : $t('component_linkingWebsiteBacklinks_informationTooltip_title'),
                text:$t('component_linkingWebsiteBacklinks_informationTooltip_text')
            }"

            :table-menu="{csv:true, fullscreen:true}"
            :table-density-selector="true"

            :component-data="{response:linkingWebsiteBacklinks, hasData:linkingWebsiteBacklinks.data, noDataMessage:$t('component_linkingMyThematicAuthority_no_data_text')}"

            @setTableMenuFullscreen="cardContainerFullscreen = !cardContainerFullscreen"
            @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"
        >
            <template v-slot:filters>
                 <linking-thematic-selector class="mb-8" :selected-thematic-name="selectedThematic" @selectValue="selectThematic"/>
            </template>

            <template v-slot:content>
                <ag-grid-table
                    :show-floating-filter="false"
                    :table-data="{columnDefs:columnDefs,rowData:linkingWebsiteBacklinks.data}"
                    :agGridStyle="`height:${tableHeightByRowCount(linkingWebsiteBacklinks.data)}px;`"
                    :is-card-container-fullscreen="cardContainerFullscreen"
                    ref="agGridTable"
                />
            </template>

        </component-container>
        <linking-ref-domains-links/>
    </div>

</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/linking.js'
import { useComponentStore } from '@/stores/ComponentStore'
import {mapState} from "pinia";
import AgGridTable from "@/components/yooda-components/ag-grid-table/AgGridTable.vue";
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";
import LinkingThematicSelector from "@/components/yooda-components/app/linking/LinkingThematicSelector.vue";
import LinkingRefDomainsLinks from "@/components/yooda-components/app/linking/popin/LinkingRefDomainsLinks.vue";

export default {
    data() {
        return {
            linkingWebsiteBacklinks:null,

            selectedThematic:this.$t('linking_thematic_label_all'),
            cardContainerFullscreen:false,
            columnDefs:[
                {headerName: this.$t('component_linkingWebsiteBacklinks_table_header_refererWebsite'),headerTooltip: this.$t('component_linkingWebsiteBacklinks_table_header_refererWebsite_tooltip'),filter: true, field: 'refererPage', cellRendererFramework: 'CellRendererLinkingBacklinkDetail', comparator: cellSort.agGridTableSortCellValue,filterParams: {valueGetter: params => { return params.data.refererPage.value }}},
                {headerName: this.$t('component_linkingWebsiteBacklinks_table_header_popularity'),headerTooltip: this.$t('component_linkingWebsiteBacklinks_table_header_popularity_tooltip'),filter: false, field: 'popularity', sortingOrder: ['desc','asc'], cellRendererFramework: 'CellRendererLinkingInfoChips', width: 80, comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_linkingWebsiteBacklinks_table_header_authority'),headerTooltip: this.$t('component_linkingWebsiteBacklinks_table_header_authority_tooltip'),filter: false, field: 'authority', sortingOrder: ['desc','asc'],  cellRendererFramework: 'CellRendererLinkingInfoChips', width: 80, comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_linkingWebsiteBacklinks_table_header_thematic'),headerTooltip: this.$t('component_linkingWebsiteBacklinks_table_header_thematic_tooltip'),filter: false, field: 'thematic', sortingOrder: ['desc','asc'],  cellRendererFramework: 'CellRendererLinkingThematic', comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_linkingWebsiteBacklinks_table_header_linkCount'),headerTooltip: this.$t('component_linkingWebsiteBacklinks_table_header_linkCount_tooltip'),filter: false, field: 'linkCount',sortingOrder: ['desc','asc'], cellRendererFramework: 'CellRendererPopinTableLink', width: 60,  cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellValue},
            ]
        }
    },
    components: {
        AgGridTable,
        LinkingThematicSelector,
        LinkingRefDomainsLinks,
        YoodaChart
    },
    computed:{
        ...mapState(useComponentStore, {
            globalFilterFrequency: 'getGlobalFilterFrequency',
        }),

        displayNoBorder(){
            return this.$parent.$options._componentTag !== 'custom-report-model' && !this.cardContainerFullscreen
        }
    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData()
        }
    },
    methods:{

        selectThematic(selectThematic){
            this.selectedThematic = selectThematic
            this.initComponentData()
        },

        formatData(jsonData){
            if(jsonData.content.length === 0){return null}
            let rowData = []
            jsonData.content.forEach(data => {
                let thematicData = this.getThematicData(data)
                let parsedRefererPage = new URL(`https://${data.domainName}`)

                let filters = {
                    params: {
                        'date-range-type':this.globalFilterFrequency,
                    }
                }

                rowData.push({
                    refererPage:{
                        value: `${parsedRefererPage.hostname}${parsedRefererPage.pathname}`,
                        redirectTo: `https://${data.domainName}`,
                        url: `${parsedRefererPage.hostname}${parsedRefererPage.pathname}`
                    },
                    popularity: {value:data.popularity, color:this.getThemeColorByName('linkingPopularity')},
                    authority: {value:data.authority, color:this.getThemeColorByName('linkingAuthority')},

                    thematic:{
                        value:thematicData.mainThematic.value,
                        color:thematicData.mainThematic.color,
                        categories:thematicData.mainThematic.categories,
                        otherThematic:thematicData.otherThematic
                    },
                    linkCount:(data.linkCount ? {
                        componentName:!this.isReportingRoute ? 'linkingRefDomainsLinks' : null,
                        value:data.linkCount,
                        displayedValue:this.$options.filters.formatNumber(data.linkCount),
                        promise: () => !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ?  apiCalls.readWebsiteBacklinksPopinLinks(this.$router.currentRoute.params.projectId, data.id, filters) : false,
                        elementName:data.domainName
                    } :  {value:0}),

                })
            })


            return rowData
        },
        initComponentData(){

            let filters = {'date-range-type':this.globalFilterFrequency}
            if(this.selectedThematic !== this.$t('linking_thematic_label_all')){
                filters['thematic'] = this.selectedThematic
            }

            this.getComponentData({
                componentName:'linkingWebsiteBacklinks',
                promise: (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId ) ? apiCalls.readWebsiteBacklinks(this.$router.currentRoute.params.projectId,{params:filters}) : false,
                dataFormatter: this.formatData
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
