<template>
    <component-container
        :card-title="$t('component_linkingPopularityByWebsite_title')"
        :card-subtitle="$t('component_linkingPopularityByWebsite_subtitle')"

        height="340"

        :information-tooltip="{
            title : $t('component_linkingPopularityByWebsite_informationTooltip_title'),
            text:$t('component_linkingPopularityByWebsite_informationTooltip_text')
        }"

        :component-data="{response:linkingPopularityByWebsite, hasData:linkingPopularityByWebsite.data, noDataMessage:$t('component_linkingMyThematicAuthority_no_data_text')}"
    >
        <template v-slot:content>


            <div style="overflow: hidden;" v-if="linkingPopularityByWebsite.data" >
                <yooda-chart
                    :options="linkingPopularityByWebsite.data.option"
                    chart-type="pie"
                    style="margin-top: -200px"
                    chart-style="height:360px"
                />

                <v-row class="mt-6">
                    <v-col :cols="6" class="text-right">
                        <p class="custom-chart-legend" >
                            <span :style="`background-color: ${getThemeColorByName('linkingPopularity')}`"></span>
                            {{ $t('component_linkingLinkCount_websiteLabel') }}
                        </p>
                    </v-col>
                    <v-col :cols="6" class="text-left">
                        <p class="custom-chart-legend" >
                            <span :style="`background-color: ${getThemeColorByName('linkingPopularity',0.4)}`"></span>
                            {{ $t('component_linkingLinkCount_competitorsLabel') }}
                        </p>
                    </v-col>
                </v-row>
            </div>


        </template>
    </component-container>
</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/linking.js'
import ComponentFilters from "@/components/yooda-components/ComponentFilters";
import { useComponentStore } from '@/stores/ComponentStore'
import {mapState} from "pinia";

export default {
    data() {
        return {
            linkingPopularityByWebsite:null,
        }
    },
    components: {
        YoodaChart,
        ComponentFilters,
    },
    computed:{
        ...mapState(useComponentStore, {
            globalFilterFrequency: 'getGlobalFilterFrequency',
        }),
    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData()
        }
    },
    methods:{


        formatData(jsonData){

            if(!jsonData.content.project && !jsonData.content.competitors){return null}

            return {
                option:{
                    series: [
                        {
                            name:"moi",
                            type: 'pie',
                            radius: ['65%', '85%'],
                            center: ['50%', '100%'],
                            startAngle: 180,
                            silent: true,
                            label:{ show:true, position:'inside', fontSize:16, fontWeight:'bold' },
                            data: [
                                {
                                    name:`${jsonData.content.project}`,
                                    value: jsonData.content.project,
                                    itemStyle: { color: this.getThemeColorByName('linkingPopularity') }
                                },
                                {
                                    value: 200-jsonData.content.project,
                                    itemStyle: { color: '#ececec' },
                                    label: { show: false }
                                }
                            ]
                        },
                        {
                            name:"conc",
                            type: 'pie',
                            radius: ['40%', '60%'],
                            center: ['50%', '100%'],
                            startAngle: 180,
                            silent: true,
                            label:{ show:true, position:'inside', verticalAlign:'top', fontSize:16, fontWeight:'bold' },
                            data: [
                                {
                                    name:`${jsonData.content.competitors}`,
                                    value: jsonData.content.competitors,
                                    itemStyle: { color: this.getThemeColorByName('linkingPopularity',0.4) }
                                },
                                {
                                    value: 200-jsonData.content.competitors,
                                    itemStyle: { color: '#ececec' },
                                    label: { show: false }
                                }
                            ]
                        }
                    ]
                }
            }
        },
        initComponentData(){
            this.getComponentData({
                componentName:'linkingPopularityByWebsite',
                promise: (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId) ? apiCalls.readPopularityByWebsite(this.$router.currentRoute.params.projectId,{params:{'date-range-type':this.globalFilterFrequency}}) : false,
                dataFormatter: this.formatData
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
