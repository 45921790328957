var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{attrs:{"card-title":_vm.$t('component_competitionVisibilityEvolution_title'),"card-subtitle":_vm.$t('component_competitionVisibilityEvolution_subtitle'),"height":(_vm.filterOpen ? 620 : 520),"information-tooltip":{
        title : _vm.$t('component_competitionVisibilityEvolution_informationTooltip_title'),
        text:_vm.$t('component_competitionVisibilityEvolution_informationTooltip_text'),
        width:450
    },"component-config":{
        hasConfig:_vm.rivalsConfiguration.length,
        redirectRoute:'competitors-configuration',
        placeholderTitle:_vm.$t('component_componentContainer_noCompetitor_title'),
        placeholderText:_vm.$t('component_componentContainer_noCompetitor_text')
    },"component-data":{
        response:_vm.competitionVisibilityEvolution,
        hasData:_vm.competitionVisibilityEvolution.data,
    }},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('component-filters',{attrs:{"market-segments":true,"result-type":true,"default-select-value":_vm.lastFilters},on:{"applyFilter":_vm.initComponentData,"filterPanelStatus":_vm.displayFilterOpen}})]},proxy:true},{key:"content",fn:function(){return [_c('yooda-chart',{attrs:{"options":_vm.competitionVisibilityEvolution.data,"chart-type":"line","chart-style":"height:380px"}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }