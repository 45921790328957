<template>
    <div>
        <component-container
            :class="{filterOpen:filterOpen}"
            :card-title="$t('component_websitePagesBestPositionedPages_title')"
            :card-subtitle="`${$t('component_websitePagesBestPositionedPages_subtitle')} ${displayKpiLabel}`"

            :information-tooltip="{
                title : $t('component_websitePagesBestPositionedPages_informationTooltip_title'),
                text:$t('component_websitePagesBestPositionedPages_informationTooltip_text')
            }"


            :table-menu="{csv:true, fullscreen:true}"
            :table-density-selector="true"

            :component-data="{response:websitePagesBestPositionedPages, hasData:websitePagesBestPositionedPages.data}"

            @setTableMenuFullscreen="cardContainerFullscreen = !cardContainerFullscreen"
            @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"
        >

            <template v-slot:filters>
                <component-filters

                    :market-segments="true"
                    @applyFilter="initComponentData"
                    @filterPanelStatus="displayFilterOpen"
                    :default-select-value="lastFilters"
                />
            </template>
            <template v-slot:content>
                <ag-grid-table
                    :show-floating-filter="false"
                    :table-data="{columnDefs:columnDefs,rowData:websitePagesBestPositionedPages.data}"
                    :agGridStyle="`height:${tableHeightByRowCount(websitePagesBestPositionedPages.data)}px;`"
                    :is-card-container-fullscreen="cardContainerFullscreen"
                    ref="agGridTable"
                />
            </template>
        </component-container>
        <website-pages-best-positioned-page-positions/>
    </div>



</template>


<script>

import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable'
import WebsitePagesBestPositionedPagePositions from '@/components/yooda-components/app/website-pages/WebsitePagesBestPositionedPagePositions'
import apiCalls from '@/api/axios/dataReport/websitePages.js'
import ComponentFilters from "@/components/yooda-components/ComponentFilters";
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";

export default {
    props: {
        hideOnNoConfig:{type: Boolean, default: false}
    },
    data() {
        return {
            websitePagesBestPositionedPages:null,
            filterOpen:false,
            cardContainerFullscreen:false,
            displayKpiLabel:'',
            lastFilters:null,
            lastKeywordGroups: null,
            columnDefs:[
                {headerName: this.$t('component_websitePagesBestPositionedPages_table_header_page'), field: 'page',filter: true, cellRendererFramework: 'CellRendererLink',comparator: cellSort.agGridTableSortCellGeneric, filterParams: {valueGetter: params => { return params.data.page.value }}},
                {headerName: this.$t('component_websitePagesBestPositionedPages_table_header_positionCount'), field: 'positionCount', cellRendererFramework: 'CellRendererPopinTableLink', width: 70, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_websitePagesBestPositionedPages_table_header_incomingTraffic'), field: 'incomingTraffic', sort:'desc', cellRendererFramework: 'CellRendererFormatedNumber', width: 70, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},
                {headerName: this.$t('component_websitePagesBestPositionedPages_table_header_conversion'), field: 'conversion', cellRendererFramework: 'CellRendererFormatedNumber', width: 70, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},
                {headerName: this.$t('component_websitePagesBestPositionedPages_table_header_turnover'), field: 'turnover', cellRendererFramework: 'CellRendererCurrency', width: 70, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},
            ]
        }
    },
    components: {
        AgGridTable,
        WebsitePagesBestPositionedPagePositions,
        ComponentFilters,
    },
    computed:{

    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData(this.lastFilters)
        },
        globalFilterLocation(){
            this.initComponentData(this.lastFilters)
        }
    },
    methods:{
        displayFilterOpen(status){ this.filterOpen = status === 'open'; },
        formatData(jsonData){
            if(jsonData.content.length === 0){return []}
            let rowData = []

            jsonData.content.forEach(data => {
                let filters = this.lastFilters
                rowData.push({
                    page: {
                        href: `//${this.projectUrl}${data.url}`,
                        value:data.url,
                        targetBlank: true
                    },
                    positionCount: {
                        componentName: 'websitePagesBestPositionedPagePositions',
                        value: data.distinctPositionCount,
                        promise: () => (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId ) ? apiCalls.readBestPositionedPagePositions(this.$router.currentRoute.params.projectId,data.pageId, filters) : false,
                        elementName: data.url,
                        elementId:data.pageId,
                        defaultFilter:filters
                    },
                    incomingTraffic: (data.traffic?data.traffic:0),
                    conversion: (data.conversion?data.conversion:0),
                    turnover: (data.turnover?data.turnover:0),
                })
            })

            if(jsonData.context.inputs){
                this.displayKpiLabel = this.$t(`common_periodPicker_kpiLabel_${jsonData.context.inputs.dateRangeType}`)
            }

            return rowData
        },
        initComponentData(filters){
            this.lastKeywordGroups = filters.params["keyword-group-id"]

            filters.params["search-geolocation-id"] = this.globalFilterLocation
            filters.params["date-range-type"] = this.globalFilterFrequency



            this.getComponentData({
                componentName:'websitePagesBestPositionedPages',
                promise: (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ) ? apiCalls.readBestPositionedPages(this.$router.currentRoute.params.projectId,filters) : false,
                dataFormatter: this.formatData
            })
            this.lastFilters = filters
        },
        selectAnalyticsPeriodPickerValue(period){

            let filters = this.lastFilters
            if(!this.lastFilters){
                filters = {
                    params:{
                        'date-range-type':this.globalFilterFrequency,
                        'search-geolocation-id':this.globalFilterLocation
                    }
                }
            }
            else{
                filters.params['date-range-type'] = period
            }


            this.initComponentData(filters)
        },
    },
    beforeMount() {
        this.initComponentData({
            params:{
                'date-range-type':this.defaultAnalyticsPeriodPickerValue
            }
        })
    },
}
</script>
<style lang="scss">


</style>
