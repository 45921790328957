<template>
    <component-container
        :card-title="$t('component_progressionAxisGoogleBlockTypeFrequency_title')"
        :card-subtitle="$t('component_progressionAxisGoogleBlockTypeFrequency_subtitle')"

        :height="(filterOpen ? 750 : 650)"

        :information-tooltip="{
            title : $t('component_progressionAxisGoogleBlockTypeFrequency_informationTooltip_title'),
            text:$t('component_progressionAxisGoogleBlockTypeFrequency_informationTooltip_text')
        }"

        :component-data="{
            response:progressionAxisGoogleBlockTypeFrequency,
            hasData:progressionAxisGoogleBlockTypeFrequency.data
        }"
    >
        <template v-slot:filters>
            <component-filters
                :market-segments="true"
                @applyFilter="initComponentData"
                @filterPanelStatus="displayFilterOpen"
                :default-select-value="lastFilters"
            />
        </template>

        <template v-slot:content>
            <v-row v-if="progressionAxisGoogleBlockTypeFrequency.data">
                <v-col :xl="6" :lg="6" :md="6" :cols="12">
                    <v-card>
                        <v-card-text>
                            <h4 class="text-h4">{{ $t('component_progressionAxisGoogleBlockTypeFrequency_title') }}</h4>
                            <div v-for="(rank, index) in progressionAxisGoogleBlockTypeFrequency.data.frequency" v-bind:key="index" class="mt-3 v-application">
                                <p class="text-body-1 mb-1">{{ $t('common_google_block_label_'+rank.type) }} : <strong>{{ rank.value }}%</strong></p>
                                <vs-progress :percent="getLogValue(rank.value)" :color="getComponentColor().getGoogleResultTypeColor(rank.type)" :height="16"></vs-progress>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col :xl="6" :lg="6" :md="6" :cols="12">
                    <v-card>
                        <v-card-text>
                            <h4 class="text-h4">{{ $t('component_progressionAxisGoogleBlockPerformance_title') }}</h4>
                            <div v-for="(rank, index) in progressionAxisGoogleBlockTypeFrequency.data.performance" v-bind:key="index" class="mt-3 v-application">
                                <p class="text-body-1 mb-1">{{ $t('common_google_block_label_'+rank.type) }} : <strong>{{ rank.value }}%</strong></p>
                                <vs-progress :percent="rank.value" :color="getComponentColor().getGoogleResultTypeColor(rank.type)" :height="16"></vs-progress>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
    </component-container>

</template>


<script>

import apiCalls from '@/api/axios/dataReport/progressionAxis'
import ComponentFilters from "@/components/yooda-components/ComponentFilters";

export default {
    components:{
        ComponentFilters
    },
    data() {
        return {
            progressionAxisGoogleBlockTypeFrequency:null,
            filterOpen:false,
            lastFilters:null,
        }
    },
    computed:{

    },
    watch:{
        globalFilterLocation(){
            this.initComponentData(this.lastFilters)
        }
    },
    methods:{
        displayFilterOpen(status){ this.filterOpen = status === 'open'; },
        formatData(jsonData){

            if(jsonData.content.length === 0){return {}}
            let formatedData= {frequency:[],performance:[]}
            jsonData.content.forEach(data=>{
                formatedData.frequency.push({
                    type:(data.positionType === 'zero' ? 'snippet' : data.positionType),
                    value:(data.occurrenceProbabilityPercent ? data.occurrenceProbabilityPercent : 0)
                })
                formatedData.performance.push({
                    type:(data.positionType === 'zero' ? 'snippet' : data.positionType),
                    value:(data.performance ? data.performance : 0)
                })

            })
            return formatedData
        },
        getLogValue(value){
            let logValue = value
            if(value >0 && value<100){
                logValue = -4.3997669 * Math.pow(10,-6) * Math.pow(value,4) + 1.097319347 * Math.pow(10,-3) * Math.pow(value,3) - 8.930361305 * Math.pow(10,-2) * Math.pow(value,2) + 3.33525641 * value + 1.363636364
            }
            return logValue
        },
        initComponentData(filters){
            filters.params['search-geolocation-id'] = this.globalFilterLocation

            this.getComponentData({
                componentName:'progressionAxisGoogleBlockTypeFrequency',
                promise:!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readBlockType(this.$router.currentRoute.params.projectId,filters) : false,
                dataFormatter: this.formatData
            })

            this.lastFilters = filters
        },
    },
    beforeMount() {
        this.initComponentData({params:{"search-geolocation-id":this.defaultSearchGeolocation}})
    },
}
</script>
