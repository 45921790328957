<template>


    <v-row>
        <v-col cols="12">
            <v-card>
                <v-card-text>
                    <p v-if="data.translationKey">{{ $t(`${data.translationKey}`) }}</p>
                    <p v-else>{{ data.string }}</p>
                </v-card-text>
            </v-card>

        </v-col>
    </v-row>



</template>


<script>

export default {
    props: {
        data:{}
    },
    data(){
        return{
        }
    },
    computed:{


    },
    beforeMount() {

    }
}
</script>
