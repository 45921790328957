var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{class:{filterOpen:_vm.filterOpen},attrs:{"card-title":_vm.$t('component_localVisibilityKeywordsPositions_title'),"card-subtitle":_vm.$t('component_localVisibilityKeywordsPositions_subtitle'),"information-tooltip":{
            title : _vm.$t('component_localVisibilityKeywordsPositions_informationTooltip_title'),
            text:_vm.$t('component_localVisibilityKeywordsPositions_informationTooltip_text')
        },"table-menu":{csv:true, fullscreen:true},"table-density-selector":true,"component-config":{
        hasConfig: _vm.searchGeolocationsConfiguration.filter(data=> data.targetType !== 'country').length,
        hideOnNoConfig:_vm.hideOnNoConfig,
        redirectRoute: 'keywords-configuration',
        placeholderLocation: true,
        placeholderTitle: _vm.$t('component_componentContainer_noLocation_title'),
        placeholderText: _vm.$t('component_componentContainer_noLocation_text')
    },"component-data":{response:_vm.localVisibilityKeywordsPositions, hasData:_vm.localVisibilityKeywordsPositions.data}},on:{"setTableMenuFullscreen":function($event){_vm.cardContainerFullscreen = !_vm.cardContainerFullscreen},"setTableMenuExportCsv":function($event){return _vm.$refs.agGridTable.exportCsvTable()}},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('component-filters',{attrs:{"market-segments":true,"competitors":true,"competitors-with-project-website":true,"competitors-with-select-all":false,"default-select-value":_vm.lastFilters},on:{"applyFilter":_vm.initComponentData,"filterPanelStatus":_vm.displayFilterOpen}})]},proxy:true},{key:"content",fn:function(){return [_c('ag-grid-table',{ref:"agGridTable",attrs:{"show-floating-filter":false,"table-data":{columnDefs:_vm.columnDefs,rowData:_vm.localVisibilityKeywordsPositions.data},"agGridStyle":`height:${_vm.tableHeightByRowCount(_vm.localVisibilityKeywordsPositions.data)}px;`,"is-card-container-fullscreen":_vm.cardContainerFullscreen}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }