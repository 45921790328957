<template>


    <v-select
        v-model="selectedThematic"
        :items="thematicList"
        outlined
        prepend-inner-icon="mdi-label-variant"
        dense
        hide-details
        class="header-nav-select"
        @change="selectValue"
        style="max-width: 280px"
    />


</template>


<script>


export default {
    props:{
        selectedThematicName:{
            type: String,
            default: ''
        }
    },
    data() {
        return {
            thematicList:[
                this.$t('linking_thematic_label_all'),
                this.$t('linking_thematic_label_16'),
                this.$t('linking_thematic_label_3'),
                this.$t('linking_thematic_label_5'),
                this.$t('linking_thematic_label_6'),
                this.$t('linking_thematic_label_14'),
                this.$t('linking_thematic_label_9'),
                this.$t('linking_thematic_label_12'),
                this.$t('linking_thematic_label_7'),
                this.$t('linking_thematic_label_2'),
                this.$t('linking_thematic_label_1'),
                this.$t('linking_thematic_label_11'),
                this.$t('linking_thematic_label_8'),
                this.$t('linking_thematic_label_13'),
                this.$t('linking_thematic_label_4'),
                this.$t('linking_thematic_label_10'),
                this.$t('linking_thematic_label_15'),
            ],
            selectedThematic:'',
        }
    },

    methods:{
        selectValue(){
            this.$emit('selectValue',this.selectedThematic)
        }
    },
    beforeMount() {
        this.selectedThematic = this.selectedThematicName
    }
}
</script>
