<template>
    <component-container
        :class="{filterOpen:filterOpen}"
        :card-title="$t('component_seoVisibilityCompareKeywordsPositions_title')"
        :card-subtitle="$t('component_seoVisibilityCompareKeywordsPositions_subtitle')"


        :information-tooltip="{
                title : $t('component_seoVisibilityCompareKeywordsPositions_informationTooltip_title'),
                text:$t('component_seoVisibilityCompareKeywordsPositions_informationTooltip_text')
            }"

        :table-menu="{csv:true, fullscreen:true}"
        :table-density-selector="true"

        :component-data="{response:seoVisibilityCompareKeywordsPositions, hasData:seoVisibilityCompareKeywordsPositions.data}"

        @setTableMenuFullscreen="cardContainerFullscreen = !cardContainerFullscreen"
        @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"

    >
        <template v-slot:filters>
            <component-filters
                :market-segments="true"
                @applyFilter="initComponentData"
                @filterPanelStatus="displayFilterOpen"
                :default-select-value="lastFilters"
            />
        </template>

        <template v-slot:content>
            <ag-grid-table
                :show-floating-filter="false"
                :table-data="{columnDefs:columnDefs,rowData:seoVisibilityCompareKeywordsPositions.data}"
                :agGridStyle="`height:${tableHeightByRowCount(seoVisibilityCompareKeywordsPositions.data)}px;`"
                :sizeColumnsToFit="true"
                :is-card-container-fullscreen="cardContainerFullscreen"
                ref="agGridTable"
            />
        </template>
    </component-container>
</template>


<script>

import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable'
import apiCalls from '@/api/axios/dataReport/seoVisbility.js'
import ComponentFilters from "@/components/yooda-components/ComponentFilters";
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";

export default {
    data() {
        return {
            seoVisibilityCompareKeywordsPositions:null,
            filterOpen:false,
            cardContainerFullscreen:false,
            lastFilters:null,
            columnDefs:[
            ],
        }
    },
    components: {
        AgGridTable,
        ComponentFilters,
    },
    computed:{

    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData(this.lastFilters)
        },
        globalFilterLocation(){
            this.initComponentData(this.lastFilters)
        }
    },
    methods:{
        displayFilterOpen(status){ this.filterOpen = status === 'open'; },
        formatData(jsonData){
            if(jsonData.content.length === 0){return []}
            let columnDefs = []
            columnDefs.push({
                headerName: this.$t('component_seoVisibilityCompareKeywordsPositions_table_header_keyword'),
                field: 'string',
                filter: true,
                // pinned: 'left',
                // width: 320,
            })
            columnDefs.push({
                headerName: this.projectUrl,
                field: 'projectWebsite',
                width: 250,
                cellClass: 'ag-grid-cell-right',
                headerClass: 'ag-grid-header-right competitor projectWebsite',
                comparator: cellSort.agGridTableSortCellGeneric,
                sort:'asc'
            })

            let competitorCount = 0
            jsonData.context.computed.competitors.forEach(competitor => {
                competitorCount++
                columnDefs.push({
                    headerName: competitor,
                    field: `competitor${competitorCount}`,
                    width: 160,
                    cellClass: 'ag-grid-cell-right',
                    headerClass: 'ag-grid-header-right competitor '+`competitor${competitorCount}`,
                    comparator: cellSort.agGridTableSortCellGeneric
                })
            })
            this.columnDefs = columnDefs


            return jsonData.content.map((data)=>{
                return {
                    string: data.string,
                    projectWebsite: data.projectWebsite ? data.projectWebsite : '-',
                    competitor1: data.competitor1 ? data.competitor1 : '-',
                    competitor2: data.competitor2 ? data.competitor2 : '-',
                    competitor3: data.competitor3 ? data.competitor3 : '-',
                    competitor4: data.competitor4 ? data.competitor4 : '-',
                    competitor5: data.competitor5 ? data.competitor5 : '-',
                    competitor6: data.competitor6 ? data.competitor6 : '-',
                    competitor7: data.competitor7 ? data.competitor7 : '-',
                    competitor8: data.competitor8 ? data.competitor8 : '-',
                    competitor9: data.competitor9 ? data.competitor9 : '-',
                    competitor10: data.competitor10 ? data.competitor10 : '-',
                }
            })

        },

        initComponentData(filters = {params:{}}){
            filters.params['search-geolocation-id'] = this.globalFilterLocation
            filters.params['date-range-type'] = this.globalFilterFrequency
            this.getComponentData({
                componentName:'seoVisibilityCompareKeywordsPositions',
                promise: !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readCompareKeywordsPositions(this.$router.currentRoute.params.projectId,filters) : false,
                dataFormatter: this.formatData
            })
            this.lastFilters = filters
        },
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
