<template>
    <component-container
        :card-title="$t('component_topBestPages_title')"
        :height="495"

        :component-data="{response:topBestPages,hasData:true}"

        @setTableMenuFullscreen="cardContainerFullscreen = !cardContainerFullscreen"
    >

        <template v-slot:content>
            <div class="v-application pt-4">
                <ag-grid-table
                    :show-floating-filter="false"
                    :table-data="{columnDefs:columnDefs,rowData:topBestPages.data}"
                    :agGridStyle="`height:${tableHeightByRowCount(topBestPages.data)}px;`"
                    :is-card-container-fullscreen="cardContainerFullscreen"
                    :show-pagination="false"
                    ref="agGridTable"
                />
                <div v-if="displayLinkButton">
                    <v-btn
                        outlined
                        :to="{name:'website_pages'}"
                        color="primary"
                        style="width: calc(100% - 35px); position: absolute; bottom: 18px"
                    >
                        {{ $t('component_topBestPages_cta') }}<v-icon right>mdi-chevron-double-right</v-icon>
                    </v-btn>
                </div>
            </div>

        </template>

    </component-container>
</template>

<script>
import apiCalls from "@/api/axios/dataReport/dashboardRecommendations";
import AgGridTable from "@/components/yooda-components/ag-grid-table/AgGridTable";
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";

export default {
    name: "TopBestPages",
    data() {
        return {
            topBestPages: null,
            cardContainerFullscreen:true,
            analyticsTitle: false,
            columnDefs: [
                {headerName: this.$t('component_topBestPages_tableHeader_url'), field: 'page', cellRendererFramework: 'CellRendererLink', cellClass:'ag-grid-cell-left', headerClass:'ag-grid-header-left', comparator: cellSort.agGridTableSortCellValue},
                {headerValueGetter: () => this.getEstimatedTrafficTitle, field: 'cumulatedVisibility', width: 100, cellRendererFramework: 'CellRendererFormatedNumber', cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', comparator: cellSort.agGridTableSortCellTraffic},
                {headerName: this.$t('component_topBestPages_tableHeader_seoPositionsCount'), field: 'seoPositionCount', width: 100, cellClass:'ag-grid-cell-right', cellRendererFramework: 'CellRendererFormatedNumber', headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellGeneric},
                {headerName: this.$t('component_topBestPages_tableHeader_adsPositionsCount'), field: 'adsPositionCount',  width: 100, cellRendererFramework: 'CellRendererFormatedNumber', cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellGeneric},
            ]
        }
    },
    components: {
        AgGridTable
    },
    props: {
        displayLinkButton:{type: Boolean, default: false},
    },
    computed: {
      getEstimatedTrafficTitle() {
          return this.analyticsTitle ? this.$t('component_topBestPages_tableHeader_analyticsTraffic') : this.$t('component_topBestPages_tableHeader_estimatedTraffic')
      }
    },
    watch:{
        globalFilterLocation(){
            this.initComponentData()
        },
        globalFilterFrequency(){
            this.initComponentData(this.lastFilters)
        }
    },
    methods: {
        formatData(jsonData) {
            if (jsonData.context.inputs.trafficDataType === 'analytics') {
                this.analyticsTitle = true
            }
            return jsonData.content.map((data)=>{
                return {
                    page: {
                        href: `//${this.projectUrl}${data.url}`,
                        value:data.url,
                        targetBlank: true
                    },
                    cumulatedVisibility: this.analyticsTitle ? data.cumulatedTraffic : data.cumulatedVisibility,
                    seoPositionCount: data.seoPositionCount,
                    adsPositionCount: data.adsPositionCount,
                }
            })
        },
        initComponentData(){
            this.getComponentData({
                componentName:'topBestPages',
                promise:(!this.projectCreationWaiting && this.$router.currentRoute.params.projectId) ? apiCalls.readTopBestPages(this.$router.currentRoute.params.projectId, {params: {
                        'date-range-type':this.globalFilterFrequency,
                        'search-geolocation-id':this.globalFilterLocation
                    }}) : false,
                dataFormatter: this.formatData
            })
        },
    },
    beforeMount() {
        this.initComponentData()
    }
}
</script>

<style scoped>

</style>
