<template>
    <v-dialog v-model="show" content-class="popin-dialog-container" overlay-opacity="0.85">

        <v-icon
            class="close-popin-dialog"
            @click="show=false"
        >
            mdi-close
        </v-icon>
        <component-container
            :card-title="`${$t('component_seoVisibilityKeywordVisibilityEvolution_title')} ${elementName}`"
            :card-subtitle="`${$t('component_seoVisibilityKeywordVisibilityEvolution_subtitle')} ${elementName}`"

            :height="600"

            :component-data="{response:seoVisibilityKeywordVisibilityEvolution, hasData:seoVisibilityKeywordVisibilityEvolution.data}"

        >
            <template v-slot:content>
                <strong style="display:block;margin: 20px 0 -35px 5px; font-size: 11px">{{$t('component_seoVisibilityKeywordVisibilityEvolution_series_name')}}</strong>
                <yooda-chart
                    v-if="seoVisibilityKeywordVisibilityEvolution.data"
                    :options="seoVisibilityKeywordVisibilityEvolution.data"
                    chart-type="line"
                    chart-style="height:490px;"
                />
            </template>
        </component-container>
    </v-dialog>


</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import ECharts from 'vue-echarts'

export default {
    data() {
        return {
            show:false,
            seoVisibilityKeywordVisibilityEvolution:{  isLoaded:false, error:null,data: null },
            elementName:null,
            popinStateName:'seoVisibilityKeywordVisibilityEvolution'
        }
    },
    watch:{
        getShowPopin(){
            if(!this.show && this.getShowPopin){
                if(this.getShowPopin.componentName === this.popinStateName){
                    this.show = true
                    this.elementName = this.getShowPopin.elementName
                    this.getComponentData({
                        componentName:'seoVisibilityKeywordVisibilityEvolution',
                        promise: this.getShowPopin.promise(),
                        dataFormatter: this.formatData
                    })
                }
            }
        },
        show(){
            if(!this.show){
                this.showAgGridPopin(null)
            }
        }
    },
    components: {
        YoodaChart,
    },
    computed:{

    },
    methods:{
        formatData(jsonData){
            let serieLabel = []
            let serieData = []
            let maxValue = 10

            jsonData.content.forEach((data)=>{
                serieLabel.push(this.$options.filters.displayDateLocale(data.date))
                serieData.push({value:(data.rank?`${data.rank}`:''), date:this.$options.filters.displayDateLocale(data.date)})
                if(data.rank && data.rank > maxValue){maxValue = data.rank}
            })

            let tooltipFormatter = params => {
                return `
                    <p>
                        ${params[0].axisValue}
                        <br>
                        <span class="toolTipColorSpan" style="background-color:${params[0].color}"></span>
                        ${params[0].seriesName} : ${this.$options.filters.displayRank(params[0].value)}
                    </p>`
            }

            let yAxisData = []
            for (let i = 1; i<= maxValue; i++){
                yAxisData.push(`${i}`)
            }
            yAxisData.push('')
            return {
                grid: { left: 50, top: 55, right: 30, bottom: 40 },
                tooltip: this.genericTooltipConfiguration('axis', tooltipFormatter),
                xAxis: { type: 'category', data: serieLabel, boundaryGap: false, offset: 15 },
                // yAxis: { type: 'value', inverse:true, min:1, max:maxValue+1,minInterval: 1, boundaryGap: ['20%', '20%']},
                yAxis: { type: 'category',data: yAxisData, inverse:true, boundaryGap: false},
                series: [
                    {
                        type: 'line',
                        color: this.getThemeColorByName('primary'),
                        areaStyle: {
                            origin:'end',
                            color: new ECharts.graphic.LinearGradient(0, 0.1, 0, 0.9, [{
                                offset: 0,
                                color: this.getThemeColorByName('primary',0.2)
                            }, {
                                offset: 1,
                                color: 'rgb(255,255,255)'
                            }])
                        },
                        name: this.$i18n.t('seoVisibilityKeywordVisibilityEvolution_series_name'),
                        data: serieData,
                        markArea: {
                            itemStyle:{
                                color:'#e07a7a'
                            },
                            label:{
                                color:'#8b0000',
                                verticalAlign:'bottom'
                            },
                            data: [ [{
                                name: 'Non positionné',
                                yAxis: `${maxValue}`
                            }, {
                                yAxis: ''
                            }]]
                        }
                    }
                ]
            }
        }
    },
}
</script>

<style lang="scss">
#seo-visibility-keyword-visibility-evolution-component {
    .vs-popup{
        width: 1300px;
    }
}
</style>
