var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{attrs:{"card-title":_vm.$t('component_advertisingPerformance_title'),"card-subtitle":_vm.$t('component_advertisingPerformance_subtitle'),"height":(_vm.displayLinkButton ? 495 : 400),"information-tooltip":{
        title : _vm.$t('component_advertisingPerformance_informationTooltip_title'),
        text:_vm.$t('component_advertisingPerformance_informationTooltip_text')
    },"component-config":{
        hasConfig:_vm.analyticsViewConfiguration,
        hideOnNoConfig:_vm.hideOnNoConfig,
        redirectRoute:_vm.displayPlaceholderConfigButton ? 'analytics-project-configuration' : null,
        placeholderTitle:_vm.$t('component_componentContainer_noAnalytics_title'),
        placeholderText:_vm.$t('component_componentContainer_noAnalytics_text'),
    },"component-data":{response:_vm.advertisingPerformance,hasData:(_vm.advertisingPerformance.data && _vm.advertisingPerformance.data.series)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('yooda-chart',{attrs:{"options":_vm.advertisingPerformance.data,"chart-type":"bar","chart-style":(_vm.displayLinkButton ? 'height:345px;' : 'height:320px;')}}),(_vm.displayLinkButton)?_c('div',{staticClass:"v-application"},[_c('v-btn',{staticStyle:{"width":"calc(100% - 35px)","position":"absolute","bottom":"18px"},attrs:{"outlined":"","to":{name:'advertising'},"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('component_advertisingPerformance_detailButton'))),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-double-right")])],1)],1):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }