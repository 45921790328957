<template>
    <component-container
        :card-title="`${$t('component_reportingShareAndRegenerate_title')} ${(project.data ? (project.data.name ? project.data.name : '') : '')}`"
        :card-subtitle="$t('component_reportingShareAndRegenerate_subtitle')"
        :height="380"
        :component-data="{
            response: { isLoaded:true, error:false,  data: true },
            hasData: true
        }"
    >
        <template v-slot:content>
            <div class="v-application pt-8">

                <v-row v-if="reportings.data">
                    <v-col :cols="9">
                        <p class="font-medium mb-1 text-body-1">{{ $t('component_reportingShareAndRegenerate_linkLabel') }}</p>
                        <v-text-field
                            id="online-dashboard-link"
                            v-model="onlineDashboardLink"
                            readonly
                            dense
                            outlined
                        ></v-text-field>
                    </v-col>
                    <v-col :cols="3" class="text-left text-body-1">

                        <v-btn
                            color="primary"
                            @click="copyLink"
                            class="mt-6 mb-2"
                        >
                            <v-icon left>mdi-content-copy</v-icon>
                            {{ $t('component_reportingShareAndRegenerate_copyBtn') }}
                        </v-btn>
                        <a style="cursor: pointer; display: block" @click="confirmGenerateLink">{{ $t('component_reportingShareAndRegenerate_generateLink') }}</a>
                    </v-col>
                </v-row>

                <div class="mt-4">
                    <h6 class="text-h6" style="display: inline-block; vertical-align: middle">{{ $t('component_reportingShareAndRegenerate_whiteMark_title') }}</h6>
                    <div class="tooltip-container">
                        <information-tooltip
                            :title="$t('component_reportingShareAndRegenerate_tooltip_title')"
                            :text="$t('component_reportingShareAndRegenerate_tooltip_text')"
                        />
                    </div>
                    <v-row>
                        <v-col cols="12" class="v-application">
                            <v-switch
                                v-model="neutralDomain"
                                inset
                                :disabled="showNeutralDomain"
                                :label="$t('component_reportingShareAndRegenerate_whiteMark_switchLabel')"
                                @change="changeNeutralDomain"
                                hide-details
                                class="mt-2"
                                style="max-width: 390px"
                            />
                        </v-col>
                    </v-row>
                </div>


                <confirm-dialog :title="$t('component_reportingShareAndRegenerate_generateLink_confirmTitle')" ref="confirmGenerateLink" @confirm-dialog-valid="regenerateLink()">
                    <div v-html="$t('component_reportingShareAndRegenerate_generateLink_confirmText')"></div>
                </confirm-dialog>
            </div>
        </template>
    </component-container>

</template>


<script>


import InformationTooltip from "@/components/yooda-components/InformationTooltip";
import reportingApiCalls from '@/api/axios/dataReport/reporting.js'
import configurationApiCalls from "@/api/axios/configuration/project";
import {mapGetters} from "vuex";


export default {
    data:()=>{
        return{
            reportings:null,
            onlineDashboardLink:'',
            reportingId:'',
            neutralDomain:false,
            dateRangeType:'',
            model:''
        }
    },

    computed:{
        ...mapGetters({
            getActiveCustomerSubscription:"customersManager/getActiveCustomerSubscription",
        }),


        showNeutralDomain(){
            let disable = true
            if(this.getActiveCustomerSubscription().subscription && this.getActiveCustomerSubscription().subscription.product){
                this.getActiveCustomerSubscription().subscription.product.productOptions.forEach((productOption)=>{
                    if(productOption.handle === 'reporting-neutral-domain'){
                        if(productOption.value){disable = false}
                    }
                })
            }
            return disable
        }
    },
    methods:{
        initComponentData(){
            this.getComponentData({
                componentName:'reportings',
                promise: reportingApiCalls.readReportings(this.$router.currentRoute.params.projectId),
            }).then(()=>{ this.setOnlineDashboardLink() })

            this.setDataComponent({
                stateName:'projectInfo',
                promise: configurationApiCalls.getProjectInfo(this.$route.params.projectId),
                dataFormatter: this.projectData
            })
        },
        changeNeutralDomain(){
            reportingApiCalls.updateReporting(this.reportingId, { whiteLabel: this.neutralDomain})
                .then(()=>{
                    this.initComponentData()
                })
        },
        copyLink(){
            navigator.clipboard.writeText(this.onlineDashboardLink);
        },

        setOnlineDashboardLink(){
            if(this.reportings.data && this.reportings.data.length >0){
                this.reportings.data.forEach(data=>{
                    this.onlineDashboardLink = data.url
                    this.reportingId = data.id
                    this.neutralDomain = data.whiteLabel
                    this.dateRangeType = data.dateRangeType
                    this.model = data.model
                })
            }
        },
        confirmGenerateLink(){
            this.$refs.confirmGenerateLink.displayDialog()
        },

        regenerateLink(){
          reportingApiCalls.generateReporting(this.reportingId, {})
              .then(()=>{
                this.initComponentData()
            })
        }
    },
    components:{
        InformationTooltip
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
