<template>
    <component-container
        :card-title="$t('component_advertisingShoppingTopCompetitor_title')"
        :card-subtitle="$t('component_advertisingShoppingTopCompetitor_subtitle')"

        :height="380"

        :information-tooltip="{
            title : $t('component_advertisingShoppingTopCompetitor_informationTooltip_title'),
            text:$t('component_advertisingShoppingTopCompetitor_informationTooltip_text')
        }"

        :component-data="{response:advertisingShoppingTopCompetitor, hasData:(advertisingShoppingTopCompetitor.data && advertisingShoppingTopCompetitor.data.length > 0)}"
    >
        <template v-slot:content>
            <div>
                <div v-for="(rank, index) in advertisingShoppingTopCompetitor.data" v-bind:key="index" class="mt-1 top-competitor-progress-bar">
                    <competition-rank-progress-bar :margin-class="'mt-2'" :rank="rank"></competition-rank-progress-bar>
                </div>
            </div>
        </template>
    </component-container>
</template>


<script>

import apiCalls from '@/api/axios/dataReport/advertising.js'
import CompetitionRankProgressBar from "@/components/yooda-components/app/competition/CompetitionRankProgressBar";


export default {
    components:{
        CompetitionRankProgressBar,
    },
    data(){
        return{
            advertisingShoppingTopCompetitor:null
        }
    },
    computed:{

    },
    watch:{
        globalFilterLocation(){
            this.initComponentData()
        }
    },
    methods:{
        formatData(jsonData){

            if(jsonData.content.length === 0){return []}
            let competitors = []
            let competitorNum = 0

            jsonData.content.forEach((content)=>{
                let competitor = {
                    name: content.domainName,
                    value: content.performance
                }
                if(content.competitorType !=='project'){
                    competitorNum++
                    competitor.competitorNum = competitorNum
                }
                else{
                    competitor.projectSite = true
                }
                competitors.push(competitor)
            })

            return competitors

        },
        initComponentData(){
            this.getComponentData({
                componentName:'advertisingShoppingTopCompetitor',
                promise: !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readShoppingTopCompetitor(this.$router.currentRoute.params.projectId, {params:{
                        'search-geolocation-id':this.globalFilterLocation
                    }}) : false,
                dataFormatter: this.formatData
            })
        }
    },
    beforeMount() {
      this.initComponentData()
    },
}
</script>
