<template>
    <div>
        <v-dialog
            v-model="displayPopin"
            scrollable
            max-width="1800px"
            width="100% !important"
            attach="#model-preview-popin-container"
        >
            <div class="popin-container" style="">
                <v-btn fab small @click="displayPopin=false" style="z-index: 9999">
                    <v-icon>mdi-window-close</v-icon>
                </v-btn>
                <v-card v-if="displayPopin" style="min-height: 400px">
                    <v-card-text class="pt-6" v-if="initComplete">
                        <custom-report-model :preview-mode="true" :template-components="templateComponents.data" v-if="templateComponents"/>
                    </v-card-text>
                    <div class="v-application text-center" style="width: calc(100% - 35px); top: 50%; position: absolute" v-else>
                        <v-progress-circular
                            indeterminate
                            color="primary"
                        ></v-progress-circular>
                    </div>
                </v-card>
            </div>
        </v-dialog>

        <div id="model-preview-popin-container"></div>
    </div>

</template>


<script>


import apiCallsProject from "@/api/axios/configuration/project";

import CustomReportModel from "@/components/yooda-components/reporting/template/CustomReportModel";
import apiCallsTemplate from "@/api/axios/dataReport/template";

export default {
    props: {
        templateId:null
    },
    data:()=>{
        return{
            displayPopin:false,
            templateComponents:null,
            initComplete:false
        }
    },

    methods:{
        showPreview(){
            if(this.templateId){
                this.displayPopin = true
            }
        }
    },

    components: {
        CustomReportModel
    },
    beforeMount() {

        this.getComponentData({
            componentName:'templateComponents',
            promise: apiCallsTemplate.readTemplateById(this.templateId),
            dataFormatter: this.getTemplateComponentReporting
        }).then(()=>{

            if(this.$route.params.projectId){
                if(!this.project.isLoaded){
                    this.setDataComponent({
                        stateName:'projectInfo',
                        promise: apiCallsProject.getProjectInfo(this.$route.params.projectId, true),
                        dataFormatter: this.projectData
                    }).then((result)=>{
                        if(result){
                            this.setDefaultLocationUsage()
                            this.initComplete = true
                        }
                    })
                }
                else{
                    this.setDefaultLocationUsage()
                    this.initComplete = true
                }
            }else{
                this.initComplete = true
            }
        })
    }
}
</script>

<style lang="scss">
#model-preview-popin-container{
    .v-dialog{
        box-shadow: none !important;
        .popin-container{
            position: relative;
            padding: 10px 10px 0 0;
            overflow: hidden;

            >.v-card{
                overflow: auto;
                height: 100%;
            }

            >button{
                position: absolute;
                border-radius: 4px;
                top: 0;
                right: 0;
                z-index: 200;
            }
        }
    }
}

</style>
