var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{attrs:{"card-title":_vm.$t('component_competitionBestProgression_title'),"card-subtitle":_vm.$t('component_competitionBestProgression_subtitle'),"height":495,"information-tooltip":{
        title : _vm.$t('component_competitionBestProgression_informationTooltip_title'),
        text:_vm.$t('component_competitionBestProgression_informationTooltip_text')
    },"component-data":{
        response:_vm.competitionBestProgression,
        hasData:(_vm.competitionBestProgression.data && _vm.competitionBestProgression.data.series),
    }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('yooda-chart',{attrs:{"options":_vm.competitionBestProgression.data,"chart-type":"bar"}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }