<template>
    <component-container
        :height="135"

        :information-tooltip="{
            title : $t('component_trafficQualityTurnoverByVisitor_informationTooltip_title'),
            text:$t('component_trafficQualityTurnoverByVisitor_informationTooltip_text')
        }"

        :component-config="{
            hasConfig:analyticsViewConfiguration,
            hideOnNoConfig:hideOnNoConfig,
            redirectRoute:'analytics-project-configuration',
            placeholderTitle:$t('component_componentContainer_noAnalytics_title'),
            placeholderText:$t('component_componentContainer_noAnalytics_text')
        }"

        :component-data="{response:trafficQualityTurnoverByVisitor, hasData:true}"
    >
        <template v-slot:content>
            <div class="v-application">
                <v-row class="mt-2">
                    <v-col :cols="2" class="pt-6 text-center" >
                        <yooda-icon name="shopping-cart" class="p-3" color="success" background="rounded" :size="30"></yooda-icon>
                    </v-col>
                    <v-col :cols="10">
                        <h3 class="text-h3 font-weight-bold">{{ trafficQualityTurnoverByVisitor.data|formatNumber }} €</h3>
                        <p class="text-body-1 mb-0">{{$t('component_trafficQualityTurnoverByVisitor_title')}} {{displayKpiLabel}}</p>
                    </v-col>
                </v-row>
            </div>
        </template>
    </component-container>
</template>


<script>

import apiCalls from '@/api/axios/dataReport/traffic.js'

export default {
    props: {
        hideOnNoConfig:{type: Boolean, default: false}
    },
    data() {
        return {
            trafficQualityTurnoverByVisitor:null,
            displayKpiLabel:''
        }
    },
    computed:{

    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData()
        }
    },
    methods:{
        formatData(jsonData){
            if(jsonData.context.inputs){
                this.displayKpiLabel = this.$t(`common_periodPicker_kpiLabel_${jsonData.context.inputs.dateRangeType}`)
            }
            if(!jsonData.content.hasOwnProperty('turnoverPerUser') || jsonData.content.turnoverPerUser === null){ return 0 }
            return jsonData.content.turnoverPerUser
        },
        initComponentData(){
            this.getComponentData({
                componentName:'trafficQualityTurnoverByVisitor',
                promise: (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  && this.analyticsViewConfiguration) ? apiCalls.readQualityTurnoverByVisitor(this.$router.currentRoute.params.projectId,{params:{'date-range-type':this.globalFilterFrequency}}) : false,
                dataFormatter: this.formatData
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
