<template>
    <component-container
        :card-title="$t('component_localVisibilityCompetitor_title')"
        :card-subtitle="$t('component_localVisibilityCompetitor_subtitle')"

        :height="(filterOpen ? 625 : 525)"

        :information-tooltip="{
            title : $t('component_localVisibilityCompetitor_informationTooltip_title'),
            text:$t('component_localVisibilityCompetitor_informationTooltip_text')
        }"

        :component-config="{
            hasConfig: searchGeolocationsConfiguration.filter(data=> data.targetType !== 'country').length,
            hideOnNoConfig:hideOnNoConfig,
            redirectRoute: 'keywords-configuration',
            placeholderLocation: true,
            placeholderTitle: $t('component_componentContainer_noLocation_title'),
            placeholderText: $t('component_componentContainer_noLocation_text')
        }"

        :component-data="{response:localVisibilityCompetitor, hasData:localVisibilityCompetitor.data}"
    >
        <template v-slot:filters>
            <component-filters
                :market-segments="true"
                :result-type="true"
                @applyFilter="initComponentData"
                @filterPanelStatus="displayFilterOpen"
                :default-select-value="lastFilters"
            />
        </template>

        <template v-slot:content>
            <yooda-chart
                :options="localVisibilityCompetitor.data"
                chart-type="bar"
            />
        </template>
    </component-container>
</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/localVisbility.js'
import ComponentFilters from "@/components/yooda-components/ComponentFilters";


export default {
    props: {
        hideOnNoConfig:{type: Boolean, default: false}
    },
    data() {
        return {
            localVisibilityCompetitor:null,
            filterOpen:false,
            lastFilters:null,
        }
    },
    components: {
        YoodaChart,
        ComponentFilters,
    },
    computed:{

    },
    methods:{
        displayFilterOpen(status){ this.filterOpen = status === 'open'; },

        formatData(jsonData){

            if(jsonData.content.length === 0){
                jsonData.content = [
                    {
                        "domainName": this.projectUrl,
                        "cumulatedVisibilities": [{"name": this.searchGeolocationsConfiguration[0].name,"cumulatedVisibility": 0}]
                    },
                ]
            }
            let seriesData = []
            let legends = []
            let serieLabels = jsonData.content[0].cumulatedVisibilities.map(data => {
                return data.name
            })

            let tooltipFormatter = params => {
                let toolTip = `<p>${this.$t('component_localVisibilityCompetitor_chartTooltip')} <strong>${params[0].axisValue}</strong><br>`
                params.forEach((param)=>{
                    toolTip += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + param.color + '"></span>'
                    toolTip += param.seriesName+' '+this.$options.filters.formatNumber(param.value)+'%<br>'
                })
                toolTip+= '</p>'
                return  toolTip
            }

            let maxValues = {}
            jsonData.content.forEach(data => {
                data.cumulatedVisibilities.forEach(visibility=>{
                    if(!maxValues[visibility.name]){ maxValues[visibility.name] = 0 }
                    maxValues[visibility.name] += visibility.cumulatedVisibility
                })
            })

            let competitorNum = 0
            jsonData.content.forEach(data => {

                let color = this.getComponentColor().getCompetitorColor(null)
                if(data.competitorType !== 'project'){
                    competitorNum++
                    color = this.getComponentColor().getCompetitorColor(competitorNum)
                }

                seriesData.push({
                    name:data.domainName,
                    type: 'bar',
                    stack: 'bar',
                    label: { show: true, formatter: '{c}%' },
                    barCategoryGap: "30%",
                    data: data.cumulatedVisibilities.map(visibility=>{
                        return ((visibility.cumulatedVisibility * 100) / maxValues[visibility.name]).toFixed(2)
                    }),
                    color: color
                })


                legends.push(data.domainName)
            })

            return  {
                grid: { left: 100, top: 45, right: 30, bottom: 40 },
                tooltip: {...this.genericTooltipConfiguration('axis',tooltipFormatter),axisPointer: { type: 'shadow' }},
                legend: {type:'scroll', orient: 'horizontal', icon:'circle', data: legends },
                yAxis: { type: 'category', data: serieLabels, axisLine:{show:false} },
                xAxis: { type: 'value', show:false,max:100 },
                series: seriesData
            }
        },
        initComponentData(filters){
            this.getComponentData({
                componentName:'localVisibilityCompetitor',
                promise:(!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  && this.searchGeolocationsConfiguration) ? apiCalls.readCompetitor(this.$router.currentRoute.params.projectId,filters) : false,
                dataFormatter: this.formatData
            })
            this.lastFilters = filters
        },
    },
    beforeMount() {
        this.initComponentData()
    },
}

</script>
