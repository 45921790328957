<template>
    <component-container
        :class="{filterOpen:filterOpen}"
        :card-title="$t('component_localVisibilityKeywordsPositions_title')"
        :card-subtitle="$t('component_localVisibilityKeywordsPositions_subtitle')"

        :information-tooltip="{
                title : $t('component_localVisibilityKeywordsPositions_informationTooltip_title'),
                text:$t('component_localVisibilityKeywordsPositions_informationTooltip_text')
            }"

        :table-menu="{csv:true, fullscreen:true}"
        :table-density-selector="true"

        :component-config="{
            hasConfig: searchGeolocationsConfiguration.filter(data=> data.targetType !== 'country').length,
            hideOnNoConfig:hideOnNoConfig,
            redirectRoute: 'keywords-configuration',
            placeholderLocation: true,
            placeholderTitle: $t('component_componentContainer_noLocation_title'),
            placeholderText: $t('component_componentContainer_noLocation_text')
        }"

        :component-data="{response:localVisibilityKeywordsPositions, hasData:localVisibilityKeywordsPositions.data}"

        @setTableMenuFullscreen="cardContainerFullscreen = !cardContainerFullscreen"
        @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"

    >
        <template v-slot:filters>
            <component-filters
                :market-segments="true"
                :competitors="true"
                :competitors-with-project-website="true"
                :competitors-with-select-all="false"
                @applyFilter="initComponentData"
                @filterPanelStatus="displayFilterOpen"
                :default-select-value="lastFilters"
            />
        </template>

        <template v-slot:content>
            <ag-grid-table
                :show-floating-filter="false"
                :table-data="{columnDefs:columnDefs,rowData:localVisibilityKeywordsPositions.data}"
                :agGridStyle="`height:${tableHeightByRowCount(localVisibilityKeywordsPositions.data)}px;`"
                :is-card-container-fullscreen="cardContainerFullscreen"
                ref="agGridTable"
            />
        </template>
    </component-container>
</template>


<script>

import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable'
import apiCalls from '@/api/axios/dataReport/localVisbility.js'
import ComponentFilters from "@/components/yooda-components/ComponentFilters";
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";

export default {
    props: {
        hideOnNoConfig:{type: Boolean, default: false}
    },
    data() {
        return {
            localVisibilityKeywordsPositions:null,
            filterOpen:false,
            cardContainerFullscreen:false,
            lastFilters:null,
            columnDefs:[],
        }
    },
    components: {
        AgGridTable,
        ComponentFilters,
    },
    computed:{

    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData(this.lastFilters)
        }
    },
    methods:{
        displayFilterOpen(status){ this.filterOpen = status === 'open'; },
        formatData(jsonData){
            if(jsonData.content.length === 0){return []}
            let columnDefs = []
            columnDefs.push({headerName: this.$t('component_localVisibilityKeywordsPositions_table_header_keyword'), filter: true, field: 'string'})

            let cityCount = 0
            jsonData.context.inputs.currentReport.searchGeolocations.forEach(city => {
                cityCount++
                let header = {
                    headerName: this.$t('component_localVisibilityKeywordsPositions_table_header_city')+" "+city.name,
                    field: `city${cityCount}`,
                    cellRendererFramework: 'CellRendererRankProgression',
                    width: 90,
                    cellClass:'ag-grid-cell-right',
                    headerClass:'ag-grid-header-right',
                    comparator: cellSort.agGridTableSortCellRank
                }
                if(cityCount === 1){ header.sort = 'asc' }

                columnDefs.push(header)
            })
            this.columnDefs = columnDefs

            return jsonData.content
        },
        initComponentData(filters){
            filters.params['date-range-type'] = this.globalFilterFrequency
            this.getComponentData({
                componentName:'localVisibilityKeywordsPositions',
                promise: !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readKeywordsPositions(this.$router.currentRoute.params.projectId,filters) : false,
                dataFormatter: this.formatData
            })
            this.lastFilters = filters
        }
    },
    beforeMount() {
        this.initComponentData({
            params:{
                "domain-name":this.projectUrl,
            }
        })
    },
}
</script>
