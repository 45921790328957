<template>
    <component-container
        :card-title="$t('component_competitionNewCompetitor_title')"
        :card-subtitle="$t('component_competitionNewCompetitor_subtitle')"

        :height="(!projectCreationWaiting ? 495 : 471)"

        :information-tooltip="{
            title : $t('component_competitionNewCompetitor_informationTooltip_title'),
            text:$t('component_competitionNewCompetitor_informationTooltip_text')
        }"

        :component-data="{
            response:competitionNewCompetitor,
            hasData:(competitionNewCompetitor.data && competitionNewCompetitor.data.length > 0),
            noDataMessage:$t('component_competitionNewCompetitor_noDataMessage')
        }"
    >
        <template v-slot:content>
            <div>
                <div v-for="(rank, index) in competitionNewCompetitor.data" v-bind:key="index" class="mt-8">
                    <competition-rank-progress-bar :rank="rank"/>
                </div>
            </div>
        </template>
    </component-container>
</template>


<script>

import apiCalls from '@/api/axios/dataReport/competition.js'
import CompetitionRankProgressBar from "@/components/yooda-components/app/competition/CompetitionRankProgressBar";

export default {
    components:{
        CompetitionRankProgressBar,
    },
    data(){
        return {
            competitionNewCompetitor:null
        }
    },
    computed:{

    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData()
        },
        globalFilterLocation(){
            this.initComponentData()
        }
    },
    methods:{
        formatData(jsonData){
            if(jsonData.content.length === 0){return []}
            let formatedData = []
            jsonData.content.forEach(data=>{
                let projectSite = false
                if(data.competitorType === 'project'){
                    projectSite = true
                }
                formatedData.push({
                    name: data.domainName,
                    value: data.performance,
                    projectSite:projectSite,
                    rank: this.getRankPositionLabel(data.rank)
                })
            })

            return formatedData
        },
        initComponentData(){
            this.getComponentData({
                componentName:'competitionNewCompetitor',
                promise:!this.projectCreationWaiting && this.$router.currentRoute.params.projectId   ? apiCalls.readNewCompetitor(this.$router.currentRoute.params.projectId,{params:{
                    'date-range-type':this.globalFilterFrequency,
                    'search-geolocation-id':this.globalFilterLocation
                }}) : false,
                dataFormatter: this.formatData
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
