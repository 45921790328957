<template>

    <div :class="{'reporting-container':!previewMode}">
        <div v-if="!projectExist">
            <v-row>
                <v-col :cols="12" class="text-center" style="margin-top: 10%">
                    <img src="@/assets/images/pages/404.png" alt="graphic-404" class="mx-auto mb-4 max-w-full">
                    <h1 class="text-h1 mb-6">{{ $t('common_reporting_template_projectNotFound_title') }}</h1>
                    <p>{{ $t('common_reporting_template_projectNotFound_text') }}</p>
                </v-col>
            </v-row>
        </div>
        <div v-else>

            <display-logo-model/>

            <header-report-model :preview-mode="previewMode" />

            <div v-if="!initComplete">
                <component-loader/>
            </div>
            <div v-else>
                <v-row v-for="row in templateComponents" :key="row.row">
                    <v-col v-for="col in row.components" :key="col.id" :xl="col.width" :lg="col.width" :md="col.width" :sm="12" :cols="12">
                        <component :is="col.name" :data="col.data"></component>
                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>


<script>



import apiCalls from "@/api/axios/configuration/project";
import HeaderReportModel from "@/components/yooda-components/reporting/template/HeaderReportModel";
import DisplayLogoModel from "@/components/yooda-components/reporting/template/DisplayLogoModel";
import AdvertisingAdwordsAdvertisers from "@/components/yooda-components/app/advertising/AdvertisingAdwordsAdvertisers";
import AdvertisingAdwordsCompetitionKeywords from "@/components/yooda-components/app/advertising/AdvertisingAdwordsCompetitionKeywords";
import AdvertisingAdwordsPerformance from "@/components/yooda-components/app/advertising/AdvertisingAdwordsPerformance";
import AdvertisingAdwordsTopCompetitor from "@/components/yooda-components/app/advertising/AdvertisingAdwordsTopCompetitor";
import AdvertisingConversion from "@/components/yooda-components/app/advertising/AdvertisingConversion";
import AdvertisingIncomingTraffic from "@/components/yooda-components/app/advertising/AdvertisingIncomingTraffic";
import AdvertisingShoppingAdvertisers from "@/components/yooda-components/app/advertising/AdvertisingShoppingAdvertisers";
import AdvertisingShoppingCompetitionKeywords from "@/components/yooda-components/app/advertising/AdvertisingShoppingCompetitionKeywords";
import AdvertisingShoppingPerformance from "@/components/yooda-components/app/advertising/AdvertisingShoppingPerformance";
import AdvertisingShoppingTopCompetitor from "@/components/yooda-components/app/advertising/AdvertisingShoppingTopCompetitor";
import AdvertisingTurnover from "@/components/yooda-components/app/advertising/AdvertisingTurnover";
import CompetitionBestProgression from "@/components/yooda-components/app/competition/CompetitionBestProgression";
import CompetitionCompetitorCompare from "@/components/yooda-components/app/competition/CompetitionCompetitorCompare";
import CompetitionMarketLeader from "@/components/yooda-components/app/competition/CompetitionMarketLeader";
import CompetitionNewCompetitor from "@/components/yooda-components/app/competition/CompetitionNewCompetitor";
import CompetitionTopCompetitor from "@/components/yooda-components/app/competition/CompetitionTopCompetitor";
import CompetitionVisibilityEvolution from "@/components/yooda-components/app/competition/CompetitionVisibilityEvolution";
import AdvertisingPerformance from "@/components/yooda-components/app/dashboard/AdvertisingPerformance";
import LocalVisibilityByLocation from "@/components/yooda-components/app/dashboard/LocalVisibilityByLocation";
import MarketSegmentEvolution from "@/components/yooda-components/app/dashboard/MarketSegmentEvolution";
import MarketSegmentProgressionPotential from "@/components/yooda-components/app/dashboard/MarketSegmentProgressionPotential";
import MarketSegmentSize from "@/components/yooda-components/app/dashboard/MarketSegmentSize";
import SeoVisibilityPresenceEvolution from "@/components/yooda-components/app/dashboard/SeoVisibilityPresenceEvolution";
import TrafficAcquisitionChannel from "@/components/yooda-components/app/dashboard/TrafficAcquisitionChannel";
import TrafficEvolutionConversion from "@/components/yooda-components/app/dashboard/TrafficEvolutionConversion";
import WebsitePagesBestPages from "@/components/yooda-components/app/dashboard/WebsitePagesBestPages";
import LocalVisibilityCompetitor from "@/components/yooda-components/app/localVisibility/LocalVisibilityCompetitor";
import LocalVisibilityKeywordsPositions from "@/components/yooda-components/app/localVisibility/LocalVisibilityKeywordsPositions";
import LocalVisibilityLocalAdvertisingPresence from "@/components/yooda-components/app/localVisibility/LocalVisibilityLocalAdvertisingPresence";
import LocalVisibilityMyBusinessKeywordsPositions from "@/components/yooda-components/app/localVisibility/LocalVisibilityMyBusinessKeywordsPositions";
import MarketSegmentBestProgressionPotential from "@/components/yooda-components/app/market-segment/MarketSegmentBestProgressionPotential";
import MarketSegmentCompetitorCompare from "@/components/yooda-components/app/market-segment/MarketSegmentCompetitorCompare";
import MarketSegmentSegmentPerformance from "@/components/yooda-components/app/market-segment/MarketSegmentSegmentPerformance";
import MarketSegmentSegmentSize from "@/components/yooda-components/app/market-segment/MarketSegmentSegmentSize";
import MarketSegmentVisibility from "@/components/yooda-components/app/market-segment/MarketSegmentVisibility";
import ProgressionAxisGoogleBlockTypeFrequency from "@/components/yooda-components/app/progression-axis/ProgressionAxisGoogleBlockTypeFrequency";
import ProgressionAxisGooglePositionList from "@/components/yooda-components/app/progression-axis/ProgressionAxisGooglePositionList";
import ProgressionAxisImprovementWay from "@/components/yooda-components/app/progression-axis/ProgressionAxisImprovementWay";
import ProgressionAxisTopActionPriorities from "@/components/yooda-components/app/progression-axis/ProgressionAxisTopActionPriorities";
import SeoVisibilityCompareKeywordsPositions from "@/components/yooda-components/app/seo-visibility/SeoVisibilityCompareKeywordsPositions";
import SeoVisibilityConversion from "@/components/yooda-components/app/seo-visibility/SeoVisibilityConversion";
import SeoVisibilityIncomingTraffic from "@/components/yooda-components/app/seo-visibility/SeoVisibilityIncomingTraffic";
import SeoVisibilityNaturalPositionList from "@/components/yooda-components/app/seo-visibility/SeoVisibilityNaturalPositionList";
import SeoVisibilityPositionRepartition from "@/components/yooda-components/app/seo-visibility/SeoVisibilityPositionRepartition";
import SeoVisibilitySeoTurnover from "@/components/yooda-components/app/seo-visibility/SeoVisibilitySeoTurnover";
import SeoVisibilityTopPositionCount from "@/components/yooda-components/app/seo-visibility/SeoVisibilityTopPositionCount";
import SeoVisibilityVisibilityScore from "@/components/yooda-components/app/seo-visibility/SeoVisibilityVisibilityScore";
import TrafficEvolution from "@/components/yooda-components/app/traffic/TrafficEvolution";
import TrafficIncomingWebsite from "@/components/yooda-components/app/traffic/TrafficIncomingWebsite";
import TrafficQuality from "@/components/yooda-components/app/traffic/TrafficQuality";
import TrafficQualityConversion from "@/components/yooda-components/app/traffic/TrafficQualityConversion";
import TrafficQualitySale from "@/components/yooda-components/app/traffic/TrafficQualitySale";
import TrafficQualityTotalConversion from "@/components/yooda-components/app/traffic/TrafficQualityTotalConversion";
import TrafficQualityTotalSale from "@/components/yooda-components/app/traffic/TrafficQualityTotalSale";
import TrafficQualityTotalTurnover from "@/components/yooda-components/app/traffic/TrafficQualityTotalTurnover";
import TrafficQualityTurnoverByVisitor from "@/components/yooda-components/app/traffic/TrafficQualityTurnoverByVisitor";
import TrafficSource from "@/components/yooda-components/app/traffic/TrafficSource";
import WebsitePagesBestPositionedPages from "@/components/yooda-components/app/website-pages/WebsitePagesBestPositionedPages";
import WebsitePagesPositionedCountEvolution from "@/components/yooda-components/app/website-pages/WebsitePagesPositionedCountEvolution";
import GlobalGoogleVisibility from "@/components/yooda-components/app/dashboardRecommendations/GlobalGoogleVisibility.vue";
import ActionPriorities from "@/components/yooda-components/app/dashboardRecommendations/ActionPriorities.vue";
import TopBestKeywords from "@/components/yooda-components/app/dashboardRecommendations/TopBestKeywords.vue";
import TopBestPages from "@/components/yooda-components/app/dashboardRecommendations/TopBestPages.vue";
import TrafficQualityTotalTraffic from "@/components/yooda-components/app/traffic/TrafficQualityTotalTraffic.vue";
import MostVisibleCompetitors from "@/components/yooda-components/app/dashboardRecommendations/MostVisibleCompetitors.vue";
import ReportingBuilderCustomTitle from "@/components/yooda-components/reporting/template/ReportingBuilderCustomTitle";
import ReportingBuilderCustomText from "@/components/yooda-components/reporting/template/ReportingBuilderCustomText";
import LinkingPopularityByWebsite from "@/components/yooda-components/app/linking/LinkingPopularityByWebsite.vue";
import LinkingWebsiteAuthority from "@/components/yooda-components/app/linking/LinkingWebsiteAuthority.vue";
import LinkingLinkCount from "@/components/yooda-components/app/linking/LinkingLinkCount.vue";
import LinkingCompetitorsLinking from "@/components/yooda-components/app/linking/LinkingCompetitorsLinking.vue";
import LinkingMyThematicAuthority from "@/components/yooda-components/app/linking/LinkingMyThematicAuthority.vue";
import LinkingAuthorityCompare from "@/components/yooda-components/app/linking/LinkingAuthorityCompare.vue";
import LinkingBacklinks from "@/components/yooda-components/app/linking/LinkingBacklinks.vue";
import LinkingRefDomains from "@/components/yooda-components/app/linking/LinkingRefDomains.vue";
import LinkingAnchors from "@/components/yooda-components/app/linking/LinkingAnchors.vue";
import LinkingTopPages from "@/components/yooda-components/app/linking/LinkingTopPages.vue";

export default {
    props: {
        previewMode:{type: Boolean, default: false},
        templateComponents:{}
    },
    data(){
        return{
            initComplete:false
        }
    },
    computed:{
        projectExist(){
            return !(this.project.data === null || this.project.error);
        },
    },
    components: {
        DisplayLogoModel,
        HeaderReportModel,
        AdvertisingAdwordsAdvertisers,
        AdvertisingAdwordsCompetitionKeywords,
        AdvertisingAdwordsPerformance,
        AdvertisingAdwordsTopCompetitor,
        AdvertisingConversion,
        AdvertisingIncomingTraffic,
        AdvertisingShoppingAdvertisers,
        AdvertisingShoppingCompetitionKeywords,
        AdvertisingShoppingPerformance,
        AdvertisingShoppingTopCompetitor,
        AdvertisingTurnover,
        CompetitionBestProgression,
        CompetitionCompetitorCompare,
        CompetitionMarketLeader,
        CompetitionNewCompetitor,
        CompetitionTopCompetitor,
        CompetitionVisibilityEvolution,
        AdvertisingPerformance,
        LocalVisibilityByLocation,
        MarketSegmentEvolution,
        MarketSegmentProgressionPotential,
        MarketSegmentSize,
        SeoVisibilityPresenceEvolution,
        TrafficAcquisitionChannel,
        TrafficEvolutionConversion,
        WebsitePagesBestPages,
        LocalVisibilityCompetitor,
        LocalVisibilityKeywordsPositions,
        LocalVisibilityLocalAdvertisingPresence,
        LocalVisibilityMyBusinessKeywordsPositions,
        MarketSegmentBestProgressionPotential,
        MarketSegmentCompetitorCompare,
        MarketSegmentSegmentPerformance,
        MarketSegmentSegmentSize,
        MarketSegmentVisibility,
        ProgressionAxisGoogleBlockTypeFrequency,
        ProgressionAxisGooglePositionList,
        ProgressionAxisImprovementWay,
        ProgressionAxisTopActionPriorities,
        SeoVisibilityCompareKeywordsPositions,
        SeoVisibilityConversion,
        SeoVisibilityIncomingTraffic,
        SeoVisibilityNaturalPositionList,
        SeoVisibilityPositionRepartition,
        SeoVisibilitySeoTurnover,
        SeoVisibilityTopPositionCount,
        SeoVisibilityVisibilityScore,
        TrafficEvolution,
        TrafficIncomingWebsite,
        TrafficQuality,
        TrafficQualityConversion,
        TrafficQualitySale,
        TrafficQualityTotalConversion,
        TrafficQualityTotalSale,
        TrafficQualityTotalTurnover,
        TrafficQualityTurnoverByVisitor,
        TrafficSource,
        WebsitePagesBestPositionedPages,
        WebsitePagesPositionedCountEvolution,
        GlobalGoogleVisibility,
        ActionPriorities,
        TopBestPages,
        TopBestKeywords,
        TrafficQualityTotalTraffic,
        MostVisibleCompetitors,
        ReportingBuilderCustomTitle,
        ReportingBuilderCustomText,
        LinkingPopularityByWebsite,
        LinkingWebsiteAuthority,
        LinkingLinkCount,
        LinkingCompetitorsLinking,
        LinkingAuthorityCompare,
        LinkingMyThematicAuthority,
        LinkingBacklinks,
        LinkingRefDomains,
        LinkingAnchors,
        LinkingTopPages,
    },
    beforeMount() {

        if(!this.project.isLoaded){
            if(this.$route.params.projectId){
                this.setDataComponent({
                    stateName:'projectInfo',
                    promise: apiCalls.getProjectInfo(this.$route.params.projectId),
                    dataFormatter: this.projectData
                }).then(()=>{
                    this.initComplete = true
                })
            }
            else{
                this.initComplete = true
            }

       }
        else{
            this.initComplete = true
        }
    },
}
</script>

<style lang="scss">

.reporting-container{
    padding: 24px 64px;
    @media (max-width: 1264px) {
        padding: 24px 32px;
    }
    @media (max-width: 960px) {
        padding: 12px 24px;
    }
    @media (max-width: 600px) {
        padding: 10px 6px;
    }
}

</style>
