<template>
    <component-container
        :card-title="$t('component_competitionVisibilityEvolution_title')"
        :card-subtitle="$t('component_competitionVisibilityEvolution_subtitle')"

        :height="(filterOpen ? 620 : 520)"

        :information-tooltip="{
            title : $t('component_competitionVisibilityEvolution_informationTooltip_title'),
            text:$t('component_competitionVisibilityEvolution_informationTooltip_text'),
            width:450
        }"

        :component-config="{
            hasConfig:rivalsConfiguration.length,
            redirectRoute:'competitors-configuration',
            placeholderTitle:$t('component_componentContainer_noCompetitor_title'),
            placeholderText:$t('component_componentContainer_noCompetitor_text')
        }"

        :component-data="{
            response:competitionVisibilityEvolution,
            hasData:competitionVisibilityEvolution.data,
        }"
    >
        <template v-slot:filters>
            <component-filters
                :market-segments="true"
                :result-type="true"
                @applyFilter="initComponentData"
                @filterPanelStatus="displayFilterOpen"
                :default-select-value="lastFilters"
            />
        </template>

        <template v-slot:content>
            <yooda-chart
                :options="competitionVisibilityEvolution.data"
                chart-type="line"
                chart-style="height:380px"
            />
        </template>
    </component-container>
</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/competition.js'
import ComponentFilters from "@/components/yooda-components/ComponentFilters";


export default {
    data() {
        return {
            competitionVisibilityEvolution:null,
            filterOpen:false,
            lastFilters:null,
        }
    },
    components: {
        YoodaChart,
        ComponentFilters,
    },
    computed:{

    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData(this.lastFilters)
        },
        globalFilterLocation(){
            this.initComponentData(this.lastFilters)
        }
    },
    methods:{
        displayFilterOpen(status){ this.filterOpen = status === 'open'; },

        formatData(jsonData){
            if(jsonData.content.length === 0){
                jsonData.content = [  {
                    domainName: this.projectUrl,competitorType: "project",
                    cumulatedVisibilities: []
                }]
            }
            let seriesData = []
            let legend = []

            let seriesLabels = jsonData.content[0].cumulatedVisibilities.map(data => {
                return this.$options.filters.displayDateLocale(data.date)
            })

            let competitorNum = 0
            let selectedLegend = {}
            jsonData.content.forEach(data => {
                legend.push(data.domainName)
                let color = this.getComponentColor().getCompetitorColor(null)
                if(data.competitorType !== 'project'){
                    competitorNum++
                    color = this.getComponentColor().getCompetitorColor(competitorNum)
                }
                let lineStyle = { width:3, type:'solid', opacity: 0.6 }

                if(data.projectSite){
                    lineStyle.width = 6
                    lineStyle.shadowColor = 'rgba(114,114,114,0.5)'
                    lineStyle.shadowBlur = 10
                    lineStyle.shadowOffsetX = 0
                    lineStyle.opacity = 1
                }

                if(competitorNum > 4 && data.competitorType !== 'project'){
                    selectedLegend[data.domainName] = false
                }

                let serieConf = {
                    type: 'line',
                    showSymbol: false,
                    smooth:true,
                    symbolSize: 10,
                    name: data.domainName,
                    data: data.cumulatedVisibilities.map(serieData =>{ return { value:serieData.cumulatedVisibility, date:this.$options.filters.displayDateLocale(serieData.date) } }),
                    color:color,
                    lineStyle:lineStyle
                }

                seriesData.push(serieConf)
            })
            let tooltipFormatter = params => {
                let toolTip = '<p>'+params[0].axisValue+'<br>'
                params.forEach((param)=>{
                    toolTip += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + param.color + '"></span>'
                    toolTip += param.seriesName+' : '+this.$options.filters.formatNumber(param.value)+'<br>'
                })
                toolTip+= '</p>'
                return  toolTip
            }

            return {
                grid: { left: 50, top: 45, right: 30, bottom: 40 },
                legend: { selected: selectedLegend,padding:[5, 0, 0, 100], orient: 'horizontal', icon:'circle', data:legend },
                tooltip: this.genericTooltipConfiguration('axis', tooltipFormatter),
                xAxis: {
                    type: 'category',
                    data: seriesLabels,
                    boundaryGap: false,
                    offset: 15,
                    axisLine:{show:false}
                },
                yAxis: {
                    show:false,
                    type: 'value',
                    nameTextStyle:{ color: '#888', fontWeight: 'bold', align: 'right', lineHeight: 35 },
                    name: this.$t('component_competitionVisibilityEvolution_series_label')
                },
                series: seriesData
            }
        },
        initComponentData(filters = {params:{}}){
            filters.params['search-geolocation-id'] = this.globalFilterLocation
            filters.params['date-range-type'] = this.globalFilterFrequency
            this.getComponentData({
                componentName:'competitionVisibilityEvolution',
                promise:(!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  && this.rivalsConfiguration.length>0) ? apiCalls.readVisibilityEvolution(this.$router.currentRoute.params.projectId,filters) : false,
                dataFormatter: this.formatData
            })
            this.lastFilters = filters
        },
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
