<template>
    <div>
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <div
                    v-bind="attrs"
                    v-on="on"
                    style="width: 50px"
                >
                    <img
                        :src="getCountryFlagByCountryCode(selectedLanguage)"
                        style="width: 26px; display: inline-block; vertical-align: center"
                    />
                    <v-icon style="display: inline-block; vertical-align: center">mdi-menu-down</v-icon>

                </div>

            </template>
            <v-list>
                <v-list-item
                    v-for="(item, index) in languages"
                    :key="index"
                >
                    <v-list-item-title @click="selectedLanguage=item" style="cursor: pointer">
                        <img
                            :src="getCountryFlagByCountryCode(item)"
                            style="width: 26px; margin-right: 10px"
                        />
                        {{ $t(`component_i18nSwitcher_language_${item}`) }}
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

    </div>
</template>

<script>
export default {
    name: "I18nSwitcher",
    data(){
        return{
            selectedLanguage:'fr',
            languages:['fr', 'en']
        }
    },
    methods:{
        watchLanguageSelect(){
            localStorage.setItem('defaultLocale',this.selectedLanguage)
            location.reload()
        }
    },
    mounted() {
        if(localStorage.getItem('defaultLocale')){
            this.selectedLanguage = localStorage.getItem('defaultLocale')
        }
        this.$watch('selectedLanguage', () => { this.watchLanguageSelect() })
    }

}
</script>

<style scoped>

</style>
