<template>
    <div>
        <component-container
            :class="{filterOpen:filterOpen}"
            :card-title="$t('component_competitionMarketLeader_title')"
            :card-subtitle="$t('component_competitionMarketLeader_subtitle')"

            :information-tooltip="{
                title : $t('component_competitionMarketLeader_informationTooltip_title'),
                text:$t('component_competitionMarketLeader_informationTooltip_text')
            }"

            :table-menu="{csv:true, fullscreen:true}"
            :table-density-selector="true"

            :component-data="{response:competitionMarketLeader, hasData:competitionMarketLeader.data}"

            @setTableMenuFullscreen="cardContainerFullscreen = !cardContainerFullscreen"
            @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"

        >
            <template v-slot:filters>
                <component-filters
                    :market-segments="true"
                    :report-date="true"
                    @applyFilter="initComponentData"
                    @filterPanelStatus="displayFilterOpen"
                    :default-select-value="lastFilters"
                />
            </template>

            <template v-slot:content>
                <ag-grid-table
                    :show-floating-filter="false"
                    :table-data="{columnDefs:columnDefs,rowData:competitionMarketLeader.data}"
                    :agGridStyle="`height:${tableHeightByRowCount(competitionMarketLeader.data)}px;`"
                    :ag-grid-options="{ rowHeight: 82 }"
                    @pagination-change="onPaginationChange"
                    :is-card-container-fullscreen="cardContainerFullscreen"
                    ref="agGridTable"
                />
            </template>
        </component-container>

        <competition-market-leader-visibility-evolution/>
        <competition-market-leader-keywords/>
        <competition-market-leader-pages/>
    </div>

</template>


<script>

import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable'
import CompetitionMarketLeaderKeywords from '@/components/yooda-components/app/competition/CompetitionMarketLeaderKeywords'
import CompetitionMarketLeaderPages from '@/components/yooda-components/app/competition/CompetitionMarketLeaderPages'
import CompetitionMarketLeaderVisibilityEvolution from '@/components/yooda-components/app/competition/CompetitionMarketLeaderVisibilityEvolution'
import apiCalls from '@/api/axios/dataReport/competition.js'
import ComponentFilters from "@/components/yooda-components/ComponentFilters";
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";

export default {
    data() {
        return {
            competitionMarketLeader:null,
            filterOpen:false,
            cardContainerFullscreen:false,
            lastFilters:null,
            sparklineAlreadyLoaded:[],
            lastKeywordGroupId: null,
            columnDefs:[
                {headerName: this.$t('component_competitionMarketLeader_table_header_rank'), field: 'rank', sort:'asc', width: 70, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},
                {headerName: this.$t('component_competitionMarketLeader_table_header_rankEvolution'), field: 'rankEvolution', cellRendererFramework: 'CellRendererProgression', width: 70, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', comparator: cellSort.agGridTableSortCellRankEvolution},
                {headerName: this.$t('component_competitionMarketLeader_table_header_website'), field: 'website',filter: true, width: 110},
                {headerName: this.$t('component_competitionMarketLeader_table_header_evolution'), field: 'evolution', width: 140, cellRendererFramework: 'CellRendererPopinChartLink', sortable:false},
                {headerName: this.$t('component_competitionMarketLeader_table_header_traffic'), field: 'traffic', cellRendererFramework: 'CellRendererFormatedNumber', width: 70, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', comparator: cellSort.agGridTableSortFormattedRankValue},
                {headerName: this.$t('component_competitionMarketLeader_table_header_keywordCount'), field: 'keywordCount', cellRendererFramework: 'CellRendererPopinTableLink', width: 70, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_competitionMarketLeader_table_header_pagesCount'), field: 'pagesCount', cellRendererFramework: 'CellRendererPopinTableLink', width: 100, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', comparator: cellSort.agGridTableSortCellValue},
            ],
        }
    },
    components: {
        AgGridTable,
        CompetitionMarketLeaderKeywords,
        CompetitionMarketLeaderPages,
        CompetitionMarketLeaderVisibilityEvolution,
        ComponentFilters,
    },
    computed:{

    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData(this.lastFilters)
        },
        globalFilterLocation(){
            this.initComponentData(this.lastFilters)
        }
    },
    methods:{
        displayFilterOpen(status){ this.filterOpen = status === 'open'; },
        formatData(jsonData){
            if(jsonData.content.length === 0){return []}
            let rowData = []
            jsonData.content.forEach(data => {
                rowData[data.websiteId] = {
                    websiteId: data.websiteId,
                    domainName: data.domainName,
                    rank: data.rank,
                    rankEvolution: (data.rankEvolution ? {rank:null,rankEvolution:data.rankEvolution} :{rank:0,rankEvolution: 0}),
                    website: data.domainName,
                    evolution: {
                        componentName: 'competitionMarketLeaderVisibilityEvolution',
                        promise: () => !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readMarketLeaderVisibilityEvolution(this.$router.currentRoute.params.projectId,{
                            params:{
                                'domain-name': data.domainName,
                                'search-geolocation-id': this.globalFilterLocation,
                                'keyword-group-id': this.lastKeywordGroupId,
                                'date-range-type': this.globalFilterFrequency
                            }
                        }) : false,
                        chartData: (data.cumulatedVisibilities)?data.cumulatedVisibilities:[],
                        elementName:data.domainName
                    },
                    traffic: data.cumulatedVisibility,
                    keywordCount: {
                        componentName: 'competitionMarketLeaderKeywords',
                        value: data.rankedKeywordCount,
                        promise: () => !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readMarketLeaderKeywords(this.$router.currentRoute.params.projectId,{
                            params:{
                                'domain-name': data.domainName,
                                'search-geolocation-id': this.globalFilterLocation,
                                'date-range-type': this.globalFilterFrequency,
                                'keyword-group-id': this.lastKeywordGroupId
                            }
                        }) : false,
                        elementName: data.domainName
                    },
                    pagesCount: {
                        componentName: 'competitionMarketLeaderPages',
                        value: data.rankedPageCount,
                        promise: () => !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readMarketLeaderPages(this.$router.currentRoute.params.projectId,{
                            params:{
                                'domain-name': data.domainName,
                                'search-geolocation-id': this.globalFilterLocation,
                                'date-range-type': this.globalFilterFrequency,
                                'keyword-group-id': this.lastKeywordGroupId
                            }
                        }) : false,
                        elementName: data.domainName
                    },
                }
            })

            return rowData
        },
        // async setSparklineData(index, keyword){
        //     return apiCalls.readMarketLeaderSparkline(this.$router.currentRoute.params.projectId,{params: {keyword:keyword}})
        //         .then(async res=>{
        //             await this.$store.dispatch("components/setCompetitionMarketLeaderListSparkline",{index:index,data:res})
        //         })
        // },
        onPaginationChange(value){
            value.forEach(row=>{
                if(!this.sparklineAlreadyLoaded[row.data.websiteId]){

                    if(this.$router.currentRoute.params.projectId){
                        apiCalls.readMarketLeaderSparkline(this.$router.currentRoute.params.projectId,{
                            params: {
                                'search-geolocation-id': this.globalFilterLocation,
                                'keyword-group-id': this.lastKeywordGroupId,
                                'domain-name': row.data.domainName,
                                'date-range-type': this.globalFilterFrequency,
                            }
                        })
                            .then(async res=>{
                                if(res){
                                    this.competitionMarketLeader.data[row.data.websiteId].evolution.chartData = res.content
                                }
                            })
                    }
                    else{
                        this.competitionMarketLeader.data[row.data.websiteId].evolution.chartData = [1,8,6,7,3,2]
                    }


                    this.sparklineAlreadyLoaded[row.data.websiteId] = true
                }
            })
        },
        initComponentData(filters = {params:{}}){
            this.sparklineAlreadyLoaded = []
            this.lastKeywordGroupId = filters.params['keyword-group-id']

            filters.params['search-geolocation-id'] = this.globalFilterLocation
            filters.params['date-range-type'] = this.globalFilterFrequency

            this.getComponentData({
                componentName:'competitionMarketLeader',
                promise: !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readMarketLeader(this.$router.currentRoute.params.projectId,filters) : false,
                dataFormatter: this.formatData
            })

            this.lastFilters = filters
        },
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
