<template>

    <component-container
        :card-title="$t('component_trafficAcquisitionChannel_title')"
        :card-subtitle="$t('component_trafficAcquisitionChannel_subtitle')"

        :height="(displayLinkButton ? 495 : 400)"

        :information-tooltip="{
            title : $t('component_trafficAcquisitionChannel_informationTooltip_title'),
            text:$t('component_trafficAcquisitionChannel_informationTooltip_text')
        }"

        :analytics-period-picker="{displayFilter: (!!analyticsViewConfiguration), default:defaultAnalyticsPeriodPickerValue}"
        @analyticsPeriodSelected="initComponentData"

        :component-config="{
            hasConfig:analyticsViewConfiguration,
            hideOnNoConfig:hideOnNoConfig,
            redirectRoute:displayPlaceholderConfigButton ? 'analytics-project-configuration' : null,
            placeholderTitle:$t('component_componentContainer_noAnalytics_title'),
            placeholderText:$t('component_componentContainer_noAnalytics_text'),
        }"

        :component-data="{response:trafficAcquisitionChanel, hasData:(trafficAcquisitionChanel.data && trafficAcquisitionChanel.data.series)}"
    >

        <template v-slot:content>
            <yooda-chart
                :options="trafficAcquisitionChanel.data"
                chart-type="pie"
                chart-style="height:340px;"
            />

            <div class="v-application" v-if="displayLinkButton">
                <v-btn
                    outlined
                    :to="{name:'traffic'}"
                    color="primary"
                    style="width: calc(100% - 35px); position: absolute; bottom: 18px"
                >
                    {{ $t('component_trafficAcquisitionChannel_detailButton') }}<v-icon right>mdi-chevron-double-right</v-icon>
                </v-btn>
            </div>
        </template>
    </component-container>
</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/dashboard.js'
import { useComponentStore } from '@/stores/ComponentStore'
import {mapState} from "pinia";

export default {
    props: {
        displayLinkButton:{type: Boolean, default: false},
        displayPlaceholderConfigButton:{type: Boolean, default: true},
        hideOnNoConfig:{type: Boolean, default: false}
    },
    data(){
        return {
            trafficAcquisitionChanel:null,
            defaultAnalyticsPeriodPickerValue:30
        }
    },
    components: {
        YoodaChart
    },
    computed:{
        ...mapState(useComponentStore, {
            globalFilterFrequency: 'getGlobalFilterFrequency',
        }),
    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData()
        }
    },
    methods:{

        formatData(jsonData){
            if(jsonData.error || jsonData.content.length === 0){ return {} }

            let tooltipLabel = this.$t('component_trafficAcquisitionChannel_label')

            let tooltipFormatter = params => {
                if(this.globalFilterFrequency !== "1"){
                    return `
                    <p>
                        ${this.$t('common_tooltip_date_comparison').replace('[*STARTDATE*]', params.data.startDate).replace('[*ENDDATE*]', params.data.endDate)}
                        <br>
                        <span class="toolTipColorSpan" style="background-color:${params.color}"></span>
                        ${params.name} : ${params.percent}%
                        <br>
                        ${this.$options.filters.formatNumber(params.value)} ${tooltipLabel}
                    </p>`
                } else {
                    return `
                    <p>
                        ${params.data.endDate}
                        <br>
                        <span class="toolTipColorSpan" style="background-color:${params.color}"></span>
                        ${params.name} : ${params.percent}%
                        <br>
                        ${this.$options.filters.formatNumber(params.value)} ${tooltipLabel}
                    </p>`
                }
            }
            // let labelFormatter = params => { return  params.name+': '+this.$options.filters.formatNumber(params.percent)+'%\r\n('+this.$options.filters.formatNumber(params.value)+' '+tooltipLabel+')' }
            let labelFormatter = params => { return  params.name+': '+this.$options.filters.formatNumber(params.percent)+'%'}

            let serieData = jsonData.content.map(data=>{
                if(data.acquisitionChannelType !== 'total'){
                    return {
                        name: this.$t(`component_trafficAcquisitionChannel_${data.acquisitionChannelType}`),
                        value: data.traffic,
                        startDate: this.$options.filters.displayDateLocale(data.startDate),
                        endDate: this.$options.filters.displayDateLocale(data.endDate),
                        itemStyle:{
                            color:this.getThemeColorByName(`acquisitionChannel${data.acquisitionChannelType.charAt(0).toUpperCase()+data.acquisitionChannelType.slice(1)}`)
                        }
                    }
                }
            })

            return {
                grid: { left: 0, top: 0, right: 0, bottom: 0 },
                tooltip: this.genericTooltipConfiguration('item', tooltipFormatter),
                series:[
                    {
                        type: 'pie',
                        radius: [20, 110],
                        roseType: 'radius',
                        avoidLabelOverlap: true,
                        label: {
                            formatter:labelFormatter,
                            show: true,
                            fontWeight:600
                        },
                        emphasis: { label: { show: true }},
                        data: serieData
                    }
                ]
            }
        },
        initComponentData(){
            this.getComponentData({
                componentName:'trafficAcquisitionChanel',
                promise: (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  && this.analyticsViewConfiguration) ? apiCalls.readAcquisitionChannel(this.$router.currentRoute.params.projectId, {params:{'date-range-type':this.globalFilterFrequency}}) : false,
                dataFormatter: this.formatData,
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
