var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{attrs:{"card-title":_vm.$t('component_seoVisibilityPresenceEvolution_title'),"height":(_vm.displayLinkButton ? 495 : 400),"information-tooltip":{
        title : _vm.$t('component_seoVisibilityPresenceEvolution_informationTooltip_title'),
        text:_vm.$t('component_seoVisibilityPresenceEvolution_informationTooltip_text')
    },"component-data":{
        response:_vm.seoVisibilityPresenceEvolution,
        hasData:(_vm.seoVisibilityPresenceEvolution.data && _vm.seoVisibilityPresenceEvolution.data.options)
    },"no-padding":true},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.seoVisibilityPresenceEvolution.data)?_c('div',[_c('div',{staticClass:"v-application px-5 mt-8"},[_c('h3',{staticClass:"text-h3 font-weight-bold"},[_vm._v(_vm._s(_vm.seoVisibilityPresenceEvolution.data.labelValue)+"%")]),(_vm.seoVisibilityPresenceEvolution.data.displayedDate)?_c('p',{staticClass:"text-body-1 mb-16"},[_vm._v(_vm._s(_vm.$t('component_seoVisibilityPresenceEvolution_legend'))+" ("+_vm._s(_vm.seoVisibilityPresenceEvolution.data.displayedDate.end)+")")]):_vm._e()]),_c('yooda-chart',{attrs:{"options":_vm.seoVisibilityPresenceEvolution.data.options,"chart-type":"line","chart-style":(_vm.displayLinkButton ? 'height:190px;' : 'height:150px;')}}),(_vm.seoVisibilityPresenceEvolution.data.displayedDate)?_c('v-row',[_c('v-col',{staticClass:"pl-6",attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.seoVisibilityPresenceEvolution.data.displayedDate.start)+" ")]),_c('v-col',{staticClass:"text-right pr-6",attrs:{"cols":"6"}},[_vm._v(" "+_vm._s(_vm.seoVisibilityPresenceEvolution.data.displayedDate.end)+" ")])],1):_vm._e(),(_vm.displayLinkButton)?_c('div',{staticClass:"v-application"},[_c('v-btn',{staticStyle:{"width":"calc(100% - 35px)","position":"absolute","bottom":"18px","left":"18px"},attrs:{"outlined":"","to":{name:'seo_visibility'},"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('component_seoVisibilityPresenceEvolution_detailButton'))),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-double-right")])],1)],1):_vm._e()],1):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }