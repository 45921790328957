<template>
    <div id="advertising-shopping-performance-component">
        <advertising-performance-generic
            :data="advertisingShoppingPerformance"
            :title="$t('component_advertisingShoppingPerformance_title')"
            :subtitle="$t('component_advertisingShoppingPerformance_subtitle')"
            :label="$t('component_advertisingShoppingAveragePosition_label')"
            :tooltip-title="$t('component_advertisingShoppingPerformance_informationTooltip_title')"
            :tooltip-text="$t('component_advertisingShoppingPerformance_informationTooltip_text')"
            :no-data-message="$t(`component_advertisingShoppingPerformance_noData`)"
        >
        </advertising-performance-generic>
    </div>

</template>


<script>

import apiCalls from '@/api/axios/dataReport/advertising.js'
import AdvertisingPerformanceGeneric from "@/components/yooda-components/app/advertising/AdvertisingPerformanceGeneric";


export default {
    data() {
        return {
            advertisingShoppingPerformance:null,
        }
    },
    components: {
        AdvertisingPerformanceGeneric,
    },
    computed:{

    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData()
        },
        globalFilterLocation(){
            this.initComponentData()
        }
    },
    methods:{
        formatData(jsonData){
            if(!jsonData.content || jsonData.content.performance === null){return null}


            let sign = '+'
            if(jsonData.content.currentAverageTopPosition > jsonData.content.previousAverageTopPosition){
                sign = '-'
            }
            if(jsonData.content.currentAverageTopPosition === jsonData.content.previousAverageTopPosition){
                sign = '='
            }
            let data ={
                data: jsonData.content.performance,
                averagePosition:jsonData.content.currentAverageTopPosition,
                evolution:{ sign:sign, value:jsonData.content.averageTopPositionEvolution }
            }
            return this.genericAdvertisingPerformance(data)

        },
        initComponentData(){
            this.getComponentData({
                componentName:'advertisingShoppingPerformance',
                promise: !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readShoppingPerformance(this.$router.currentRoute.params.projectId,{params:{
                    'date-range-type':this.globalFilterFrequency,
                    'search-geolocation-id':this.globalFilterLocation
                }}) : false,
                dataFormatter: this.formatData
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
