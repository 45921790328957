<template>
    <component-container
        :card-title="$t('component_seoVisibilityTopPositionCount_title')"
        :card-subtitle="$t('component_seoVisibilityTopPositionCount_subtitle')"

        :height="(filterOpen ? 630 : 530)"

        :information-tooltip="{
            title : $t('component_seoVisibilityTopPositionCount_informationTooltip_title'),
            text:$t('component_seoVisibilityTopPositionCount_informationTooltip_text')
        }"

        :component-data="{response:seoVisibilityTopPositionCount, hasData:seoVisibilityTopPositionCount.data}"
    >
        <template v-slot:filters>
            <component-filters
                :market-segments="true"
                @applyFilter="initComponentData"
                @filterPanelStatus="displayFilterOpen"
                :default-select-value="lastFilters"
            />
        </template>

        <template v-slot:content>
            <yooda-chart
                :options="seoVisibilityTopPositionCount.data"
                chart_type="pie"
                chart-style="height: 450px;margin-bottom: -50px"
            />
        </template>
    </component-container>
</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/seoVisbility.js'
import ComponentFilters from "@/components/yooda-components/ComponentFilters";


export default {
    data() {
        return {
            seoVisibilityTopPositionCount:null,
            filterOpen:false,
            lastFilters:null,
        }
    },
    components: {
        YoodaChart,
        ComponentFilters,
    },
    computed:{

    },
    watch:{
        globalFilterLocation(){
            this.initComponentData(this.lastFilters)
        }
    },
    methods:{
        displayFilterOpen(status){ this.filterOpen = status === 'open'; },
        formatData(jsonData){

            let series = []
            let index = 1;
            let defaultStartRadius = 15
            let defaultEndRadius = 29
            let legends = []
            let colors = ['primary', 'success','warning']

            if(jsonData.content.length === 0){
                jsonData.content = [
                    {"rankedKeywordPercent": 0},
                    {"rankedKeywordPercent": 0},
                    {"rankedKeywordPercent": 0}
                ]
            }

            jsonData.content.forEach((data)=>{
                legends.push(this.$i18n.t('component_seoVisibilityTopPositionCount_serie_name_'+index))

                series.push({
                    name: this.$i18n.t('component_seoVisibilityTopPositionCount_serie_name_'+index),
                    type: 'pie',
                    animation: false,
                    radius: [defaultStartRadius+'%', defaultEndRadius+'%'],
                    startAngle:180,
                    label: { show:true,position: 'inside', formatter:'{c}%'},
                    data: [
                        {
                            value: data.rankedKeywordPercent,
                            name: this.$i18n.t('component_seoVisibilityTopPositionCount_serie_name_'+index),
                            itemStyle: { color:this.getThemeColorByName(colors[index-1]) }
                        },
                        {
                            value: parseFloat(100-data.rankedKeywordPercent).toPrecision(3),
                            name: this.$i18n.t('component_seoVisibilityTopPositionCount_serie_name_progression'),
                            itemStyle: {
                                opacity:0.2,
                                color: '#c4c4c4'
                            }
                        },
                    ]
                })

                if(defaultStartRadius === 15){
                    defaultStartRadius = 30
                    defaultEndRadius = 44
                }
                else{
                    defaultStartRadius= defaultStartRadius*2
                    defaultEndRadius++
                }
                index ++
            })

            let tooltipFormatter = params => {
                return `
                    <p>
                        <span class="toolTipColorSpan" style="background-color:${params.color}"></span>
                        ${params.seriesName} : ${params.value}%
                    </p>`
            }

            return {
                legend: {
                    orient: 'horizontal',
                    icon:'circle',
                    data: legends
                },
                grid: { left: 0, top: 0, right: 0, bottom: 0 },
                tooltip:  this.genericTooltipConfiguration('item', tooltipFormatter),
                series: series
            }
        },
        initComponentData(filters = { params: {}}){

            filters.params['search-geolocation-id'] = this.globalFilterLocation
            this.getComponentData({
                componentName:'seoVisibilityTopPositionCount',
                promise: !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readTopPositionCount(this.$router.currentRoute.params.projectId,filters) : false,
                dataFormatter: this.formatData
            })
            this.lastFilters = filters
        },
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
