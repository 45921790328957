<template>
    <div id="advertising-adwords-performance-component">
        <advertising-performance-generic
            :data="advertisingAdwordsPerformance"
            :title="$t('component_advertisingAdwordsPerformance_title')"
            :subtitle="$t('component_advertisingAdwordsPerformance_subtitle')"
            :label="$t('component_advertisingAdwordsAveragePosition_label')"
            :tooltip-title="$t('component_advertisingAdwordsAveragePosition_informationTooltip_title')"
            :tooltip-text="$t('component_advertisingAdwordsAveragePosition_informationTooltip_text')"
            :no-data-message="$t(`component_advertisingAdwordsPerformance_noData`)"
        >
        </advertising-performance-generic>
    </div>
</template>


<script>

import AdvertisingPerformanceGeneric from "@/components/yooda-components/app/advertising/AdvertisingPerformanceGeneric";
import apiCalls from '@/api/axios/dataReport/advertising.js'

export default {
    data() {
        return {
            advertisingAdwordsPerformance:null
        }
    },
    components: {
        AdvertisingPerformanceGeneric,
    },
    computed:{

    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData()
        },
        globalFilterLocation(){
            this.initComponentData()
        }
    },
    methods:{
        formatData(jsonData){
            if(!jsonData.content || jsonData.content.performance === null){return null}

            let sign = '+'
            if(jsonData.content.currentAverageTopPosition > jsonData.content.previousAverageTopPosition){
                sign = '-'
            }
            if(jsonData.content.currentAverageTopPosition === jsonData.content.previousAverageTopPosition){
                sign = '='
            }
            let data ={
                data: jsonData.content.performance,
                averagePosition:jsonData.content.currentAverageTopPosition,
                evolution:{ sign:sign, value:jsonData.content.averageTopPositionEvolution }
            }

            return this.genericAdvertisingPerformance(data)
        },
        initComponentData(){
            this.getComponentData({
                componentName:'advertisingAdwordsPerformance',
                promise: !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readAdwordsPerformance(this.$router.currentRoute.params.projectId,{params:{
                    'date-range-type':this.globalFilterFrequency,
                    'search-geolocation-id':this.globalFilterLocation
                }}) : false,
                dataFormatter: this.formatData
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
