<template>
    <div>
        <component-container
            :card-title="$t('component_linkingCompetitorsLinking_title')"
            :card-subtitle="$t('component_linkingCompetitorsLinking_subtitle')"


            :information-tooltip="{
                title : $t('component_linkingCompetitorsLinking_informationTooltip_title'),
                text:$t('component_linkingCompetitorsLinking_informationTooltip_text')
            }"

            :table-menu="{csv:true, fullscreen:true}"
            :table-density-selector="true"

            :component-data="{response:linkingCompetitorsLinking, hasData:linkingCompetitorsLinking.data}"

            @setTableMenuFullscreen="cardContainerFullscreen = !cardContainerFullscreen"
            @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"
        >
            <template v-slot:content>

                <ag-grid-table
                    :show-floating-filter="false"
                    :table-data="{columnDefs:columnDefs,rowData:linkingCompetitorsLinking.data}"
                    :agGridStyle="`height:${tableHeightByRowCount(linkingCompetitorsLinking.data)}px;`"
                    :is-card-container-fullscreen="cardContainerFullscreen"
                    ref="agGridTable"
                />
            </template>

        </component-container>

        <linking-competitors-linking-referral-domains/>
    </div>

</template>


<script>

import LinkingCompetitorsLinkingReferralDomains from "@/components/yooda-components/app/linking/popin/LinkingCompetitorsLinkingReferralDomains.vue";
import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/linking.js'
import { useComponentStore } from '@/stores/ComponentStore'
import {mapState} from "pinia";
import AgGridTable from "@/components/yooda-components/ag-grid-table/AgGridTable.vue";
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";

export default {
    data() {
        return {
            linkingCompetitorsLinking:null,
            cardContainerFullscreen:false,
            columnDefs:[
                {headerName: this.$t('component_linkingCompetitorsLinking_table_header_ranking'),filter: false, field: 'seoRank', sort:'asc', width: 80, cellClass:'ag-grid-cell-left'},
                {headerName: this.$t('component_linkingCompetitorsLinking_table_header_rank'),filter: true,field: 'linkingRank', width: 140, cellClass:'ag-grid-cell-left',   cellStyle: params => {
                        if (params.value === this.project.data.url) { return {fontWeight: '600'}; }
                        return null;
                    }},

                {headerName: this.$t('component_linkingCompetitorsLinking_table_header_popularity'),filter: false, field: 'popularity', sortingOrder: ['desc','asc'], cellRendererFramework: 'CellRendererLinkingProgressBar', width: 120, cellClass:'ag-grid-cell-left',comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_linkingCompetitorsLinking_table_header_authority'),filter: false, field: 'authority', sortingOrder: ['desc','asc'], cellRendererFramework: 'CellRendererLinkingProgressBar', width: 120, cellClass:'ag-grid-cell-left', comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_linkingCompetitorsLinking_table_header_thematic'),filter: false, field: 'thematic', sortingOrder: ['desc','asc'],  cellRendererFramework: 'CellRendererLinkingThematic', cellClass:'ag-grid-cell-left',comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_linkingCompetitorsLinking_table_header_referralDomains'),filter: false, field: 'referralDomainCount', sortingOrder: ['desc','asc'], width: 70, cellRendererFramework: 'CellRendererPopinTableLink', cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellValue},
            ]
        }
    },
    components: {
        LinkingCompetitorsLinkingReferralDomains,
        AgGridTable,
        YoodaChart,
    },
    computed:{
        ...mapState(useComponentStore, {
            globalFilterFrequency: 'getGlobalFilterFrequency',
        }),
    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData()
        }
    },
    methods:{



        formatData(jsonData){
            if(jsonData.content.length === 0){return []}
            let rowData = []
            jsonData.content.forEach(data => {
                let thematicData = this.getThematicData(data)

                let filters = {
                    params: {
                        'date-range-type':this.globalFilterFrequency
                    }
                }
                rowData.push({
                    seoRank:data.seoRank,
                    linkingRank:data.domainName,
                    popularity: {
                        value: data.popularity,
                        color: '#b9a6da',
                    },
                    authority: {
                        value: data.authority,
                        color: '#8dc9cb',
                    },
                    thematic:{
                        value:thematicData.mainThematic.value,
                        color:thematicData.mainThematic.color,
                        categories:thematicData.mainThematic.categories,
                        otherThematic:thematicData.otherThematic
                    },
                    // referralDomainCount:data.refDomainCount
                    referralDomainCount:(data.refDomainCount ? {
                        componentName:!this.isReportingRoute ? 'linkingCompetitorsLinkingReferralDomains' : null,
                        value:data.refDomainCount,
                        displayedValue:this.$options.filters.formatNumber(data.refDomainCount),
                        promise: () => !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ?  apiCalls.readCompetitorsLinkingPopin(this.$router.currentRoute.params.projectId, data.id, filters) : false,
                        elementName:data.domainName
                    } :  {value:0})
                })
            })


            return rowData
        },
        initComponentData(){
            this.getComponentData({
                componentName:'linkingCompetitorsLinking',
                promise: (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId ) ? apiCalls.readCompetitorsLinking(this.$router.currentRoute.params.projectId,{params:{'date-range-type':this.globalFilterFrequency}}) : false,
                dataFormatter: this.formatData
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
