<template>
    <component-container
        :card-title="$t('component_mostVisibleCompetitors_title')"
        :height="495"

        :component-data="{response:mostVisibleCompetitors,hasData:mostVisibleCompetitors.data}"

        @setTableMenuFullscreen="cardContainerFullscreen = !cardContainerFullscreen"
    >

        <template v-slot:content>
            <div class="v-application pt-4">
                <ag-grid-table
                    :show-floating-filter="false"
                    :table-data="{columnDefs:columnDefs,rowData:mostVisibleCompetitors.data}"
                    :agGridStyle="`height:${tableHeightByRowCount(mostVisibleCompetitors.data)}px;`"
                    :is-card-container-fullscreen="cardContainerFullscreen"
                    :show-pagination="false"
                    ref="agGridTable"
                />
                <div v-if="displayLinkButton">
                    <v-btn
                        outlined
                        :to="{name:'competitor'}"
                        color="primary"
                        style="width: calc(100% - 35px); position: absolute; bottom: 18px"
                    >
                        {{ $t('component_mostVisibleCompetitors_cta') }}<v-icon right>mdi-chevron-double-right</v-icon>
                    </v-btn>
                </div>
            </div>
        </template>

    </component-container>
</template>

<script>
import apiCalls from "@/api/axios/dataReport/dashboardRecommendations";
import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable'
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";

export default {
    name: "MostVisibleCompetitors",
    data() {
        return {
            mostVisibleCompetitors: null,
            cardContainerFullscreen:true,
            columnDefs: [
                {headerName: this.$t('component_mostVisibleCompetitors_tableHeader_competitor'), field: 'domainName', width: 170, cellRendererFramework: 'CellRendererCompetitorDisplay', cellClass:'ag-grid-cell-left', headerClass:'ag-grid-header-left'},
                {headerName: this.$t('component_mostVisibleCompetitors_tableHeader_rank'), field: 'rank', width: 110, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', sort: 'asc', comparator: cellSort.agGridTableSortFormattedRankValue},
                {headerName: this.$t('component_mostVisibleCompetitors_tableHeader_estimatedTraffic'), field: 'cumulatedVisibility',  width: 95, cellRendererFramework: 'CellRendererFormatedNumber', cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellTraffic},
                {headerName: this.$t('component_mostVisibleCompetitors_tableHeader_keywordsCount'), field: 'rankedKeywordCount', width: 95, cellClass:'ag-grid-cell-right', cellRendererFramework: 'CellRendererFormatedNumber', headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellGeneric},
            ]
        }
    },
    components: {
        AgGridTable
    },
    props: {
        displayLinkButton:{type: Boolean, default: false},
    },
    watch:{
        globalFilterLocation(){
            this.initComponentData()
        }
    },
    methods: {
        formatData(jsonData) {
            return jsonData.content.map((data)=>{
                return {
                    domainName: data.domainName,
                    cumulatedVisibility: data.cumulatedVisibility,
                    rankedKeywordCount: data.rankedKeywordCount,
                    competitorType: data.competitorType,
                    rank: this.$options.filters.displayRank(data.rank),
                }
            })
        },
        initComponentData(){
            this.getComponentData({
                componentName:'mostVisibleCompetitors',
                promise:(!this.projectCreationWaiting && this.$router.currentRoute.params.projectId) ? apiCalls.readMostVisibleCompetitors(this.$router.currentRoute.params.projectId, {params: {
                        'date-range-type':this.globalFilterFrequency,
                        'search-geolocation-id':this.globalFilterLocation
                    }}) : false,
                dataFormatter: this.formatData
            })
        },
    },
    beforeMount() {
        this.initComponentData()
    }
}
</script>

<style scoped>

</style>
