<template>
    <div>
        <component-container
            :class="{filterOpen:filterOpen}"
            :card-title="$t('component_advertisingShoppingAdvertisers_title')"
            :card-subtitle="$t('component_advertisingShoppingAdvertisers_subtitle')"


            :information-tooltip="{
                title: $t('component_advertisingShoppingAdvertisers_informationTooltip_title'),
                text: $t('component_advertisingShoppingAdvertisers_informationTooltip_text')
            }"

            :table-menu="{csv:true, fullscreen:true}"
            :table-density-selector="true"

            :component-data="{response:advertisingShoppingAdvertisers, hasData:advertisingShoppingAdvertisers.data}"

            @setTableMenuFullscreen="cardContainerFullscreen = !cardContainerFullscreen"

            @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"

            :no-shadow="true"
        >
            <template v-slot:filters>
                <component-filters

                    :market-segments="true"
                    @applyFilter="initComponentData"
                    @filterPanelStatus="displayFilterOpen"
                    :default-select-value="lastFilters"
                />
            </template>

            <template v-slot:content>
                <ag-grid-table
                    :show-floating-filter="false"
                    :table-data="{columnDefs:columnDefs,rowData:advertisingShoppingAdvertisers.data}"
                    :agGridStyle="`height:${tableHeightByRowCount(advertisingShoppingAdvertisers.data)}px;`"
                    :is-card-container-fullscreen="cardContainerFullscreen"
                    ref="agGridTable"
                />
            </template>
        </component-container>

        <advertising-shopping-advertiser-keywords/>
        <advertising-shopping-advertiser-ads/>
    </div>
</template>


<script>

import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable'

import AdvertisingShoppingAdvertiserKeywords from '@/components/yooda-components/app/advertising/AdvertisingShoppingAdvertiserKeywords'
import AdvertisingShoppingAdvertiserAds from '@/components/yooda-components/app/advertising/AdvertisingShoppingAdvertiserAds'
import apiCalls from '@/api/axios/dataReport/advertising.js'
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";

import ComponentFilters from "@/components/yooda-components/ComponentFilters";

export default {
    data() {
        return {
            advertisingShoppingAdvertisers:null,
            filterOpen:false,
            cardContainerFullscreen:false,
            lastKeywordGroupId: null,
            lastFilters: null,
            columnDefs: [
                {headerName: this.$t('component_advertisingShoppingAdvertisers_table_header_advertiser'), field: 'advertiser',filter: true},
                {headerName: this.$t('component_advertisingShoppingAdvertisers_table_header_shoppingPerformance'), field: 'shoppingPerformance',  sort:'desc', cellRendererFramework: 'CellRendererPercent', width: 110, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},
                {headerName: this.$t('component_advertisingShoppingAdvertisers_table_header_keywordCount'), field: 'keywordCount', cellRendererFramework: 'CellRendererPopinTableLink', width: 90, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_advertisingShoppingAdvertisers_table_header_averagePosition'), field: 'averagePosition', width: 90, cellClass:'ag-grid-cell-right', cellRendererFramework: 'CellRendererFormatedNumber',headerClass:'ag-grid-header-right'},
                {headerName: this.$t('component_advertisingShoppingAdvertisers_table_header_adCount'), field: 'adCount', cellRendererFramework: 'CellRendererPopinTableLink', width: 90, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellValue},
            ],
        }
    },
    components: {
        AgGridTable,
        AdvertisingShoppingAdvertiserKeywords,
        AdvertisingShoppingAdvertiserAds,
        ComponentFilters,
    },
    computed:{

    },
    watch:{
        globalFilterLocation(){
            this.initComponentData(this.lastFilters)
        }
    },
    methods:{
        displayFilterOpen(status){ this.filterOpen = status === 'open'; },
        formatData(jsonData){
            if(jsonData.content.length === 0){return []}
            let rowData = []
            jsonData.content.forEach(data => {
                let filters = {
                    params: {
                        "website-id":data.websiteId,
                        "search-geolocation-id": this.globalFilterLocation,
                        "keyword-group-id": this.lastKeywordGroupId ? this.lastKeywordGroupId : null
                    }
                }
                rowData.push({
                    advertiser: data.domainName,
                    shoppingPerformance: data.performance,
                    keywordCount: (data.rankedKeywordCount ? {
                        componentName: 'advertisingShoppingAdvertiserKeywords',
                        value: data.rankedKeywordCount,
                        promise: () => !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readShoppingAdvertiserKeywords(this.$router.currentRoute.params.projectId, filters) : false,
                        elementName: data.domainName
                    } :  {value:0}),
                    averagePosition: data.averageTopPosition,
                    adCount: (data.distinctAdsCount ? {
                        componentName: 'advertisingShoppingAdvertiserAds',
                        value: data.distinctAdsCount,
                        promise: () => !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readShoppingAdvertiserAds(this.$router.currentRoute.params.projectId, filters) : false,
                        elementName: data.domainName
                    } :  {value:0}),
                })
            })
            return rowData
        },
        initComponentData(filters = {params:{}}){
            filters.params["search-geolocation-id"] = this.globalFilterLocation
            this.lastKeywordGroupId = filters.params["keyword-group-id"]

            this.getComponentData({
                componentName:'advertisingShoppingAdvertisers',
                promise: !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readShoppingAdvertisers(this.$router.currentRoute.params.projectId,filters) : false,
                dataFormatter: this.formatData
            })
            this.lastFilters = filters
        },
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
