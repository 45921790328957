<template>
    <component-container
        :card-title="$t('component_competitionCompetitorCompare_title')"
        :card-subtitle="$t('component_competitionCompetitorCompare_subtitle')"

        :height="(filterOpen ? 620 : 520)"

        :information-tooltip="{
            title : $t('component_competitionCompetitorCompare_informationTooltip_title'),
            text:$t('component_competitionCompetitorCompare_informationTooltip_text')
        }"

        :component-config="{
            hasConfig:rivalsConfiguration.length,
            redirectRoute:'competitors-configuration',
            placeholderTitle:$t('component_componentContainer_noCompetitor_title'),
            placeholderText:$t('component_componentContainer_noCompetitor_text')
        }"

        :component-data="{
            response:competitionCompetitorCompare,
            hasData:competitionCompetitorCompare.data,
        }"
    >
        <template v-slot:filters>
            <component-filters

                :market-segments="true"
                :result-type="true"
                @applyFilter="initComponentData"
                @filterPanelStatus="displayFilterOpen"
                :default-select-value="lastFilters"
            />
        </template>

        <template v-slot:content>
            <yooda-chart
                :options="competitionCompetitorCompare.data"
                chart-type="radar"
                :chart-style="`height:${(rivalsConfiguration.length ? 440 : 425)}px;margin-top: -20px`"
            />
        </template>
    </component-container>

</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/competition.js'
import ComponentFilters from "@/components/yooda-components/ComponentFilters";


export default {
    data() {
        return {
            competitionCompetitorCompare:null,
            filterOpen:false,
            lastFilters:null,
        }
    },
    components: {
        YoodaChart,
        ComponentFilters,
    },
    computed:{

    },
    watch:{
        globalFilterLocation(){
            this.initComponentData(this.lastFilters)
        }
    },
    methods:{
        displayFilterOpen(status){ this.filterOpen = status === 'open'; },
        formatData(jsonData){

            let values = []
            let maxValue = 0

            jsonData.content.forEach(data=>{
                values.push(data.performance)
                if(data.performance>maxValue){maxValue = data.performance}
            })
            values.sort(function(a,b) { return b - a})

            if(maxValue === 0){ maxValue = 100 }

            let serieLabels = jsonData.content.map(data=>{
                return {name:data.domainName, max:maxValue}
            })
            let tooltipFormatter = params => {
                let toolTip = '<p><strong>'+params.name+'</strong><br>'
                params.data.value.forEach((param,index)=>{
                    toolTip += serieLabels[index].name+' : '+this.$options.filters.formatNumber(param)+'<br>'
                })
                toolTip+= '</p>'
                return  toolTip
            }

            return {
                grid: { left: 50, top: 25, right: 30, bottom: 40 },
                tooltip: this.genericTooltipConfiguration('item', tooltipFormatter),
                radar:{ indicator: serieLabels },
                series:[{ type:'radar', areaStyle: { opacity:0.4 }, data:[{value:values,name:this.$t('common_rank_performance')}] }]
            }
        },
        initComponentData(filters = {params:{}}){
            filters.params['search-geolocation-id'] = this.globalFilterLocation

            this.getComponentData({
                componentName:'competitionCompetitorCompare',
                promise:(!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  && this.rivalsConfiguration.length>0) ? apiCalls.readCompetitorCompare(this.$router.currentRoute.params.projectId,filters) : false,
                dataFormatter: this.formatData
            })
            this.lastFilters = filters
        },
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
