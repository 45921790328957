<template>
    <div>
        <div class="v-application" :class="marginClass">
            <p class="text-body-1 mb-2">
                <span v-if="displayCompetitorChip" class="competitorChip" :style="'background-color:'+getComponentColor().getCompetitorColor(rank.competitorNum)"></span>
                <strong>{{rank.rank}}</strong> {{ rank.name }} : {{ rank.value }}<span v-if="rank.value">%</span>
            </p>
            <vs-progress :percent="parseFloat(rank.value)" :color="getProgressBarCompetitorColor(rank.projectSite, rank.competitorNum)" :height="16"></vs-progress>
        </div>
    </div>

</template>


<script>


export default {
    props:{
        rank:{},
        marginClass:{
            type: String,
            default: 'mt-6'
        }
    },
    computed:{
        displayCompetitorChip(){
            if(this.rank.projectSite || this.rank.competitorNum){
                return true
            }
            return false
        },
    },
    methods:{
        getProgressBarCompetitorColor(projectSite, competitorNum){
            let color = this.getThemeColorByName('genericCompetitor')
            if(projectSite){
                color = this.getThemeColorByName('primary')
            }
            if(competitorNum){
                color = this.getThemeColorByName('primary',0.3)
            }
            return color
        },
    },
}
</script>

<style lang="scss">
    .competitorChip{
        display:inline-block;
        margin:0 5px -5px 0;
        border-radius:20px;
        width:19px;
        height:19px;
    }
</style>
