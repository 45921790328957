<template>
    <component-container
        :card-title="$t('component_competitionBestProgression_title')"
        :card-subtitle="$t('component_competitionBestProgression_subtitle')"

        :height="495"

        :information-tooltip="{
            title : $t('component_competitionBestProgression_informationTooltip_title'),
            text:$t('component_competitionBestProgression_informationTooltip_text')
        }"


        :component-data="{
            response:competitionBestProgression,
            hasData:(competitionBestProgression.data && competitionBestProgression.data.series),
        }"
    >
        <template v-slot:content>
            <yooda-chart
                :options="competitionBestProgression.data"
                chart-type="bar"
            />
        </template>
    </component-container>

</template>


<script>


import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/competition.js'


export default {
    components: {
        YoodaChart,
    },
    data(){
        return {
            competitionBestProgression:null
        }
    },
    computed:{

    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData()
        },
        globalFilterLocation(){
            this.initComponentData()
        }
    },
    methods:{
        formatData(jsonData){
            if(jsonData.content.length === 0){return {}}
            let max = 0
            let competitorNum = 1
            let series = []
            let colors = []
            let legend = []

            jsonData.content.forEach(data => {
                if(data.rankEvolution > max){max = data.rankEvolution}

                series.push({
                    name:data.domainName,
                    type: 'bar',
                    data: [{value:data.rankEvolution, itemStyle:{color:this.getComponentColor().getCompetitorColor(competitorNum)}}],
                    itemStyle:{normal:{barBorderRadius: [10,10,0,0]}},showBackground: true
                })

                colors.push(this.getComponentColor().getCompetitorColor(competitorNum))
                legend.push({name:data.domainName,})
                competitorNum++
            })

            let tooltipLabel = this.$t('component_competitionBestProgression_chartTooltipLabel')

            let tooltipFormatter = params => {
                return `
                    <p>
                        <span class="toolTipColorSpan" style="background-color:${params.color}"></span>
                        ${params.seriesName} : ${params.value} ${tooltipLabel}
                    </p>`
            }

            return {
                grid: { left: 5, top: 50, right: 5, bottom: 30 },
                color:colors,
                legend: { orient: 'horizontal', icon:'circle', data:legend },
                tooltip: this.genericTooltipConfiguration('item', tooltipFormatter),
                xAxis: { type: 'category', show:false },
                yAxis: { show: false, type: 'value', max:(max+1) },
                series: series,
                label: { show: true, position: 'insideBottom', formatter: `{c} \r\n ${this.$t('common_rank_placesWon')}` },
            }
        },
        initComponentData(){
            this.getComponentData({
                componentName:'competitionBestProgression',
                promise:(!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ) ? apiCalls.readBestProgression(this.$router.currentRoute.params.projectId,{params:{
                    'date-range-type':this.globalFilterFrequency,
                     'search-geolocation-id':this.globalFilterLocation
                }}) : false,
                dataFormatter: this.formatData
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
