import { render, staticRenderFns } from "./LinkingTopPages.vue?vue&type=template&id=22a57a85&"
import script from "./LinkingTopPages.vue?vue&type=script&lang=js&"
export * from "./LinkingTopPages.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports