<template>

    <v-dialog v-model="show" :content-class="`popin-dialog-container ${filterOpen ? 'filter-open' : ''}`" overlay-opacity="0.85">

        <v-icon
            class="close-popin-dialog"
            @click="show=false"
        >
            mdi-close
        </v-icon>
        <component-container
            :card-title="`${$t('component_competitionMarketLeaderKeywords_title')} ${elementName}`"
            :card-subtitle="$t('component_competitionMarketLeaderKeywords_subtitle')"



            :component-data="{response:competitionMarketLeaderKeywords, hasData:competitionMarketLeaderKeywords.data}"

            :table-menu="{csv:true}"
            :table-density-selector="true"
            @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"
        >
            <template v-slot:filters>
                <component-filters
                    class="mt-10"
                    :market-segments="true"
                    @applyFilter="initComponentData"
                    @filterPanelStatus="displayFilterOpen"
                    :default-select-value="lastFilters"
                />
            </template>

            <template v-slot:content>
                <ag-grid-table v-if="competitionMarketLeaderKeywords.data"
                       :with-fullscreen="false"
                       class="table-with-filter"
                       :show-pagination="false"
                       :show-floating-filter="false"
                       :table-data="{columnDefs:columnDefs,rowData:competitionMarketLeaderKeywords.data}"
                       ref="agGridTable"
                />
            </template>
        </component-container>

    </v-dialog>

</template>


<script>

import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable'


import ComponentFilters from "@/components/yooda-components/ComponentFilters";
import apiCalls from "@/api/axios/dataReport/competition";
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";

export default {
    data() {
        return {
            show:false,
            competitionMarketLeaderKeywords:{  isLoaded:false, error:null,data: null },
            elementName:null,
            filterOpen:false,
            lastFilters:null,
            popinStateName:'competitionMarketLeaderKeywords',
            columnDefs:[
                {headerName: this.$t('component_competitionMarketLeaderKeywords_table_header_keyword'), field: 'keyword',filter: true},
                {headerName: this.$t('component_competitionMarketLeaderKeywords_table_header_seo'), field: 'seo', sort:'asc', cellRendererFramework: 'CellRendererRankProgression', width: 70, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', comparator: cellSort.agGridTableSortCellRank},
                {headerName: this.$t('component_competitionMarketLeaderKeywords_table_header_image'), field: 'image', cellRendererFramework: 'CellRendererRankProgression', width: 70, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', comparator: cellSort.agGridTableSortCellRank},
                {headerName: this.$t('component_competitionMarketLeaderKeywords_table_header_map'), field: 'map', cellRendererFramework: 'CellRendererRankProgression', width: 70, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', comparator: cellSort.agGridTableSortCellRank},
                {headerName: this.$t('component_competitionMarketLeaderKeywords_table_header_zero'), field: 'zero', cellRendererFramework: 'CellRendererWithIcon', width: 70, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},
                {headerName: this.$t('component_competitionMarketLeaderKeywords_table_header_video'), field: 'video', cellRendererFramework: 'CellRendererRankProgression', width: 70, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', comparator: cellSort.agGridTableSortCellRank},
                {headerName: this.$t('component_competitionMarketLeaderKeywords_table_header_adwords'), field: 'adwords', cellRendererFramework: 'CellRendererRankProgression', width: 70, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', comparator: cellSort.agGridTableSortCellRank},
                {headerName: this.$t('component_competitionMarketLeaderKeywords_table_header_shopping'), field: 'shopping', cellRendererFramework: 'CellRendererRankProgression', width: 70, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', comparator: cellSort.agGridTableSortCellRank},
            ]
        }
    },
    components: {
        AgGridTable,
        ComponentFilters,
    },
    watch:{
        getShowPopin(){
            if(!this.show && this.getShowPopin){
                if(this.getShowPopin.componentName === this.popinStateName){
                    this.show = true
                    this.elementName = this.getShowPopin.elementName
                    this.getComponentData({
                        componentName:'competitionMarketLeaderKeywords',
                        promise: this.getShowPopin.promise(),
                        dataFormatter: this.formatData
                    })
                }
            }
        },
        show(){
            if(!this.show){
                this.showAgGridPopin(null)
            }
        }
    },
    computed:{

    },
    methods:{
        displayFilterOpen(status){ this.filterOpen = status === 'open'; },
        formatData(jsonData){
            let rowData = []
            jsonData.content.forEach(data => {
                let zeroIconName = 'check'
                let zeroIconColor = 'positiveVariation'
                let checkValue = ''
                if(!data.zero){
                    checkValue = '-'
                    zeroIconName = ''
                }
                rowData.push({
                    keyword: data.string,
                    seo: (data.natural ? data.natural : null),
                    image: (data.image ? data.image : null),
                    map: (data.map ? data.map : null),
                    zero: {
                        value: checkValue,
                        iconName: zeroIconName,
                        iconColor: zeroIconColor,
                        iconSize:12
                    },
                    video:(data.video ? data.video : null),
                    adwords: (data.adwords ? data.adwords : null),
                    shopping: (data.shopping ? data.shopping : null),
                })
            })
            return rowData
        },
        initComponentData(filters){
            filters.params['domain-name'] = this.elementName
            filters.params['date-range-type'] = this.globalFilterFrequency
            filters.params['search-geolocation-id'] = this.globalFilterLocation


             this.getComponentData( {
                 componentName:'competitionMarketLeaderKeywords',
                promise: apiCalls.readMarketLeaderKeywords(this.$router.currentRoute.params.projectId,filters),
                dataFormatter:  this.formatData
            })
            this.lastFilters = filters
        },
    },
}
</script>
