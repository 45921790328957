<template>
    <div>
        <component-container
            :card-title="$t('component_linkingMyThematicAuthority_title')"
            :card-subtitle="$t('component_linkingMyThematicAuthority_subtitle')"

            :height="630"
            :information-tooltip="{
                title : $t('component_linkingMyThematicAuthority_informationTooltip_title'),
                text:$t('component_linkingMyThematicAuthority_informationTooltip_text')
            }"


            :table-density-selector="false"
            :table-menu="{fullscreen:false, csv:true}"
            @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"

            :component-data="{response:linkingMyThematicAuthority, hasData:linkingMyThematicAuthority.data, noDataMessage:$t('component_linkingMyThematicAuthority_no_data_text')}"
        >
            <template v-slot:content>
                <ag-grid-table
                    :show-floating-filter="false"
                    :table-data="{columnDefs:columnDefs,rowData:linkingMyThematicAuthority.data}"
                    :agGridStyle="`height:480px;`"
                    :pagination-size="7"
                    :is-card-container-fullscreen="cardContainerFullscreen"
                    ref="agGridTable"
                    :ag-grid-options="{ rowHeight: 55 }"
                />
            </template>

        </component-container>

    </div>

</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/linking.js'
import { useComponentStore } from '@/stores/ComponentStore'
import {mapState} from "pinia";
import AgGridTable from "@/components/yooda-components/ag-grid-table/AgGridTable.vue";
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";

export default {
    data() {
        return {
            linkingMyThematicAuthority:null,
            cardContainerFullscreen:false,
            columnDefs:[
                {headerName: this.$t('component_linkingMyThematicAuthority_table_header_thematic'),filter: true,filterParams: {valueGetter: params => { return params.data.thematic.categories.toString() }}, sortable:false, field: 'thematic',   cellRendererFramework: 'CellRendererLinkingThematic', cellClass:'ag-grid-cell-left', headerClass:'ag-grid-header-left',comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_linkingMyThematicAuthority_table_header_authority'),filter: false, field: 'authority', sortingOrder: ['desc','asc'], sort:'desc',  cellRendererFramework: 'CellRendererLinkingProgressBar', width: 95, cellClass:'ag-grid-cell-left', headerClass:'ag-grid-header-left',comparator: cellSort.agGridTableSortCellValue},
            ]
        }
    },
    components: {
        AgGridTable,
        YoodaChart
    },
    computed:{
        ...mapState(useComponentStore, {
            globalFilterFrequency: 'getGlobalFilterFrequency',
        }),
    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData()
        }
    },
    methods:{



        formatData(jsonData){
            if(jsonData.content.length === 0){return null}
            let rowData = []
            jsonData.content.forEach(data => {

                let thematic = { value:0, color:null, categories:null }

                if(data.topic){
                    thematic.value = data.authority
                    thematic.color = this.getLinkingThematicColor(data.topic.split('/')[0])
                    thematic.categories = data.topic.split('/')
                }
                rowData.push({
                    thematic:{
                        value:thematic.value,
                        color:thematic.color,
                        categories:thematic.categories,
                        disabledHover:true
                    },
                    authority: {
                        value: data.authority,
                        color: thematic.color,
                    },
                })
            })


            return rowData
        },
        initComponentData(){
            this.getComponentData({
                componentName:'linkingMyThematicAuthority',
                promise: (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId ) ? apiCalls.readMyThematicAuthority(this.$router.currentRoute.params.projectId,{params:{'date-range-type':this.globalFilterFrequency}}) : false,
                dataFormatter: this.formatData
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
