<template>
    <component-container
        :card-title="$t('component_topBestKeywords_title')"
        :height="495"

        :component-data="{response:topBestKeywords,hasData:true}"

        @setTableMenuFullscreen="cardContainerFullscreen = !cardContainerFullscreen"
    >

        <template v-slot:content>
            <div class="v-application pt-4">
                <ag-grid-table
                    :show-floating-filter="false"
                    :table-data="{columnDefs:columnDefs,rowData:topBestKeywords.data}"
                    :agGridStyle="`height:${tableHeightByRowCount(topBestKeywords.data)}px;`"
                    :is-card-container-fullscreen="cardContainerFullscreen"
                    :show-pagination="false"
                    ref="agGridTable"
                />
                <div v-if="displayLinkButton">
                    <v-btn
                        outlined
                        :to="{name:'keywords_configuration'}"
                        color="primary"
                        style="width: calc(100% - 35px); position: absolute; bottom: 18px"
                    >
                        {{ $t('component_topBestKeywords_cta') }}<v-icon right>mdi-chevron-double-right</v-icon>
                    </v-btn>
                </div>
            </div>
        </template>

    </component-container>
</template>

<script>
import apiCalls from "@/api/axios/dataReport/dashboardRecommendations";
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";
import AgGridTable from "@/components/yooda-components/ag-grid-table/AgGridTable";

export default {
    name: "TopBestKeywords",
    data() {
        return {
            topBestKeywords: null,
            cardContainerFullscreen:true,
            columnDefs: [
                {headerName: this.$t('component_topBestKeywords_tableHeader_keyword'), field: 'string', cellClass:'ag-grid-cell-left', headerClass:'ag-grid-header-left', comparator: cellSort.agGridTableSortCellGeneric},
                {headerName: this.$t('component_topBestKeywords_tableHeader_seoPositionRank'), field: 'seoRank', width: 100, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', sort: 'asc', comparator: cellSort.agGridTableSortFormattedRankValue},
                {headerName: this.$t('component_topBestKeywords_tableHeader_adsPositionRank'), field: 'adsRank',  width: 100, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', comparator: cellSort.agGridTableSortFormattedRankValue},
                {headerName: this.$t('component_topBestKeywords_tableHeader_searchesCount'), field: 'searchCount',  width: 100, cellRendererFramework: 'CellRendererFormatedNumber', cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellGeneric},
            ]
        }
    },
    components: {
        AgGridTable
    },
    props: {
        displayLinkButton:{type: Boolean, default: false},
    },
    watch:{
        globalFilterLocation(){
            this.initComponentData()
        }
    },
    methods: {
        formatData(jsonData) {
            return jsonData.content.map((data)=>{
                return {
                    string: data.string,
                    seoRank: this.$options.filters.displayRank(data.seoRank),
                    adsRank: this.$options.filters.displayRank(data.adsRank),
                    searchCount: data.searchCount
                }
            })
        },
        initComponentData(){
            this.getComponentData({
                componentName:'topBestKeywords',
                promise:(!this.projectCreationWaiting && this.$router.currentRoute.params.projectId) ? apiCalls.readTopBestKeywords(this.$router.currentRoute.params.projectId, {params: {
                        'date-range-type':this.globalFilterFrequency,
                        'search-geolocation-id':this.globalFilterLocation
                    }}) : false,
                dataFormatter: this.formatData
            })
        },
    },
    beforeMount() {
        this.initComponentData()
    }
}
</script>

<style scoped>

</style>
