var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{attrs:{"card-title":_vm.$t('component_trafficEvolution_title'),"card-subtitle":_vm.$t('component_trafficEvolution_subtitle'),"height":495,"information-tooltip":{
        title : _vm.$t('component_trafficEvolution_informationTooltip_title'),
        text:_vm.$t('component_trafficEvolution_informationTooltip_text')
    },"component-config":{
        hasConfig:_vm.analyticsViewConfiguration,
        hideOnNoConfig:_vm.hideOnNoConfig,
        redirectRoute:'analytics-project-configuration',
        placeholderTitle:_vm.$t('component_componentContainer_noAnalytics_title'),
        placeholderText:_vm.$t('component_componentContainer_noAnalytics_text')
    },"component-data":{response:_vm.trafficEvolution, hasData:(_vm.trafficEvolution.data && _vm.trafficEvolution.data.options)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.trafficEvolution.data)?_c('div',[_c('div',{staticClass:"v-application pl-5 text-center",staticStyle:{"display":"inline-block"}},[_c('div',{staticClass:"p-3"},[_c('yooda-icon',{staticClass:"p-3",attrs:{"name":"users","color":"primary","background":"rounded","size":30}})],1),_c('h3',{staticClass:"text-h3 font-weight-bold"},[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.trafficEvolution.data.labelValue)))]),_c('p',{staticClass:"text-body-1 mb-0"},[_vm._v(_vm._s(_vm.$t('component_trafficEvolution_label'))+" "+_vm._s(_vm.displayKpiLabel))])]),_c('yooda-chart',{attrs:{"options":_vm.trafficEvolution.data.options,"chart-type":"line","chart-style":"height:275px;"}})],1):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }