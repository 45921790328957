<template>
    <component-container
        :card-title="$t('component_seoVisibilityVisibilityScore_title')"
        :card-subtitle="$t('component_seoVisibilityVisibilityScore_subtitle')"

        :height="495"

        :information-tooltip="{
            title : $t('component_seoVisibilityVisibilityScore_informationTooltip_title'),
            text:$t('component_seoVisibilityVisibilityScore_informationTooltip_text')
        }"

        :component-data="{response:seoVisibilityIncomingTraffic, hasData:(seoVisibilityIncomingTraffic.data && seoVisibilityIncomingTraffic.data.options)}"
    >
        <template v-slot:content>
            <div v-if="seoVisibilityIncomingTraffic.data">
                <div class="v-application pl-5 text-center" style="display: inline-block">
                    <div class="p-3">
                        <yooda-icon name="eye" class="p-2" color="contrast" background="rounded" :size="32"></yooda-icon>
                    </div>
                    <h3 class="text-h3 font-weight-bold">{{ seoVisibilityIncomingTraffic.data.labelValue|formatNumber }}</h3>
                    <p class="text-body-1 mb-0">{{$t('component_seoVisibilityVisibilityScore_label')}}</p>
                </div>

                <yooda-chart
                    :options="seoVisibilityIncomingTraffic.data.options"
                    chart-type="line"
                    chart-style="height:295px;"
                />
            </div>
        </template>
    </component-container>

</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/seoVisbility.js'
import ComponentFilters from "@/components/yooda-components/ComponentFilters";


export default {
    components: {
        YoodaChart,
        ComponentFilters,
    },
    data(){
        return{
            seoVisibilityIncomingTraffic:null
        }
    },
    computed:{

    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData()
        },
        globalFilterLocation(){
            this.initComponentData()
        }
    },
    methods:{
        formatData(jsonData){
            if(jsonData.content.length === 0){return {}}
            let serieLabel = []
            let serieData = []
            let lastValue = 0

            jsonData.content.forEach((data)=>{
                serieLabel.push(this.$options.filters.displayDateLocale(data.date))
                serieData.push({date:this.$options.filters.displayDateLocale(data.date), value:data.cumulatedVisibility})
                lastValue = data.cumulatedVisibility
            })
            let cardLineData = {
                labels:serieLabel,
                data: serieData,
                labelValue: lastValue
            }
            let tooltipFormatter = params => {
                return `
                    <p>
                        ${params[0].axisValue}
                        <br>
                        <span class="toolTipColorSpan" style="background-color:${params[0].color}"></span>
                        ${params[0].seriesName} : ${this.$options.filters.formatNumber(params[0].value)}
                    </p>`
            }

            let formatedData = this.genericCardLineOptions(
                cardLineData,
                this.$t('component_seoVisibilityVisibilityScore_label'),
                'contrast',
                'axis',
                tooltipFormatter
            )


            formatedData.options.xAxis.show = true
            formatedData.options.grid.bottom = 30
            formatedData.options.grid.left = 40
            formatedData.options.grid.right = 40

            return formatedData
        },
        initComponentData(){
            this.getComponentData({
                componentName:'seoVisibilityIncomingTraffic',
                promise: !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readVisibility(this.$router.currentRoute.params.projectId,{params:{
                    'date-range-type':this.globalFilterFrequency,
                    'search-geolocation-id':this.globalFilterLocation
                }}) : false,
                dataFormatter: this.formatData
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
