<template>
    <component-container
        :card-title="$t('component_reportingLogoConfig_title')"
        :card-subtitle="$t('component_reportingLogoConfig_subtitle')"
        :height="410"

        :information-tooltip="{
            title : $t('component_reportingLogoConfig_tooltip_title'),
            text:$t('component_reportingLogoConfig_tooltip_text'),
            width:450
        }"
        :component-data="{
            response: { isLoaded:true, error:false,  data: true },
            hasData: true
        }"
    >

        <template v-slot:content>
            <v-row class="v-application mt-6">
                <v-col cols="6" style="position: relative">
                    <v-switch
                        v-model="useLogo"
                        inset
                        :disabled="disableSwitch"
                        :label="$t('component_reportingLogoConfig_switchLabel')"
                        @change="changeLogoUsage"
                        hide-details
                        class="mt-0"
                        style="max-width: 310px"
                    />
                </v-col>

                <v-col cols="6" align-self="center">
                    <v-fade-transition>
                                <span
                                    class="rounded px-2 py-2 red lighten-5 red--text text--darken-3"
                                    v-show="displayError"
                                >
                                    <v-icon class="mb-1" color="red darken-3">mdi-close-circle</v-icon>
                                    {{ errorMessage }}
                                </span>
                    </v-fade-transition>
                </v-col>
            </v-row>

            <v-row class="v-application">
                <v-col  :cols="6" class="pt-3 pl-3 pr-6 pb-2">
                    <div style="padding:5px; border: 1px dashed #cbcbcb; border-radius:10px; height: 180px; max-width: 300px; text-align: center; display: flex">
                        <img  v-if="subscriptionMetaData.data && subscriptionMetaData.data.logo" :src="subscriptionMetaData.data.logo" style="align-self:center;margin:0 auto;max-width: 100%; max-height: 100%" />
                    </div>
                </v-col>
                <v-col  :cols="6">
                    <v-row>
                        <v-col cols="12">
                            <v-btn
                                color="primary"
                                outlined
                                @click="selectLogo"
                                :disabled="disableSwitch"
                            >
                                <v-icon left>mdi-image-plus</v-icon>
                                {{ $t('component_reportingLogoConfig_changeLogoBtn') }}
                            </v-btn>

                            <input
                                style="display: none"
                                ref="uploadInputFile"
                                type="file"
                                accept="image/jpeg/*"
                                @change="uploadLogo()"
                            />
                        </v-col>
                        <v-col cols="2" align-self="center" style="max-width: 40px">
                            <v-icon color="orange">mdi-alert</v-icon>
                        </v-col>
                        <v-col cols="10">
                            <p class="ma-0" v-html="$t('component_reportingLogoConfig_changeLogoInformation')"></p>
                        </v-col>

                    </v-row>


                </v-col>
            </v-row>
        </template>

    </component-container>


</template>


<script>

import apiCalls from "@/api/axios/configuration/project";
import reportingApiCalls from "@/api/axios/dataReport/reporting";
import {mapGetters} from "vuex";
import InformationTooltip from "@/components/yooda-components/InformationTooltip";

export default {
    data(){
        return{
            subscriptionMetaData:null,
            reportingList:null,
            reporting:null,
            useLogo:false,
            displayError:false,
            errorMessage:'',
        }
    },
    computed:{
        ...mapGetters({
            getActiveCustomerSubscription:"customersManager/getActiveCustomerSubscription",
        }),

        disableSwitch(){
            let disable = true

            if(this.getActiveCustomerSubscription().subscription && this.getActiveCustomerSubscription().subscription.product){
                this.getActiveCustomerSubscription().subscription.product.productOptions.forEach((productOption)=>{
                    if(productOption.handle === 'reporting-custom-logo'){
                        if(productOption.value){disable = false}
                    }
                })
            }
            return disable
        }

    },
    methods:{
        changeLogoUsage(){
            if(this.useLogo && ((this.subscriptionMetaData.data && !this.subscriptionMetaData.data.logo))){
                this.errorMessage = this.$t('component_reportingLogoConfig_errorNoLogo')
                this.displayError = true

                setTimeout(() => {
                    this.useLogo = false
                }, 200);


                return false
            }

            reportingApiCalls.updateReporting(this.reporting.id, { logo: this.useLogo})
        },
        selectLogo(){
            this.$refs.uploadInputFile.click()
        },
        uploadLogo(){
            const file = document.querySelector('input[type=file]').files[0]
            const reader = new FileReader()

            if(file){
                let rawImg = null
                reader.onloadend = () => {
                    rawImg = reader.result
                    if(['image/jpg','image/png','image/svg+xml','image/jpeg'].includes(file.type)){
                        if(file.size <= 3000000){
                            apiCalls.updateSubscriptionMeta(this.$route.params.subscriptionId, {logo:rawImg}).then(()=>{
                                this.getComponentData( {
                                    componentName:'subscriptionMetaData',
                                    promise: apiCalls.getSubscriptionMeta(this.$route.params.subscriptionId, true)
                                }).then(()=>{
                                    this.displayError = false
                                }).catch(()=>{
                                    this.errorMessage = this.$t('component_reportingLogoConfig_errorUnknown')
                                    this.displayError = true
                                })
                            })
                        }
                        else{
                            this.errorMessage = this.$t('component_reportingLogoConfig_errorSize')
                            this.displayError = true
                        }
                    }
                    else{
                        this.errorMessage = this.$t('component_reportingLogoConfig_errorFormat')
                        this.displayError = true
                    }
                }
                reader.readAsDataURL(file)
            }



        },

        initComponentData(){
            this.getComponentData( {
                componentName:'subscriptionMetaData',
                promise: apiCalls.getSubscriptionMeta(this.$route.params.subscriptionId, true)
            })

            this.getComponentData( {
                componentName:'reportingList',
                promise: reportingApiCalls.readReportings(this.$router.currentRoute.params.projectId),
            }).then(()=>{
                if(this.reportingList.data.length > 0){
                    this.reporting = this.reportingList.data[0]
                    this.useLogo = this.reporting.logo
                }
            })
        },
    },
    components:{
        InformationTooltip
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>

<style lang="scss">

</style>
