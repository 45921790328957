var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{attrs:{"card-title":_vm.$t('component_websitePagesBestPages_title'),"card-subtitle":_vm.$t('component_websitePagesBestPages_subtitle'),"height":495,"information-tooltip":{
        title : _vm.$t('component_websitePagesBestPages_informationTooltip_title'),
        text:_vm.$t('component_websitePagesBestPages_informationTooltip_text')
    },"component-data":{
        response:_vm.websitePagesBestPages,
        hasData:(_vm.websitePagesBestPages.data && _vm.websitePagesBestPages.data.length > 0)
    }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"mt-5 v-application"},[_vm._l((_vm.websitePagesBestPages.data),function(rank,index){return _c('div',{key:index,staticClass:"mt-3"},[_c('p',{staticClass:"text-body-1 mb-2"},[_c('span',{staticClass:"display-url"},[_vm._v(_vm._s(rank.name))]),_vm._v(" : "+_vm._s(rank.percent)+"%")]),_c('vs-progress',{attrs:{"percent":rank.percent,"color":_vm.getThemeColorByName('primary'),"height":16}})],1)}),(_vm.displayLinkButton)?_c('div',{staticClass:"v-application"},[_c('v-btn',{staticStyle:{"width":"calc(100% - 35px)","position":"absolute","bottom":"18px"},attrs:{"outlined":"","to":{name:'website_pages'},"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('component_websitePagesBestPages_detailButton'))),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-double-right")])],1)],1):_vm._e()],2)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }