var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{attrs:{"card-title":_vm.$t('component_marketSegmentSegmentPerformance_title'),"card-subtitle":_vm.$t('component_marketSegmentSegmentPerformance_subtitle'),"height":495,"information-tooltip":{
        title : _vm.$t('component_marketSegmentSegmentPerformance_informationTooltip_title'),
        text:_vm.$t('component_marketSegmentSegmentPerformance_informationTooltip_text')
    },"component-config":{
        hasConfig:_vm.keywordGroupsConfiguration.length,
        hideOnNoConfig:_vm.hideOnNoConfig,
        redirectRoute:_vm.displayPlaceholderConfigButton ? 'segments-configuration' : null,
        placeholderTitle:_vm.$t('component_componentContainer_noMarketSegment_title'),
        placeholderText:_vm.$t('component_componentContainer_noMarketSegment_text')
    },"component-data":{
        response: _vm.marketSegmentSegmentPerformance,
        hasData: (_vm.marketSegmentSegmentPerformance.data && _vm.marketSegmentSegmentPerformance.data.series)
    }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('yooda-chart',{attrs:{"options":_vm.marketSegmentSegmentPerformance.data,"on-mouseover":_vm.setSelectedSegment,"chart-type":"bar"}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }