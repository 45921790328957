<template>
    <component-container
        :card-title="$t('component_marketSegmentSegmentPerformance_title')"
        :card-subtitle="$t('component_marketSegmentSegmentPerformance_subtitle')"

        :height="495"

        :information-tooltip="{
            title : $t('component_marketSegmentSegmentPerformance_informationTooltip_title'),
            text:$t('component_marketSegmentSegmentPerformance_informationTooltip_text')
        }"

        :component-config="{
            hasConfig:keywordGroupsConfiguration.length,
            hideOnNoConfig:hideOnNoConfig,
            redirectRoute:displayPlaceholderConfigButton ? 'segments-configuration' : null,
            placeholderTitle:$t('component_componentContainer_noMarketSegment_title'),
            placeholderText:$t('component_componentContainer_noMarketSegment_text')
        }"

        :component-data="{
            response: marketSegmentSegmentPerformance,
            hasData: (marketSegmentSegmentPerformance.data && marketSegmentSegmentPerformance.data.series)
        }"
    >
        <template v-slot:content>
            <yooda-chart
                :options="marketSegmentSegmentPerformance.data"
                :on-mouseover="setSelectedSegment"
                chart-type="bar"
            />
        </template>
    </component-container>
</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/marketSegment.js'


export default {
    props: {
        displayPlaceholderConfigButton:{type: Boolean, default: true},
        hideOnNoConfig:{type: Boolean, default: false}
    },
    data() {
        return {
            marketSegmentSegmentPerformance:null,
        }
    },
    components: {
        YoodaChart
    },
    computed:{

    },
    watch:{
        globalFilterLocation(){
            this.initComponentData()
        },
        getChartSelectedSeriesName(){
            if(this.marketSegmentSegmentPerformance.data.series){
                this.marketSegmentSegmentPerformance.data.series.data.forEach((data)=>{
                    if(data.name === this.getChartSelectedSeriesName){
                        data.itemStyle.borderWidth = 2
                    }
                    else{
                        data.itemStyle.borderWidth = 0
                    }
                })
            }
        }
    },
    methods:{
        setSelectedSegment(pie){
            this.setSegmentSizeSelected(pie.name)
        },
        formatData(jsonData){
            if(jsonData.content.length === 0){return {}}
            let serieData = []
            let serieLabels = []
            let colorIndex = 1
            let tooltipLabel = this.$t('component_marketSegmentSegmentPerformance_label')
            let tooltipLabel2 = this.$t('component_marketSegmentSegmentSize_label')

            let tooltipFormatter = params => {
                return `
                    <p>
                        <span class="toolTipColorSpan" style="background-color:${params.color}"></span>
                        ${params.name} : ${this.$options.filters.formatNumber(params.value)}% ${tooltipLabel}
                        <br>
                        soit ${params.data.search} ${tooltipLabel2}
                         <br>
                         sur un potentiel de ${params.data.totalSearch}

                    </p>`
            }
            let labelFormatter = params => {
                return`${params.name} : ${this.$options.filters.formatNumber(params.value)}%
(${params.data.search} / ${params.data.totalSearch})`
            }

            jsonData.content.sort((a,b)=>{
                return (a.keywordGroupName < b.keywordGroupName) ? -1 : (a.keywordGroupName > b.keywordGroupName) ? 1 : 0
            })


            jsonData.content.forEach(data => {
                serieLabels.push((data.keywordGroupName === '#other#'?this.$t('component_marketSegmentSegmentPerformance_other'):data.keywordGroupName))
                serieData.push({
                    value: (data.performance?data.performance:0),
                    totalSearch: (data.maximumCumulatedVisibility?data.maximumCumulatedVisibility:0),
                    search: (data.websiteCumulatedVisibility?data.websiteCumulatedVisibility:0),
                    name: (data.keywordGroupName === '#other#'?this.$t('marketSegmentSegmentSize_other'):data.keywordGroupName),
                    itemStyle: { color: this.getThemeColorByName('marketSegmentColor'+colorIndex), borderWidth:0, borderColor:'#a70202' }
                })
                colorIndex++
            })

            return {
                grid: { left: 5, top: 5, right: 5, bottom: 5 },
                tooltip: this.genericTooltipConfiguration('item', tooltipFormatter),
                yAxis: { type: 'category', data: serieLabels, show: false, inverse:true },
                xAxis: { show: false, type: 'value' },
                series: {
                    type: 'bar',
                    data: serieData,
                    showBackground: true,
                    itemStyle: { barBorderRadius: [0,10,10,0]},
                    label: { show: true, position: 'insideLeft', distance:15, formatter:labelFormatter, fontWeight: 'bold', },
                }
            }

        },
        initComponentData(){
            this.getComponentData({
                componentName:'marketSegmentSegmentPerformance',
                promise: (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  && this.keywordGroupsConfiguration.length>0) ? apiCalls.readSegmentPerformance(this.$router.currentRoute.params.projectId,{params:{'search-geolocation-id':this.globalFilterLocation}}) : false,
                dataFormatter: this.formatData
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
