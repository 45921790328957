<template>
    <component-container
        :card-title="$t('component_reportingProjectTemplateSelector_title')"
        :card-subtitle="$t('component_reportingProjectTemplateSelector_subtitle')"
        :height="410"
        :information-tooltip="{
            title : $t('component_reportingFrequencyConfig_informationTooltip_title'),
            text:$t('component_reportingFrequencyConfig_informationTooltip_text'),
            width:450
        }"
        :component-data="{
            response: { isLoaded:isLoaded, error:false,  data: true },
            hasData: true
        }"
    >

        <template v-slot:content>
            <v-row class="mt-6">
                <v-col :cols="12" class="v-application">
                    <v-select
                        v-model="selectedTemplate"
                        :items="templateList.data"
                        item-text="name"
                        item-value="id"
                        hide-details
                        return-object
                        :label="$t('component_reportingProjectTemplateSelector_selectModelLabel')"
                        outlined
                        @change="saveConfig"
                        v-if="templateList"
                    >
                        <template v-slot:selection="{ attrs, item, parent, selected }">
                            <span v-if="item.provider === 'yooda'">{{ $t(`component_reportingProjectTemplateSelector_templateName_${item.name}`) }}</span>
                            <span v-else>{{ item.name }}</span>
                        </template>

                        <template v-slot:item="{ attrs, item, parent, selected }">
                            <v-row class="my-1">
                                <v-col cols="6" class="py-2">
                                    <span v-if="item.provider === 'yooda'">{{ $t(`component_reportingProjectTemplateSelector_templateName_${item.name}`) }}</span>
                                    <span v-else>{{ item.name }}</span>
                                </v-col>
                                <v-col cols="6" class="py-2">
                                    {{ templateProjectUsage(item.id).length }} {{ ( templateProjectUsage(item.id).length > 1 ? $t('component_reportingProjectTemplateSelector_modelSelectUsages') : $t('component_reportingProjectTemplateSelector_selectModelLabel'))}}
                                </v-col>
                            </v-row>
                        </template>

                        <template v-slot:append-item>
                            <div class="text-center pt-2">
                                <router-link :to="{ name: 'report_template',query: { addToReporting: currentProjectReporting.id, projectId:$router.currentRoute.params.projectId }}">{{ $t('component_reportingProjectTemplateSelector_linkToTemplate') }}</router-link>
                            </div>
                        </template>
                    </v-select>
                </v-col>
                <v-col :cols="12" class="v-application" style="height: 20px; padding: 0 0 0 15px; margin-top: -5px">
                    <v-row class="">
                        <v-col :cols="6">
                            <v-fade-transition>
                        <span
                            class="green--text text--darken-1"
                            v-show="displayConfirmSave"
                        >
                            {{ $t('common_changeSaved') }}
                        </span>
                            </v-fade-transition>
                        </v-col>
                        <v-col class="text-right pr-8" :cols="6">
                            <router-link :to="{ name: 'report_template',query: { addToReporting: currentProjectReporting.id, projectId:$router.currentRoute.params.projectId }}">{{ $t('component_reportingProjectTemplateSelector_linkToTemplate') }}</router-link>
                        </v-col>
                    </v-row>


                </v-col>
                <v-col :cols="5" class="v-application">
                    <div class="rounded-xl blue-grey lighten-5 pa-5 text-center" style="position: relative">
                        <img src="@/assets/images/reporting-model.png" style="width: 100%; max-width: 230px">
                        <v-btn
                            elevation="2"
                            outlined
                            color="primary"
                            style="background-color:#fff;position: absolute; top: calc(50% - 18px); left: calc(50% - 75px); width: 150px"
                            @click="displayPreview"
                        >
                            <strong class="uppercase">{{ $t('component_reportingProjectTemplateSelector_btnPreview') }}</strong>
                        </v-btn>
                    </div>

                </v-col>
                <v-col :cols="7" class="v-application">
                    <div class="rounded-lg grey lighten-4 pa-2 mb-2" style="border: 1px dashed #d0d0d0 !important;">
                        <span
                            v-html="$t('component_reportingProjectTemplateSelector_placeholderTitle')
                            .replace('[*COMPONENT-COUNT*]',currentProjectReporting.template.componentCount)"
                            v-if="currentProjectReporting.template"
                        ></span>
                        <span v-else>&nbsp;</span>
                    </div>

                    <div class="rounded-lg grey lighten-4 pa-2" style="border: 1px dashed #d0d0d0 !important;">
                        <span>{{ $t('component_reportingProjectTemplateSelector_usageLabel') }}</span>

                        <ul class="mt-2" style="list-style: disc">
                            <li v-for="(project, index) in projectUsed" :key="index">{{ project }}</li>
                        </ul>
                    </div>

                </v-col>
            </v-row>

            <reporting-preview-model ref="reportingPreview" :template-id="currentProjectReporting.template.id" v-if="currentProjectReporting && showPreview"/>
        </template>

    </component-container>


</template>


<script>

import ReportingPreviewModel from "@/components/yooda-components/reporting/ReportingPreviewModel";
import reportingApiCalls from "@/api/axios/dataReport/reporting";
import templateApiCalls from "@/api/axios/dataReport/template";
import Vue from "vue";


export default {
    data(){
        return{
            reportings:null,
            templateList:null,
            computedReportingList:null,
            isLoaded: false,
            displayConfirmSave:false,
            currentProjectReporting:null,
            selectedTemplate:null,
            showPreview:false,
        }
    },
    components:{
        ReportingPreviewModel
    },
    computed:{
        // models(){
        //     if(this.templateList && this.computedReportingList){
        //            return this.templateList.data.map(data=>{
        //             return {
        //                 id:data.id,
        //                 name:this.$t(`component_reportingProjectTemplateSelector_templateName_${data.name}`),
        //                 projectUsed:(this.computedReportingList.data ? this.computedReportingList.data.filter(elem=>elem.template.id === data.id).map(elem=>{return elem.project.name}) : [''])
        //             }
        //         })
        //     }
        //     return []
        // },
        projectUsed(){
            if(this.currentProjectReporting.template){
                let displayUsedProject = this.templateProjectUsage( this.currentProjectReporting.template.id)
                let projectCount = displayUsedProject.length
                if(projectCount>6){
                    displayUsedProject = displayUsedProject.slice(0,5)
                    displayUsedProject.push(`...et ${projectCount-6} autres`)
                }
                return displayUsedProject
            }
            return []
        }
    },
    methods:{
        templateProjectUsage(templateId){
            return (this.computedReportingList.data ? this.computedReportingList.data.filter(elem=>elem.template.id === templateId).map(elem=>{return elem.project.name}) : [''])
        },
        saveConfig(){
            this.displayConfirmSave = true
            reportingApiCalls.updateReportingTemplate(this.currentProjectReporting.id,this.selectedTemplate.id)
            .then(()=>{
                this.initComponentData()
                setTimeout(() => {
                    this.displayConfirmSave = false
                }, 5000);
            })
        },
        displayPreview(){
            this.showPreview = false

            setTimeout(() => {
                this.showPreview = true
                Vue.nextTick(() => {
                    this.$refs.reportingPreview.showPreview()
                })
            }, 300)


        },
        initComponentData(){
            this.getComponentData({
                componentName:'templateList',
                promise: templateApiCalls.readTemplatesBySubscriptionId(this.$route.params.subscriptionId)
            }).then(()=>{
                this.getComponentData({
                    componentName:'computedReportingList',
                    promise: reportingApiCalls.readAllReporting(this.$route.params.subscriptionId)
                }).then(()=>{
                    this.getComponentData({
                        componentName:'reportings',
                        promise: reportingApiCalls.readReportings(this.$router.currentRoute.params.projectId),
                    }).then(()=>{
                        if(this.reportings.data && this.reportings.data.length >0){
                            this.currentProjectReporting = this.reportings.data[0]
                            this.selectedTemplate = {
                                id:this.currentProjectReporting.template.id,
                                name:this.$t(`component_reportingProjectTemplateSelector_templateName_${this.currentProjectReporting.template.name}`),
                                projectUsed:this.templateProjectUsage(this.currentProjectReporting.template.id)
                            }
                        }
                        this.isLoaded = true
                    })
                })
            })
        },
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>

<style lang="scss">

</style>
