
export default {

    agGridTableSortCellGeneric(valueA, valueB) {

        if(valueA === '-'){valueA = 9999999999}
        if(valueB === '-'){valueB = 9999999999}

        if (valueA === valueB) return 0;
        return (valueA > valueB) ? 1 : -1;

    },
    agGridTableSortCellTraffic(valueA, valueB) {

        if(valueA === '-'){valueA = -9999999999}
        if(valueB === '-'){valueB = -9999999999}

        if (valueA === valueB) return 0;
        return (valueA > valueB) ? 1 : -1;

    },
    agGridTableSortCellValue(valueA, valueB) {
        if(typeof valueA.value === 'string'){ valueA.value = valueA.value.toLowerCase() }
        if(typeof valueB.value === 'string'){ valueB.value = valueB.value.toLowerCase() }


        if (valueA.value === valueB.value) return 0;
        return (valueA.value > valueB.value) ? 1 : -1;

    },
    agGridTableSortCellFloatValue(valueA, valueB, nodeA, nodeB, isInverted) {
        let intValueA = valueA.value
        let intValueB = valueB.value



        if(typeof valueA.value !== 'number'){
            intValueA = valueA.value.match(/\d/g)
            if(intValueA){intValueA = parseFloat(intValueA.join(""))}
        }
        if(typeof valueB.value !== 'number'){
            intValueB = valueB.value.match(/\d/g)
            if(intValueB){intValueB = parseFloat(intValueB.join(""))}
        }


        if(valueA.value === '-'){intValueA = (isInverted ? -9999999999 : 9999999999)}
        if(valueB.value === '-'){intValueB = (isInverted ? -9999999999 : 9999999999)}

        if (intValueA === intValueB) return 0;
        return (intValueA > intValueB) ? 1 : -1;
    },

    agGridTableSortFormattedRankValue(valueA, valueB) {

        let intValueA = valueA
        let intValueB = valueB

        if(typeof valueA !== 'number'){
            intValueA = valueA.match(/\d/g)
            if(intValueA){intValueA = parseFloat(intValueA.join(""))}
        }
        if(typeof valueB !== 'number'){
            intValueB = valueB.match(/\d/g)
            if(intValueB){intValueB = parseFloat(intValueB.join(""))}
        }

        if(!intValueA){intValueA = 9999999999}
        if(!intValueB){intValueB = 9999999999}

        if (intValueA === intValueB) return 0;
        return (intValueA > intValueB) ? 1 : -1;
    },

    agGridTableSortCellRank(valueA, valueB, nodeA, nodeB, isInverted) {

        if(!valueA){valueA = {rank:null}}
        if(!valueB){valueB = {rank:null}}

        let intValueA = valueA.rank
        let intValueB = valueB.rank

        if(typeof valueA.rank !== 'number'){
            if(!valueA.rank){
                intValueA = (isInverted ? 9999999999 : 9999999998)
                if(intValueA.rankEvolution){
                    intValueA = (isInverted ? 9999999998 : 9999999999)
                }
            }
            else{
                intValueA = valueA.rank.match(/\d/g)
                intValueA = parseFloat(intValueA.join(""))
            }

        }

        if(typeof valueB.rank !== 'number'){
            if(!valueB.rank){
                intValueB = (isInverted ? 9999999999 : 9999999998)
                if(valueB.rankEvolution){
                    intValueB = (isInverted ? 9999999998 : 9999999999)
                }
            }
            else{
                intValueB = valueB.rank.match(/\d/g)
                intValueB = parseFloat(intValueB.join(""))
            }
        }

        if (intValueA === intValueB) return 0;
        return (intValueA > intValueB) ? 1 : -1;
    },

    agGridTableSortCellRankEvolution(valueA, valueB) {
        let intValueA = valueA.rankEvolution
        let intValueB = valueB.rankEvolution


        if (valueA.rankEvolution === 'New') {
            intValueA = 9999999999
        } else if (valueA.rankEvolution === 'Out') {
            intValueA = -9999999998
        } else if (valueA.rankEvolution === 'Missing') {
            intValueA = -0.5
        } else if (typeof valueA.rankEvolution !== 'number') {
            intValueA = valueA.rankEvolution.match(/\d/g)
            intValueA = parseFloat(intValueA.join(""))
        }

        if (valueB.rankEvolution === 'New') {
            intValueB = 9999999999
        } else if (valueB.rankEvolution === 'Out') {
            intValueB = -9999999998
        } else if (valueB.rankEvolution === 'Missing') {
            intValueB = -0.5
        } else if (typeof valueB.rankEvolution !== 'number') {
            intValueB = valueB.rankEvolution.match(/\d/g)
            intValueB = parseFloat(intValueB.join(""))
        }

        if (intValueA === intValueB) return 0;
        return (intValueA > intValueB) ? 1 : -1;
    },
}
