<template>
    <component-container
        :class="{filterOpen:filterOpen}"
        :card-title="$t('component_progressionAxisGooglePositionList_title')"
        :card-subtitle="$t('component_progressionAxisGooglePositionList_subtitle')"


        :information-tooltip="{
            title : $t('component_progressionAxisGooglePositionList_informationTooltip_title'),
            text:$t('component_progressionAxisGooglePositionList_informationTooltip_text')
        }"

        :table-menu="{csv:true, fullscreen:true}"
        :table-density-selector="true"

        :component-data="{response:progressionAxisGooglePositionList, hasData:progressionAxisGooglePositionList.data}"

        @setTableMenuFullscreen="cardContainerFullscreen = !cardContainerFullscreen"
        @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"
    >
        <template v-slot:filters>
            <component-filters

                :market-segments="true"
                :competitors="true"
                :competitors-with-project-website="true"
                :competitors-with-select-all="false"
                :report-date="true"
                @applyFilter="initComponentData"
                @filterPanelStatus="displayFilterOpen"
                :default-select-value="lastFilters"
            />
        </template>

        <template v-slot:content>
            <ag-grid-table
                id="progressionAxisGooglePositionList"
                :show-floating-filter="false"
                :table-data="{columnDefs:columnDefs,rowData:progressionAxisGooglePositionList.data}"
                :agGridStyle="`height:${tableHeightByRowCount(progressionAxisGooglePositionList.data)}px;`"
                :is-card-container-fullscreen="cardContainerFullscreen"
                ref="agGridTable"
            />
        </template>
    </component-container>

</template>


<script>

import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable'
import apiCalls from '@/api/axios/dataReport/progressionAxis'
import ComponentFilters from "@/components/yooda-components/ComponentFilters";
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";

export default {

    data() {
        return {
            progressionAxisGooglePositionList:null,
            filterOpen:false,
            cardContainerFullscreen:false,
            lastFilters:null,
            columnDefs:[
                {headerName: this.$t('component_progressionAxisGooglePositionList_table_header_keyword'),filter: true, field: 'keyword'},
                {headerName: this.$t('component_progressionAxisGooglePositionList_table_header_seo'), field: 'seo',  sort:'asc', cellRendererFramework: 'CellRendererRankProgression', width: 70, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', comparator: cellSort.agGridTableSortCellRank},
                {headerName: this.$t('component_progressionAxisGooglePositionList_table_header_map'), field: 'map', cellRendererFramework: 'CellRendererRankProgression', width: 110, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', comparator: cellSort.agGridTableSortCellRank},
                {headerName: this.$t('component_progressionAxisGooglePositionList_table_header_zero'), field: 'zero', cellRendererFramework: 'CellRendererWithIcon', width: 70, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_progressionAxisGooglePositionList_table_header_image'), field: 'image', cellRendererFramework: 'CellRendererRankProgression', width: 70, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', comparator: cellSort.agGridTableSortCellRank},
                {headerName: this.$t('component_progressionAxisGooglePositionList_table_header_actuality'), field: 'actuality', cellRendererFramework: 'CellRendererRankProgression', width: 70, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', comparator: cellSort.agGridTableSortCellRank},
                {headerName: this.$t('component_progressionAxisGooglePositionList_table_header_adwords'), field: 'adwords', cellRendererFramework: 'CellRendererRankProgression', width: 70, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', comparator: cellSort.agGridTableSortCellRank},
                {headerName: this.$t('component_progressionAxisGooglePositionList_table_header_shopping'), field: 'shopping', cellRendererFramework: 'CellRendererRankProgression', width: 70, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', comparator: cellSort.agGridTableSortCellRank},
            ],
        }
    },
    components: {
        AgGridTable,
        ComponentFilters,
    },
    computed:{

    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData(this.lastFilters)
        },
        globalFilterLocation(){
            this.initComponentData(this.lastFilters)
        }
    },
    methods:{


        displayFilterOpen(status){ this.filterOpen = status === 'open'; },
        formatData(jsonData){
            if(jsonData.content.length === 0){return []}
            let rowData = []

            jsonData.content.forEach(data => {
                let zeroIconName = 'check'
                let zeroIconColor = 'positiveVariation'
                let checkValue = ''
                if(!data.zero){
                    checkValue = '-'
                    zeroIconName = ''
                }

                rowData.push({
                    keyword: data.string,
                    seo: (data.natural ? data.natural : null),
                    map: (data.map ? data.map : null),
                    zero: {
                        value: checkValue,
                        iconName: zeroIconName,
                        iconColor: zeroIconColor,
                        iconSize:12
                    },
                    image:(data.image ? data.image : null),
                    actuality: (data.actuality ? data.actuality : null),
                    adwords: (data.adwords ? data.adwords : null),
                    shopping: (data.shopping ? data.shopping : null),
                })
            })


            return rowData
        },
        initComponentData(filters){
            filters.params['search-geolocation-id'] = this.globalFilterLocation
            filters.params['date-range-type'] = this.globalFilterFrequency

            this.getComponentData({
                componentName:'progressionAxisGooglePositionList',
                promise:!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readPositionList(this.$router.currentRoute.params.projectId,filters) : false,
                dataFormatter: this.formatData
            })

            this.lastFilters = filters
        },
    },
    beforeMount() {
        this.initComponentData({
            params:{
                "domain-name":this.projectUrl.replace('https://','').replace('http://','').replace('www.',''),
            }
        })

    },
}
</script>
