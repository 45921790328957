<template>

    <component-container
        :card-title="$t('component_marketSegmentProgressionPotential_title')"
        :height="495"

        :information-tooltip="{
            title : $t('component_marketSegmentProgressionPotential_informationTooltip_title'),
            text:$t('component_marketSegmentProgressionPotential_informationTooltip_text')
        }"

        :component-data="{
            response:marketSegmentProgressionPotential,
            hasData:(marketSegmentProgressionPotential.data && marketSegmentProgressionPotential.data.totalPotential)
        }"
    >
        <template v-slot:content>
            <div v-if="marketSegmentProgressionPotential.data">
                <div class="v-application mt-10">
                    <h3 class="text-h3 font-weight-bold text-center">+{{ marketSegmentProgressionPotential.data.totalPotential|formatNumber }}</h3>
                    <p class="text-body-1 text-center mb-8">{{$t('component_marketSegmentProgressionPotential_subtitle')}}</p>

                    <vs-divider></vs-divider>

                    <div class="mt-6 mb-10 vx-row">
                        <div v-for="(rank, index) in marketSegmentProgressionPotential.data.ranks" v-bind:key="index" class="vx-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-0" >
                            <p class="text-body-1 mb-4 mt-6" style="font-size: 13px !important"><strong>{{ $t('common_rank_including') }} {{ $t('common_google_block_label_'+rank.positionType) }}</strong> : <br><i>+{{ rank.potentialTrafficGain|formatNumber }} {{$t('component_marketSegmentProgressionPotential_label')}} (+{{rank.cumulatedPotentialTrafficGainPercent}}%)</i></p>
                            <vs-progress :percent="rank.cumulatedPotentialTrafficGainPercent" :color="getComponentColor().getGoogleResultTypeColor(rank.positionType)" :height="16"></vs-progress>
                        </div>
                    </div>

                    <div class="v-application" v-if="displayLinkButton">
                        <v-btn
                            outlined
                            :to="{name:'progression_axis'}"
                            color="primary"
                            style="width: calc(100% - 35px); position: absolute; bottom: 18px"
                        >
                            {{ $t('component_marketSegmentProgressionPotential_detailButton') }}<v-icon right>mdi-chevron-double-right</v-icon>
                        </v-btn>
                    </div>

                </div>
            </div>
        </template>
    </component-container>

</template>


<script>

import apiCalls from '@/api/axios/dataReport/dashboard.js'

export default {
    props: {
        displayLinkButton:{ type: Boolean, default: false }
    },
    data() {
        return {
            marketSegmentProgressionPotential:null
        }
    },
    computed:{

    },
    watch:{
        globalFilterLocation(){
            this.initComponentData()
        }
    },
    methods:{
        formatData(jsonData){
            if(jsonData.content.length === 0){ return {} }
            let totalPotential = 0
            jsonData.content.forEach((data)=>{
                totalPotential += data.potentialTrafficGain
            })
            return {
                totalPotential : totalPotential,
                ranks:jsonData.content
            }
        },
        initComponentData(){
            this.getComponentData({
                componentName:'marketSegmentProgressionPotential',
                promise:!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readProgressionPotential(this.$router.currentRoute.params.projectId,{params:{'search-geolocation-id':this.globalFilterLocation}}) : false,
                dataFormatter: this.formatData,
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>

<style lang="scss">
#market-segment-progression-potential-component{

    @media (max-width: 768px) {
        .vx-row{
            margin: 10px 0 10px 0 !important;
        }
        .cardKpiLabel{
            margin: 8px 0 5px 0 !important;
        }
    }
}
</style>
