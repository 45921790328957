<template>
    <component-container
        :card-title="$t('component_linkingLinkCount_title')"


        height="340"

        :information-tooltip="{
            title : $t('component_linkingLinkCount_informationTooltip_title'),
            text:$t('component_linkingLinkCount_informationTooltip_text')
        }"

        :component-data="{response:linkingLinkCount, hasData:(linkingLinkCount.data && linkingLinkCount.data.website)}"
    >
        <template v-slot:content>

            <div class="mt-8" style="min-height: 220px">
                <div v-if="linkingLinkCount.data">


                    <div class="v-application">
                        <h3 class="text-h5 font-bold mb-2">{{ linkingLinkCount.data.website.websiteCount|formatNumber }} <span class="text-body-1">{{ $t('component_linkingLinkCount_websiteCount') }}</span></h3>
                        <div class="d-inline-block" style="vertical-align:middle;width: calc(100% - 95px)">
                            <v-progress-linear
                                :value="linkingLinkCount.data.website.websiteCountPercent"
                                background-color="grey lighten-3"
                                rounded
                                height="11"
                                class="mt-1"
                                :color="getThemeColorByName('primary')"
                            />
                        </div>
                        <div class="d-inline-block text-right " style="vertical-align:middle;width: 95px">
                            <strong  style="font-weight:500;font-size: 16px">{{ linkingLinkCount.data.website.websiteCount|formatNumber }}</strong>
                        </div>

                        <div class="d-inline-block" style="vertical-align:middle;width: calc(100% - 95px)">
                            <v-progress-linear
                                :value="linkingLinkCount.data.competitors.websiteCountPercent"
                                background-color="grey lighten-3"
                                rounded
                                height="11"
                                class="mt-1"
                                :color="getThemeColorByName('linkingLinkCompetitorCount')"
                            />
                        </div>
                        <div class="d-inline-block text-right" style="vertical-align:middle;width: 95px">
                            <strong style="font-weight:500;font-size: 16px">{{ linkingLinkCount.data.competitors.websiteCount|formatNumber }}</strong>
                        </div>



                        <h3 class="text-h5 font-bold mb-2 mt-6">{{ linkingLinkCount.data.website.linkCount|formatNumber }} <span class="text-body-1">{{ $t('component_linkingLinkCount_linkCount') }}</span></h3>

                        <div class="d-inline-block" style="vertical-align:middle;width: calc(100% - 95px)">
                            <v-progress-linear
                                :value="linkingLinkCount.data.website.linkCountPercent"
                                background-color="grey lighten-3"
                                rounded
                                height="11"
                                class="mt-1"
                                :color="getThemeColorByName('primary')"
                          />
                        </div>
                        <div class="d-inline-block text-right" style="vertical-align:middle;width: 95px">
                            <strong style="font-weight:500;font-size: 16px">{{ linkingLinkCount.data.website.linkCount|formatNumber }}</strong>
                        </div>

                        <div class="d-inline-block" style="vertical-align:middle;width: calc(100% - 95px)">
                            <v-progress-linear
                                :value="linkingLinkCount.data.competitors.linkCountPercent"
                                background-color="grey lighten-3"
                                rounded
                                height="11"
                                class="mt-1"
                                :color="getThemeColorByName('linkingLinkCompetitorCount')"
                            />
                        </div>
                        <div class="d-inline-block text-right" style="vertical-align:middle;width: 95px">
                            <strong style="font-weight:500;font-size: 16px">{{ linkingLinkCount.data.competitors.linkCount|formatNumber }}</strong>
                        </div>
                    </div>

                    <v-row class="mt-4">
                        <v-col :cols="6" class="text-right">
                            <p class="custom-chart-legend" >
                                <span :style="`background-color: ${getThemeColorByName('primary')}`"></span>
                                {{ $t('component_linkingLinkCount_websiteLabel') }}
                            </p>
                        </v-col>
                        <v-col :cols="6" class="text-left">
                            <p class="custom-chart-legend" >
                                <span :style="`background-color: ${getThemeColorByName('linkingLinkCompetitorCount')}`"></span>
                                {{ $t('component_linkingLinkCount_competitorsLabel') }}
                            </p>
                        </v-col>
                    </v-row>

                </div>
            </div>


        </template>
    </component-container>
</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/linking.js'
import ComponentFilters from "@/components/yooda-components/ComponentFilters";
import { useComponentStore } from '@/stores/ComponentStore'
import {mapState} from "pinia";

export default {
    data() {
        return {
            linkingLinkCount:null,
        }
    },
    components: {
        YoodaChart,
        ComponentFilters,
    },
    computed:{
        ...mapState(useComponentStore, {
            globalFilterFrequency: 'getGlobalFilterFrequency',
        }),
    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData()
        }
    },
    methods:{


        formatData(jsonData){
            if(!jsonData.content.sites){return {}}

            const maxWebsiteCount = jsonData.content.sites.project > jsonData.content.sites.competitors ? jsonData.content.sites.project : jsonData.content.sites.competitors
            const maxLinkCount = jsonData.content.backlinks.project > jsonData.content.backlinks.competitors ? jsonData.content.backlinks.project : jsonData.content.backlinks.competitors

            return {
                website:{
                    color: '#a6d5fa',
                    websiteCount:jsonData.content.sites.project,
                    websiteCountPercent: (jsonData.content.sites.project*100)/maxWebsiteCount,

                    linkCount:jsonData.content.backlinks.project,
                    linkCountPercent: (jsonData.content.backlinks.project*100)/maxLinkCount

                },
                competitors:{
                    color: '#c5b2e6',
                    websiteCount:jsonData.content.sites.competitors,
                    websiteCountPercent: (jsonData.content.sites.competitors*100)/maxWebsiteCount,

                    linkCount:jsonData.content.backlinks.competitors,
                    linkCountPercent: (jsonData.content.backlinks.competitors*100)/maxLinkCount
                }
            }
        },
        initComponentData(){
            this.getComponentData({
                componentName:'linkingLinkCount',
                promise: (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId ) ? apiCalls.readLinkCount(this.$router.currentRoute.params.projectId,{params:{'date-range-type':this.globalFilterFrequency}}) : false,
                dataFormatter: this.formatData
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
