var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{attrs:{"card-title":_vm.$t('component_trafficEvolutionConversion_title'),"card-subtitle":_vm.$t('component_trafficEvolutionConversion_subtitle'),"height":495,"information-tooltip":{
        title : _vm.$t('component_trafficEvolutionConversion_informationTooltip_title'),
        text:_vm.$t('component_trafficEvolutionConversion_informationTooltip_text')
    },"component-config":{
        hasConfig:_vm.analyticsViewConfiguration,
        hideOnNoConfig:_vm.hideOnNoConfig,
        redirectRoute:_vm.displayPlaceholderConfigButton ? 'analytics-project-configuration' : null,
        placeholderTitle:_vm.$t('component_componentContainer_noAnalytics_title'),
        placeholderText:_vm.$t('component_componentContainer_noAnalytics_text'),
    },"component-data":{response:_vm.trafficEvolutionConversion, hasData:(_vm.trafficEvolutionConversion.data && _vm.trafficEvolutionConversion.data.series)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('yooda-chart',{attrs:{"options":_vm.trafficEvolutionConversion.data,"chart-type":"line"}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }