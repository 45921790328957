var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('component-container',{attrs:{"card-title":_vm.$t('component_marketSegmentBestProgressionPotential_title'),"card-subtitle":_vm.$t('component_marketSegmentBestProgressionPotential_subtitle'),"information-tooltip":{
            title : _vm.$t('component_marketSegmentBestProgressionPotential_informationTooltip_title'),
            text:_vm.$t('component_marketSegmentBestProgressionPotential_informationTooltip_text')
        },"component-config":{
            hasConfig:_vm.keywordGroupsConfiguration.length,
            hideOnNoConfig:_vm.hideOnNoConfig,
            redirectRoute:_vm.displayPlaceholderConfigButton ? 'segments-configuration' : null,
            placeholderTitle:_vm.$t('component_componentContainer_noMarketSegment_title'),
            placeholderText:_vm.$t('component_componentContainer_noMarketSegment_text')
        },"table-menu":{csv:true, fullscreen:true},"table-density-selector":true,"component-data":{response:_vm.marketSegmentBestProgressionPotential, hasData:_vm.marketSegmentBestProgressionPotential.data}},on:{"setTableMenuFullscreen":function($event){_vm.cardContainerFullscreen = !_vm.cardContainerFullscreen},"setTableMenuExportCsv":function($event){return _vm.$refs.agGridTable.exportCsvTable()}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ag-grid-table',{ref:"agGridTable",attrs:{"show-floating-filter":false,"table-data":{columnDefs:_vm.columnDefs,rowData:_vm.marketSegmentBestProgressionPotential.data},"agGridStyle":`height:${_vm.tableHeightByRowCount(_vm.marketSegmentBestProgressionPotential.data)}px;`,"is-card-container-fullscreen":_vm.cardContainerFullscreen}})]},proxy:true}])}),_c('market-segment-best-progression-potential-keywords')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }