<template>
    <component-container
        :card-title="$t('component_trafficSource_title')"
        :card-subtitle="$t('component_trafficSource_subtitle')"

        :height="495"

        :information-tooltip="{
            title : $t('component_trafficSource_informationTooltip_title'),
            text:$t('component_trafficSource_informationTooltip_text')
        }"


        :component-config="{
            hasConfig:analyticsViewConfiguration,
            hideOnNoConfig:hideOnNoConfig,
            redirectRoute:'analytics-project-configuration',
            placeholderTitle:$t('component_componentContainer_noAnalytics_title'),
            placeholderText:$t('component_componentContainer_noAnalytics_text')
        }"

        :component-data="{response:trafficSource, hasData:(trafficSource.data && trafficSource.data.series)}"
    >

        <template v-slot:content>
            <yooda-chart
                :options="trafficSource.data"
                chart-type="line"
            />
        </template>
    </component-container>
</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/traffic.js'
import ComponentFilters from "@/components/yooda-components/ComponentFilters";
import { useComponentStore } from '@/stores/ComponentStore'
import {mapState} from "pinia";

export default {
    props: {
        hideOnNoConfig:{type: Boolean, default: false}
    },
    data() {
        return {
            trafficSource:null
        }
    },
    components: {
        YoodaChart,
        ComponentFilters,
    },
    computed:{
        ...mapState(useComponentStore, {
            globalFilterFrequency: 'getGlobalFilterFrequency',
        }),
    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData()
        }
    },
    methods:{
        formatData(jsonData){

            if(jsonData.content.length === 0){return {}}
            let legendData = []
            let seriesData = []
            let serieLabels = jsonData.content[0].traffics.map(data=>{ return this.$options.filters.displayDateLocale(data.endDate) })
            let tooltipFormatter = params => {
                let toolTip = this.globalFilterFrequency !== "1" ? '<p>'+params[0].data.comparisonDates+'<br>' : '<p>'+params[0].axisValue+'<br>';
                params.forEach((param)=>{
                    toolTip += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + param.color + '"></span>'
                    toolTip += param.seriesName+' : '+this.$options.filters.formatNumber(param.value)+'<br>'
                })
                toolTip+= '</p>'
                return  toolTip
            }
            jsonData.content.forEach((data)=>{
                if(data.traffics && data.traffics.length>0){
                    if(data.acquisitionChannelType !== 'total'){
                        legendData.push(this.$t('component_trafficSource_analytics_label_'+data.acquisitionChannelType))

                        seriesData.push({
                            type: 'line',
                            name: this.$t('component_trafficSource_analytics_label_'+data.acquisitionChannelType),
                            stack: true,
                            areaStyle: {opacity:0.7},
                            data: data.traffics.map(serieData=>{
                                return {
                                    value:serieData.traffic,
                                    date:this.$options.filters.displayDateLocale(serieData.endDate),
                                    comparisonDates: this.$t('common_tooltip_date_comparison').replace('[*STARTDATE*]',this.$options.filters.displayDateLocale(serieData.startDate)).replace('[*ENDDATE*]', this.$options.filters.displayDateLocale(serieData.endDate)),

                                }
                            }),
                            itemStyle: { color: this.getThemeColorByName('acquisitionChannel'+(data.acquisitionChannelType.charAt(0).toUpperCase() + data.acquisitionChannelType.slice(1))) }
                        })
                    }
                }

            })

            if(seriesData.length === 0){
                return {}
            }
            return {
                grid: { left: 50, top: 50, right: 30, bottom: 40 },
                tooltip: this.genericTooltipConfiguration('axis',tooltipFormatter),
                legend: { orient: 'horizontal', icon:'circle', data: legendData },
                xAxis: { type: 'category', data: serieLabels, boundaryGap: false, offset: 15 },
                yAxis: {
                    type: 'value',
                    nameTextStyle:{ color: '#888', fontWeight: 'bold', align: 'right', lineHeight: 35 },
                    name: this.$t('component_trafficSource_series_label')
                },
                series: seriesData
            }
        },
        initComponentData(){
            this.getComponentData({
                componentName:'trafficSource',
                promise: (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  && this.analyticsViewConfiguration) ? apiCalls.readSource(this.$router.currentRoute.params.projectId,{
                    params:{
                        'analytics-data-source-type':'traffic',
                        'date-range-type':this.globalFilterFrequency
                    }
                }) : false,
                dataFormatter: this.formatData
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
