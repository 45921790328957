<template>
    <v-row class="v-application">
        <v-col :cols="6" v-if="displayFrequency">
            <div style="position: relative">
                <v-select
                    v-model="defaultLocationSelect"
                    :items="filterLocation"
                    item-text="name"
                    item-value="id"
                    :label="$t('component_globalFilter_location_select_label')"
                    hide-details
                    prepend-inner-icon="mdi-map-marker"
                    outlined
                    class="mr-8 header-nav-select"
                    dense
                >
                </v-select>
                <div class="tooltip-container" style="position: absolute; top: -10px; right: 16px">
                    <information-tooltip
                        :title="$t('component_globalFilter_location_tooltip_title')"
                        :text="$t('component_globalFilter_location_tooltip_text')"
                    />
                </div>
            </div>
        </v-col>


        <v-col :cols="6" v-if="displayDataDate">
            <div style="position: relative" class="text-left">
                <v-select
                    v-model="defaultFrequencySelect"
                    :items="periods"
                    item-text="name"
                    item-value="value"
                    :label="$t('component_globalFilter_frequency_select_label')"
                    hide-details
                    prepend-inner-icon="mdi-calendar"
                    outlined
                    dense
                    class="header-nav-select mr-8"
                >
                </v-select>

                <div class="tooltip-container" style="position: absolute; top: -10px; right: 16px">
                    <information-tooltip
                        :title="$t('component_globalFilter_frequency_tooltip_title')"
                        :text="$t('component_globalFilter_frequency_tooltip_text')"
                    />
                </div>
            </div>
        </v-col>
    </v-row>
</template>


<script>

import InformationTooltip from "@/components/yooda-components/InformationTooltip";
import apiCalls from "@/api/axios/configuration/project";
export default {
    props: {
        displayFrequency:{type:Boolean, default:true},
        displayDataDate:{type:Boolean, default:true},
    },
    data(){
        return {
            defaultFrequencySelect:'1',
            periods:[
                {name:this.$t('component_globalFilter_frequency_select_1'), value:'1'},
                {name:this.$t('component_globalFilter_frequency_select_7'), value:'7'},
                {name:this.$t('component_globalFilter_frequency_select_30'), value:'30'},
                {name:this.$t('component_globalFilter_frequency_select_365'), value:'365'}
            ],
            defaultLocationSelect:1,
        }
    },
    computed:{
        filterLocation(){
            return this.searchGeolocationsConfiguration
        },
    },
    watch:{
        defaultLocationSelect(){
            localStorage.setItem('globalFilterLocation', this.defaultLocationSelect)
            this.setGlobalFilterLocation(this.defaultLocationSelect)
        },
    },
    methods:{
        watchDefaultFrequencySelect(){
            this.setDataComponent({
                stateName:'projectInfo',
                promise: apiCalls.getProjectInfo(this.$route.params.projectId, true),
                dataFormatter: this.projectData
            }).then((result)=>{
                if(result){
                    localStorage.setItem('globalFilterFrequency', this.defaultFrequencySelect)
                    this.setGlobalFilterFrequency(this.defaultFrequencySelect)
                }
            })
        },
        initComponent(){
            this.setDefaultLocationUsage()
            this.$watch('defaultFrequencySelect', () => { this.watchDefaultFrequencySelect() })
        }
    },
    components:{
        InformationTooltip
    },
     mounted() {
        if(localStorage.getItem('globalFilterFrequency')){
            this.defaultFrequencySelect = localStorage.getItem('globalFilterFrequency')
            this.setGlobalFilterFrequency(this.defaultFrequencySelect)
        }
        else{
            this.defaultFrequencySelect = this.globalFilterFrequency
        }



         if(!this.project.isLoaded){
             this.setDataComponent({
                 stateName:'projectInfo',
                 promise: apiCalls.getProjectInfo(this.$route.params.projectId, true),
                 dataFormatter: this.projectData
             }).then((result)=>{
                 if(result){
                     this.initComponent()
                 }
             })
         }
         else {
             this.initComponent()
         }


    }

}
</script>

<style lang="scss">

.header-nav-select{
    .v-select__selections, .v-label{
        font-size: 14px;
    }
    .v-label{
        color: $primary !important;
    }
    .v-input__prepend-inner{
        i{
            font-size: 20px;
            margin: 4px 9px 0 0;
        }
    }
    i{
        color: $primary !important;
    }
    fieldset{
        border-color: $primary !important;
    }
}

</style>
