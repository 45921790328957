<template>

    <component-container
        :card-title="$t('component_seoVisibilityPositionRepartition_title')"
        :card-subtitle="$t('component_seoVisibilityPositionRepartition_subtitle')"

        :height="(filterOpen ? 630 : 530)"

        :information-tooltip="{
            title : $t('component_seoVisibilityPositionRepartition_informationTooltip_title'),
            text:$t('component_seoVisibilityPositionRepartition_informationTooltip_text')
        }"

        :component-data="{response:seoVisibilityPositionRepartition, hasData:seoVisibilityPositionRepartition.data}"
    >
        <template v-slot:filters>
            <component-filters
                :market-segments="true"
                @applyFilter="initComponentData"
                @filterPanelStatus="displayFilterOpen"
                :default-select-value="lastFilters"
            />
        </template>

        <template v-slot:content>
            <yooda-chart
                :options="seoVisibilityPositionRepartition.data"
                chart-type="bar"
            />
        </template>
    </component-container>
</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/seoVisbility.js'
import ComponentFilters from "@/components/yooda-components/ComponentFilters";


export default {
    data() {
        return {
            seoVisibilityPositionRepartition:null,
            filterOpen:false,
            lastFilters:null,
        }
    },
    components: {
        YoodaChart,
        ComponentFilters,
    },
    computed:{

    },
    watch:{
        globalFilterLocation(){
            this.initComponentData(this.lastFilters)
        }
    },
    methods:{
        displayFilterOpen(status){ this.filterOpen = status === 'open'; },
        formatData(jsonData){
            let options = null
            let dataSerie = [0,0,0,0,0,0,0,0,0,0]
            jsonData.content.forEach(data=>{
                dataSerie[data.rank-1] = data.distinctKeywordCount
            })
            if(jsonData.content){
                options = {
                    grid: { left: 75, top: 10, right: 20, bottom: 10 },
                    yAxis: {
                        type: 'category',
                        inverse: true,
                        splitLine: {show: false},
                        data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
                        axisLabel:{formatter: this.$i18n.t('component_seoVisibilityPositionRepartition_axis_label')+' {value}'}
                    },
                    xAxis: { type: 'value', show:false },
                    series: [
                        {
                            type: 'bar',
                            barCategoryGap:'1%',
                            label: {
                                show: true,
                                position: 'insideLeft',
                                formatter: '{c} '+this.$i18n.t('component_seoVisibilityPositionRepartition_serie_label'),
                                fontWeight:600
                            },
                            data: dataSerie
                        }
                    ]
                }
            }
            return options
        },
        initComponentData(filters = { params: {}}){
            filters.params['search-geolocation-id'] = this.globalFilterLocation

            this.getComponentData({
                componentName:'seoVisibilityPositionRepartition',
                promise: !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readPositionRepartition(this.$router.currentRoute.params.projectId,filters) : false,
                dataFormatter: this.formatData
            })
            this.lastFilters = filters
        },
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
