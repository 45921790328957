var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{attrs:{"height":330,"information-tooltip":{
        title : _vm.$t('component_seoVisibilityConversion_informationTooltip_title'),
        text:_vm.$t('component_seoVisibilityConversion_informationTooltip_text')
    },"component-config":{
        hasConfig:_vm.analyticsViewConfiguration,
        hideOnNoConfig:_vm.hideOnNoConfig,
        redirectRoute:'analytics-project-configuration',
        placeholderTitle:_vm.$t('component_componentContainer_noAnalytics_title'),
        placeholderText:_vm.$t('component_componentContainer_noAnalytics_text')
    },"component-data":{response:_vm.seoVisibilityConversion, hasData:(_vm.seoVisibilityConversion.data && _vm.seoVisibilityConversion.data.options)},"no-padding":true},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.seoVisibilityConversion.data)?_c('div',[_c('div',{staticClass:"v-application pl-5",staticStyle:{"display":"inline-block"}},[_c('div',{staticClass:"py-3"},[_c('yooda-icon',{staticClass:"p-3",attrs:{"name":"filter","color":"warning","background":"rounded","size":26}})],1),_c('h3',{staticClass:"text-h3 font-weight-bold"},[_vm._v(_vm._s(_vm.seoVisibilityConversion.data.labelValue))]),_c('p',{staticClass:"text-body-1 mb-0"},[_vm._v(_vm._s(_vm.$t('component_seoVisibilityConversion_label'))+" "+_vm._s(_vm.displayKpiLabel))])]),_c('yooda-chart',{attrs:{"options":_vm.seoVisibilityConversion.data.options,"chart-type":"line","chart-style":`height:${(_vm.analyticsViewConfiguration ? 165 : 145)}px;`}}),(_vm.seoVisibilityConversion.data.displayedDate)?_c('v-row',[_c('v-col',{staticClass:"pl-6",attrs:{"cols":"6"}},[_vm._v(_vm._s(_vm.seoVisibilityConversion.data.displayedDate.start))]),_c('v-col',{staticClass:"text-right pr-6",attrs:{"cols":"6"}},[_vm._v(_vm._s(_vm.seoVisibilityConversion.data.displayedDate.end))])],1):_vm._e()],1):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }