<template>
    <div>
        <component-container
            :card-title="$t('component_actionPriorities_title')"
            :card-subtitle="$t('component_actionPriorities_subtitle')"
            :height="395"

            :component-data="{response:actionPriorities,hasData:actionPriorities.data}"
        >


        <template v-slot:content>
            <v-row class="v-application px-4 mt-6">
                <v-col cols="12"  class="px-0" style="border-bottom: 1px solid #d0d0d0">
                    <v-row >
                        <v-col :cols="6">
                            <strong class="text-body-1 font-weight-bold d-inline-block">{{ $t('component_actionPriorities_resultTypePriority') }}</strong>
                        </v-col>
                        <v-col :cols="6" class="text-right">
                            <strong class="text-h6 font-weight-bold primary--text d-inline-block ml-auto">{{ $t('component_actionPriorities_resultTypeLabel').replace('[*TYPE*]', actionPriorities.data.resultType) }}</strong>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12"  class="px-0 text-right">
                    <span class="text-body-1">+{{ actionPriorities.data.resultTypeVisitorCount|formatNumber }} {{ $t('component_actionPriorities_potentialVisitor') }}</span>
                </v-col>
            </v-row>
            <v-row class="v-application px-4 mt-6 relative">
                <v-col cols="12"  class="px-0" style="border-bottom: 1px solid #d0d0d0">

                    <v-row class="v-application relative" >
                        <v-col :cols="6">
                            <strong class="text-body-1 font-weight-bold">{{ $t('component_actionPriorities_segmentPriority') }}</strong>
                        </v-col>
                        <v-col :cols="6" class="text-right">
                            <strong class="text-h6 font-weight-bold" :style="`color: ${getThemeColorByName('marketSegmentColor7')}`">
                                <v-icon dense class="purple--text text--lighten-3">mdi-tag</v-icon>
                                {{ $t('component_actionPriorities_segmentLabel') }}  {{ actionPriorities.data.segment }}
                            </strong>
                        </v-col>

                    </v-row>
                </v-col>

                <v-col cols="12"  class="px-0 text-right">
                    <span class="text-body-1">+{{ actionPriorities.data.segmentVisitorCount|formatNumber }} {{ $t('component_actionPriorities_potentialVisitor') }}</span>
                </v-col>
                <div class="placeholder-blurred-background v-application" v-if="keywordGroupsConfiguration.length===0 && $route.name !== 'report_template' ">
                    <v-card class="placeholder-link-container-custom" :class="{'no-link-btn': $route.name === 'display_reporting'}">
                        <v-card-text>
                            <p class="text-body-1">{{ $t('component_progressionAxisTopActionPriorities_noSegment') }}</p>

                            <v-btn color="warning" :href="'segments-configuration'" v-if="$route.name !== 'display_reporting'">{{ $t('component_progressionAxisTopActionPriorities_addSegment') }}</v-btn>
                        </v-card-text>
                    </v-card>
                </div>
            </v-row>







                <v-col cols="12" class="px-0 v-application" >
                    <v-btn
                        outlined
                        :to="{name:'progression_axis'}"
                        color="primary"
                        style="width: 100%;"
                        class="mt-6"
                        v-if="displayLinkButton"
                    >
                        {{ $t('component_actionPriorities_cta') }}<v-icon right>mdi-chevron-double-right</v-icon>
                    </v-btn>
                </v-col>

        </template>
        </component-container>
    </div>
</template>

<script>
import apiCalls from '@/api/axios/dataReport/progressionAxis'

export default {
    name: "ActionPriorities",
    components: {},

    data() {
        return {
            actionPriorities: null,
        }
    },
    props: {
        displayLinkButton:{type: Boolean, default: false},
    },
    watch:{
        globalFilterLocation(){
            this.initComponentData()
        },
        globalFilterFrequency(){
            this.initComponentData()
        }
    },
    methods: {
        getLogValue(value) {
            let logValue = value
            if(value >0 && value<100){
                logValue = -4.3997669 * Math.pow(10,-6) * Math.pow(value,4) + 1.097319347 * Math.pow(10,-3) * Math.pow(value,3) - 8.930361305 * Math.pow(10,-2) * Math.pow(value,2) + 3.33525641 * value + 1.363636364
            }
            return logValue
        },
        formatData(jsonData){
            if(jsonData.content.length === 0){return {}}
            let formatedData = {}

            jsonData.content.forEach(data=>{

                if(data.type === 'position'){
                    formatedData.resultType = this.$t(`common_google_block_label_${data.name}`)
                    formatedData.resultTypeVisitorCount = data.potentialTrafficGain
                    formatedData.keywordWithResultType = `<strong>${data.occurrenceProbabilityPercent}%</strong> ${this.$t('component_progressionAxisTopActionPriorities_keywordWithResultType')} ${this.$t(`common_google_block_label_${data.name}`)}`
                    formatedData.keywordWithResultPercent = `${this.$t('component_progressionAxisTopActionPriorities_keywordResultTypePresence').replace('[*PERCENT*]', data.websiteOccurrenceProbabilityPercent)} ${this.$t(`common_google_block_label_${data.name}`)}`
                    formatedData.keywordPercentWidth = Math.round((data.websiteOccurrenceProbabilityPercent*this.progressBarMaxWidth) / 100)
                    formatedData.resultTypeOptimizeCount = data.potentialProgressionKeywordCount
                }

                else {
                    if (this.keywordGroupsConfiguration.length===0) {
                        data.name = 'Example'
                        data.potentialTrafficGain = 547
                        data.potentialProgressionKeywordCount = 51
                        data.websiteOccurrenceProbabilityPercent= 32
                    }

                    formatedData.segment = data.name
                    formatedData.segmentVisitorCount = data.potentialTrafficGain
                    formatedData.keywordWithSegment = `<strong>${data.potentialProgressionKeywordCount}</strong> ${this.$t('component_progressionAxisTopActionPriorities_keywordWithSegment')}`
                    formatedData.keywordWithSegmentPercent = `${this.$t('component_progressionAxisTopActionPriorities_keywordSegmentPresence').replace('[*PERCENT*]', data.websiteOccurrenceProbabilityPercent)}`
                    formatedData.segmentPercentWidth = Math.round((data.websiteOccurrenceProbabilityPercent*this.progressBarMaxWidth) / 100)
                    formatedData.segmentOptimizeCount = 22
                }

            })

            return formatedData
        },
        initComponentData(){
            this.getComponentData({
                componentName:'actionPriorities',
                promise:!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.topActionPriorities(this.$router.currentRoute.params.projectId,{params:{
                        'date-range-type':this.globalFilterFrequency,
                        'search-geolocation-id':this.globalFilterLocation
                }}) : false,
                dataFormatter: this.formatData
            })
        },
    },
    beforeMount() {
        this.initComponentData()
    }
}
</script>

<style lang="scss">

.action-priorities-container{
    margin: 0 24px;

    .action-priorities-gain {
        margin-top: 10px;

        span {
            margin-top: 26px;
            white-space:nowrap;

            &.natural { color: $googleResultTypeNatural;}
            &.image { color: $googleResultTypeImage;}
            &.adwords { color: $googleResultTypeAdwords;}
            &.shopping { color: $googleResultTypeShopping;}
            &.map { color: $googleResultTypeMap;}
            &.snippet { color: $googleResultTypeSnippet;}
            &.actuality { color: $googleResultTypeActuality;}
            &.video { color: $googleResultTypeVideo;}
        }
    }


}

.placeholder-blurred-background{
    position: absolute;
    z-index: 105;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    backdrop-filter: saturate(100%) blur(2px)!important;
    background-color: #0000004d;
    border-radius: 10px;
    .placeholder-link-container-custom{
        position: absolute;
        left: calc(50% - 150px);
        top: calc(50% - 45px);
        width: 300px;
        height: 90px;
        text-align: center;

        p {
         margin-bottom: 8px;
        }
        &.no-link-btn{
            height: 60px;
            p{
                margin-top: 6px;
            }
        }
    }
}


</style>
