var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{attrs:{"card-title":_vm.$t('component_reportingFrequencyConfig_scheduleConfigTitle'),"card-subtitle":_vm.$t('component_reportingFrequencyConfig_scheduleConfigSubtitle'),"height":380,"information-tooltip":{
        title : _vm.$t('component_reportingFrequencyConfig_informationTooltip_title'),
        text:_vm.$t('component_reportingFrequencyConfig_informationTooltip_text'),
        width:450
    },"component-data":{
        response: { isLoaded:true, error:false,  data: true },
        hasData: true
    }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-row',{staticClass:"mt-6"},[_c('v-col',{staticClass:"v-application",attrs:{"cols":12}},[_c('v-select',{attrs:{"items":_vm.scheduledPeriods,"item-text":"name","item-value":"value","hide-details":"","label":_vm.$t('component_reportingFrequencyConfig_scheduleConfigPeriodLabel'),"outlined":""},on:{"change":_vm.saveConfig},model:{value:(_vm.selectedScheduledPeriods),callback:function ($$v) {_vm.selectedScheduledPeriods=$$v},expression:"selectedScheduledPeriods"}})],1),_c('v-col',{staticClass:"v-application",staticStyle:{"height":"20px","padding":"0 0 0 15px","margin-top":"-5px"},attrs:{"cols":12}},[_c('v-fade-transition',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.displayConfirmSave),expression:"displayConfirmSave"}],staticClass:"green--text text--darken-1"},[_vm._v(" "+_vm._s(_vm.$t('common_changeSaved'))+" ")])])],1),_c('v-col',{staticClass:"v-application",attrs:{"cols":12}},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.displayScheduledSummary)}})])],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }