<template>
    <component-container
        :card-title="$t('component_trafficEvolutionConversion_title')"
        :card-subtitle="$t('component_trafficEvolutionConversion_subtitle')"

        :height="495"

        :information-tooltip="{
            title : $t('component_trafficEvolutionConversion_informationTooltip_title'),
            text:$t('component_trafficEvolutionConversion_informationTooltip_text')
        }"


        :component-config="{
            hasConfig:analyticsViewConfiguration,
            hideOnNoConfig:hideOnNoConfig,
            redirectRoute:displayPlaceholderConfigButton ? 'analytics-project-configuration' : null,
            placeholderTitle:$t('component_componentContainer_noAnalytics_title'),
            placeholderText:$t('component_componentContainer_noAnalytics_text'),
        }"

        :component-data="{response:trafficEvolutionConversion, hasData:(trafficEvolutionConversion.data && trafficEvolutionConversion.data.series)}"
    >

        <template v-slot:content>

            <yooda-chart
                :options="trafficEvolutionConversion.data"
                chart-type="line"
            />
        </template>
    </component-container>
</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/dashboard.js'
import ECharts from 'vue-echarts'
import { useComponentStore } from '@/stores/ComponentStore'
import {mapState} from "pinia";

export default {
    props: {
        displayPlaceholderConfigButton:{type: Boolean, default: true},
        hideOnNoConfig:{type: Boolean, default: false}
    },
    data(){
        return {
            trafficEvolutionConversion:null,
        }
    },
    components: {
        YoodaChart,
    },
    computed:{
        ...mapState(useComponentStore, {
            globalFilterFrequency: 'getGlobalFilterFrequency',
        }),
    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData()
        }
    },
    methods:{
        formatData(jsonData){
            if(!jsonData.content.traffics){ return {} }
            let displayOneSymbol = false
            let symbolSize = 9
            let tooltipFormatter = params => {
                let toolTip = this.globalFilterFrequency !== "1" ? '<p>'+params[0].data.comparisonDates+'<br>' : '<p>'+params[0].axisValue+'<br>';
                params.forEach((param)=>{
                    toolTip += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + param.color + '"></span>'
                    toolTip += param.seriesName+' : '+this.$options.filters.formatNumber(param.value)+'<br>'
                })
                toolTip+= '</p>'
                return  toolTip
            }

            let serieLabels = []
            let serieDataVisitor = []
            let serieDataConversion = []

            jsonData.content.traffics.forEach((data)=>{
                serieLabels.push(this.$options.filters.displayDateLocale(data.endDate))
                serieDataVisitor.push({
                    value: data.traffic,
                    comparisonDates: this.$t('common_tooltip_date_comparison').replace('[*STARTDATE*]', this.$options.filters.displayDateLocale(data.startDate)).replace('[*ENDDATE*]', this.$options.filters.displayDateLocale(data.endDate)),
                })
                serieDataConversion.push(data.conversion)
            })

            if(jsonData.content.traffics.length === 1){
                displayOneSymbol = true
                symbolSize = 14
            }

            return {
                grid: { left: 70, top: 40, right: 55, bottom: 40 },
                tooltip: this.genericTooltipConfiguration('axis',tooltipFormatter),
                legend: {
                    orient: 'horizontal',
                    icon:'circle',
                    data: [
                        this.$t('component_trafficEvolutionConversion_series_name_1'),
                        this.$t('component_trafficEvolutionConversion_series_name_2'),
                    ]
                },
                // toolbox: this.genericToolboxOptions(),
                xAxis: { type: 'category', data: serieLabels,  offset: 15 },
                yAxis: [
                    {
                        type: 'value',
                        axisPointer:{
                            show:false
                        },
                        nameTextStyle:{
                            color: '#888',
                            fontWeight: 'bold',
                            align: 'right',
                            lineHeight: 35
                        },
                        name: this.$t('component_trafficEvolutionConversion_series_label_1')
                    },
                    {
                        type: 'value',
                        nameTextStyle:{
                            color: '#888',
                            fontWeight: 'bold',
                            lineHeight: 35
                        },
                        name: this.$t('component_trafficEvolutionConversion_series_label_2')
                    }
                ],
                series: [
                    {
                        showSymbol:displayOneSymbol,
                        symbolSize:symbolSize,
                        type: 'line',
                        name: this.$t('component_trafficEvolutionConversion_series_name_1'),
                        data: serieDataVisitor,
                        color:this.getThemeColorByName('warning'),
                        areaStyle: {
                            color: new ECharts.graphic.LinearGradient(0, 0.1, 0, 0.9, [{
                                offset: 0,
                                color: this.getThemeColorByName('warning',0.2)
                            }, {
                                offset: 1,
                                color: 'rgb(255,255,255)'
                            }])
                        },
                    },
                    {
                        showSymbol:displayOneSymbol,
                        symbolSize:symbolSize,
                        type: 'bar',
                        name: this.$t('component_trafficEvolutionConversion_series_name_2'),
                        data: serieDataConversion,
                        yAxisIndex:1,

                        itemStyle: {
                            color:this.getThemeColorByName('primary',0.6)
                        },
                        emphasis:{
                            itemStyle: {
                                color:this.getThemeColorByName('primary')
                            }
                        }
                    }
                ]
            }
        },
        initComponentData(){
            this.getComponentData({
                componentName:'trafficEvolutionConversion',
                dataFormatter: this.formatData,
                promise: (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  && this.analyticsViewConfiguration) ? apiCalls.readEvolutionConversion(this.$router.currentRoute.params.projectId, {params:{'date-range-type':this.globalFilterFrequency}}) : false
            })
        }
    },

    beforeMount() {
        this.initComponentData()

    },
}
</script>
