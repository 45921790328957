var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{attrs:{"card-title":_vm.$t('component_marketSegmentSegmentSize_title'),"card-subtitle":_vm.$t('component_marketSegmentSegmentSize_subtitle'),"height":495,"information-tooltip":{
        title : _vm.$t('component_marketSegmentSegmentSize_informationTooltip_title'),
        text:_vm.$t('component_marketSegmentSegmentSize_informationTooltip_text')
    },"component-config":{
        hasConfig:_vm.keywordGroupsConfiguration.length,
        hideOnNoConfig:_vm.hideOnNoConfig,
        redirectRoute:_vm.displayPlaceholderConfigButton ? 'segments-configuration' : null,
        placeholderTitle:_vm.$t('component_componentContainer_noMarketSegment_title'),
        placeholderText:_vm.$t('component_componentContainer_noMarketSegment_text')
    },"component-data":{
        response: _vm.marketSegmentSegmentSize,
        hasData: (_vm.marketSegmentSegmentSize.data && _vm.marketSegmentSegmentSize.data.series)
    }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('yooda-chart',{attrs:{"on-mouseover":_vm.setSelectedSegment,"options":_vm.marketSegmentSegmentSize.data,"chart-type":"pie"}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }