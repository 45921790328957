var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{attrs:{"card-title":_vm.$t('component_trafficIncomingWebsite_title'),"card-subtitle":_vm.$t('component_trafficIncomingWebsite_subtitle'),"information-tooltip":{
        title : _vm.$t('component_trafficIncomingWebsite_informationTooltip_title'),
        text:_vm.$t('component_trafficIncomingWebsite_informationTooltip_text')
    },"component-config":{
        hasConfig:_vm.analyticsViewConfiguration,
        hideOnNoConfig:_vm.hideOnNoConfig,
        redirectRoute:'analytics-project-configuration',
        placeholderTitle:_vm.$t('component_componentContainer_noAnalytics_title'),
        placeholderText:_vm.$t('component_componentContainer_noAnalytics_text')
    },"table-menu":{csv:true, fullscreen:true},"table-density-selector":true,"component-data":{response:_vm.trafficIncomingWebsite, hasData:(_vm.trafficIncomingWebsite.data && _vm.trafficIncomingWebsite.data.length > 0)}},on:{"setTableMenuFullscreen":function($event){_vm.cardContainerFullscreen = !_vm.cardContainerFullscreen},"setTableMenuExportCsv":function($event){return _vm.$refs.agGridTable.exportCsvTable()}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ag-grid-table',{ref:"agGridTable",attrs:{"show-floating-filter":false,"table-data":{columnDefs:_vm.columnDefs,rowData:_vm.trafficIncomingWebsite.data},"agGridStyle":`height:${_vm.tableHeightByRowCount(_vm.trafficIncomingWebsite.data)}px;`,"is-card-container-fullscreen":_vm.cardContainerFullscreen}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }