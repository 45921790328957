<template>

    <component-container
        :card-title="$t('component_marketSegmentEvolution_title')"
        :card-subtitle="$t('component_marketSegmentEvolution_subtitle')"

        :height="495"

        :information-tooltip="{
            title : $t('component_marketSegmentEvolution_informationTooltip_title'),
            text:$t('component_marketSegmentEvolution_informationTooltip_text')
        }"

        :component-config="{
            hasConfig:keywordGroupsConfiguration.length,
            hideOnNoConfig:hideOnNoConfig,
            redirectRoute:displayPlaceholderConfigButton ? 'segments-configuration' : null,
            placeholderTitle:$t('component_componentContainer_noMarketSegment_title'),
            placeholderText:$t('component_componentContainer_noMarketSegment_text')
        }"

        :component-data="{
            response:marketSegmentEvolution,
            hasData:(marketSegmentEvolution.data && marketSegmentEvolution.data.series)
        }"
    >

        <template v-slot:content>
            <yooda-chart
                :options="marketSegmentEvolution.data"
                chart-type="bar"
                chart-style="height:340px;"
            />

            <div class="v-application" v-if="displayLinkButton">
                <v-btn
                    outlined
                    :to="{name:'market_segment'}"
                    color="primary"
                    style="width: calc(100% - 35px); position: absolute; bottom: 18px"
                >
                    {{ $t('component_marketSegmentEvolution_detailButton') }}<v-icon right>mdi-chevron-double-right</v-icon>
                </v-btn>
            </div>
        </template>

    </component-container>
</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/dashboard.js'

export default {
    props: {
        displayLinkButton:{type: Boolean, default: false},
        displayPlaceholderConfigButton:{type: Boolean, default: true},
        hideOnNoConfig:{type: Boolean, default: false}
    },
    data() {
        return {
            marketSegmentEvolution:null
        }
    },
    components: {
        YoodaChart
    },
    computed:{

    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData()
        },
        globalFilterLocation(){
            this.initComponentData()
        }
    },
    methods:{
        formatData(jsonData){

            if(jsonData.content.length === 0){ return {} }
            let series = []
            let seriesCount = 0
            jsonData.content.sort((a,b)=>{
                return (a.keywordGroupName < b.keywordGroupName) ? -1 : (a.keywordGroupName > b.keywordGroupName) ? 1 : 0
            })

            jsonData.content.forEach((data)=>{
                if(seriesCount <=7){
                    let radius = [0,10,10,0]
                    let color = this.getThemeColorByName('positiveVariation',0.8)
                    let colorHover = this.getThemeColorByName('positiveVariation')
                    if(data.cumulatedVisibilityEvolutionPercent < 0){
                        radius = [10,0,0,10]
                        color = this.getThemeColorByName('negativeVariation',0.8)
                        colorHover = this.getThemeColorByName('negativeVariation')
                    }

                    let serie = {
                        type: 'bar',
                        name: (data.keywordGroupName === '#other#'?this.$t('component_marketSegmentSegmentSize_other'):data.keywordGroupName),
                        data: [(data.cumulatedVisibilityEvolutionPercent?data.cumulatedVisibilityEvolutionPercent:0)],
                        showBackground: true,
                        itemStyle: { color:color, barBorderRadius: radius, },
                        emphasis:{ itemStyle: { color:colorHover, } },
                        label: { show: true, position: 'insideLeft', formatter:'{a}: {c}%', fontWeight:600 },
                    }
                    series.push(serie)
                }

                seriesCount++
            })

            let tooltipFormatter = params => {
                return `
                    <p>
                        <span class="toolTipColorSpan" style="background-color:${params.color}"></span>
                        ${params.seriesName} : ${this.$options.filters.formatNumber(jsonData.content[params.seriesIndex].currentCumulatedVisibility)}
                    </p>`
            }

            return {
                grid: { left: 0, top: 25, right: 0, bottom: 10 },
                tooltip: this.genericTooltipConfiguration('item', tooltipFormatter),
                yAxis: { type: 'category', position:'top',axisTicks: {show: false}, },
                xAxis: { show: false, type: 'value' },
                series: series
            }
        },
        initComponentData(){
            this.getComponentData({
                componentName:'marketSegmentEvolution',
                promise:(!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  && this.keywordGroupsConfiguration.length >0) ? apiCalls.readEvolution(this.$router.currentRoute.params.projectId, {params:{
                    'date-range-type':this.globalFilterFrequency,
                    'search-geolocation-id':this.globalFilterLocation
                }}) : false,
                dataFormatter: this.formatData,
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
