<template>
    <div>
        <component-container
            :class="{filterOpen:filterOpen}"
            :card-title="$t('component_seoVisibilityNaturalPositionList_title')"
            :card-subtitle="$t('component_seoVisibilityNaturalPositionList_subtitle')"


            :information-tooltip="{
                title : $t('component_seoVisibilityNaturalPositionList_informationTooltip_title'),
                text:$t('component_seoVisibilityNaturalPositionList_informationTooltip_text')
            }"

            :table-menu="{csv:true, fullscreen:true}"
            :table-density-selector="true"

            :component-data="{response:seoVisibilityNaturalPositionList, hasData:seoVisibilityNaturalPositionList.data}"

            @setTableMenuFullscreen="cardContainerFullscreen = !cardContainerFullscreen"
            @setTableMenuExportCsv=" $refs.naturalPositionList.exportCsvTable()"

        >
            <template v-slot:filters>
                <component-filters

                    :market-segments="true"
                    :competitors="true"
                    :competitors-with-project-website="true"
                    :competitors-with-select-all="false"
                    :report-date="true"
                    @applyFilter="initComponentData"
                    @filterPanelStatus="displayFilterOpen"
                    :default-select-value="lastFilters"
                />
            </template>

            <template v-slot:content>
                <ag-grid-table
                    :show-floating-filter="false"
                    :table-data="{columnDefs:columnDefs,rowData:seoVisibilityNaturalPositionList.data}"
                    :agGridStyle="`height:${tableHeightByRowCount(seoVisibilityNaturalPositionList.data)}px;`"
                    :ag-grid-options="{ rowHeight: 82 }"
                    ref="naturalPositionList"
                    @pagination-change="onPaginationChange"
                    :is-card-container-fullscreen="cardContainerFullscreen"
                />
            </template>
        </component-container>
        <seo-visibility-keyword-visibility-evolution/>
    </div>
</template>


<script>

import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable'
import SeoVisibilityKeywordVisibilityEvolution from '@/components/yooda-components/app/seo-visibility/SeoVisibilityKeywordVisibilityEvolution'
import apiCalls from '@/api/axios/dataReport/seoVisbility.js'
import ComponentFilters from "@/components/yooda-components/ComponentFilters";
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";
// import * as echarts from "echarts";

export default {
    data() {
        return {
            seoVisibilityNaturalPositionList: null,
            filterOpen:false,
            cardContainerFullscreen:false,
            domainName: null,
            lastFilters:null,
            sparklineAlreadyLoaded:[],
            timeOut:null,
            columnDefs:[
                {headerName: this.$t('component_seoVisibilityNaturalPositionList_table_header_keyword'), field: 'keyword',filter: true, width: 110 },
                {headerName: this.$t('component_seoVisibilityNaturalPositionList_table_header_evo'), field: 'evo',filter: false, sortable: false, width: 180 , cellRendererFramework: 'CellRendererPopinChartLink'},
                {headerName: this.$t('component_seoVisibilityNaturalPositionList_table_header_position'), field: 'position', width: 70, cellRendererFramework: 'CellRendererFormatedNumber', headerClass:'ag-grid-header-right', cellClass:'ag-grid-cell-right',comparator: cellSort.agGridTableSortCellGeneric},
                {headerName: this.$t('component_seoVisibilityNaturalPositionList_table_header_variation'), field: 'variation', cellRendererFramework: 'CellRendererProgression', width: 90, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellRankEvolution},
                {headerName: this.$t('component_seoVisibilityNaturalPositionList_table_header_page'), field: 'page', cellRendererFramework: 'CellRendererLink',comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_seoVisibilityNaturalPositionList_table_header_searchCount'), field: 'searchCount', width: 90, cellRendererFramework: 'CellRendererFormatedNumber', cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellTraffic},
                {headerName: this.$t('component_seoVisibilityNaturalPositionList_table_header_seoTraffic'), field: 'seoTraffic', sort:'desc',  width: 90, cellRendererFramework: 'CellRendererWithLabel',cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellFloatValue},
            ],
        }
    },
    components: {
        AgGridTable,
        SeoVisibilityKeywordVisibilityEvolution,
        ComponentFilters,
    },
    computed:{

    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData(this.lastFilters)
        },
        globalFilterLocation(){
            this.initComponentData(this.lastFilters)
        }
    },
    methods:{
        displayFilterOpen(status){ this.filterOpen = status === 'open'; },

        formatData(jsonData){
            if(jsonData.content.length === 0){return []}
            let rowData = []

            let index = 0

            jsonData.content.forEach(data => {
                rowData.push({
                    externalKeywordId: data.externalKeywordId,
                    keyword: data.string,
                    arrayIndex:index,
                    evo: {
                        componentName: 'seoVisibilityKeywordVisibilityEvolution',
                        chartData: data.ranks,
                        params: {
                            yAxisInverted: true,
                            formatter:'position'
                        },
                        promise: () => !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readKeywordPositionEvolution(
                            this.$router.currentRoute.params.projectId,
                            data.externalKeywordId,
                            { params: {
                                    'search-geolocation-id': this.globalFilterLocation,
                                    'date-range-type': this.globalFilterFrequency,
                                    'domain-name': this.domainName ? this.domainName : this.projectUrl
                                }}
                        ) : false,
                        elementName:data.string
                    },
                    position: data.rank !== null ? data.rank : '-',
                    variation: {
                        rankEvolution: this.getVariation(data.rank, data.previousRank)
                    },
                    page: {
                        href: `//${this.domainName}${data.url}`,
                        value: data.url,
                        targetBlank: true
                    },
                    searchCount: data.searchCount !== null ? data.searchCount : '-',
                    seoTraffic: {
                        value: this.$options.filters.formatNumber(data.visibility),
                        label: this.$i18n.t('component_seoVisibilityNaturalPositionList_table_visitorLabel')
                    },
                })

                index++
            })

            return rowData
        },

        onPaginationChange(value){
            window.clearTimeout(this.timeOut)
            this.timeOut = window.setTimeout(()=>{
                value.forEach(row=>{
                    if(!this.sparklineAlreadyLoaded[row.data.arrayIndex]){

                        if(this.$router.currentRoute.params.projectId){
                            apiCalls.readNaturalPositionListSparkline(this.$router.currentRoute.params.projectId,{
                                params: {
                                    'search-geolocation-id': this.globalFilterLocation,
                                    'domain-name': this.domainName,
                                    'keyword-id': row.data.externalKeywordId,
                                    'date-range-type': this.globalFilterFrequency
                                }
                            })
                                .then(async res=>{
                                    if(res){
                                        this.seoVisibilityNaturalPositionList.data[row.data.arrayIndex].evo.chartData = res.content
                                    }
                                })
                        }
                        else{
                            this.seoVisibilityNaturalPositionList.data[row.data.arrayIndex].evo.chartData = [8,5,7,2,2,6]
                        }
                        this.sparklineAlreadyLoaded[row.data.arrayIndex] = true
                    }
                })
            },1000)

        },
        initComponentData(filters){
            this.sparklineAlreadyLoaded = []
            this.domainName = filters.params['domain-name']
            filters.params['search-geolocation-id'] = this.globalFilterLocation
            filters.params['date-range-type'] = this.globalFilterFrequency

            this.getComponentData({
                componentName:'seoVisibilityNaturalPositionList',
                promise: !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readNaturalPositionList(this.$router.currentRoute.params.projectId,filters) : false,
                dataFormatter: this.formatData
            })
            this.lastFilters = filters
        },
        getVariation(rank, previousRank) {

            if (rank === null && previousRank === null) {
                return 'Missing';
            } else if (previousRank === null) {
                return 'New';
            } else if (rank === null) {
                return 'Out';
            } else {
                return -(rank - previousRank)
            }
        }
    },
    beforeMount() {
        this.initComponentData({ params: {'domain-name': this.projectUrl}})
    },
}
</script>
