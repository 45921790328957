var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{attrs:{"card-title":_vm.$t('component_marketSegmentVisibility_title'),"card-subtitle":_vm.$t('component_marketSegmentVisibility_subtitle'),"height":(_vm.filterOpen ? 510 : 410),"information-tooltip":{
        title : _vm.$t('component_marketSegmentVisibility_informationTooltip_title'),
        text:_vm.$t('component_marketSegmentVisibility_informationTooltip_text')
    },"component-config":{
        hasConfig:_vm.keywordGroupsConfiguration.length,
        hideOnNoConfig:_vm.hideOnNoConfig,
        redirectRoute:'segments-configuration',
        placeholderTitle:_vm.$t('component_componentContainer_noMarketSegment_title'),
        placeholderText:_vm.$t('component_componentContainer_noMarketSegment_text')
    },"component-data":{
        response: _vm.marketSegmentVisibility,
        hasData: (_vm.marketSegmentVisibility.data && _vm.marketSegmentVisibility.data.series)
    },"has-filter":true},scopedSlots:_vm._u([{key:"filters",fn:function(){return [(_vm.keywordGroupsConfiguration.length)?_c('component-filters',{attrs:{"result-type":true,"default-select-value":_vm.lastFilters},on:{"applyFilter":_vm.initComponentData,"filterPanelStatus":_vm.displayFilterOpen}}):_vm._e()]},proxy:true},{key:"content",fn:function(){return [_c('yooda-chart',{attrs:{"options":_vm.marketSegmentVisibility.data,"chart-type":"line"}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }