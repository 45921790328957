
import axios from '@/api/axios.js'

export default {

    /**
     * @param projectId int
     * @param filters
     * @returns Promise
     */
    readPopularityByWebsite (projectId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/linking/popularity`,filters)
    },

    /**
     * @param projectId int
     * @param filters
     * @returns Promise
     */
    readWebsiteAuthority (projectId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/linking/authority`,filters)
    },

    /**
     * @param projectId int
     * @param filters
     * @returns Promise
     */
    readLinkCount (projectId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/linking/links-count`,filters)
    },

    /**
     * @param projectId int
     * @param filters
     * @returns Promise
     */
    readCompetitorsLinking (projectId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/linking/competitors-linking`,filters)
    },

    /**
     * @param projectId int
     * @param indexItemInfoId int
     * @param filters
     * @returns Promise
     */
    readCompetitorsLinkingPopin (projectId, indexItemInfoId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/linking/competitors/index-item-infos/${indexItemInfoId}/ref-domains`,filters)
    },




    /**
     * @param projectId int
     * @param filters
     * @returns Promise
     */
    readMyThematicAuthority (projectId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/linking/project-topical-authorities`,filters)
    },




    /**
     * @param projectId int
     * @param filters
     * @returns Promise
     */
    readAuthorityCompareCompetitors (projectId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/linking/authority-compare-competitor`,filters)
    },

    /**
     * @param projectId int
     * @param filters
     * @returns Promise
     */
    readAuthorityCompare (projectId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/linking/topical-authorities-comparison`,filters)
    },

    /**
     * @param projectId int
     * @param filters
     * @returns Promise
     */
    readBestBacklinks (projectId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/linking/backlinks`,filters)
    },

    /**
     * @param projectId int
     * @param filters
     * @returns Promise
     */
    readWebsiteBacklinks (projectId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/linking/ref-domains`,filters)
    },


    /**
     * @param projectId int
     * @param refDomainId string
     * @param filters
     * @returns Promise
     */
    readWebsiteBacklinksPopinLinks (projectId, refDomainId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/linking/ref-domains/${refDomainId}/backlinks`,filters)
    },


    /**
     * @param projectId int
     * @param filters
     * @returns Promise
     */
    readAnchorBacklinks (projectId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/linking/anchors`,filters)
    },
    /**
     * @param projectId int
     * @param anchorId int
     * @param filters
     * @returns Promise
     */
    readAnchorBacklinksPopinLink (projectId, anchorId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/linking/anchors/${anchorId}/backlinks`,filters)
    },
    /**
     * @param projectId int
     * @param anchorId int
     * @param filters
     * @returns Promise
     */
    readAnchorBacklinksPopinDomains (projectId, anchorId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/linking/anchors/${anchorId}/ref-domains`,filters)
    },

    /**
     * @param projectId int
     * @param filters
     * @returns Promise
     */
    readReferralBacklinks (projectId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/linking/top-pages`,filters)
    },

    /**
     * @param projectId int
     * @param topPageId string
     * @param filters
     * @returns Promise
     */
    readReferralBacklinksPopinLinks (projectId, topPageId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/linking/top-pages/${topPageId}/backlinks`,filters)
    },

    /**
     * @param projectId int
     * @param topPageId int
     * @param filters
     * @returns Promise
     */
    readReferralBacklinksPopinDomain (projectId, topPageId, filters=null) {
       return axios.get(`/report/data/projects/${projectId}/linking/top-pages/${topPageId}/positions`,filters)
    },

};
