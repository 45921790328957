<template>



    <v-row id="reporting-header-container" class="v-application">
        <v-col cols="12" >
            <vx-card>
                <v-row>
                    <v-col :xl="5" :lg="5" :md="5" :cols="12" >
                        <div v-if="previewMode">
                            <h3 class="font-weight-bold" v-if="project.data">{{ $t('component_headerReportModel_preview_title') }} {{ project.data.name }}</h3>
                        </div>
                        <div v-else>
                            <span v-if="project.isLoaded">{{ project.data.name }}</span>
                            <h3  v-if="displayedReporting.data">{{ $t(`component_headerReportModel_scheduledReport_period_${displayedReporting.data.dateRangeType}`) }}</h3>
                        </div>
                    </v-col>
                    <v-col :xl="5" :lg="5" :md="5" :cols="12"  class="xl:text-right lg:text-right">
                        <div  v-if="displayedReporting && displayedReporting.data">
                            <global-filter :display-data-date="false" :display-frequency="displayedReporting.data.type === 'live'"/>
                        </div>
                    </v-col>

                    <v-col :xl="2" :lg="2" :md="2" :cols="12" class="xl:text-right lg:text-right">
                        <div v-if="previewMode">
                            <p style="font-size: 13px">{{ $t('component_headerReportModel_headerLabel') }} {{ lastReportDate }}</p>
                        </div>
                        <div v-else>
                            <i18n-switcher class="d-inline-block mr-4"/>
                            <div class="d-inline-block">
                                <p class="my-0">{{ $t('common_report_date_title') }}</p>
                                <p class="my-0">{{lastReportDate}}</p>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </vx-card>
        </v-col>
    </v-row>


</template>


<script>

import apiCalls from "@/api/axios/configuration/project";
import apiCallsReporting from "@/api/axios/dataReport/reporting";
import GlobalFilter from "@/components/yooda-components/GlobalFilter";
import I18nSwitcher from "@/components/yooda-components/I18nSwitcher.vue";

export default {

    props: {
        previewMode:{type: Boolean, default: false},
        reportingId:{type: Number, default: 0}
    },
    data(){
        return{
            displayedReporting:null,
            subscriptionMetaData:null,
        }
    },
    computed:{
        lastReportDate(){
            let lastDate = ''
            if(this.previewMode){
                this.projectReports.forEach(data=>{
                    if(data.complete && lastDate === ''){
                        lastDate = new Date(data.createdAt).toLocaleDateString('fr-FR')
                    }
                })
            }
            else{
                if(this.displayedReporting.data){
                    lastDate = new Date(this.displayedReporting.data.currentReportingDate).toLocaleDateString('fr-FR')
                }
            }
            return lastDate
        },
        projectExist(){
            return !(this.project.data === null || this.project.error);
        },
    },
    components: {
        GlobalFilter,
        I18nSwitcher,
    },
    beforeMount() {

        if(this.previewMode){
            if(!this.project.isLoaded){
                if(this.$route.params.projectId){
                    this.setDataComponent( {
                        stateName:'projectInfo',
                        promise: apiCalls.getProjectInfo(this.$route.params.projectId),
                        dataFormatter: this.projectData
                    })
                }
            }
        }
        else{
            this.getComponentData({
                componentName:'displayedReporting',
                promise: apiCallsReporting.readReportingByHash(this.$route.query.hash)
            }).then(()=>{
                if(!this.project.isLoaded){
                    if(this.$route.params.projectId){
                        this.setDataComponent( {
                            stateName:'projectInfo',
                            promise: apiCalls.getProjectInfo(this.$route.params.projectId),
                            dataFormatter: this.projectData
                        })
                    }
                }
            })
        }




    }
}
</script>

<style lang="scss">

#reporting-header-container{
    position: sticky;
    top: 0;
    padding-bottom: 20px;
    z-index: 200;

    .vx-card__body{
        padding: 12px 20px;
        span{
            font-size: 14px;
            font-weight: 400;
            color: $primary;
        }
        h3{
            font-size: 24px;
            font-weight: 200;
            text-transform: uppercase;
        }
    }
}


</style>
