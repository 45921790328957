<template>
    <component-container
        :card-title="$t('component_trafficQuality_title')"
        :card-subtitle="$t('component_trafficQuality_subtitle')"

        :height="550"

        :information-tooltip="{
            title : $t('component_trafficQuality_informationTooltip_title'),
            text:$t('component_trafficQuality_informationTooltip_text')
        }"

        :component-config="{
            hasConfig:analyticsViewConfiguration,
            hideOnNoConfig:hideOnNoConfig,
            redirectRoute:'analytics-project-configuration',
            placeholderTitle:$t('component_componentContainer_noAnalytics_title'),
            placeholderText:$t('component_componentContainer_noAnalytics_text')
        }"
        :table-menu="{csv:true, fullscreen:true}"
        :table-density-selector="true"

        :component-data="{response:trafficQuality, hasData:(trafficQuality.data && trafficQuality.data.length > 0)}"

        @setTableMenuFullscreen="cardContainerFullscreen = !cardContainerFullscreen"
        @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"
    >
        <template v-slot:content>
            <ag-grid-table
                :show-floating-filter="false"
                :showPagination="false"
                :table-data="{columnDefs:columnDefs,rowData:trafficQuality.data}"
                agGridStyle="max-height:450px;"
                :is-card-container-fullscreen="cardContainerFullscreen"
                ref="agGridTable"
            />
        </template>
    </component-container>
</template>


<script>

import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable'
import apiCalls from '@/api/axios/dataReport/traffic.js'

export default {
    props: {
        hideOnNoConfig:{type: Boolean, default: false}
    },
    data() {
        return {
            trafficQuality:null,
            cardContainerFullscreen:false,
            columnDefs:[
                {headerName: '', field: 'channel', sortable: false,width: 280},
                {headerName: this.$t('component_trafficQuality_table_header_natural'), field: 'seo', sortable: false,cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},
                {headerName: this.$t('component_trafficQuality_table_header_adwords'), field: 'adwords', sortable: false,cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},
                {headerName: this.$t('component_trafficQuality_table_header_display'), field: 'display', sortable: false,cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},
                {headerName: this.$t('component_trafficQuality_table_header_social'), field: 'social', sortable: false,cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},
                {headerName: this.$t('component_trafficQuality_table_header_referral'), field: 'referral', sortable: false,cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},
                {headerName: this.$t('component_trafficQuality_table_header_direct'), field: 'direct', sortable: false,cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},
            ]
        }
    },
    components: {
        AgGridTable,
    },
    computed:{

    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData()
        }
    },
    methods:{
        formatData(jsonData){
            if(jsonData.content.length === 0){return []}
            let rowData = []
            jsonData.content.forEach(data => {
                let unity = '€'
                let formatedValue = {
                    seo:this.$options.filters.formatNumber(data.seo),
                    adwords:this.$options.filters.formatNumber(data.ads),
                    display:this.$options.filters.formatNumber(data.affiliation),
                    social:this.$options.filters.formatNumber(data.social),
                    referral:this.$options.filters.formatNumber(data.referral),
                    direct:this.$options.filters.formatNumber(data.direct)
                }
                if(data.channel === 'traffic'){ unity = ''}
                if(data.channel === 'conversion'){ unity = ''}
                if(data.channel === 'transactions_count'){ unity = ''}
                if(data.channel === 'conversion_rate'){ unity = '%'}
                if(data.channel === 'ecommerce_conversion_rate'){ unity = '%'}



                rowData.push({
                    channel:this.$t(`component_trafficQuality_label_${data.channel}`),
                    seo:formatedValue.seo+unity,
                    adwords:formatedValue.adwords+unity,
                    display:formatedValue.display+unity,
                    social:formatedValue.social+unity,
                    referral:formatedValue.referral+unity,
                    direct:formatedValue.direct+unity,
                })
            })
            return rowData
        },
        initComponentData(){
            this.getComponentData({
                componentName:'trafficQuality',
                promise: (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  && this.analyticsViewConfiguration) ? apiCalls.readQuality(this.$router.currentRoute.params.projectId,{params:{'date-range-type':this.globalFilterFrequency}}) : false,
                dataFormatter: this.formatData
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
