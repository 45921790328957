
import axios from '@/api/axios.js'

export default {

    /**
     * @param projectId int
     * @returns Promise
     */
    readGlobalGoogleVisibility (projectId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/dashboard/recommendations/global-google-visibility`, filters)
    },

    /**
     * @param projectId
     * @returns {*}
     */
    readActionPriorities(projectId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/dashboard/recommendations/action-priorities`, filters)
    },

    /**
     * @param projectId
     * @returns {*}
     */
    readMostVisibleCompetitors(projectId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/dashboard/recommendations/most-visible-competitors`, filters)
    },

    /**
     * @param projectId
     * @returns {*}
     */
    readMarketSegmentVisibility(projectId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/dashboard/recommendations/market-segment-visibility`, filters)
    },

    /**
     * @param projectId
     * @returns {*}
     */
    readTopBestPages(projectId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/dashboard/recommendations/best-pages`, filters)
    },

    /**
     * @param projectId
     * @returns {*}
     */
    readTopBestKeywords(projectId, filters=null) {
        return axios.get(`/report/data/projects/${projectId}/dashboard/recommendations/best-keywords`, filters)
    }
};
