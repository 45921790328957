<template>
    <component-container
        :card-title="$t('component_websitePagesBestPages_title')"
        :card-subtitle="$t('component_websitePagesBestPages_subtitle')"

        :height="495"

        :information-tooltip="{
            title : $t('component_websitePagesBestPages_informationTooltip_title'),
            text:$t('component_websitePagesBestPages_informationTooltip_text')
        }"

        :component-data="{
            response:websitePagesBestPages,
            hasData:(websitePagesBestPages.data && websitePagesBestPages.data.length > 0)
        }"
    >
        <template v-slot:content>
            <div class="mt-5 v-application">
                <div v-for="(rank, index) in websitePagesBestPages.data" v-bind:key="index" class="mt-3">
                    <p class="text-body-1 mb-2"><span class="display-url">{{ rank.name }}</span> : {{rank.percent}}%</p>
                    <vs-progress
                        :percent="rank.percent"
                        :color="getThemeColorByName('primary')"
                        :height="16">
                    </vs-progress>
                </div>

                <div class="v-application" v-if="displayLinkButton">
                    <v-btn
                        outlined
                        :to="{name:'website_pages'}"
                        color="primary"
                        style="width: calc(100% - 35px); position: absolute; bottom: 18px"
                    >
                        {{ $t('component_websitePagesBestPages_detailButton') }}<v-icon right>mdi-chevron-double-right</v-icon>
                    </v-btn>
                </div>
            </div>
        </template>

    </component-container>
</template>


<script>

import apiCalls from '@/api/axios/dataReport/websitePages.js'

export default {
    props: {
        displayLinkButton:{ type: Boolean, default: false }
    },
    data() {
        return {
            websitePagesBestPages:null
        }
    },
    computed:{

    },
    watch:{
        globalFilterLocation(){
            this.initComponentData()
        }
    },
    methods:{
        formatData(jsonData){
            if(jsonData.content.length === 0){ return [] }
            return jsonData.content.map(data =>{
                return { name: data.url, value: data.performance, percent: data.performance }
            })
        },
        initComponentData(){
            this.getComponentData({
                componentName:'websitePagesBestPages',
                promise:!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readBestPages(this.$router.currentRoute.params.projectId, {params:{'search-geolocation-id':this.globalFilterLocation}}) : false,
                dataFormatter: this.formatData,
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    }
}
</script>

<style lang="scss">
.display-url{
    width: calc(100% - 64px);
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}


</style>
