<template>
    <div>
        <component-container
            :card-title="$t('component_linkingBacklinks_title')"
            :card-subtitle="$t('component_linkingBacklinks_subtitle')"

            :custom-style="`padding-top:15px;border:none !important;${displayNoBorder ? 'border-radius:0!important;': 'box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.15) !important;'}`"

            :no-shadow="true"
            :information-tooltip="{
                title : $t('component_linkingBestBacklinks_informationTooltip_title'),
                text:$t('component_linkingBestBacklinks_informationTooltip_text')
            }"

            :table-menu="{csv:true, fullscreen:true}"
            :table-density-selector="true"

            :component-data="{response:linkingBestBacklinks, hasData:linkingBestBacklinks.data, noDataMessage:$t('component_linkingMyThematicAuthority_no_data_text')}"

            @setTableMenuFullscreen="cardContainerFullscreen = !cardContainerFullscreen"
            @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"
        >
            <template v-slot:filters>
                <linking-thematic-selector class="mb-8" :selected-thematic-name="selectedThematic" @selectValue="selectThematic"/>
            </template>

            <template v-slot:content>

                <ag-grid-table
                    :show-floating-filter="false"
                    :table-data="{columnDefs:columnDefs,rowData:linkingBestBacklinks.data}"
                    :agGridStyle="`height:${linkingBestBacklinks.data.length >= 11 ? tableHeightByRowCount(linkingBestBacklinks.data) : tableHeightByRowCount(linkingBestBacklinks.data)*1.3}px;`"
                    :ag-grid-options="{ rowHeight: 74 }"

                    :is-card-container-fullscreen="cardContainerFullscreen"
                    ref="agGridTable"
                />
            </template>

        </component-container>

    </div>

</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/linking.js'
import { useComponentStore } from '@/stores/ComponentStore'
import {mapState} from "pinia";
import AgGridTable from "@/components/yooda-components/ag-grid-table/AgGridTable.vue";
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";
import LinkingThematicSelector from "@/components/yooda-components/app/linking/LinkingThematicSelector.vue";

export default {
    data() {
        return {
            linkingBestBacklinks:null,

            selectedThematic:this.$t('linking_thematic_label_all'),
            cardContainerFullscreen:false,
            columnDefs:[
                {headerName: this.$t('component_linkingBestBacklinks_table_header_refererPage'),headerTooltip: this.$t('component_linkingBestBacklinks_table_header_refererPage_tooltip'),filter: true, field: 'refererPage', cellRendererFramework: 'CellRendererLinkingBacklinkDetail', comparator: cellSort.agGridTableSortCellValue,filterParams: {valueGetter: params => { return params.data.refererPage.value }}},
                {headerName: this.$t('component_linkingBestBacklinks_table_header_popularity'),headerTooltip: this.$t('component_linkingBestBacklinks_table_header_popularity_tooltip'),filter: false, field: 'popularity', sort:'desc', sortingOrder: ['desc','asc'], cellRendererFramework: 'CellRendererLinkingPopularityDetail', width: 75, comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_linkingBestBacklinks_table_header_linkingPage'),headerTooltip: this.$t('component_linkingBestBacklinks_table_header_linkingPage_tooltip'),filter: false, field: 'linkingPage',   cellRendererFramework: 'CellRendererLinkingBacklinkDetail', width: 120, comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_linkingBestBacklinks_table_header_thematic'),headerTooltip: this.$t('component_linkingBestBacklinks_table_header_thematic_tooltip'),filter: false, field: 'thematic', sortingOrder: ['desc','asc'],  cellRendererFramework: 'CellRendererLinkingThematic', comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_linkingBestBacklinks_table_header_anchor'),headerTooltip: this.$t('component_linkingBestBacklinks_table_header_anchor_tooltip'),filter: false, field: 'anchor', sortingOrder: ['asc','desc'], width: 100, cellRendererFramework: 'CellRendererTextEllipsis'},
            ]
        }
    },
    components: {
        AgGridTable,
        LinkingThematicSelector,
        YoodaChart
    },
    computed:{
        ...mapState(useComponentStore, {
            globalFilterFrequency: 'getGlobalFilterFrequency',
        }),
        displayNoBorder(){
            return this.$parent.$options._componentTag !== 'custom-report-model' && !this.cardContainerFullscreen
        }
    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData()
        }
    },
    methods:{

        selectThematic(selectThematic){
            this.selectedThematic = selectThematic
            this.initComponentData()
        },

        formatData(jsonData){
            if(jsonData.content.length === 0){return null}
            let rowData = []
            jsonData.content.forEach(data => {

                let thematicData = this.getThematicData(data)


                let parsedRefererPage = new URL(`${data.sourceUrl}`)
                let parsedLinkingPage = new URL(`${data.targetUrl}`)

                rowData.push({
                    refererPage:{
                        redirectTo: `${data.sourceUrl}`,
                        value: `${parsedRefererPage.hostname}${parsedRefererPage.pathname}`,
                        url: `${parsedRefererPage.hostname}${parsedRefererPage.pathname}`,
                        title: data.sourceTitle,
                    },
                    popularity: {
                        value: data.transmittedPopularity,
                        referralPagePopularity: data.popularity,
                        countLink: data.internalLinkCount,
                        color:this.getThemeColorByName('primary')
                    },
                    linkingPage: {
                        redirectTo: `${data.targetUrl}`,
                        value: parsedLinkingPage.pathname,
                        url: parsedLinkingPage.pathname,
                        title: data.targetTitle,
                    },
                    thematic:{
                        value:thematicData.mainThematic.value,
                        color:thematicData.mainThematic.color,
                        categories:thematicData.mainThematic.categories,
                        otherThematic:thematicData.otherThematic
                    },
                    anchor:data.anchorText ? data.anchorText : this.$t('component_linkingBestBacklinks_no_anchor_text')
                })
            })

            return rowData
        },
        initComponentData(){
            let filters = {'date-range-type':this.globalFilterFrequency}
            if(this.selectedThematic !== this.$t('linking_thematic_label_all')){
                filters['thematic'] = this.selectedThematic
            }

            this.getComponentData({
                componentName:'linkingBestBacklinks',
                promise: (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId ) ? apiCalls.readBestBacklinks(this.$router.currentRoute.params.projectId,{params:filters}) : false,
                dataFormatter: this.formatData
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
