<template>
    <component-container
        :card-title="$t('component_advertisingIncomingTraffic_title')"
        :card-subtitle="$t('component_advertisingIncomingTraffic_subtitle')"

        :height="495"

        :information-tooltip="{
            title : $t('component_advertisingIncomingTraffic_informationTooltip_title'),
            text:$t('component_advertisingIncomingTraffic_informationTooltip_text')
        }"

        :component-config="{
            hasConfig:analyticsViewConfiguration,
            hideOnNoConfig:hideOnNoConfig,
            redirectRoute:'analytics-project-configuration',
            placeholderTitle:$t('component_componentContainer_noAnalytics_title'),
            placeholderText:$t('component_componentContainer_noAnalytics_text')
        }"

        :component-data="{response:advertisingIncomingTraffic, hasData:(advertisingIncomingTraffic.data && advertisingIncomingTraffic.data.options)}"
    >
        <template v-slot:content>
            <div v-if="advertisingIncomingTraffic.data">
                <div class="v-application pl-5 text-center" style="display: inline-block">
                    <div class="p-3">
                        <yooda-icon name="users" class="p-3" color="primary" background="rounded" :size="30"></yooda-icon>
                    </div>
                    <h3 class="text-h3 font-weight-bold">{{ advertisingIncomingTraffic.data.labelValue|formatNumber }}</h3>
                    <p class="text-body-1 mb-0">{{$t('component_advertisingIncomingTraffic_label')}} {{displayKpiLabel}}</p>
                </div>

                <yooda-chart
                    :options="advertisingIncomingTraffic.data.options"
                    chart-type="line"
                    chart-style="height:290px;"
                />
            </div>
        </template>
    </component-container>

</template>


<script>
import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/advertising.js'
import { useComponentStore } from '@/stores/ComponentStore'
import {mapState} from "pinia";

export default {
    props: {
        hideOnNoConfig:{type: Boolean, default: false}
    },
    data() {
        return {
            advertisingIncomingTraffic:null,
            displayKpiLabel:''
        }
    },
    components: {
        YoodaChart,
    },
    computed:{
        ...mapState(useComponentStore, {
            globalFilterFrequency: 'getGlobalFilterFrequency',
        }),
    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData()
        }
    },
    methods:{
        formatData(jsonData){

            if(!jsonData.content.hasOwnProperty('currentTraffic')){return {}}
            if(!jsonData.content.hasOwnProperty('traffics')){return {}}
            if(jsonData.content.traffics.length === 0){return {}}

            let serieLabel = []
            let serieData = []
            jsonData.content.traffics.forEach((data)=>{
                serieLabel.push(this.$options.filters.displayDateLocale(data.endDate))
                serieData.push({
                    date:data.endDate,
                    value:data.traffic,
                    comparisonDates: this.$t('common_tooltip_date_comparison').replace('[*STARTDATE*]', this.$options.filters.displayDateLocale(data.startDate)).replace('[*ENDDATE*]', this.$options.filters.displayDateLocale(data.endDate)),
                })
            })

            let cardLineData = {
                labels:serieLabel,
                data: serieData,
                labelValue: jsonData.content.currentTraffic
            }

            let tooltiplabel = this.$t('component_advertisingIncomingTraffic_label')

            let tooltipFormatter = params => {
                let toolTip = this.globalFilterFrequency !== "1" ? '<p>'+params[0].data.comparisonDates+'<br>' : '<p>'+params[0].axisValue+'<br>';
                params.forEach((param)=>{
                    toolTip += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + param.color + '"></span>'
                    toolTip += param.seriesName+' : '+this.$options.filters.formatNumber(params[0].value)+' '+tooltiplabel+'<br>'
                })
                toolTip+= '</p>'
                return  toolTip
            }


            let formatedData = this.genericCardLineOptions(
                cardLineData,
                this.$t('component_advertising_incoming_traffic_series_name_1'),
                'primary',
                'axis',
                tooltipFormatter
            )
            formatedData.options.xAxis.show = true
            formatedData.options.grid.bottom = 30
            formatedData.options.grid.left = 40
            formatedData.options.grid.right = 40


            if(jsonData.context.inputs){
                this.displayKpiLabel = this.$t(`common_periodPicker_kpiLabel_${jsonData.context.inputs.dateRangeType}`)
            }

            return formatedData
        },
        initComponentData(){
            this.getComponentData({
                componentName:'advertisingIncomingTraffic',
                promise: (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  && this.analyticsViewConfiguration) ? apiCalls.readIncomingTraffic(this.$router.currentRoute.params.projectId,{params:{'date-range-type':this.globalFilterFrequency}}) : false,
                dataFormatter: this.formatData
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
