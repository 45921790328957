<template>
    <div>
        <v-row>
            <v-col :cols="12" class="v-application pl-4 pr-4 mt-4" v-if="displayAlertHeader">
                <v-alert
                    text
                    dense
                    outlined
                    color="primary"
                    icon="mdi-information-outline"
                    border="left"
                    class="bordered-alert"
                >
                    <div v-html="$t(`view_reportingConfiguration_headerText`)"></div>
                </v-alert>
            </v-col>

            <v-col :xl="6" :lg="6" :cols="12">
                <reporting-project-template-selector/>
            </v-col>
            <v-col :xl="6" :lg="6" :cols="12">
                <reporting-logo-config/>
            </v-col>
            <v-col :xl="6" :lg="6" :cols="12">
                <reporting-frequency-config/>
            </v-col>

            <v-col :xl="6" :lg="6" :cols="12">
                <reporting-share-and-regenerate/>
            </v-col>
        </v-row>
    </div>

</template>


<script>

import ReportingProjectTemplateSelector from "@/components/yooda-components/reporting/ReportingProjectTemplateSelector";
import ReportingLogoConfig from "@/components/yooda-components/reporting/ReportingLogoConfig";
import ReportingFrequencyConfig from "@/components/yooda-components/reporting/ReportingFrequencyConfig";
import ReportingShareAndRegenerate from "@/components/yooda-components/reporting/ReportingShareAndRegenerate";


export default {
    data() {
        return {
            displayAlertHeader:false,
        }
    },

    components: {
        ReportingProjectTemplateSelector,
        ReportingLogoConfig,
        ReportingFrequencyConfig,
        ReportingShareAndRegenerate,
    },
    beforeMount() {
        if(!localStorage.getItem('displayedReportingHeaderAlert')){
            localStorage.setItem('displayedReportingHeaderAlert','true')
            this.displayAlertHeader = true
        }
    },
}

</script>

