var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{attrs:{"card-title":_vm.$t('component_marketSegmentProgressionPotential_title'),"height":495,"information-tooltip":{
        title : _vm.$t('component_marketSegmentProgressionPotential_informationTooltip_title'),
        text:_vm.$t('component_marketSegmentProgressionPotential_informationTooltip_text')
    },"component-data":{
        response:_vm.marketSegmentProgressionPotential,
        hasData:(_vm.marketSegmentProgressionPotential.data && _vm.marketSegmentProgressionPotential.data.totalPotential)
    }},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.marketSegmentProgressionPotential.data)?_c('div',[_c('div',{staticClass:"v-application mt-10"},[_c('h3',{staticClass:"text-h3 font-weight-bold text-center"},[_vm._v("+"+_vm._s(_vm._f("formatNumber")(_vm.marketSegmentProgressionPotential.data.totalPotential)))]),_c('p',{staticClass:"text-body-1 text-center mb-8"},[_vm._v(_vm._s(_vm.$t('component_marketSegmentProgressionPotential_subtitle')))]),_c('vs-divider'),_c('div',{staticClass:"mt-6 mb-10 vx-row"},_vm._l((_vm.marketSegmentProgressionPotential.data.ranks),function(rank,index){return _c('div',{key:index,staticClass:"vx-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-0"},[_c('p',{staticClass:"text-body-1 mb-4 mt-6",staticStyle:{"font-size":"13px !important"}},[_c('strong',[_vm._v(_vm._s(_vm.$t('common_rank_including'))+" "+_vm._s(_vm.$t('common_google_block_label_'+rank.positionType)))]),_vm._v(" : "),_c('br'),_c('i',[_vm._v("+"+_vm._s(_vm._f("formatNumber")(rank.potentialTrafficGain))+" "+_vm._s(_vm.$t('component_marketSegmentProgressionPotential_label'))+" (+"+_vm._s(rank.cumulatedPotentialTrafficGainPercent)+"%)")])]),_c('vs-progress',{attrs:{"percent":rank.cumulatedPotentialTrafficGainPercent,"color":_vm.getComponentColor().getGoogleResultTypeColor(rank.positionType),"height":16}})],1)}),0),(_vm.displayLinkButton)?_c('div',{staticClass:"v-application"},[_c('v-btn',{staticStyle:{"width":"calc(100% - 35px)","position":"absolute","bottom":"18px"},attrs:{"outlined":"","to":{name:'progression_axis'},"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('component_marketSegmentProgressionPotential_detailButton'))),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-double-right")])],1)],1):_vm._e()],1)]):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }