<template>
    <div>
        <component-container
            :card-title="$t('component_globalGoogleVisibility_title')"
            :card-subtitle="$t('component_globalGoogleVisibility_subtitle')"
            :height="395"

            :component-data="{response:globalGoogleVisibility,hasData:true}"
        >

            <template v-slot:content>
                <v-row class="v-application mt-2">
                    <v-col cols="5">
                        <div class="pill-container">
                            <div class="label">
                                <span>{{ $t('component_globalGoogleVisibility_label_overwhelming') }}</span>
                                <span>{{ $t('component_globalGoogleVisibility_label_dominating') }}</span>
                                <span>{{ $t('component_globalGoogleVisibility_label_excellent') }}</span>
                                <span>{{ $t('component_globalGoogleVisibility_label_correct') }}</span>
                                <span>{{ $t('component_globalGoogleVisibility_label_null') }}</span>
                            </div>
                            <div class="value">
                                <div :class="`percent ${getVisibilityText.color}`" :style="`height: ${getPerformance}%;`">
                                </div>
                            </div>
                            <div class="title">
                                <h3 :class="`${getVisibilityText.color}--text text-h3 font-weight-medium`" :style="`bottom:calc(${getPerformance}% - 10px) ;`">{{ getPerformance }}%</h3>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="7">
                        <div class="py-0 px-2">
                            <p class="mb-2" :class="`${getVisibilityText.color}--text text-h6 font-weight-bold`">{{getVisibilityText.title}}</p>
                            <p v-html="getVisibilityText.text" class="text-body-1 mt-0"/>
                        </div>
                        <div>
                            <div class="px-2 pt-3">
                                <p class="text-body-1 font-weight-bold mb-0">{{ $t('component_globalGoogleVisibility_seo_visibility') }}</p>
                                <v-row class="d-flex align-center">
                                    <v-col cols="8" class="d-flex align-center">
                                        <v-progress-linear color="primary" background-color="bg-grey-light" height="8" rounded :value="getSeoPerformance"></v-progress-linear>
                                        <span class="font-weight-medium ml-2">{{ getSeoPerformance }}%</span>
                                    </v-col>
                                    <v-col cols="4">
                                        <router-link to="seo-visibility" class="d-flex align-center">
                                            {{ $t('component_globalGoogleVisibility_read_more') }}
                                            <v-icon small color="primary" style="padding-bottom: 1px;">mdi-chevron-right</v-icon>
                                        </router-link>
                                    </v-col>
                                </v-row>
                            </div>
                            <div class="mt-2">
                                <div class="px-2">
                                    <p class="text-body-1 font-weight-bold mb-1">{{ $t('component_globalGoogleVisibility_sea_visibility') }}</p>
                                    <v-row class="d-flex align-center">
                                        <v-col cols="8" class="d-flex align-center">
                                            <v-progress-linear color="yellow darken-2" background-color="bg-grey-light" height="8" rounded :value="getSeaPerformance"></v-progress-linear>
                                            <span class="font-weight-medium ml-2">{{ getSeaPerformance }}%</span>
                                        </v-col>
                                        <v-col cols="4">
                                            <router-link to="advertising" class="d-flex align-center">
                                                {{ $t('component_globalGoogleVisibility_read_more') }}
                                                <v-icon small color="primary" style="padding-bottom: 1px;">mdi-chevron-right</v-icon>
                                            </router-link>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </template>
        </component-container>
    </div>

</template>

<script>
import apiCalls from "@/api/axios/dataReport/dashboardRecommendations";
export default {
    name: "GlobalGoogleVisibility",
    data() {
        return {
            globalGoogleVisibility: null
        }
    },
    watch:{
        globalFilterLocation(){
            this.initComponentData()
        }
    },
    computed: {
        getPerformance() {
            return this.globalGoogleVisibility.data ? Math.round(this.globalGoogleVisibility.data.performance) : 0
        },
        getSeoPerformance() {
            return this.globalGoogleVisibility.data ? this.globalGoogleVisibility.data.seoPerformance : 0
        },
        getSeaPerformance() {
            return this.globalGoogleVisibility.data ? this.globalGoogleVisibility.data.seaPerformance : 0
        },
        getVisibilityText() {
            switch (true) {
                case this.getPerformance <= 12:
                    return {
                        color: 'red',
                        title: this.$t('component_globalGoogleVisibility_visibility_status').replace('[*VISIBILITY*]', this.$t('component_globalGoogleVisibility_label_null').toUpperCase()),
                        text: this.$t('component_globalGoogleVisibility_null_paragraph').replace('[*SCORE*]', this.getPerformance)
                    }

                case this.getPerformance > 12 && this.getPerformance <= 37:
                    return {
                        color: 'orange',
                        title: this.$t('component_globalGoogleVisibility_visibility_status').replace('[*VISIBILITY*]', this.$t('component_globalGoogleVisibility_label_correct').toUpperCase()),
                        text: this.$t('component_globalGoogleVisibility_correct_paragraph').replace('[*SCORE*]', this.getPerformance)
                    }

                case this.getPerformance > 37 && this.getPerformance <= 62:
                    return {
                        color: 'green',
                        title: this.$t('component_globalGoogleVisibility_visibility_status').replace('[*VISIBILITY*]', this.$t('component_globalGoogleVisibility_label_excellent').toUpperCase()),
                        text: this.$t('component_globalGoogleVisibility_excellent_paragraph').replace('[*SCORE*]', this.getPerformance)
                    }

                case this.getPerformance > 62 && this.getPerformance <= 87:
                    return {
                        color: 'green',
                        title: this.$t('component_globalGoogleVisibility_visibility_status').replace('[*VISIBILITY*]', this.$t('component_globalGoogleVisibility_label_dominating').toUpperCase()),
                        text: this.$t('component_globalGoogleVisibility_dominating_paragraph').replace('[*SCORE*]', this.getPerformance)
                    }

                case this.getPerformance > 87 && this.getPerformance <= 100:
                    return {
                        color: 'green',
                        title: this.$t('component_globalGoogleVisibility_visibility_status').replace('[*VISIBILITY*]', this.$t('component_globalGoogleVisibility_label_overwhelming').toUpperCase()),
                        text: this.$t('component_globalGoogleVisibility_overwhelming_paragraph').replace('[*SCORE*]', this.getPerformance)
                    }
            }
        }
    },
    methods: {
        initComponentData(){
            this.getComponentData({
                componentName:'globalGoogleVisibility',
                promise:(!this.projectCreationWaiting && this.$router.currentRoute.params.projectId) ? apiCalls.readGlobalGoogleVisibility(this.$router.currentRoute.params.projectId, {params: {
                        'date-range-type':this.globalFilterFrequency,
                        'search-geolocation-id':this.globalFilterLocation
                    }}) : false,
            })
        },
    },
    beforeMount() {
        this.initComponentData()
    }
}
</script>

<style lang="scss">
.pill-container {
    height: 275px;
    display: flex;

    .label {
        width: 100px;
        padding-right:6px;
        margin-right: 10px;
        margin-top: 0;

        span {
            display: block;
            text-align:right;
            height: 67px;
            position: relative;
            &:nth-of-type(3) {
                height: 60px;
            }
            &:nth-of-type(1),&:nth-of-type(5){
                &:after {
                    height: 0;
                }
            }

            &:after {
                content: '';
                position: absolute;
                width: 70px;
                height: 2px;
                background-color: white;
                right: -75px;
                margin-top:12px;
                z-index: 99;
            }
        }
    }

    .value {
        width: 50px;
        height: 100%;
        background-color: #eee;
        position: relative;
        margin-top: 0px;
        border-radius:8px;

        .percent {
            position: absolute;
            width: 100%;
            bottom: 0;
            transition: height 0.6s;

            border-bottom-left-radius:8px;
            border-bottom-right-radius:8px;
        }
    }
    .title{
        margin-top:14px;
        position:relative;
        padding-left:30px;
        h3{
            margin:0;
            position:absolute;
            bottom: 0;
            transition: bottom 0.6s;
        }
    }
}

</style>
