<template>
    <div>
        <component-container
            :class="{filterOpen:filterOpen}"
            :card-title="$t('component_advertisingAdwordsAdvertisers_title')"
            :card-subtitle="$t('component_advertisingAdwordsAdvertisers_subtitle')"


            :information-tooltip="{
                title : $t('component_advertisingAdwordsAdvertisers_informationTooltip_title'),
                text:$t('component_advertisingAdwordsAdvertisers_informationTooltip_text')
            }"

            :table-menu="{csv:true, fullscreen:true}"
            :table-density-selector="true"

            :component-data="{response:advertisingAdwordsAdvertisers, hasData:advertisingAdwordsAdvertisers.data}"

            @setTableMenuFullscreen="cardContainerFullscreen = !cardContainerFullscreen"
            @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"

            :no-shadow="true"
        >
            <template v-slot:filters>
                <component-filters
                    :market-segments="true"
                    @applyFilter="initComponentData"
                    @filterPanelStatus="displayFilterOpen"
                    :default-select-value="lastFilters"
                />
            </template>

            <template v-slot:content>
                <ag-grid-table
                    :show-floating-filter="false"
                    :table-data="{columnDefs:columnDefs,rowData:advertisingAdwordsAdvertisers.data}"
                    :agGridStyle="`height:${tableHeightByRowCount(advertisingAdwordsAdvertisers.data)}px;`"
                    :is-card-container-fullscreen="cardContainerFullscreen"
                    ref="agGridTable"
                />
            </template>
        </component-container>

        <advertising-adwords-advertiser-keywords/>
        <advertising-adwords-advertiser-ads/>
    </div>
</template>


<script>

import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable'
import AdvertisingAdwordsAdvertiserKeywords from '@/components/yooda-components/app/advertising/AdvertisingAdwordsAdvertiserKeywords'
import AdvertisingAdwordsAdvertiserAds from '@/components/yooda-components/app/advertising/AdvertisingAdwordsAdvertiserAds'
import apiCalls from '@/api/axios/dataReport/advertising.js'
import ComponentFilters from "@/components/yooda-components/ComponentFilters";
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";

export default {
    data() {
        return {
            advertisingAdwordsAdvertisers:null,
            filterOpen:false,
            cardContainerFullscreen:false,
            lastFilters: null,
            columnDefs:[
                {headerName: this.$t('component_advertisingAdwordsAdvertisers_table_header_advertiser'),filter: true, field: 'advertiser'},
                {headerName: this.$t('component_advertisingAdwordsAdvertisers_table_header_adwordsPerformance'), field: 'adwordsPerformance',  sort:'desc', cellRendererFramework: 'CellRendererCompetition', width: 100, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_advertisingAdwordsAdvertisers_table_header_keywordCount'), field: 'keywordCount', cellRendererFramework: 'CellRendererPopinTableLink', width: 90, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellFloatValue},
                {headerName: this.$t('component_advertisingAdwordsAdvertisers_table_header_averagePosition'), field: 'averagePosition', width: 90, cellRendererFramework: 'CellRendererFormatedNumber',cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},
                {headerName: this.$t('component_advertisingAdwordsAdvertisers_table_header_adCount'), field: 'adCount', cellRendererFramework: 'CellRendererPopinTableLink', width: 90, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellFloatValue},
            ]
        }
    },
    components: {
        AgGridTable,
        AdvertisingAdwordsAdvertiserKeywords,
        AdvertisingAdwordsAdvertiserAds,
        ComponentFilters,
    },
    computed:{

    },
    watch:{
        globalFilterLocation(){
            this.initComponentData(this.lastFilters)
        }
    },
    methods:{
        displayFilterOpen(status){ this.filterOpen = status === 'open'; },
        formatData(jsonData){
            if(jsonData.content.length === 0){return []}

            let rowData = []
            jsonData.content.forEach(data => {
                let filters = {
                    params: {
                        "website-id":data.websiteId,
                        "search-geolocation-id": this.globalFilterLocation,
                        "keyword-group-id": this.lastKeywordGroupId ? this.lastKeywordGroupId : null,
                    }
                }

                rowData.push({
                    advertiser: data.domainName,
                    adwordsPerformance:{value:data.performance,progression:true},
                    keywordCount:(data.rankedKeywordCount ? {
                        componentName:'advertisingAdwordsAdvertiserKeywords',
                        value:data.rankedKeywordCount,
                        promise: () => !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ?  apiCalls.readAdvertisingAdwordsAdvertiserKeywords(this.$router.currentRoute.params.projectId, filters) : false,
                        elementName:data.domainName
                    } :  {value:0}),
                    averagePosition:data.averageTopPosition,
                    adCount:(data.distinctAdsCount ? {
                        componentName:'advertisingAdwordsAdvertiserAds',
                        value:data.distinctAdsCount,
                        promise: () => !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readAdvertisingAdwordsAdvertiserAds(this.$router.currentRoute.params.projectId, filters) : false,
                        elementName:data.domainName
                    } :  {value:0}),
                })
            })

            return rowData
        },
        initComponentData(filters={params:{}}){
            filters.params["search-geolocation-id"] = this.globalFilterLocation
            this.lastKeywordGroupId = filters.params["keyword-group-id"]

            this.getComponentData({
                componentName:'advertisingAdwordsAdvertisers',
                promise: !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readAdwordsAdvertisers(this.$router.currentRoute.params.projectId,filters) : false,
                dataFormatter: this.formatData
            })
            this.lastFilters = filters
        },
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
