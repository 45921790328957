var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{attrs:{"card-title":_vm.$t('component_competitionTopCompetitor_title'),"card-subtitle":_vm.$t('component_competitionTopCompetitor_subtitle'),"height":(_vm.forceFullHeight ? 620 : 495 ),"information-tooltip":{
        title : _vm.$t('component_competitionTopCompetitor_informationTooltip_title'),
        text:_vm.$t('component_competitionTopCompetitor_informationTooltip_text')
    },"component-config":{
        hasConfig:_vm.rivalsConfiguration.length,
        redirectRoute:_vm.displayPlaceholderConfigButton ? 'competitors-configuration' : null,
        placeholderTitle:_vm.$t('component_componentContainer_noCompetitor_title'),
        placeholderText:_vm.$t('component_componentContainer_noCompetitor_text'),
    },"component-data":{
        response:_vm.competitionTopCompetitor,
        hasData:(_vm.competitionTopCompetitor.data),
        noDataMessage:_vm.$t('component_competitionTopCompetitor_noDataMessage')
    }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._l((_vm.competitionTopCompetitor.data),function(website){return _c('div',{key:website.websiteId,staticClass:"text-body-1 top-competitor-rank-display",class:{
                'first-rank':website.myProject,
                'with-link-button':_vm.displayLinkButton
            }},[_c('span',{style:('background-color:'+_vm.getComponentColor().getCompetitorColor(website.pos))}),_c('p',[_c('strong',[_vm._v(_vm._s(website.rank))]),_vm._v(" : "+_vm._s(website.name)+" ("+_vm._s(website.performance)+"%)")])])}),(_vm.displayLinkButton)?_c('div',{staticClass:"v-application"},[_c('v-btn',{staticStyle:{"width":"calc(100% - 35px)","position":"absolute","bottom":"18px"},attrs:{"outlined":"","to":{name:'competitor'},"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('component_competitionTopCompetitor_detailButton'))),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-double-right")])],1)],1):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }