<template>

    <div>
        <component-container
            :card-title="$t('component_progressionAxisTopActionPriorities_title')"
            :card-subtitle="$t('component_progressionAxisTopActionPriorities_subtitle')"

            :height="500"

            :component-data="{response:progressionAxisTopActionPriorities, hasData:progressionAxisTopActionPriorities.data}"
        >

            <template v-slot:content>


                <v-row class="v-application px-4 mt-8">
                    <v-col cols="12"  class="px-0 mb-6" style="border-bottom: 1px solid #d0d0d0">
                        <strong class="text-body-1 font-weight-bold">{{ $t('component_progressionAxisTopActionPriorities_resultTypePriority') }}</strong>
                    </v-col>
                    <v-col cols="4"  class="py-1">
                        <strong class="text-h6 font-weight-bold primary--text">
                            {{  $t('component_progressionAxisTopActionPriorities_resultTypeLabel').replace('[*TYPE*]', progressionAxisTopActionPriorities.data.resultType) }}
                        </strong>
                        <p class="text-body-1">
                            +{{ progressionAxisTopActionPriorities.data.resultTypeVisitorCount|formatNumber }}
                            {{ $t('component_progressionAxisTopActionPriorities_potentialVisitor') }}
                        </p>
                    </v-col>

                    <v-col cols="7"  class="py-0">
                        <div>
                            <div class="primary d-inline-block mr-4" style="height: 28px; vertical-align: middle" :style="`width:${progressBarMaxWidth}px`"/>
                            <span class="text-body-1" style="display: inline-block; vertical-align: middle" v-html="progressionAxisTopActionPriorities.data.keywordWithResultType"/>
                        </div>
                        <div>
                            <div class="primary lighten-2 d-inline-block mr-4" style="height: 28px; vertical-align: middle" :style="`width: ${progressionAxisTopActionPriorities.data.keywordPercentWidth}px`"/>
                            <span class="text-body-1" style="display: inline-block; vertical-align: middle" v-html="progressionAxisTopActionPriorities.data.keywordWithResultPercent"/>
                        </div>

                    </v-col>
                    <v-col cols="1" class="text-body-1 text-right">
                        <p class="text-primary underline cursor-pointer" @click="openPopin('resultType')">{{ progressionAxisTopActionPriorities.data.resultTypeOptimizeCount }}</p>
                    </v-col>
                    <v-col cols="12"  class="pt-4" style="border-top: 1px solid #d0d0d0"></v-col>
                </v-row>

                <v-row class="v-application relative mt-10 px-4">
                    <v-col cols="12"  class="px-0 mb-8 pt-6"  style="border-bottom: 1px solid #d0d0d0">
                        <strong class="text-body-1 font-weight-bold">{{ $t('component_progressionAxisTopActionPriorities_segmentPriority') }}</strong>
                    </v-col>
                    <v-col cols="4"  class="py-1">
                        <strong class="text-h6 font-weight-bold" :style="`color: ${getThemeColorByName('marketSegmentColor7')}`">
                            <v-icon dense class="purple--text text--lighten-3">mdi-tag</v-icon>
                            {{ $t('component_progressionAxisTopActionPriorities_segmentLabel') }}
                            {{ progressionAxisTopActionPriorities.data.segment }}
                        </strong>

                        <p class="text-body-1">
                            +{{ progressionAxisTopActionPriorities.data.segmentVisitorCount|formatNumber }}
                            {{ $t('component_progressionAxisTopActionPriorities_potentialVisitor') }}
                        </p>
                    </v-col>
                    <v-col cols="7"  class="py-0">
                        <div>
                            <div class="d-inline-block mr-4" style="height: 28px; vertical-align: middle" :style="`background-color: ${getThemeColorByName('marketSegmentColor7')}; width:${progressBarMaxWidth}px`"/>
                            <span class="text-body-1" style="display: inline-block; vertical-align: middle" v-html="progressionAxisTopActionPriorities.data.keywordWithSegment"/>
                        </div>
                        <div>
                            <div  class="d-inline-block mr-4" style="height: 28px; width: 80px; vertical-align: middle" :style="`background-color: ${getThemeColorByName('marketSegmentColor6')}; width: ${progressionAxisTopActionPriorities.data.segmentPercentWidth}px`"/>
                            <span class="text-body-1" style="display: inline-block; vertical-align: middle" v-html="progressionAxisTopActionPriorities.data.keywordWithSegmentPercent"/>
                        </div>
                    </v-col>
                    <v-col cols="1" class="text-body-1 text-right">
                        <p class="text-primary underline cursor-pointer" @click="openPopin('marketSegment')">{{ progressionAxisTopActionPriorities.data.segmentOptimizeCount }}</p>
                    </v-col>
                    <div class="placeholder-blurred-background v-application" v-if="keywordGroupsConfiguration.length===0 && $route.name !== 'report_template' ">
                        <v-card class="placeholder-link-container" :class="{'no-link-btn': $route.name === 'display_reporting'}">
                            <v-card-text>
                                <p class="text-body-1">{{ $t('component_progressionAxisTopActionPriorities_noSegment') }}</p>

                                <v-btn color="warning" :href="'segments-configuration'" v-if="$route.name !== 'display_reporting'">{{ $t('component_progressionAxisTopActionPriorities_addSegment') }}</v-btn>
                            </v-card-text>
                        </v-card>
                    </div>
                </v-row>

                <v-row class="px-4 mb-8">
                    <v-col cols="12"  class="pt-4" style="border-top: 1px solid #d0d0d0"></v-col>
                </v-row>

            </template>
        </component-container>
    </div>



</template>


<script>

import ComponentFilters from "@/components/yooda-components/ComponentFilters";
import AgGridTable from "@/components/yooda-components/ag-grid-table/AgGridTable";
import apiCallsMarketSegment from "@/api/axios/dataReport/marketSegment";
import apiCalls from '@/api/axios/dataReport/progressionAxis';

export default {
    components:{
        ComponentFilters,
        AgGridTable,
    },
    data() {
        return {
            progressionAxisTopActionPriorities:null,
            progressBarMaxWidth: 200,
            resultTypePromise: null,
            marketSegmentPromise: null,
            keywordGroupName: null,
            resultType: null
        }
    },
    computed:{
    },
    watch:{
        globalFilterLocation(){
            this.initComponentData(this.lastFilters)
        }
    },
    methods:{
        openPopin(mode) {
            if (mode === 'resultType') {
                this.showAgGridPopin(this.resultTypePromise)
            } else if (mode === 'marketSegment') {
                this.showAgGridPopin(this.marketSegmentPromise)
            }
        },

        formatData(jsonData){

            if(jsonData.content.length === 0){return {}}
            let formatedData = {}

            jsonData.content.forEach(data=>{

                if(data.type === 'position'){
                    this.resultTypePromise = {
                        componentName: 'progressionAxisImprovementPotentialKeywords',
                        promise: () => (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId) ? apiCalls.readImprovementPotentialKeywords(this.$router.currentRoute.params.projectId, {
                            params:{
                                "search-geolocation-id":this.globalFilterLocation,
                                "position-type":data.name
                            }}) : false,
                        elementName: this.$t(`common_google_block_label_${data.name}`)
                    }

                    formatedData.resultType = this.$t(`common_google_block_label_${data.name}`)
                    formatedData.resultTypeVisitorCount = data.potentialTrafficGain
                    formatedData.keywordWithResultType = `<strong>${data.occurrenceProbabilityPercent}%</strong> ${this.$t('component_progressionAxisTopActionPriorities_keywordWithResultType').replace('[*TYPE*]', this.$t(`common_google_block_label_${data.name}`))}`
                    formatedData.keywordWithResultPercent = `${this.$t('component_progressionAxisTopActionPriorities_keywordResultTypePresence').replace('[*PERCENT*]', data.websiteOccurrenceProbabilityPercent)} ${this.$t(`common_google_block_label_${data.name}`)}`
                    formatedData.keywordPercentWidth = Math.round((data.websiteOccurrenceProbabilityPercent*this.progressBarMaxWidth) / 100)
                    formatedData.resultTypeOptimizeCount = `${this.$t('component_progressionAxisTopActionPriorities_keywordToOptimizeCount').replace('[*COUNT*]', data.potentialProgressionKeywordCount)}`
                }

                else {
                    if (this.keywordGroupsConfiguration.length===0) {
                        data.name = 'Example'
                        data.potentialTrafficGain = 547
                        data.potentialProgressionKeywordCount = 51
                        data.websiteOccurrenceProbabilityPercent= 32
                    }

                    this.marketSegmentPromise = {
                        componentName: 'marketSegmentBestProgressionPotentialKeywords',
                        promise: () => (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  && this.keywordGroupsConfiguration.length>0) ? apiCallsMarketSegment.readBestProgressionPotentialKeyword(this.$router.currentRoute.params.projectId, {
                            params:{
                                "search-geolocation-id":this.globalFilterLocation,
                                "keyword-group-id":data.id
                            }}) : false,
                        elementName: data.name
                    }

                    formatedData.segment = data.name
                    formatedData.segmentVisitorCount = data.potentialTrafficGain
                    formatedData.keywordWithSegment = `<strong>${data.keywordCount}</strong> ${this.$t('component_progressionAxisTopActionPriorities_keywordWithSegment')}`
                    formatedData.keywordWithSegmentPercent = `${this.$t('component_progressionAxisTopActionPriorities_keywordSegmentPresence').replace('[*PERCENT*]', data.websiteOccurrenceProbabilityPercent)}`
                    formatedData.segmentPercentWidth = Math.round((data.websiteOccurrenceProbabilityPercent*this.progressBarMaxWidth) / 100)
                    formatedData.segmentOptimizeCount = `${this.$t('component_progressionAxisTopActionPriorities_keywordToOptimizeCount').replace('[*COUNT*]', data.potentialProgressionKeywordCount)}`
                }

            })

            return formatedData
        },
        initComponentData(){
            this.getComponentData({
                componentName:'progressionAxisTopActionPriorities',
                promise:!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.topActionPriorities(this.$router.currentRoute.params.projectId,{params:{'search-geolocation-id':this.globalFilterLocation}}) : false,
                dataFormatter: this.formatData
            })
        },
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
