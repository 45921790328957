<template>
    <div>
        <component-container
            :class="{filterOpen:filterOpen}"
            :card-title="$t('component_progressionAxisImprovementWay_title')"
            :card-subtitle="$t('component_progressionAxisImprovementWay_subtitle')"

            :height="(filterOpen ? 760 : 660)"

            :information-tooltip="{
            title : $t('component_progressionAxisImprovementWay_informationTooltip_title'),
            text:$t('component_progressionAxisImprovementWay_informationTooltip_text')
        }"

            :table-menu="{csv:true, fullscreen:true}"
            :table-density-selector="true"

            :component-data="{response:progressionAxisImprovementWay, hasData:(progressionAxisImprovementWay.data && progressionAxisImprovementWay.data.length > 0)}"

            @setTableMenuFullscreen="cardContainerFullscreen = !cardContainerFullscreen"
            @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"
        >
            <template v-slot:filters>
                <component-filters
                    :market-segments="true"
                    @applyFilter="initComponentData"
                    @filterPanelStatus="displayFilterOpen"
                    :default-select-value="lastFilters"
                />
            </template>

            <template v-slot:content>
                <ag-grid-table
                    :show-pagination="false"
                    :show-floating-filter="false"
                    :table-data="{columnDefs:columnDefs,rowData:progressionAxisImprovementWay.data}"
                    agGridStyle="height:530px;"
                    :is-card-container-fullscreen="cardContainerFullscreen"
                    ref="agGridTable"
                />
            </template>
        </component-container>
        <progression-axis-improvement-potential-keywords/>
    </div>

</template>


<script>

import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable'
import ProgressionAxisImprovementPotentialKeywords from '@/components/yooda-components/app/progression-axis/ProgressionAxisImprovementPotentialKeywords'
import apiCalls from '@/api/axios/dataReport/progressionAxis'
import ComponentFilters from "@/components/yooda-components/ComponentFilters";
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";

export default {
    data() {
        return {
            progressionAxisImprovementWay:null,
            filterOpen:false,
            cardContainerFullscreen:false,
            lastFilters:null,
            lastKeywordGroupId: null,
            columnDefs:[
                {headerName: this.$t('component_progressionAxisImprovementWay_table_header_channel'), field: 'channel'},
                {headerName: this.$t('component_progressionAxisImprovementWay_table_header_potentialGain'), field: 'potentialGain',  sort:'desc', cellRendererFramework: 'CellRendererPotentialTraffic', width: 180, comparator: cellSort.agGridTableSortCellFloatValue},
                {headerName: this.$t('component_progressionAxisImprovementWay_table_header_performance'), field: 'performance', cellRendererFramework: 'CellRendererPercent', width: 90, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_progressionAxisImprovementWay_table_header_resultType'), field: 'occurrenceProbabilityPercent', cellRendererFramework: 'CellRendererPercent', width: 90, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_progressionAxisImprovementWay_table_header_potentialKeywords'), field: 'potentialKeywords', cellRendererFramework: 'CellRendererPopinTableLink', width: 70,  cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', comparator: cellSort.agGridTableSortCellFloatValue},
            ],
        }
    },
    components: {
        AgGridTable,
        ProgressionAxisImprovementPotentialKeywords,
        ComponentFilters
    },
    computed:{

    },
    watch:{
        globalFilterLocation(){
            this.initComponentData(this.lastFilters)
        }
    },
    methods:{
        displayFilterOpen(status){ this.filterOpen = status === 'open'; },
        formatData(jsonData){
            if(jsonData.content.length === 0){return []}
            let rowData = []

            let maxPotentialTrafficGain = 1
            jsonData.content.forEach(data=>{
                if(data.potentialTrafficGain > maxPotentialTrafficGain){ maxPotentialTrafficGain = data.potentialTrafficGain }
            })

            jsonData.content.forEach(data => {

                let filters = {
                    params: {
                        "position-type":data.positionType,
                        "search-geolocation-id": this.globalFilterLocation,
                        "keyword-group-id": this.lastKeywordGroupId
                    }
                }

                rowData.push({
                    channel: this.$i18n.t('common_google_block_label_'+(data.positionType === 'zero' ? 'snippet' : data.positionType)),
                    performance: data.performance,
                    occurrenceProbabilityPercent: data.occurrenceProbabilityPercent,
                    potentialGain:{
                        value: `+${this.$options.filters.formatNumber(data.potentialTrafficGain)} ${this.$i18n.t('component_progressionAxisImprovementWay_label')}`,
                        percent:Math.round((data.potentialTrafficGain*100)/maxPotentialTrafficGain),
                        color:this.getComponentColor().getGoogleResultTypeColor(data.positionType)
                    },
                    potentialKeywords:{
                        componentName:'progressionAxisImprovementPotentialKeywords',
                        value:data.potentialProgressionKeywordCount,
                        promise: () =>!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ?  apiCalls.readImprovementPotentialKeywords(this.$router.currentRoute.params.projectId, filters) : false,
                        elementName:this.$i18n.t('common_google_block_label_'+(data.positionType === 'zero' ? 'snippet' : data.positionType))
                    }
                })
            })
            return rowData
        },
        initComponentData(filters){
            filters.params["search-geolocation-id"] = this.globalFilterLocation
            this.lastKeywordGroupId = filters.params["keyword-group-id"]

            this.getComponentData({
                componentName:'progressionAxisImprovementWay',
                promise:!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readImprovementWay(this.$router.currentRoute.params.projectId,filters) : false,
                dataFormatter: this.formatData
            })

            this.lastFilters = filters
        },
    },
    beforeMount() {
        this.initComponentData({params:{"search-geolocation-id":this.defaultSearchGeolocation}})
    },

}
</script>
