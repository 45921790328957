<template>
    <v-dialog v-model="show" content-class="popin-dialog-container" overlay-opacity="0.85">

        <v-icon
            class="close-popin-dialog"
            @click="show=false"
        >
            mdi-close
        </v-icon>

        <component-container
            :card-title="`${$t('component_linkingCompetitorsLinkingReferralDomains_title')} <em>${elementName}</em>`"
            :card-subtitle="$t('component_linkingCompetitorsLinkingReferralDomains_subtitle')"
            :table-menu="{csv:true}"
            :component-data="{response:linkingCompetitorsLinkingReferralDomains, hasData:linkingCompetitorsLinkingReferralDomains.data}"
            @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"

            :table-density-selector="true"
        >
            <template v-slot:content>

                <linking-thematic-selector class="mb-2 mt-6" :selected-thematic-name="selectedThematic" @selectValue="selectThematic"/>


                <ag-grid-table
                   v-if="linkingCompetitorsLinkingReferralDomains.data"
                   :with-fullscreen="false"
                   :show-pagination="false"
                   :show-floating-filter="false"
                   :table-data="{columnDefs:columnDefs,rowData:tableData}"
                   ref="agGridTable"
                />
            </template>
        </component-container>
    </v-dialog>
</template>


<script>

import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable.vue'
import ComponentFilters from "@/components/yooda-components/ComponentFilters.vue";
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";
import LinkingThematicSelector from "@/components/yooda-components/app/linking/LinkingThematicSelector.vue";

export default {
    data() {
        return {
            show:false,
            selectedThematic:this.$t('linking_thematic_label_all'),
            linkingCompetitorsLinkingReferralDomains:{  isLoaded:false, error:null,data: null },
            elementName:null,
            popinStateName:'linkingCompetitorsLinkingReferralDomains',
            columnDefs:[
                {headerName: this.$t('component_linkingWebsiteBacklinks_table_header_refererWebsite'),filter: true, field: 'refererPage', cellRendererFramework: 'CellRendererLinkingBacklinkDetail', comparator: cellSort.agGridTableSortCellValue,filterParams: {valueGetter: params => { return params.data.refererPage.value }}},
                {headerName: this.$t('component_linkingWebsiteBacklinks_table_header_popularity'),filter: false, field: 'popularity', sortingOrder: ['desc','asc'], cellRendererFramework: 'CellRendererLinkingInfoChips', width: 80, comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_linkingWebsiteBacklinks_table_header_authority'),filter: false, field: 'authority', sortingOrder: ['desc','asc'],  cellRendererFramework: 'CellRendererLinkingInfoChips', width: 80, comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_linkingWebsiteBacklinks_table_header_thematic'),filter: false, field: 'thematic', sortingOrder: ['desc','asc'],  cellRendererFramework: 'CellRendererLinkingThematic', comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_linkingWebsiteBacklinks_table_header_linkCount'),filter: false, field: 'linkCount',  sort:'desc', sortingOrder: ['desc','asc'], width: 70, cellRendererFramework: 'CellRendererFormatedNumber', cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},

            ]
        }
    },
    computed:{
        tableData(){

            if(this.selectedThematic !== this.$t('linking_thematic_label_all')){
                return this.linkingCompetitorsLinkingReferralDomains.data.filter((data)=>{
                    if(data.thematic && data.thematic.categories && (data.thematic.categories[0] === this.selectedThematic)){
                        return true
                    }
                    return false
                })
            }


            return this.linkingCompetitorsLinkingReferralDomains.data
        }
    },
    components: {
        LinkingThematicSelector,
        AgGridTable,
        ComponentFilters,
    },
    watch:{
        getShowPopin(){
            if(!this.show && this.getShowPopin){
                if(this.getShowPopin.componentName === this.popinStateName){
                    this.show = true
                    this.elementName = this.getShowPopin.elementName
                    this.getComponentData({
                        componentName:'linkingCompetitorsLinkingReferralDomains',
                        promise: this.getShowPopin.promise(),
                        dataFormatter: this.formatData
                    })
                }
            }
        },
        show(){
            if(!this.show){
                this.showAgGridPopin(null)
            }
        }


    },
    methods:{
        selectThematic(selectThematic){
            this.selectedThematic = selectThematic
        },

        formatData(jsonData){
            if(jsonData.content.length === 0){return null}
            let rowData = []
            jsonData.content.forEach(data => {
                let thematicData = this.getThematicData(data)

                let parsedRefererPage = new URL(`https://${data.domainName}`)

                rowData.push({
                    refererPage:{
                        value: `${parsedRefererPage.hostname}${parsedRefererPage.pathname}`,
                        redirectTo: `https://${data.domainName}`,
                        url: `${parsedRefererPage.hostname}${parsedRefererPage.pathname}`
                    },
                    popularity: {value:data.popularity, color:this.getThemeColorByName('linkingPopularity')},
                    authority: {value:data.authority, color:this.getThemeColorByName('linkingAuthority')},

                    thematic:{
                        value:thematicData.mainThematic.value,
                        color:thematicData.mainThematic.color,
                        categories:thematicData.mainThematic.categories,
                        otherThematic:thematicData.otherThematic
                    },
                    linkCount:data.linkCount

                })
            })


            return rowData
        },

    },
}
</script>
