<template>
    <component-container
        :card-title="$t('component_reportingFrequencyConfig_scheduleConfigTitle')"
        :card-subtitle="$t('component_reportingFrequencyConfig_scheduleConfigSubtitle')"
        :height="380"
        :information-tooltip="{
            title : $t('component_reportingFrequencyConfig_informationTooltip_title'),
            text:$t('component_reportingFrequencyConfig_informationTooltip_text'),
            width:450
        }"
        :component-data="{
            response: { isLoaded:true, error:false,  data: true },
            hasData: true
        }"
    >

        <template v-slot:content>
            <v-row class="mt-6">
                <v-col :cols="12" class="v-application">
                    <v-select
                        v-model="selectedScheduledPeriods"
                        :items="scheduledPeriods"
                        item-text="name"
                        item-value="value"
                        hide-details
                        :label="$t('component_reportingFrequencyConfig_scheduleConfigPeriodLabel')"
                        outlined
                        @change="saveConfig"
                    />
                </v-col>
                <v-col :cols="12" class="v-application" style="height: 20px; padding: 0 0 0 15px; margin-top: -5px">
                    <v-fade-transition>
                        <span
                            class="green--text text--darken-1"
                            v-show="displayConfirmSave"
                        >
                            {{ $t('common_changeSaved') }}
                        </span>
                    </v-fade-transition>

                </v-col>
                <v-col :cols="12" class="v-application">
                    <p v-html="displayScheduledSummary"></p>
                </v-col>
            </v-row>
        </template>

    </component-container>


</template>


<script>

import apiCalls from "@/api/axios/dataReport/reporting";

export default {
    data(){
        return{
            reportings:null,
            displayConfirmSave:false,
            reportingId:null,
            selectedScheduledPeriods:30,
            neutralDomain:null,
            scheduledPeriods:[
                {name:this.$t('common_scheduled_period_1'), value:1},
                {name:this.$t('common_scheduled_period_7'), value:7},
                {name:this.$t('common_scheduled_period_30'), value:30},
                {name:this.$t('common_scheduled_period_365'), value:365}
            ]
        }
    },
    computed:{
        displayScheduledSummary(){
            return this.$t('component_reportingFrequencyConfig_scheduleConfigPeriodSummary')
                .replace('[*PERIOD*]',this.$t(`component_reportingFrequencyConfig_scheduleConfigPeriodSummary_period_${this.selectedScheduledPeriods}`))
                .replace('[*DATA*]',this.$t(`component_reportingFrequencyConfig_scheduleConfigPeriodSummary_data_${this.selectedScheduledPeriods}`))
                .replace('[*DATE-CURRENT*]',this.getCurrentReportingDate(this.selectedScheduledPeriods))
                .replace('[*DATE*]',this.getNextReportingDate(this.selectedScheduledPeriods))
        }
    },
    methods:{
        saveConfig(){
            apiCalls.updateReporting(this.reportingId, {dateRangeType: this.selectedScheduledPeriods,}).then(()=>{
                this.displayConfirmSave = true
                setTimeout(() => {
                    this.displayConfirmSave = false
                }, 5000);
            })
        },

        initComponentData(){
            this.getComponentData({
                componentName:'reportings',
                promise: apiCalls.readReportings(this.$router.currentRoute.params.projectId),
            }).then(()=>{
                this.reportings.data.forEach(data=>{
                    this.selectedScheduledPeriods = data.dateRangeType
                    this.reportingId = data.id
                    this.neutralDomain = data.whiteLabel
                })
            })
        },
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>

<style lang="scss">

</style>
