var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{attrs:{"card-title":_vm.$t('component_progressionAxisGoogleBlockTypeFrequency_title'),"card-subtitle":_vm.$t('component_progressionAxisGoogleBlockTypeFrequency_subtitle'),"height":(_vm.filterOpen ? 750 : 650),"information-tooltip":{
        title : _vm.$t('component_progressionAxisGoogleBlockTypeFrequency_informationTooltip_title'),
        text:_vm.$t('component_progressionAxisGoogleBlockTypeFrequency_informationTooltip_text')
    },"component-data":{
        response:_vm.progressionAxisGoogleBlockTypeFrequency,
        hasData:_vm.progressionAxisGoogleBlockTypeFrequency.data
    }},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('component-filters',{attrs:{"market-segments":true,"default-select-value":_vm.lastFilters},on:{"applyFilter":_vm.initComponentData,"filterPanelStatus":_vm.displayFilterOpen}})]},proxy:true},{key:"content",fn:function(){return [(_vm.progressionAxisGoogleBlockTypeFrequency.data)?_c('v-row',[_c('v-col',{attrs:{"xl":6,"lg":6,"md":6,"cols":12}},[_c('v-card',[_c('v-card-text',[_c('h4',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.$t('component_progressionAxisGoogleBlockTypeFrequency_title')))]),_vm._l((_vm.progressionAxisGoogleBlockTypeFrequency.data.frequency),function(rank,index){return _c('div',{key:index,staticClass:"mt-3 v-application"},[_c('p',{staticClass:"text-body-1 mb-1"},[_vm._v(_vm._s(_vm.$t('common_google_block_label_'+rank.type))+" : "),_c('strong',[_vm._v(_vm._s(rank.value)+"%")])]),_c('vs-progress',{attrs:{"percent":_vm.getLogValue(rank.value),"color":_vm.getComponentColor().getGoogleResultTypeColor(rank.type),"height":16}})],1)})],2)],1)],1),_c('v-col',{attrs:{"xl":6,"lg":6,"md":6,"cols":12}},[_c('v-card',[_c('v-card-text',[_c('h4',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.$t('component_progressionAxisGoogleBlockPerformance_title')))]),_vm._l((_vm.progressionAxisGoogleBlockTypeFrequency.data.performance),function(rank,index){return _c('div',{key:index,staticClass:"mt-3 v-application"},[_c('p',{staticClass:"text-body-1 mb-1"},[_vm._v(_vm._s(_vm.$t('common_google_block_label_'+rank.type))+" : "),_c('strong',[_vm._v(_vm._s(rank.value)+"%")])]),_c('vs-progress',{attrs:{"percent":rank.value,"color":_vm.getComponentColor().getGoogleResultTypeColor(rank.type),"height":16}})],1)})],2)],1)],1)],1):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }