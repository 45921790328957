var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{attrs:{"card-title":_vm.$t('component_competitionCompetitorCompare_title'),"card-subtitle":_vm.$t('component_competitionCompetitorCompare_subtitle'),"height":(_vm.filterOpen ? 620 : 520),"information-tooltip":{
        title : _vm.$t('component_competitionCompetitorCompare_informationTooltip_title'),
        text:_vm.$t('component_competitionCompetitorCompare_informationTooltip_text')
    },"component-config":{
        hasConfig:_vm.rivalsConfiguration.length,
        redirectRoute:'competitors-configuration',
        placeholderTitle:_vm.$t('component_componentContainer_noCompetitor_title'),
        placeholderText:_vm.$t('component_componentContainer_noCompetitor_text')
    },"component-data":{
        response:_vm.competitionCompetitorCompare,
        hasData:_vm.competitionCompetitorCompare.data,
    }},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('component-filters',{attrs:{"market-segments":true,"result-type":true,"default-select-value":_vm.lastFilters},on:{"applyFilter":_vm.initComponentData,"filterPanelStatus":_vm.displayFilterOpen}})]},proxy:true},{key:"content",fn:function(){return [_c('yooda-chart',{attrs:{"options":_vm.competitionCompetitorCompare.data,"chart-type":"radar","chart-style":`height:${(_vm.rivalsConfiguration.length ? 440 : 425)}px;margin-top: -20px`}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }