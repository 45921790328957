
import axios from '@/api/axios.js'

export default {

    /**
     * @param projectId int
     * @returns Promise
     */
    readReportings (projectId) {
        return axios.get(`/configuration/projects/${projectId}/reportings`)
    },

    /**
     * @param hash string
     * @returns Promise
     */
    readReportingByHash(hash) {
        return axios.get(`/configuration/reportings/${hash}`)
    },

    /**
     * @param id string
     * @returns Promise
     */
    readReportingById(id) {
        return axios.get(`/configuration/reportings/${id}`)
    },

    /**
     * @param reportingId int
     * @param reporting
     * @returns Promise
     */
    generateReporting (reportingId, reporting=null) {
        return axios.post(`/configuration/reportings/${reportingId}/regenerate`, reporting)
    },


    /**
     * @param subscriptionMetaId int
     * @param fetchDatesFromReport
     * @returns Promise
     */
    readAllReporting (subscriptionMetaId, fetchDatesFromReport = true) {
        return axios.get(`/configuration/subscription-metas/${subscriptionMetaId}/reportings`, {
            params: {
                'fetch-dates-from-report': fetchDatesFromReport
            }
        })
    },


    /**
     * @param reportingId int
     * @param reporting
     * @returns Promise
     */
    updateReporting (reportingId, reporting=null) {
        return axios.patch(`/configuration/reportings/${reportingId}`, reporting)
    },

    /**
     * @param reportingId int
     * @param templateId int
     * @returns Promise
     */
    updateReportingTemplate (reportingId, templateId) {
        return axios.post(`/configuration/reportings/${reportingId}/templates/${templateId}`)
    },

    /**
     * @param subscriptionMetaTemplateId int
     * @param param
     * @returns Promise
     */
    updateSubscriptionMetaTemplate (subscriptionMetaTemplateId, param=null) {
        return axios.patch(`/configuration/subscription-meta-templates/${subscriptionMetaTemplateId}`,param)
    },

    //
    // /**
    //  * @param subscriptionId int
    //  * @returns Promise
    //  */
    // readComputedReporting (subscriptionId) {
    //     return axios.post(`/reporting/subscriptions/${subscriptionId}/computed`)
    // },
    //
    // /**
    //  * @param subscriptionId int
    //  * @returns Promise
    //  */
    // readScheduledReporting (subscriptionId) {
    //     return axios.post(`/reporting/subscriptions/${subscriptionId}/scheduled`)
    // },

};
