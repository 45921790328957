
import axios from '@/api/axios.js'

export default {

    /**
     * @returns Promise
     */
    readComponents () {
        return axios.get(`/configuration/components`)
    },


    /**
     * @param templateId int
     * @returns Promise
     */
    readTemplateComponentsByTemplateId (templateId) {
        return axios.get(`/configuration/template/${templateId}/template-components`)
    },

    /**
     * @param templateId int
     * @param param object
     * @returns Promise
     */
    updateTemplate(templateId,param) {
        return axios.patch(`/configuration/templates/${templateId}`, param)
    },

    /**
     * @param templateId int
     * @param param object
     * @returns Promise
     */
    saveTemplateComponent(templateId,param) {
        return axios.post(`/configuration/templates/${templateId}/components`, param)
    },

    /**
     * @param subscriptionId int
     * @param params
     * @returns Promise
     */
    createTemplate(subscriptionId, params) {
        return axios.post(`/configuration/subscription-metas/${subscriptionId}/templates`, params)
    },


    /**
     * @param id int
     * @returns Promise
     */
    readTemplateById (id) {
        return axios.get(`/configuration/templates/${id}`)
    },

    /**
     * @param subscriptionId int
     * @returns Promise
     */
    readTemplatesBySubscriptionId (subscriptionId) {
        return axios.get(`/configuration/subscription-metas/${subscriptionId}/templates`)
    },
};
