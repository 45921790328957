<template>
    <v-dialog v-model="show" content-class="popin-dialog-container" overlay-opacity="0.85">

        <v-icon
            class="close-popin-dialog"
            @click="show=false"
        >
            mdi-close
        </v-icon>

        <component-container
            :card-title="`${$t('component_competitionMarketLeaderVisibilityEvolution_title')} ${elementName}`"
            :card-subtitle="$t('component_competitionMarketLeaderVisibilityEvolution_subtitle')"

            :height="660"

            :component-data="{response:competitionMarketLeaderVisibilityEvolution, hasData:competitionMarketLeaderVisibilityEvolution.data}"
        >
            <template v-slot:content>
                <strong style="display:block;margin: 30px 0 -45px 5px; font-size: 11px">{{$t('component_competitionMarketLeaderVisibilityEvolution_label')}}</strong>
                <yooda-chart
                    v-if="competitionMarketLeaderVisibilityEvolution.data"
                    :options="competitionMarketLeaderVisibilityEvolution.data"
                    chart-type="line"
                    chart-style="height:560px; margin-top: 20px;"
                />
            </template>
        </component-container>
    </v-dialog>
</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";

export default {
    data() {
        return {
            show:false,
            competitionMarketLeaderVisibilityEvolution:{  isLoaded:false, error:null,data: null },
            elementName:null,
            popinStateName:'competitionMarketLeaderVisibilityEvolution'
        }
    },
    components: {
        YoodaChart,
    },
    computed:{

    },
    watch:{
        getShowPopin(){
            if(!this.show && this.getShowPopin){
                if(this.getShowPopin.componentName === this.popinStateName){
                    this.show = true
                    this.elementName = this.getShowPopin.elementName
                    this.getComponentData({
                        componentName:'competitionMarketLeaderVisibilityEvolution',
                        promise: this.getShowPopin.promise(),
                        dataFormatter: this.formatData
                    })
                }
            }
        },
        show(){
            if(!this.show){
                this.showAgGridPopin(null)
            }
        }
    },
    methods:{
        formatData(jsonData){
            let seriesData = []
            let legend = []

            let seriesLabels = jsonData.content[0].cumulatedVisibilities.map(data => {
                return this.$options.filters.displayDateLocale(data.date)
            })

            jsonData.content.forEach(data => {
                legend.push(data.domainName)
                let color = this.getComponentColor().getCompetitorColor(1)
                if(data.competitorType ==='project'){
                    color = this.getComponentColor().getCompetitorColor(null)
                }

                let lineStyle = { width:3, type:'solid', opacity: 0.6 }

                if(data.projectSite){
                    lineStyle.width = 6
                    lineStyle.shadowColor = 'rgba(114,114,114,0.5)'
                    lineStyle.shadowBlur = 10
                    lineStyle.shadowOffsetX = 0
                    lineStyle.opacity = 1
                }

                seriesData.push({
                    type: 'line',
                    name: data.domainName,
                    data: data.cumulatedVisibilities.map(serieData => {return{value:serieData.cumulatedVisibility,date:this.$options.filters.displayDateLocale(serieData.date)}}),
                    color:color,
                    lineStyle:lineStyle
                })
            })
            let tooltipFormatter = params => {
                let toolTip = '<p>'+params[0].axisValue+'<br>'
                params.forEach((param)=>{
                    toolTip += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + param.color + '"></span>'
                    toolTip += param.seriesName+' : '+this.$options.filters.formatNumber(param.value)+'<br>'
                })
                toolTip+= '</p>'
                return  toolTip
            }
            return {
                grid: { left: 50, top: 50, right: 30, bottom: 40 },
                legend: { orient: 'horizontal', icon:'circle', data: legend },
                tooltip: this.genericTooltipConfiguration('axis', tooltipFormatter),
                xAxis: { type: 'category', data: seriesLabels, boundaryGap: false, offset: 15 },
                yAxis: { type: 'value' },
                series: seriesData
            }
        },
    },
}
</script>
