<template>
    <component-container
        :card-title="$t('component_advertisingPerformance_title')"
        :card-subtitle="$t('component_advertisingPerformance_subtitle')"

        :height="(displayLinkButton ? 495 : 400)"

        :information-tooltip="{
            title : $t('component_advertisingPerformance_informationTooltip_title'),
            text:$t('component_advertisingPerformance_informationTooltip_text')
        }"


        :component-config="{
            hasConfig:analyticsViewConfiguration,
            hideOnNoConfig:hideOnNoConfig,
            redirectRoute:displayPlaceholderConfigButton ? 'analytics-project-configuration' : null,
            placeholderTitle:$t('component_componentContainer_noAnalytics_title'),
            placeholderText:$t('component_componentContainer_noAnalytics_text'),
        }"

        :component-data="{response:advertisingPerformance,hasData:(advertisingPerformance.data && advertisingPerformance.data.series)}"
    >
        <template v-slot:content>
            <yooda-chart
                :options="advertisingPerformance.data"
                chart-type="bar"
                :chart-style=" (displayLinkButton ? 'height:345px;' : 'height:320px;')"
            />

            <div class="v-application" v-if="displayLinkButton">
                <v-btn
                    outlined
                    :to="{name:'advertising'}"
                    color="primary"
                    style="width: calc(100% - 35px); position: absolute; bottom: 18px"
                >
                    {{ $t('component_advertisingPerformance_detailButton') }}<v-icon right>mdi-chevron-double-right</v-icon>
                </v-btn>
            </div>
        </template>
    </component-container>
</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/dashboard.js'

export default {
    props: {
        displayLinkButton:{type: Boolean, default: false},
        displayPlaceholderConfigButton:{type: Boolean, default: true},
        hideOnNoConfig:{type: Boolean, default: false}
    },
    data() {
        return {
            advertisingPerformance:null
        }
    },
    components: {
        YoodaChart
    },
    computed:{

    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData()
        }
    },
    methods:{
        formatData(jsonData){
            if(
                jsonData.content.trafficEvolutionRate === null &&
                jsonData.content.conversionEvolutionRate === null &&
                jsonData.content.turnoverEvolutionRate === null
            ){ return null }

            let series = []
            let index = 1

            let contentArray = [
                {
                    value:jsonData.content.trafficEvolutionRate,
                    currentPeriodEndDate: jsonData.content.currentPeriodEndDate,
                    currentPeriodStartDate: jsonData.content.currentPeriodStartDate,
                    previousPeriodEndDate: jsonData.content.previousPeriodEndDate,
                    previousPeriodStartDate: jsonData.content.previousPeriodStartDate
                },
                {
                    value:jsonData.content.conversionEvolutionRate,
                    currentPeriodEndDate: jsonData.content.currentPeriodEndDate,
                    currentPeriodStartDate: jsonData.content.currentPeriodStartDate,
                    previousPeriodEndDate: jsonData.content.previousPeriodEndDate,
                    previousPeriodStartDate: jsonData.content.previousPeriodStartDate
                },
                {
                    value:jsonData.content.turnoverEvolutionRate,
                    currentPeriodEndDate: jsonData.content.currentPeriodEndDate,
                    currentPeriodStartDate: jsonData.content.currentPeriodStartDate,
                    previousPeriodEndDate: jsonData.content.previousPeriodEndDate,
                    previousPeriodStartDate: jsonData.content.previousPeriodStartDate
                }
            ]

            contentArray.forEach((data)=>{
                let radius = [0,10,10,0]
                let color = this.getThemeColorByName('positiveVariation',0.8)
                let colorHover = this.getThemeColorByName('positiveVariation')
                if(data.value < 0) {
                    radius = [10,0,0,10]
                    color = this.getThemeColorByName('negativeVariation',0.8)
                    colorHover = this.getThemeColorByName('negativeVariation')
                } else if(data.value === null) {
                    radius = [10,0,0,10]
                    color = this.getThemeColorByName('noVariation',0.8)
                    colorHover = this.getThemeColorByName('noVariation')
                }

                let serie = {
                    type: 'bar',
                    name:this.$t('component_advertisingPerformance_serie_name_'+index),
                    currentPeriodEndDate: this.$options.filters.displayDateLocale(data.currentPeriodEndDate),
                    currentPeriodStartDate: this.$options.filters.displayDateLocale(data.currentPeriodStartDate),
                    previousPeriodEndDate: this.$options.filters.displayDateLocale(data.previousPeriodEndDate),
                    previousPeriodStartDate: this.$options.filters.displayDateLocale(data.previousPeriodStartDate),
                    data: [data.value !== null ? data.value : 0.000000001],
                    showBackground: true,
                    itemStyle: {
                        color:color,
                        barBorderRadius: radius,
                    },
                    emphasis:{
                        itemStyle: {
                            color:colorHover,
                        }
                    },
                    label: {
                        show: true,
                        position: 'insideLeft',
                        formatter: label => {
                            return `${label.seriesName}: ${label.value !== 0.000000001 ? label.value + '%': 'N/A'}`
                        },
                        fontWeight:600
                    },

                }
                series.push(serie)
                index++
            })

            let tooltipFormatter = params => {
                if(this.globalFilterFrequency !== "1"){
                    return `
                    <p>
                        ${this.$t('common_tooltip_date_comparison').replace('[*STARTDATE*]', series[0].currentPeriodStartDate).replace('[*ENDDATE*]',series[0].currentPeriodEndDate)}
                        <span class="toolTipColorSpan">${this.$t('common_tooltip_compared_to')}</span>
                        <br>
                        ${this.$t('common_tooltip_date_comparison').replace('[*STARTDATE*]', series[0].previousPeriodStartDate).replace('[*ENDDATE*]',series[0].previousPeriodEndDate)}
                        <br>
                        <span class="toolTipColorSpan" style="background-color:${params.color}"></span>
                        ${params.seriesName} : ${params.value +'%'}
                    </p>`
                } else {
                    return `
                    <p>
                        ${series[0].currentPeriodEndDate } ${this.$t('common_tooltip_compared_to')} ${series[0].previousPeriodStartDate}
                       <br>
                        <span class="toolTipColorSpan" style="background-color:${params.color}"></span>
                        ${params.seriesName} : ${params.value +'%'}
                    </p>`
                }
            }

            return {
                grid: { left: 0, top: 10, right: 0, bottom: 10 },

                tooltip: this.genericTooltipConfiguration('item', tooltipFormatter),
                xAxis: { type: 'value', show: false },
                yAxis: { show: true,  type: 'category', position:'left',axisTicks: {show: false} },
                series: series
            }
        },
        initComponentData(){
            this.getComponentData({
                componentName:'advertisingPerformance',
                promise:(!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  && this.analyticsViewConfiguration) ? apiCalls.readPerformance(this.$router.currentRoute.params.projectId,{params:{'date-range-type':this.globalFilterFrequency}}) : false,
                dataFormatter: this.formatData,
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
