var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{attrs:{"card-title":_vm.$t('component_trafficAcquisitionChannel_title'),"card-subtitle":_vm.$t('component_trafficAcquisitionChannel_subtitle'),"height":(_vm.displayLinkButton ? 495 : 400),"information-tooltip":{
        title : _vm.$t('component_trafficAcquisitionChannel_informationTooltip_title'),
        text:_vm.$t('component_trafficAcquisitionChannel_informationTooltip_text')
    },"analytics-period-picker":{displayFilter: (!!_vm.analyticsViewConfiguration), default:_vm.defaultAnalyticsPeriodPickerValue},"component-config":{
        hasConfig:_vm.analyticsViewConfiguration,
        hideOnNoConfig:_vm.hideOnNoConfig,
        redirectRoute:_vm.displayPlaceholderConfigButton ? 'analytics-project-configuration' : null,
        placeholderTitle:_vm.$t('component_componentContainer_noAnalytics_title'),
        placeholderText:_vm.$t('component_componentContainer_noAnalytics_text'),
    },"component-data":{response:_vm.trafficAcquisitionChanel, hasData:(_vm.trafficAcquisitionChanel.data && _vm.trafficAcquisitionChanel.data.series)}},on:{"analyticsPeriodSelected":_vm.initComponentData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('yooda-chart',{attrs:{"options":_vm.trafficAcquisitionChanel.data,"chart-type":"pie","chart-style":"height:340px;"}}),(_vm.displayLinkButton)?_c('div',{staticClass:"v-application"},[_c('v-btn',{staticStyle:{"width":"calc(100% - 35px)","position":"absolute","bottom":"18px"},attrs:{"outlined":"","to":{name:'traffic'},"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('component_trafficAcquisitionChannel_detailButton'))),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-double-right")])],1)],1):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }