var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{attrs:{"height":135,"information-tooltip":{
        title : _vm.$t('component_trafficQualityConversion_informationTooltip_title'),
        text:_vm.$t('component_trafficQualityConversion_informationTooltip_text')
    },"component-config":{
        hasConfig:_vm.analyticsViewConfiguration,
        hideOnNoConfig:_vm.hideOnNoConfig,
        redirectRoute:'analytics-project-configuration',
        placeholderTitle:_vm.$t('component_componentContainer_noAnalytics_title'),
        placeholderText:_vm.$t('component_componentContainer_noAnalytics_text')
    },"component-data":{response:_vm.trafficQualityConversion, hasData:true}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"v-application"},[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"pt-6 text-center",attrs:{"cols":2}},[_c('yooda-icon',{staticClass:"p-3",attrs:{"name":"filter","color":"warning","background":"rounded","size":30}})],1),_c('v-col',{attrs:{"cols":10}},[_c('h3',{staticClass:"text-h3 font-weight-bold"},[_vm._v(_vm._s(_vm._f("formatNumber")(_vm.trafficQualityConversion.data))+" %")]),_c('p',{staticClass:"text-body-1 mb-0"},[_vm._v(_vm._s(_vm.$t('component_trafficQualityConversion_title'))+" "+_vm._s(_vm.displayKpiLabel))])])],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }