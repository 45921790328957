<template>
    <component-container
        :card-title="$t('component_marketSegmentSize_title')"
        :card-subtitle="$t('component_marketSegmentSize_subtitle')"

        :height="495"

        :information-tooltip="{
            title : $t('component_marketSegmentSize_informationTooltip_title'),
            text:$t('component_marketSegmentSize_informationTooltip_text')
        }"

        :component-data="{
            response: marketSegmentSize,
            hasData: true
        }"
    >
        <template v-slot:content>
            <div class="text-center pt-10 pb-12 mt-10 mb-12 v-application">
                <div class="p-6">
                    <yooda-icon name="users" class="p-3" color="warning" background="rounded" :size="30"></yooda-icon>
                </div>
                <h3 class="text-h3 font-weight-bold mt-6 mb-6">{{ marketSegmentSize.data|formatNumber }}</h3>
                <p class="text-body-1 mb-8">{{$t('component_marketSegmentSize_legend')}}</p>
            </div>
        </template>
    </component-container>
</template>


<script>

import apiCalls from '@/api/axios/dataReport/dashboard.js'

export default {
    data() {
        return {
            marketSegmentSize:null
        }
    },
    computed:{

    },
    methods:{
        formatData(jsonData){
            return jsonData.content.cumulatedSearchCount
        },
        initComponentData(){
            this.getComponentData({
                componentName:'marketSegmentSize',
                promise:!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readSize(this.$router.currentRoute.params.projectId) :false,
                dataFormatter: this.formatData,
            })
        }
    },
    beforeMount() {
       this.initComponentData()
    },
}
</script>
