<template>
    <component-container
        :card-title="$t('component_trafficIncomingWebsite_title')"
        :card-subtitle="$t('component_trafficIncomingWebsite_subtitle')"

        :information-tooltip="{
            title : $t('component_trafficIncomingWebsite_informationTooltip_title'),
            text:$t('component_trafficIncomingWebsite_informationTooltip_text')
        }"

        :component-config="{
            hasConfig:analyticsViewConfiguration,
            hideOnNoConfig:hideOnNoConfig,
            redirectRoute:'analytics-project-configuration',
            placeholderTitle:$t('component_componentContainer_noAnalytics_title'),
            placeholderText:$t('component_componentContainer_noAnalytics_text')
        }"
        :table-menu="{csv:true, fullscreen:true}"
        :table-density-selector="true"

        :component-data="{response:trafficIncomingWebsite, hasData:(trafficIncomingWebsite.data && trafficIncomingWebsite.data.length > 0)}"

        @setTableMenuFullscreen="cardContainerFullscreen = !cardContainerFullscreen"
        @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"
    >
        <template v-slot:content>
            <ag-grid-table
                :show-floating-filter="false"
                :table-data="{columnDefs:columnDefs,rowData:trafficIncomingWebsite.data}"
                :agGridStyle="`height:${tableHeightByRowCount(trafficIncomingWebsite.data)}px;`"
                :is-card-container-fullscreen="cardContainerFullscreen"
                ref="agGridTable"
            />
        </template>
    </component-container>
</template>


<script>

import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable'
import apiCalls from '@/api/axios/dataReport/traffic.js'
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";

export default {
    props: {
        hideOnNoConfig:{type: Boolean, default: false}
    },
    data() {
        return {
            trafficIncomingWebsite:null,
            cardContainerFullscreen:false,
            columnDefs:[
                {headerName: this.$t('component_trafficIncomingWebsite_table_header_website'), field: 'website', cellRendererFramework: 'CellRendererLink', filter: 'agTextColumnFilter', filterParams: {valueGetter: params => { return params.data.website.value }}, comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_trafficIncomingWebsite_table_header_incomingTraffic'),sort:'desc',field: 'incomingTraffic', cellRendererFramework: 'CellRendererFormatedNumber', width: 70, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},
                {headerName: this.$t('component_trafficIncomingWebsite_table_header_conversion'), field: 'conversion', cellRendererFramework: 'CellRendererFormatedNumber', width: 70, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},
                {headerName: this.$t('component_trafficIncomingWebsite_table_header_turnover'), field: 'turnover', cellRendererFramework: 'CellRendererCurrency', width: 90, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},
            ]
        }
    },
    components: {
        AgGridTable,
    },
    computed:{

    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData()
        }
    },
    methods:{
        formatData(jsonData){

            if(jsonData.content.length === 0){return []}

            let rowData = []
            jsonData.content.forEach(data => {
                rowData.push({
                    website: {
                        href: `//${data.domainName}`,
                        value: data.domainName,
                        targetBlank: true
                    },
                    incomingTraffic: parseInt(data.cumulatedTraffic),
                    conversion: parseInt(data.conversions),
                    turnover: parseInt(data.cumulatedTurnover)
                })
            })
            return rowData
        },
        initComponentData(){
            this.getComponentData({
                componentName:'trafficIncomingWebsite',
                promise: (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  && this.analyticsViewConfiguration) ? apiCalls.readIncoming(this.$router.currentRoute.params.projectId,{params:{'date-range-type':this.globalFilterFrequency}}) : false,
                dataFormatter: this.formatData
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
