<template>
    <div>
        <component-container
            :class="{filterOpen:filterOpen}"
            :card-title="$t('component_advertisingShoppingCompetitionKeywords_title')"
            :card-subtitle="$t('component_advertisingShoppingCompetitionKeywords_subtitle')"

            :information-tooltip="{
                title: $t('component_advertisingShoppingCompetitionKeywords_informationTooltip_title'),
                text: $t('component_advertisingShoppingCompetitionKeywords_informationTooltip_text')
            }"

            :table-menu="{csv:true, fullscreen:true}"
            :table-density-selector="true"

            :component-data="{response:advertisingShoppingCompetitionKeywords, hasData:advertisingShoppingCompetitionKeywords.data}"

            @setTableMenuFullscreen="cardContainerFullscreen = !cardContainerFullscreen"

            @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"

            :no-shadow="true"
        >
            <template v-slot:filters>
                <component-filters

                    :market-segments="true"
                    :advertiser-keywords-presence="true"
                    @applyFilter="initComponentData"
                    @filterPanelStatus="displayFilterOpen"
                    :default-select-value="lastFilters"
                />
            </template>

            <template v-slot:content>
                <ag-grid-table
                    :show-floating-filter="false"
                    :table-data="{columnDefs:columnDefs,rowData:advertisingShoppingCompetitionKeywords.data}"
                    :agGridStyle="`height:${tableHeightByRowCount(advertisingShoppingCompetitionKeywords.data)}px;`"
                    :is-card-container-fullscreen="cardContainerFullscreen"
                    ref="agGridTable"
                />
            </template>
        </component-container>

        <advertising-shopping-keywords-ads/>
    </div>

</template>


<script>

import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable'
import AdvertisingShoppingKeywordsAds from '@/components/yooda-components/app/advertising/AdvertisingShoppingKeywordsAds'
import apiCalls from '@/api/axios/dataReport/advertising.js'
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";
import ComponentFilters from "@/components/yooda-components/ComponentFilters";

export default {
    data() {
        return {
            advertisingShoppingCompetitionKeywords:null,
            filterOpen:false,
            cardContainerFullscreen:false,
            lastFilters: null,
            columnDefs: [
                {headerName: this.$t('component_advertisingShoppingCompetitionKeywords_table_header_keyword'), field: 'keyword',filter: true},
                {headerName: this.$t('component_advertisingShoppingCompetitionKeywords_table_header_advertiserCount'), field: 'advertiserCount',  sort:'desc', cellRendererFramework: 'CellRendererPopinTableLink', width: 90, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_advertisingShoppingCompetitionKeywords_table_header_searchCount'), field: 'searchCount', width: 90, cellClass:'ag-grid-cell-right',cellRendererFramework: 'CellRendererFormatedNumber', headerClass:'ag-grid-header-right'},
            ],
        }
    },
    components: {
        AgGridTable,
        AdvertisingShoppingKeywordsAds,
        ComponentFilters,
    },
    computed:{

    },
    watch:{
        globalFilterLocation(){
            this.initComponentData(this.lastFilters)
        }
    },
    methods:{
        displayFilterOpen(status){ this.filterOpen = status === 'open'; },
        formatData(jsonData){
            if(jsonData.content.length === 0){return []}
            let rowData = []
            jsonData.content.forEach(data => {
                let filters = {
                    params: {
                        "website-id":data.websiteId,
                        "search-geolocation-id": this.globalFilterLocation,
                    }
                }
                rowData.push({
                    keyword: data.string,
                    advertiserCount: (data.distinctAdsCount ? {
                        componentName: 'advertisingShoppingKeywordsAds',
                        value: data.distinctAdsCount,
                        promise: () => !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readShoppingKeywordsAds(this.$router.currentRoute.params.projectId,data.keywordId, filters) : false,
                        elementName: data.string
                    } :  {value:0}),
                    searchCount: data.searchCount !== null ? data.searchCount : '-',
                })
            })
            return rowData
        },
        initComponentData(filters = {params:{}}){
            filters.params["search-geolocation-id"] = this.globalFilterLocation

            this.getComponentData({
                componentName:'advertisingShoppingCompetitionKeywords',
                promise: !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readShoppingCompetitionKeywords(this.$router.currentRoute.params.projectId,filters) : false,
                dataFormatter: this.formatData
            })
            this.lastFilters = filters
        },
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
