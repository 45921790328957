<template>
    <div>
        <component-container
            :card-title="$t('component_linkingTopPages_title')"
            :card-subtitle="$t('component_linkingTopPages_subtitle')"

            :custom-style="`padding-top:15px;border:none !important;${displayNoBorder ? 'border-radius:0!important;': 'box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.15) !important;'}`"

            :no-shadow="true"
            :information-tooltip="{
                title : $t('component_linkingReferralBacklinks_informationTooltip_title'),
                text:$t('component_linkingReferralBacklinks_informationTooltip_text')
            }"

            :table-menu="{csv:true, fullscreen:true}"
            :table-density-selector="true"

            :component-data="{response:linkingReferralBacklinks, hasData:linkingReferralBacklinks.data, noDataMessage:$t('component_linkingMyThematicAuthority_no_data_text')}"

            @setTableMenuFullscreen="cardContainerFullscreen = !cardContainerFullscreen"
            @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"
        >
            <template v-slot:filters>
                <linking-thematic-selector class="mb-8" :selected-thematic-name="selectedThematic" @selectValue="selectThematic"/>
            </template>

            <template v-slot:content>

                <ag-grid-table
                    :show-floating-filter="false"
                    :table-data="{columnDefs:columnDefs,rowData:linkingReferralBacklinks.data}"
                    :agGridStyle="`height:${linkingReferralBacklinks.data.length >= 11 ? tableHeightByRowCount(linkingReferralBacklinks.data) : tableHeightByRowCount(linkingReferralBacklinks.data)*1.3}px;`"
                    :is-card-container-fullscreen="cardContainerFullscreen"

                    :ag-grid-options="{ rowHeight: 74 }"
                    ref="agGridTable"
                />
            </template>

        </component-container>
        <linking-referral-backlinks-links/>
        <linking-referral-backlinks-positions/>
    </div>

</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/linking.js'
import { useComponentStore } from '@/stores/ComponentStore'
import {mapState} from "pinia";
import AgGridTable from "@/components/yooda-components/ag-grid-table/AgGridTable.vue";
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";
import LinkingThematicSelector from "@/components/yooda-components/app/linking/LinkingThematicSelector.vue";
import LinkingReferralBacklinksPositions from "@/components/yooda-components/app/linking/popin/LinkingReferralBacklinksPositions.vue";
import LinkingReferralBacklinksLinks from "@/components/yooda-components/app/linking/popin/LinkingReferralBacklinksLinks.vue";

export default {
    data() {
        return {
            linkingReferralBacklinks:null,

            selectedThematic:this.$t('linking_thematic_label_all'),
            cardContainerFullscreen:false,
            columnDefs:[
                {headerName: this.$t('component_linkingReferralBacklinks_table_header_refererPage'),headerTooltip: this.$t('component_linkingReferralBacklinks_table_header_refererPage_tooltip'),filter: true, field: 'refererPage',  cellRendererFramework: 'CellRendererLinkingBacklinkDetail', comparator: cellSort.agGridTableSortCellValue,filterParams: {valueGetter: params => { return params.data.refererPage.value }}},
                {headerName: this.$t('component_linkingReferralBacklinks_table_header_popularity'),headerTooltip: this.$t('component_linkingReferralBacklinks_table_header_popularity_tooltip'),filter: false, field: 'popularity',sortingOrder: ['asc','desc'], cellRendererFramework: 'CellRendererLinkingInfoChips', width: 70, comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_linkingReferralBacklinks_table_header_authority'),headerTooltip: this.$t('component_linkingReferralBacklinks_table_header_authority_tooltip'),filter: false, field: 'authority', sortingOrder: ['desc','asc'], cellRendererFramework: 'CellRendererLinkingInfoChips', width: 70, comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_linkingReferralBacklinks_table_header_thematic'),headerTooltip: this.$t('component_linkingReferralBacklinks_table_header_thematic_tooltip'),filter: false, field: 'thematic',  sortingOrder: ['desc','asc'], cellRendererFramework: 'CellRendererLinkingThematic', comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_linkingReferralBacklinks_table_header_linkCount'),headerTooltip: this.$t('component_linkingReferralBacklinks_table_header_linkCount_tooltip'),filter: false, field: 'linkCount',sort:'desc', sortingOrder: ['desc','asc'], cellRendererFramework: 'CellRendererPopinTableLink', width: 60,  cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_linkingReferralBacklinks_table_header_positionCount'),headerTooltip: this.$t('component_linkingReferralBacklinks_table_header_positionCount_tooltip'),filter: false, field: 'positionCount',sortingOrder: ['desc','asc'], cellRendererFramework: 'CellRendererPopinTableLink', width: 60,  cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellValue},
            ]
        }
    },
    components: {
        AgGridTable,
        LinkingThematicSelector,
        LinkingReferralBacklinksPositions,
        LinkingReferralBacklinksLinks,
        YoodaChart
    },
    computed:{
        ...mapState(useComponentStore, {
            globalFilterFrequency: 'getGlobalFilterFrequency',
        }),
        displayNoBorder(){
            return this.$parent.$options._componentTag !== 'custom-report-model' && !this.cardContainerFullscreen
        }
    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData()
        }
    },
    methods:{

        selectThematic(selectThematic){
            this.selectedThematic = selectThematic
            this.initComponentData()
        },

        formatData(jsonData){
            if(jsonData.content.length === 0){return null}
            let rowData = []

            jsonData.content.forEach(data => {
                let thematicData = this.getThematicData(data)
                let parsedRefererPage = new URL(data.url)

                let filters = {
                    params: {
                        'date-range-type':this.globalFilterFrequency,
                        "search-geolocation-id": this.globalFilterLocation
                    }
                }


                rowData.push({
                    refererPage:{
                        value: `${parsedRefererPage.hostname}${parsedRefererPage.pathname}`,
                        redirectTo: data.url,
                        url: `${parsedRefererPage.hostname}${parsedRefererPage.pathname}`,
                        title: data.title,
                    },
                    popularity: {value: data.popularity, color:this.getThemeColorByName('linkingPopularity')},
                    authority: {value: data.authority, color:this.getThemeColorByName('linkingAuthority')},
                    thematic:{
                        value:thematicData.mainThematic.value,
                        color:thematicData.mainThematic.color,
                        categories:thematicData.mainThematic.categories,
                        otherThematic:thematicData.otherThematic
                    },

                    linkCount:(data.linkCount ? {
                        componentName: !this.isReportingRoute ? 'linkingReferralBacklinksLinks' : null,
                        value:data.linkCount,
                        displayedValue:this.$options.filters.formatNumber(data.linkCount),
                        promise: () => !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ?  apiCalls.readReferralBacklinksPopinLinks(this.$router.currentRoute.params.projectId, data.id, filters) : false,
                        elementName:data.url
                    } :  {value:0}),


                    positionCount:(data.positionCount ? {
                        componentName: !this.isReportingRoute ? 'linkingReferralBacklinksPositions' : null,
                        value:data.positionCount,
                        displayedValue:this.$options.filters.formatNumber(data.positionCount),
                        promise: () => !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ?  apiCalls.readReferralBacklinksPopinDomain(this.$router.currentRoute.params.projectId, data.id, filters) : false,
                        elementName:data.url
                    } :  {value:0})

                })
            })


            return rowData
        },
        initComponentData(){
            let filters = {
                'date-range-type':this.globalFilterFrequency,
                "search-geolocation-id": this.globalFilterLocation
            }
            if(this.selectedThematic !== this.$t('linking_thematic_label_all')){
                filters['thematic'] = this.selectedThematic
            }
            this.getComponentData({
                componentName:'linkingReferralBacklinks',
                promise: (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId ) ? apiCalls.readReferralBacklinks(this.$router.currentRoute.params.projectId,{params:filters}) : false,
                dataFormatter: this.formatData
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
