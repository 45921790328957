<template>

    <component-container
        :card-title="$t('component_marketSegmentVisibility_title')"
        :card-subtitle="$t('component_marketSegmentVisibility_subtitle')"

        :height="(filterOpen ? 510 : 410)"

        :information-tooltip="{
            title : $t('component_marketSegmentVisibility_informationTooltip_title'),
            text:$t('component_marketSegmentVisibility_informationTooltip_text')
        }"

        :component-config="{
            hasConfig:keywordGroupsConfiguration.length,
            hideOnNoConfig:hideOnNoConfig,
            redirectRoute:'segments-configuration',
            placeholderTitle:$t('component_componentContainer_noMarketSegment_title'),
            placeholderText:$t('component_componentContainer_noMarketSegment_text')
        }"

        :component-data="{
            response: marketSegmentVisibility,
            hasData: (marketSegmentVisibility.data && marketSegmentVisibility.data.series)
        }"

        :has-filter="true"
    >

        <template v-slot:filters>
            <component-filters
                v-if="keywordGroupsConfiguration.length"

                :result-type="true"
                @applyFilter="initComponentData"
                @filterPanelStatus="displayFilterOpen"
                :default-select-value="lastFilters"
            />
        </template>

        <template v-slot:content>
            <yooda-chart
                :options="marketSegmentVisibility.data"
                chart-type="line"
            />
        </template>

    </component-container>
</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/marketSegment.js'
import ComponentFilters from "@/components/yooda-components/ComponentFilters";


export default {
    props: {
        hideOnNoConfig:{type: Boolean, default: false}
    },
    data() {
        return {
            marketSegmentVisibility:null,
            filterOpen:false,
            lastFilters: null
        }
    },
    components: {
        YoodaChart,
        ComponentFilters,
    },
    computed:{

    },
    watch:{
        globalFilterFrequency(){
            this.initComponentData(this.lastFilters)
        },
        globalFilterLocation(){
            this.initComponentData(this.lastFilters)
        }
    },
    methods:{
        displayFilterOpen(status){ this.filterOpen = status === 'open'; },
        formatData(jsonData){

            if(jsonData.content.length === 0){return {}}
            let seriesData = []
            let colorIndex = 1
            let legend = []
            jsonData.content.sort((a,b)=>{
                return (a.keywordGroupName < b.keywordGroupName) ? -1 : (a.keywordGroupName > b.keywordGroupName) ? 1 : 0
            })

            let seriesLables = jsonData.content[0].cumulatedVisibilities.map(data =>{
                return  this.$options.filters.displayDateLocale(data.date)
            })

            jsonData.content.forEach(data => {
                legend.push((data.keywordGroupName === '#other#'?this.$t('component_marketSegmentVisibility_other'):data.keywordGroupName))
                let formatedData = []
                data.cumulatedVisibilities.forEach((value)=>{
                    formatedData.push({date: this.$options.filters.displayDateLocale(value.date), value:value.cumulatedVisibility})
                })
                seriesData.push(
                    {
                        type: 'line',
                        name: (data.keywordGroupName === '#other#'?this.$t('component_marketSegmentVisibility_other'):data.keywordGroupName),
                        stack: true,
                        areaStyle: { opacity: 0.7 },
                        data: formatedData,
                        itemStyle:{color:this.getThemeColorByName('marketSegmentColor'+colorIndex)} }
                )
                colorIndex++
            })

            let tooltipFormatter = params => {
                let toolTip = '<p>'+params[0].axisValue+'<br>'
                params.forEach((param)=>{
                    toolTip += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + param.color + '"></span>'
                    toolTip += param.seriesName+' : '+this.$options.filters.formatNumber(param.value)+'<br>'
                })
                toolTip+= '</p>'
                return  toolTip
            }

            return {
                grid: { left: 50, top: 45, right: 30, bottom: 40 },
                legend: { orient: 'horizontal', icon:'circle', data:legend },
                tooltip: this.genericTooltipConfiguration('axis', tooltipFormatter),
                xAxis: { type: 'category', data: seriesLables, boundaryGap: false, offset: 15 },
                yAxis: {
                    type: 'value',
                    nameTextStyle:{ color: '#888', fontWeight: 'bold', align: 'right', lineHeight: 35 },
                    name:this.$t('component_marketSegmentVisibility_series_label')
                },
                series: seriesData
            }
        },
        initComponentData(filters){
            filters.params['date-range-type'] = this.globalFilterFrequency
            filters.params['search-geolocation-id'] = this.globalFilterLocation

            this.getComponentData({
                componentName:'marketSegmentVisibility',
                promise: (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  && this.keywordGroupsConfiguration.length>0) ? apiCalls.readVisibility(this.$router.currentRoute.params.projectId,filters) : false,
                dataFormatter: this.formatData
            })

            this.lastFilters = filters
        },
    },
    beforeMount() {
        this.initComponentData({
            params:{
                'search-geolocation-id':this.globalFilterLocation
            }
        })
    },

}
</script>
