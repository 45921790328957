<template>
    <div class="v-application filter-container">

        <v-expansion-panels
            focusable
            v-model="panel"
            class="mb-6"
        >
            <v-expansion-panel>
                <v-expansion-panel-header color="grey darken-1" class="text-center">
                    {{ $t('component_componentFilter_title') }}
                </v-expansion-panel-header>

                <v-expansion-panel-content color="grey lighten-3" style="min-height: 97px"  v-if="this.$router.currentRoute.params.projectId">

                    <v-row class="mt-2" v-if="filtersInitialized">

                        <v-col v-if="marketSegments">
                            <div class="simpleSelectContainer">
                                <label for="filter-select-marketSegment">{{$t('component_componentFilter_marketSegment_label')}}</label>
                                <select v-model="filterMarketSegmentSelect" id="filter-select-marketSegment" style="width: 100%">
                                    <option v-for="(marketSegment,index) in filterMarketSegments" :key="index" :value="marketSegment.id">{{marketSegment.name}}</option>
                                </select>
                            </div>
                        </v-col>

                        <v-col v-if="competitors">
                            <div class="simpleSelectContainer">
                                <label for="filter-select-website">{{$t('component_componentFilter_website_label')}}</label>
                                <select v-model="filterCompetitorSelect" id="filter-select-website" style="width: 100%">
                                    <option v-for="(competitor,index) in filterCompetitors" :key="index" :value="competitor.url">{{competitor.url}}</option>
                                </select>
                            </div>
                        </v-col>

                        <v-col v-if="compareTwoDomains">
                            <div class="simpleSelectContainer">
                                <label for="filter-select-compare-domain-one">{{$t('component_componentFilter_compareTwoDomainsOne_label')}}</label>
                                <select v-model="filterCompareDomainOneSelect" id="filter-select-compare-domain-one" style="width: 100%">
                                    <optgroup :label="$t('component_componentFilter_compareTwoDomains_optLabel_followed')">
                                        <option v-for="(domain,index) in filterCompareDomains" :key="index" :value="domain.id" v-if="domain.followed">{{domain.url}}</option>
                                    </optgroup>
                                    <optgroup :label="$t('component_componentFilter_compareTwoDomains_optLabel_other')">
                                        <option v-for="(domain,index) in filterCompareDomains" :key="index" :value="domain.id" v-if="!domain.followed">{{domain.url}}</option>
                                    </optgroup>
                                </select>
                            </div>
                        </v-col>

                        <v-col v-if="compareTwoDomains">
                            <div class="simpleSelectContainer">
                                <label for="filter-select-compare-domain-two">{{$t('component_componentFilter_compareTwoDomainsTwo_label')}}</label>
                                <select v-model="filterCompareDomainTwoSelect" id="filter-select-compare-domain-two" style="width: 100%">
                                    <optgroup :label="$t('component_componentFilter_compareTwoDomains_optLabel_followed')">
                                        <option v-for="(domain,index) in filterCompareDomains" :key="index" :value="domain.id" v-if="domain.followed">{{domain.url}}</option>
                                    </optgroup>
                                    <optgroup :label="$t('component_componentFilter_compareTwoDomains_optLabel_other')">
                                        <option v-for="(domain,index) in filterCompareDomains" :key="index" :value="domain.id" v-if="!domain.followed">{{domain.url}}</option>
                                    </optgroup>
                                </select>
                            </div>
                        </v-col>

                        <v-col v-if="resultType">
                            <div class="simpleSelectContainer">
                                <label for="filter-select-resultType">{{$t('component_componentFilter_resultType_label')}}</label>
                                <select v-model="filterResultTypeSelect" id="filter-select-resultType" style="width: 100%">
                                    <option v-for="(resultType,index) in filterResultType" :key="index" :value="resultType.id">{{resultType.name}}</option>
                                </select>
                            </div>
                        </v-col>

                        <v-col v-if="reportDate">
                            <div class="simpleSelectContainer">
                                <label for="filter-select-compareDate">{{$t('component_componentFilter_compareDate_label')}}</label>
                                <select v-model="filterReportDateSelect" id="filter-select-compareDate" style="width: 100%">
                                    <option v-for="(reportDate,index) in filterReportDate" :key="index" :value="reportDate.id">{{reportDate.date}}</option>
                                </select>
                            </div>
                        </v-col>

                        <v-col v-if="advertiserKeywordsPresence">
                            <div class="simpleSelectContainer">
                                <label for="filter-select-advertiserPresence">{{$t('component_componentFilter_advertiserPresence_label')}}</label>
                                <select v-model="filterAdvertiserKeywordsPresenceSelect" id="filter-select-advertiserPresence" style="width: 100%">
                                    <option v-for="(advertiserPresence,index) in filterAdvertiserKeywordsPresence" :key="index" :value="advertiserPresence.id">{{advertiserPresence.name}}</option>
                                </select>
                            </div>
                        </v-col>

                        <v-col v-if="withProjectKeywords">
                            <div class="simpleSelectContainer">
                                <label for="filter-select-keyword">{{$t('filters_keyword_label')}}</label>
                                <select v-model="filterProjectKeywordsSelect" id="filter-select-keyword" style="width: 100%">
                                    <option v-for="(keyword,index) in filterProjectKeywords" :key="index" :value="keyword.id">{{ keyword.string }}</option>
                                </select>
                            </div>
                        </v-col>

                    </v-row>
                    <div v-else>
                        <div class="v-application text-center" >
                            <v-progress-circular indeterminate color="primary" class="mt-6"/>
                        </div>
                    </div>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>

</template>


<script>

import apiCalls from "@/api/axios/configuration/project";
import apiCallsMarket from "@/api/axios/dataReport/marketSegment";
import apiCallsReport from "@/api/axios/dataReport/report";

export default {
    props: {
        openDefault:{type:Boolean, default:true},
        marketSegments:{type:Boolean, default:false},
        competitors:{type:Boolean, default:false},
        competitorsWithProjectWebsite:{type:Boolean, default:false},
        competitorsWithSelectAll:{type:Boolean, default:true},
        compareTwoDomains:{type:Boolean, default:false},
        resultType:{type:Boolean, default:false},
        reportDate:{type:Boolean, default:false},
        advertiserKeywordsPresence:{type:Boolean, default:false},
        withProjectKeywords:{type:Boolean, default:false},
        defaultSelectValue:null
    },
    data() {
        return {
            panel:null,
            marketSegmentTopCompetitor:null,
            projectReportsList:null,
            marketSegmentDataLoaded:false,
            competitorsDataLoaded:false,
            compareDomainsDataLoaded:false,
            dateDataLoaded:false,
            keywordsDataLoaded:false,

            filterMarketSegmentSelect:null,
            filterCompetitorSelect:null,
            filterCompareDomainOneSelect:null,
            filterCompareDomainTwoSelect:null,
            filterResultTypeSelect:null,
            filterReportDateSelect:0,
            filterAdvertiserKeywordsPresenceSelect:null,
            filterProjectKeywordsSelect:null,

        }
    },
    watch:{
        panel(){

            if(this.$router.currentRoute.params.projectId){
                this.panel === 0 ? this.$emit('filterPanelStatus', 'open') : setTimeout(()=>{this.$emit('filterPanelStatus', 'close')},100)
            }
            else{
                this.$emit('filterPanelStatus', 'close')
            }


        },
        filtersInitialized(){
            this.setInitialFilterValue()
        }
    },
    computed: {
        filtersInitialized(){
            return (this.marketSegmentDataLoaded && this.competitorsDataLoaded && this.compareDomainsDataLoaded && this.dateDataLoaded && this.keywordsDataLoaded)
        },

        filterMarketSegments(){
            let marketSegment = [{name:this.$t('component_componentFilter_marketSegment_all'), id:null}]
            if(this.projectSegments.data){
                this.projectSegments.data.forEach(segment=>{
                    marketSegment.push({name:segment.name, id:segment.id})
                })
            }
            return marketSegment
        },

        filterCompetitors(){
            let competitors = []
            if(this.competitorsWithSelectAll){
                competitors.push ({url:this.$t('component_componentFilter_competitor_all'), id:null})
            }
            if(this.competitorsWithProjectWebsite){
                competitors.push ({url:this.projectUrl, id:0})
            }

            if(this.rivalsConfiguration){
                this.rivalsConfiguration.forEach((rival)=>{
                    competitors.push(rival)
                })
            }
            return competitors
        },

        filterCompareDomains(){
            let competitors = []
            let rivals = []
            if(this.marketSegmentTopCompetitor.data){
                this.marketSegmentTopCompetitor.data.forEach((rival)=>{
                    if(this.projectUrl!==rival && !this.rivalsConfiguration.find(data =>  data.url === rival)){
                        competitors.push({url:rival, id:rival})
                    }
                    else{
                        rivals.push({url:rival, id:rival, followed:true})
                    }
                })
            }

            return rivals.concat(competitors)
        },

        filterResultType(){
            return [
                {name:this.$t('component_componentFilter_resultType_select_all'), id:null},
                {name:this.$t('component_componentFilter_resultType_select_natural'), id:'natural'},
                {name:this.$t('component_componentFilter_resultType_select_map'), id:'map'},
                {name:this.$t('component_componentFilter_resultType_select_adwords'), id:'adwords'},
                {name:this.$t('component_componentFilter_resultType_select_shopping'), id:'shopping'},
                {name:this.$t('component_componentFilter_resultType_select_image'), id:'image'},
                {name:this.$t('component_componentFilter_resultType_select_video'), id:'video'},
                {name:this.$t('component_componentFilter_resultType_select_actuality'), id:'actuality'},
                {name:this.$t('component_componentFilter_resultType_select_zero'), id:'zero'},
            ]
        },
        filterReportDate(){
            let reportDates = []
            if(this.projectReportsList && Array.isArray(this.projectReportsList)){
                let count = 0
                this.projectReportsList.forEach(report =>{
                    if(count>0){
                        let displayedDate = new Date(Date.parse(report.createdAt))
                        reportDates.push({
                            date: displayedDate.toLocaleDateString('fr-FR', { timeZone: 'UTC' }),
                            id: report.id
                        })
                    }
                    count++
                })
            }

            return reportDates
        },
        filterAdvertiserKeywordsPresence(){
            return [
                {name:this.$t('component_componentFilter_advertiserKeywordPresence_all'), id: null},
                {name:this.$t('component_componentFilter_advertiserKeywordPresence_with'), id:'with'},
                {name:this.$t('component_componentFilter_advertiserKeywordPresence_without'), id:'without'},
            ]
        },
        filterProjectKeywords(){
            let keywords = [{string:this.$t('component_componentFilter_projectKeyword_select_all'), id:null}]
            return keywords.concat(this.projectInfoKeywords)
        },
    },

    methods: {
        getAllSelectedFilter(){
            let filters = {params:{}}
            if(this.filterMarketSegmentSelect){ filters.params["keyword-group-id"] = this.filterMarketSegmentSelect }
            if(this.reportDate && this.filterReportDateSelect){ filters.params["previous-report-id"] = this.filterReportDateSelect }
            if(this.filterCompareDomainOneSelect){
                filters.params["domain-names"] = this.filterCompareDomainOneSelect.replace('https://','').replace('http://','').replace('www.','')
                if(this.filterCompareDomainTwoSelect){
                    filters.params["domain-names"] += ','+this.filterCompareDomainTwoSelect.replace('https://','').replace('http://','').replace('www.','')
                }
            }
            if(this.filterCompetitorSelect){ filters.params["domain-name"] = this.filterCompetitorSelect.replace('https://','').replace('http://','').replace('www.','') }
            if(this.filterResultTypeSelect){ filters.params["position-type"] = this.filterResultTypeSelect }
            if(this.filterAdvertiserKeywordsPresenceSelect){ filters.params["advertiser-keywords-presence"] = this.filterAdvertiserKeywordsPresenceSelect }
            if(this.filterProjectKeywordsSelect){ filters.params["keyword-id"] = this.filterProjectKeywordsSelect }

            return filters
        },

        initComponentData(){
            this.$emit('applyFilter',this.getAllSelectedFilter())
        },

        loadFilterMarketSegmentData(){
            if(this.marketSegments){
                if(!this.projectSegments.data){

                    return this.setDataComponent( {
                        stateName:'projectSegments',
                        promise: apiCalls.getKeywordGroups(this.$route.params.projectId)
                    }).then((result)=>{
                        if(result){
                            this.marketSegmentDataLoaded = true
                        }
                    })
                }
                else{ this.marketSegmentDataLoaded = true }
            }
            else{ this.marketSegmentDataLoaded = true }
        },
        loadFilterCompetitorsData(){

            if(this.competitors){
                if(!this.rivalsConfiguration){
                    this.setDataComponent({
                        stateName:'projectInfo',
                        promise: apiCalls.getProjectInfo(this.$route.params.projectId),
                        dataFormatter: this.projectData
                    }).then((result)=>{
                        if(result){
                            this.initFilterCompetitors()
                            this.competitorsDataLoaded = true
                        }
                    })
                }
                else{
                    this.initFilterCompetitors()
                    this.competitorsDataLoaded = true
                }
            }
            else{ this.competitorsDataLoaded = true }
        },
        initFilterCompetitors(){
            if(this.competitorsWithSelectAll){
                this.filterCompetitorSelect = null
            }
            else{
                if(this.competitorsWithProjectWebsite){
                    this.filterCompetitorSelect = this.projectUrl
                }
                else{
                    this.filterCompetitorSelect = 0
                }
            }
        },

        loadFilterCompareDomainsData(){

            if(this.compareTwoDomains){

                this.getComponentData( {
                    componentName:'marketSegmentTopCompetitor',
                    promise: apiCallsMarket.readMarketTopCompetitor(this.$route.params.projectId,{params:{'search-geolocation-id':this.globalFilterLocation}})
                }).then((result)=>{
                    if(result){
                        this.initFilterCompareDomains()
                        this.compareDomainsDataLoaded = true
                    }
                })
            }
            else{ this.compareDomainsDataLoaded = true }
        },
        initFilterCompareDomains(){
            if(this.marketSegmentTopCompetitor.data && this.marketSegmentTopCompetitor.data.length>1){
                this.filterCompareDomainOneSelect = this.marketSegmentTopCompetitor.data[0]
                if(this.marketSegmentTopCompetitor.data[1]){
                    this.filterCompareDomainTwoSelect = this.marketSegmentTopCompetitor.data[1]
                }
            }
        },
        loadFilterDateData(){
            if(this.reportDate){
                if(!this.projectReportsList){
                    apiCallsReport.readCompletedReports(this.$route.params.projectId,this.globalFilterFrequency)
                    .then((result)=>{
                        if(result){
                            this.projectReportsList = result.content
                            this.initFilterDate()
                            this.dateDataLoaded = true
                        }
                    })
                }
                else{
                    this.initFilterDate()
                    this.dateDataLoaded = true
                }
            }
            else{ this.dateDataLoaded = true }
        },
        initFilterDate(){
            if(!this.projectReportsList[1]){
                this.filterReportDateSelect = 0
            }
            else{
                this.filterReportDateSelect = this.projectReportsList[1].id
            }
        },

        loadFilterKeywordsData(){
            if(this.withProjectKeywords){
                if(!this.projectInfoKeywords){
                    this.setDataComponent( {
                        stateName:'projectInfo',
                        promise: apiCalls.getProjectInfo(this.$route.params.projectId),
                        dataFormatter: this.projectData
                    }).then((result)=>{
                        if(result){ this.keywordsDataLoaded = true }
                    })
                }
                else{ this.keywordsDataLoaded = true }
            }
            else{ this.keywordsDataLoaded = true }
        },

        setInitialFilterValue(){
            if(this.defaultSelectValue){
                if(this.defaultSelectValue.params["keyword-group-id"]){ this.filterMarketSegmentSelect = this.defaultSelectValue.params["keyword-group-id"]}
                if(this.defaultSelectValue.params["previous-report-id"]){ this.filterReportDateSelect = this.defaultSelectValue.params["previous-report-id"] }
                if(this.defaultSelectValue.params["domain-name"]){ this.filterCompetitorSelect = this.defaultSelectValue.params["domain-name"] }
                if(this.defaultSelectValue.params["position-type"]){ this.filterResultTypeSelect = this.defaultSelectValue.params["position-type"] }
                if(this.defaultSelectValue.params["domain-names"]){
                    let domains = this.defaultSelectValue.params["domain-names"].split(',')
                    if(domains[0]){ this.filterCompareDomainOneSelect = domains[0] }
                    if(domains[1]){ this.filterCompareDomainTwoSelect = domains[1] }
                }
                if(this.defaultSelectValue.params["advertiser-keywords-presence"]){ this.filterAdvertiserKeywordsPresenceSelect = this.defaultSelectValue.params["advertiser-keywords-presence"] }
                if(this.defaultSelectValue.params["keyword-id"]){ this.filterProjectKeywordsSelect = this.defaultSelectValue.params["keyword-id"] }
            }

            this.$watch('filterMarketSegmentSelect', () => { this.initComponentData() })
            this.$watch('filterReportDateSelect', this.initComponentData);
            this.$watch('filterCompetitorSelect', this.initComponentData);
            this.$watch('filterResultTypeSelect', () => { this.initComponentData() })
            this.$watch('filterCompareDomainOneSelect', this.initComponentData);
            this.$watch('filterCompareDomainTwoSelect', this.initComponentData);
            this.$watch('filterAdvertiserKeywordsPresenceSelect', () => { this.initComponentData() })
            this.$watch('filterProjectKeywordsSelect', () => { this.initComponentData() })
        }

    },
    beforeMount() {
        this.panel = (this.openDefault ? 0 : null)

        if(this.$router.currentRoute.params.projectId){
            this.loadFilterMarketSegmentData()

            this.loadFilterCompetitorsData()

            this.loadFilterCompareDomainsData()

            this.loadFilterDateData()

            this.loadFilterKeywordsData()
        }


    }
}
</script>

<style lang="scss" >

.filter-container{
    .v-expansion-panel-header{
        display: block;
        margin: auto;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        min-height: 25px;
        height: 25px;
        padding: 0;
        .v-icon{
            position: absolute;
            top: 2px;
            right:4px;
            color: #fff !important;
        }
    }
    .v-expansion-panel--active > .v-expansion-panel-header{
        min-height: 25px;
        height: 25px;
    }

}


</style>
