var render = function render(){var _vm=this,_c=_vm._self._c;return _c('component-container',{attrs:{"card-title":_vm.$t('component_marketSegmentEvolution_title'),"card-subtitle":_vm.$t('component_marketSegmentEvolution_subtitle'),"height":495,"information-tooltip":{
        title : _vm.$t('component_marketSegmentEvolution_informationTooltip_title'),
        text:_vm.$t('component_marketSegmentEvolution_informationTooltip_text')
    },"component-config":{
        hasConfig:_vm.keywordGroupsConfiguration.length,
        hideOnNoConfig:_vm.hideOnNoConfig,
        redirectRoute:_vm.displayPlaceholderConfigButton ? 'segments-configuration' : null,
        placeholderTitle:_vm.$t('component_componentContainer_noMarketSegment_title'),
        placeholderText:_vm.$t('component_componentContainer_noMarketSegment_text')
    },"component-data":{
        response:_vm.marketSegmentEvolution,
        hasData:(_vm.marketSegmentEvolution.data && _vm.marketSegmentEvolution.data.series)
    }},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('yooda-chart',{attrs:{"options":_vm.marketSegmentEvolution.data,"chart-type":"bar","chart-style":"height:340px;"}}),(_vm.displayLinkButton)?_c('div',{staticClass:"v-application"},[_c('v-btn',{staticStyle:{"width":"calc(100% - 35px)","position":"absolute","bottom":"18px"},attrs:{"outlined":"","to":{name:'market_segment'},"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('component_marketSegmentEvolution_detailButton'))),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-double-right")])],1)],1):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }