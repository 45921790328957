<template>
    <div>
        <component-container
            :card-title="$t('component_marketSegmentBestProgressionPotential_title')"
            :card-subtitle="$t('component_marketSegmentBestProgressionPotential_subtitle')"

            :information-tooltip="{
                title : $t('component_marketSegmentBestProgressionPotential_informationTooltip_title'),
                text:$t('component_marketSegmentBestProgressionPotential_informationTooltip_text')
            }"


            :component-config="{
                hasConfig:keywordGroupsConfiguration.length,
                hideOnNoConfig:hideOnNoConfig,
                redirectRoute:displayPlaceholderConfigButton ? 'segments-configuration' : null,
                placeholderTitle:$t('component_componentContainer_noMarketSegment_title'),
                placeholderText:$t('component_componentContainer_noMarketSegment_text')
            }"
            :table-menu="{csv:true, fullscreen:true}"
            :table-density-selector="true"

            :component-data="{response:marketSegmentBestProgressionPotential, hasData:marketSegmentBestProgressionPotential.data}"

            @setTableMenuFullscreen="cardContainerFullscreen = !cardContainerFullscreen"
            @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"
        >

            <template v-slot:content>
                <ag-grid-table
                    :show-floating-filter="false"
                    :table-data="{columnDefs:columnDefs,rowData:marketSegmentBestProgressionPotential.data}"
                    :agGridStyle="`height:${tableHeightByRowCount(marketSegmentBestProgressionPotential.data)}px;`"
                    :is-card-container-fullscreen="cardContainerFullscreen"
                    ref="agGridTable"
                />
            </template>
        </component-container>

        <market-segment-best-progression-potential-keywords/>
    </div>

</template>


<script>

import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable'
import MarketSegmentBestProgressionPotentialKeywords from '@/components/yooda-components/app/market-segment/MarketSegmentBestProgressionPotentialKeywords'
import apiCalls from '@/api/axios/dataReport/marketSegment.js'
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";

export default {
    props: {
        displayPlaceholderConfigButton:{type: Boolean, default: true},
        hideOnNoConfig:{type: Boolean, default: false}
    },
    data() {
        return {
            marketSegmentBestProgressionPotential:null,
            cardContainerFullscreen:false,
            searchGeolocations:null,
            columnDefs: [
                {headerName: this.$t('component_marketSegmentBestProgressionPotential_table_header_market'), field: 'market', cellRendererFramework: 'CellRendererTextWithIcon', filters: true},
                {headerName: this.$t('component_marketSegmentBestProgressionPotential_table_header_potentialGain'), field: 'potentialGain',  sort:'desc', cellRendererFramework: 'CellRendererPotentialTraffic', width: 120, comparator: cellSort.agGridTableSortCellFloatValue},
                {headerName: this.$t('component_marketSegmentBestProgressionPotential_table_header_performance'), field: 'performance', cellRendererFramework: 'CellRendererPercent', width: 90, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},
                {headerName: this.$t('component_marketSegmentBestProgressionPotential_table_header_keywords'), field: 'keywords', cellRendererFramework: 'CellRendererPopinTableLink', width: 90, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellValue},
            ]
        }
    },
    components: {
        AgGridTable,
        MarketSegmentBestProgressionPotentialKeywords,
    },
    computed:{

    },
    watch:{
        globalFilterLocation(){
            this.initComponentData()
        }
    },
    methods:{
        formatData(jsonData){
            if(jsonData.content.length === 0){return []}
            let rowData = []
            jsonData.content.sort((a,b)=>{
                return (a.keywordGroupName < b.keywordGroupName) ? -1 : (a.keywordGroupName > b.keywordGroupName) ? 1 : 0
            })
            let maxPotentialTrafficGain = 1
            jsonData.content.forEach(data=>{
                if(data.potentialTrafficGain > maxPotentialTrafficGain){ maxPotentialTrafficGain = data.potentialTrafficGain }
            })
            jsonData.content.forEach(data => {
                rowData.push({
                    market: data.keywordGroupName,
                    performance: data.performance,
                    potentialGain:{
                        value: `+${this.$options.filters.formatNumber(data.potentialTrafficGain)} ${this.$i18n.t('component_progressionAxisImprovementWay_label')}`,
                        percent:Math.round((data.potentialTrafficGain*100)/maxPotentialTrafficGain),
                        color:'purple'
                    },
                    iconName: 'tag',
                    iconSize: 'small',

                    keywords: {
                        componentName: 'marketSegmentBestProgressionPotentialKeywords',
                        value: data.potentialProgressionKeywordCount,
                        promise: () => (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  && this.keywordGroupsConfiguration.length>0) ? apiCalls.readBestProgressionPotentialKeyword(this.$router.currentRoute.params.projectId, {
                            params:{
                                "search-geolocation-id":this.globalFilterLocation,
                                "keyword-group-id":data.keywordGroupId
                            }}) : false,
                        elementName: data.keywordGroupName

                    },
                })
            })
            return rowData
        },


        initComponentData(){
            this.getComponentData({
                componentName:'marketSegmentBestProgressionPotential',
                promise: (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  && this.keywordGroupsConfiguration.length>0) ? apiCalls.readBestProgressionPotential(this.$router.currentRoute.params.projectId,{params:{"search-geolocation-id":this.globalFilterLocation}}) : false,
                dataFormatter: this.formatData
            })
        },
    },
    beforeMount() {
        this.initComponentData()
    },

}
</script>
