<template>
    <v-dialog v-model="show" content-class="popin-dialog-container" overlay-opacity="0.85">

        <v-icon
            class="close-popin-dialog"
            @click="show=false"
        >
            mdi-close
        </v-icon>

        <component-container
            :card-title="`${$t('component_linkingAnchorsLinks_title')} <em>${elementName}</em>`"
            :card-subtitle="$t('component_linkingAnchorsLinks_subtitle')"
            :table-menu="{csv:true}"
            :component-data="{response:linkingAnchorsLinks, hasData:linkingAnchorsLinks.data}"
            @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"

            :table-density-selector="true"
        >
            <template v-slot:content>

                <linking-thematic-selector class="mb-2 mt-6" :selected-thematic-name="selectedThematic" @selectValue="selectThematic"/>


                <ag-grid-table
                   v-if="linkingAnchorsLinks.data"
                   :with-fullscreen="false"
                   :show-pagination="false"
                   :show-floating-filter="false"
                   :table-data="{columnDefs:columnDefs,rowData:tableData}"
                   :ag-grid-options="{ rowHeight: 74 }"
                   ref="agGridTable"
                />
            </template>
        </component-container>
    </v-dialog>
</template>


<script>

import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable.vue'
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";
import LinkingThematicSelector from "@/components/yooda-components/app/linking/LinkingThematicSelector.vue";

export default {
    data() {
        return {
            show:false,
            selectedThematic:this.$t('linking_thematic_label_all'),
            linkingAnchorsLinks:{  isLoaded:false, error:null,data: null },
            elementName:null,
            popinStateName:'linkingAnchorsLinks',
            columnDefs:[
                {headerName: this.$t('component_linkingAnchorsLinks_table_header_referral'),filter: true, field: 'refererPage', cellRendererFramework: 'CellRendererLinkingBacklinkDetail', comparator: cellSort.agGridTableSortCellValue,filterParams: {valueGetter: params => { return params.data.refererPage.value }}},
                {headerName: this.$t('component_linkingAnchorsLinks_table_header_popularity'),filter: false, field: 'popularity', sort:'desc', sortingOrder: ['desc','asc'], cellRendererFramework: 'CellRendererLinkingPopularityDetail', width: 75, comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_linkingAnchorsLinks_table_header_linkedPage'),filter: false, field: 'linkingPage', width: 100, cellRendererFramework: 'CellRendererLinkingBacklinkDetail', comparator: cellSort.agGridTableSortCellValue,filterParams: {valueGetter: params => { return params.data.refererPage.value }}},
                {headerName: this.$t('component_linkingAnchorsLinks_table_header_thematic'),filter: false, field: 'thematic', sortingOrder: ['desc','asc'],  cellRendererFramework: 'CellRendererLinkingThematic', comparator: cellSort.agGridTableSortCellValue},

            ]
        }
    },

    computed:{
        tableData(){

            if(this.selectedThematic !== this.$t('linking_thematic_label_all')){
                return this.linkingAnchorsLinks.data.filter((data)=>{
                    if(data.thematic && data.thematic.categories && (data.thematic.categories[0] === this.selectedThematic)){
                        return true
                    }
                    return false
                })
            }


            return this.linkingAnchorsLinks.data
        }
    },
    components: {
        LinkingThematicSelector,
        AgGridTable,
    },
    watch:{
        getShowPopin(){
            if(!this.show && this.getShowPopin){
                if(this.getShowPopin.componentName === this.popinStateName){
                    this.show = true
                    this.elementName = this.getShowPopin.elementName
                    this.getComponentData({
                        componentName:'linkingAnchorsLinks',
                        promise: this.getShowPopin.promise(),
                        dataFormatter: this.formatData
                    })
                }
            }
        },
        show(){
            if(!this.show){
                this.showAgGridPopin(null)
            }
        }


    },
    methods:{

        selectThematic(selectThematic){
            this.selectedThematic = selectThematic
        },
        formatData(jsonData){
            if(jsonData.content.length === 0){return null}
            let rowData = []
            jsonData.content.forEach(data => {
                let thematicData = this.getThematicData(data)

                let parsedRefererPage = new URL(`${data.sourceURL}`)
                let parsedLinkingPage = new URL(`${data.targetURL}`)
                rowData.push({
                    refererPage:{
                        redirectTo: `${data.sourceURL}`,
                        value: `${parsedRefererPage.hostname}${parsedRefererPage.pathname}`,
                        url: `${parsedRefererPage.hostname}${parsedRefererPage.pathname}`,
                        title: data.sourceTitle,
                    },



                    popularity: {
                        value: data.transmittedPopularity,
                        referralPagePopularity: data.popularity,
                        countLink: data.sourceOutLinksExternal,
                        color:this.getThemeColorByName('primary')
                    },

                    linkingPage: {
                        redirectTo: `${data.targetUrl}`,
                        value: parsedLinkingPage.pathname,
                        url: parsedLinkingPage.pathname,
                        title: data.targetTitle,
                    },

                    thematic:{
                        value:thematicData.mainThematic.value,
                        color:thematicData.mainThematic.color,
                        categories:thematicData.mainThematic.categories,
                        otherThematic:thematicData.otherThematic
                    }
                })
            })

            return rowData
        },

    },
}
</script>
