<template>
    <div>
        <component-container
            :class="{filterOpen:filterOpen}"
            :card-title="$t('component_advertisingAdwordsCompetitionKeywords_title')"
            :card-subtitle="$t('component_advertisingAdwordsCompetitionKeywords_subtitle')"


            :information-tooltip="{
                title: $t('component_advertisingAdwordsCompetitionKeywords_informationTooltip_title'),
                text: $t('component_advertisingAdwordsCompetitionKeywords_informationTooltip_text')
            }"

            :table-menu="{csv:true, fullscreen:true}"
            :table-density-selector="true"

            :component-data="{response:advertisingAdwordsCompetitionKeywords, hasData:advertisingAdwordsCompetitionKeywords.data}"

            @setTableMenuFullscreen="cardContainerFullscreen = !cardContainerFullscreen"
            @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"

            :no-shadow="true"
        >
            <template v-slot:filters>
                <component-filters
                    :market-segments="true"
                    :advertiser-keywords-presence="true"
                    @applyFilter="initComponentData"
                    @filterPanelStatus="displayFilterOpen"
                    :default-select-value="lastFilters"
                />
            </template>

            <template v-slot:content>
                <ag-grid-table
                    :show-floating-filter="false"
                    :table-data="{columnDefs:columnDefs,rowData:advertisingAdwordsCompetitionKeywords.data}"
                    :agGridStyle="`height:${tableHeightByRowCount(advertisingAdwordsCompetitionKeywords.data)}px;`"
                    :is-card-container-fullscreen="cardContainerFullscreen"
                    ref="agGridTable"
                />
            </template>
        </component-container>

        <advertising-adwords-keywords-ads/>
    </div>
</template>


<script>

import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable'
import AdvertisingAdwordsKeywordsAds from '@/components/yooda-components/app/advertising/AdvertisingAdwordsKeywordsAds'
import apiCalls from '@/api/axios/dataReport/advertising.js'
import ComponentFilters from "@/components/yooda-components/ComponentFilters";
import cellSort from "@/components/yooda-components/ag-grid-table/cell-sort/cellSort";

export default {
    data() {
        return {
            advertisingAdwordsCompetitionKeywords:null,
            filterOpen:false,
            cardContainerFullscreen:false,
            lastFilters: null,
            columnDefs: [
                {headerName: this.$t('component_advertisingAdwordsCompetitionKeywords_table_header_keyword'),filter: true, field: 'keyword'},
                {headerName: this.$t('component_advertisingAdwordsCompetitionKeywords_table_header_advertiserCount'), field: 'advertiserCount', sort:'desc',  cellRendererFramework: 'CellRendererPopinTableLink', width: 90, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right',comparator: cellSort.agGridTableSortCellValue},
                {headerName: this.$t('component_advertisingAdwordsCompetitionKeywords_table_header_searchCount'), field: 'searchCount', width: 90, cellClass:'ag-grid-cell-right', cellRendererFramework: 'CellRendererFormatedNumber',headerClass:'ag-grid-header-right'},
                {headerName: this.$t('component_advertisingAdwordsCompetitionKeywords_table_header_competition'), field: 'competition', width: 90, cellRendererFramework: 'CellRendererCompetition',comparator: cellSort.agGridTableSortCellFloatValue},
                {headerName: this.$t('component_advertisingAdwordsCompetitionKeywords_table_header_averageCpc'), field: 'averageCpc', cellRendererFramework: 'CellRendererCurrency', width: 90, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},
            ]
        }
    },
    components: {
        AgGridTable,
        AdvertisingAdwordsKeywordsAds,
        ComponentFilters,
    },
    computed:{

    },
    watch:{
        globalFilterLocation(){
            this.initComponentData(this.lastFilters)
        }
    },
    methods:{
        displayFilterOpen(status){ this.filterOpen = status === 'open'; },
        formatData(jsonData){
            if(jsonData.content.length === 0){return []}
            let rowData = []
            jsonData.content.forEach(data => {
                let filters = {
                    params: {
                        "search-geolocation-id": this.globalFilterLocation,
                    }
                }
                rowData.push({
                    keyword: data.string,
                    advertiserCount: (data.distinctAdsCount ? {
                        componentName: 'advertisingAdwordsKeywordsAds',
                        value:data.distinctAdsCount,
                        promise: () => !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readAdwordsKeywordsAds(this.$router.currentRoute.params.projectId,data.keywordId,filters) : false,
                        elementName:data.string
                    } : {value:0}),
                    searchCount: data.searchCount !== null ? data.searchCount : '-',
                    competition: { value: data.competition !== null ? data.competition : '-' },
                    averageCpc: data.averageSuggestedBid !== null ? data.averageSuggestedBid : '-',
                })
            })
            return rowData
        },
        initComponentData(filters={params:{}}){
            filters.params["search-geolocation-id"] = this.globalFilterLocation

            this.getComponentData({
                componentName:'advertisingAdwordsCompetitionKeywords',
                promise: !this.projectCreationWaiting && this.$router.currentRoute.params.projectId  ? apiCalls.readAdwordsCompetitionKeywords(this.$router.currentRoute.params.projectId,filters) : false,
                dataFormatter: this.formatData
            })
            this.lastFilters = filters
        },
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
