<template>
    <component-container
        :card-title="$t('component_marketSegmentCompetitorCompare_title')"
        :card-subtitle="$t('component_marketSegmentCompetitorCompare_subtitle')"

        :height="(filterOpen ? 595 : 495)"

        :information-tooltip="{
            title : $t('component_marketSegmentCompetitorCompare_informationTooltip_title'),
            text:$t('component_marketSegmentCompetitorCompare_informationTooltip_text')
        }"

        :component-config="{
            hasConfig:keywordGroupsConfiguration.length,
            hideOnNoConfig:hideOnNoConfig,
            redirectRoute:displayPlaceholderConfigButton ? 'segments-configuration' : null,
            placeholderTitle:$t('component_componentContainer_noMarketSegment_title'),
            placeholderText:$t('component_componentContainer_noMarketSegment_text')
        }"

        :component-data="{
            response: marketSegmentCompetitorCompare,
            hasData: (marketSegmentCompetitorCompare.data && marketSegmentCompetitorCompare.data.series)
        }"
    >
        <template v-slot:filters>
            <component-filters
                v-if="!projectCreationWaiting"
                :open-default="filterOpen"
                :compare-two-domains="true"
                @applyFilter="initComponentData"
                @filterPanelStatus="displayFilterOpen"
                :default-select-value="lastFilters"
            />
        </template>

        <template v-slot:content>
            <yooda-chart
                :options="marketSegmentCompetitorCompare.data"
                chart-type="radar"
                chart-style="height:360px;margin-bottom: -28px"
            />
        </template>
    </component-container>

</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/marketSegment.js'
import ComponentFilters from "@/components/yooda-components/ComponentFilters";
import apiCallsMarket from "@/api/axios/dataReport/marketSegment";


export default {
    props:{
        displayPlaceholderConfigButton:{type: Boolean, default: true},
        hideOnNoConfig:{type: Boolean, default: false}
    },
    data() {
        return {
            marketSegmentCompetitorCompare: { isLoaded:false, error:null,data: null },
            marketSegmentTopCompetitorData: null,
            filterOpen:false,
            lastFilters:null,
        }
    },
    components: {
        YoodaChart,
        ComponentFilters,
    },
    computed:{

    },
    watch:{
        globalFilterLocation(){
            this.initComponentData()
        }
    },
    methods:{
        displayFilterOpen(status){ this.filterOpen = status === 'open'; },
        formatData(jsonData){
            let serieData = []
            let serieLabels = []
            let labels = []
            let maxValue = 0
            let siteKey = 0


            jsonData.content.forEach(data => {
                let valueKey = 0
                data.performances.forEach((value)=>{
                    if(value.performance > maxValue){maxValue = value.performance}

                    if(labels[valueKey] === undefined){
                        labels[valueKey] = {bestValue:value.performance, name:value.keywordGroupName}
                    }
                    else{
                        if(value.performance > labels[valueKey].bestValue){
                            labels[valueKey].bestValue = value.performance
                        }
                    }

                    labels[valueKey][`site${siteKey}`] = value.performance
                    valueKey++
                })

                // let color = (data.competitorType === 'project' ? this.getComponentColor().getCompetitorColor(1) : this.getComponentColor().getCompetitorColor(2))
                let color = this.getComponentColor().getCompetitorColor(siteKey+1)
                serieData.push({
                    value:[],
                    name:data.domainName,
                    itemStyle: {color:color},
                    areaStyle: {color:color},
                    lineStyle: {color:color},
                })
                siteKey++
            })
            labels.sort((a,b)=>{ return b.bestValue - a.bestValue })

            if(maxValue === 0 ){maxValue = 100}
            let legend = []
            labels.forEach((data)=>{
                serieLabels.push({name:data.name, max:maxValue})
                serieData[0].value.push(data.site0)
                legend.push(data.name)
                if(serieData.length > 1){
                    serieData[1].value.push(data.site1)
                    legend.push(data.name)
                }

            })

            if(serieData[0]){ legend.push(serieData[0].name) }
            if(serieData[1]){ legend.push(serieData[1].name) }

            let tooltipFormatter = params => {
                let toolTip = '<p><strong>'+params.name+'</strong><br>'
                params.data.value.forEach((param,index)=>{
                    toolTip += serieLabels[index].name+' : '+this.$options.filters.formatNumber(param)+'%<br>'
                })
                toolTip+= '</p>'
                return  toolTip
            }


            return {
                grid: { left: 50, top: 25, right: 30, bottom: 40 },
                legend: { orient: 'vertical', icon:'circle', data: legend, top: 'center', left:'auto' },
                tooltip: this.genericTooltipConfiguration('item', tooltipFormatter),
                radar:{ indicator: serieLabels },
                series:[{ type:'radar', areaStyle: { opacity:0.4 }, data:serieData }]
            }
        },

        initComponentData(filters){

            if(!filters){
                filters = {params:{"domain-names": this.marketSegmentTopCompetitorData.data[0]}}

                if(this.marketSegmentTopCompetitorData.data[1]){
                    filters.params["domain-names"] += ','+this.marketSegmentTopCompetitorData.data[1]
                }
            }

            filters.params['search-geolocation-id'] = this.globalFilterLocation

            this.getComponentData({
                componentName:'marketSegmentCompetitorCompare',
                promise: (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  && this.keywordGroupsConfiguration.length>0) ? apiCalls.readCompetitorMarketCompare(this.$router.currentRoute.params.projectId,filters) : false,
                dataFormatter: this.formatData
            })
            this.lastFilters = filters
        }
    },
    beforeMount() {
        this.getComponentData({
            componentName:'marketSegmentTopCompetitorData',
            promise: (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  && this.keywordGroupsConfiguration.length>0) ? apiCallsMarket.readMarketTopCompetitor(this.$route.params.projectId,{params:{'search-geolocation-id':this.globalFilterLocation}}) : false,
        }) .then((result)=>{
            if(result){
                this.initComponentData()
            }
        })
    },
}
</script>
