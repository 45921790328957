<template>
    <v-row>
        <v-col cols="12">
            <div style=" text-align: center; display: flex" v-if=" useLogo">
                <img  v-if="dataLogo" :src="dataLogo" style="align-self:center;margin:20px auto 32px auto;max-width: 100%; max-height: 100px" />
            </div>
        </v-col>
    </v-row>

</template>


<script>

import apiCalls from "@/api/axios/configuration/project";
import reportingApiCalls from "@/api/axios/dataReport/reporting";

export default {
    data(){
        return{
            subscriptionMetaData:null,
            useLogo:false,
            dataLogo:null
        }
    },
    computed:{
    },
    beforeMount() {

        if(!this.$router.currentRoute.params.projectId){
            this.useLogo = true
            this.getComponentData( {
                componentName:'subscriptionMetaData',
                promise: apiCalls.getSubscriptionMeta(this.$route.params.subscriptionId, true)
            }).then(()=>{
                this.dataLogo =  this.subscriptionMetaData.data.logo
            })
        }
        else{

            this.getComponentData( {
                componentName:'subscriptionMetaData',
                promise: apiCalls.getSubscriptionMeta(this.project.data.subscriptionMeta.externalSubscriptionId, true)
            }).then(()=>{
                this.dataLogo =  this.subscriptionMetaData.data.logo
            })

            this.getComponentData( {
                componentName:'reportingList',
                promise: reportingApiCalls.readReportings(this.$router.currentRoute.params.projectId),
            }).then(()=>{
                if(this.reportingList.data.length > 0){
                    this.useLogo = this.reportingList.data[0].logo
                }
            })
        }

    }
}
</script>
