<template>

    <component-container
        :card-title="title"
        :card-subtitle="subtitle"

        :height="380"

        :information-tooltip="{
            title: tooltipTitle,
            text: tooltipText
        }"

        :component-data="{response:data, hasData:(data.data), noDataMessage:noDataMessage}"
    >
        <template v-slot:content>
            <v-row v-if="data.data">
                <v-col :cols="6">
                    <yooda-chart
                        :options="data.data.options"
                        chart-type="pie"
                        chart-style="height:220px; margin-bottom: -20px"
                    />
                </v-col>

                <v-col :cols="6">
                    <div class="text-center v-application">
                        <div class="pt-10 pb-6">
                            <yooda-icon name="ribbon-b" class="p-3" :color="getComponentColor().getProgressBarColor(data.data.chartValue)" background="rounded" :size="30"></yooda-icon>
                        </div>
                        <div v-if="data.data.averagePosition === 0 || data.data.averagePosition === null">
                            <h2 class="mt-6 mb-6">-</h2>
                        </div>
                        <kpi-value-evolution v-else :value="data.data.averagePosition|formatNumber(1)" :evolutionSign="data.data.evolution.sign" :evolutionValue="data.data.evolution.value"></kpi-value-evolution>

                        <p class="text-body-1 mb-4">{{label}}</p>
                    </div>
                </v-col>
            </v-row>
        </template>

    </component-container>

</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import KpiValueEvolution from "@/components/yooda-components/KpiValueEvolution";
import InformationTooltip from "@/components/yooda-components/InformationTooltip";

export default {
    props:{
        data:{},
        title:{
            type: String,
            default: ''
        },
        subtitle:{
            type: String,
            default: ''
        },
        label:{
            type: String,
            default: ''
        },
        tooltipTitle:{
            type: String,
            default: ''
        },
        tooltipText:{
            type: String,
            default: ''
        },
        noDataMessage:{
            type: String,
            default: ''
        }
    },
    data() {
        return {
            chartData:{}
        }
    },
    components: {
        YoodaChart,
        InformationTooltip,
        KpiValueEvolution,

    },
    methods:{
        getEvoIconName(sign){
            let iconName = 'arrow-down-c'
            if(sign === 'increase'){
                iconName = 'arrow-up-c'
            }

            return iconName
        },
        getEvoIconColor(sign){
            let iconColor = 'negativeVariation'
            if(sign === 'increase'){
                iconColor = 'positiveVariation'
            }

            return iconColor
        }
    },
    updated() {

    },
}
</script>
