<template>
    <component-container
        :card-title="$t('component_marketSegmentSegmentSize_title')"
        :card-subtitle="$t('component_marketSegmentSegmentSize_subtitle')"

        :height="495"

        :information-tooltip="{
            title : $t('component_marketSegmentSegmentSize_informationTooltip_title'),
            text:$t('component_marketSegmentSegmentSize_informationTooltip_text')
        }"

        :component-config="{
            hasConfig:keywordGroupsConfiguration.length,
            hideOnNoConfig:hideOnNoConfig,
            redirectRoute:displayPlaceholderConfigButton ? 'segments-configuration' : null,
            placeholderTitle:$t('component_componentContainer_noMarketSegment_title'),
            placeholderText:$t('component_componentContainer_noMarketSegment_text')
        }"

        :component-data="{
            response: marketSegmentSegmentSize,
            hasData: (marketSegmentSegmentSize.data && marketSegmentSegmentSize.data.series)
        }"
    >
        <template v-slot:content>
            <yooda-chart
                :on-mouseover="setSelectedSegment"
                :options="marketSegmentSegmentSize.data"
                chart-type="pie"
            />
        </template>
    </component-container>
</template>


<script>

import YoodaChart from "@/components/yooda-components/YoodaChart";
import apiCalls from '@/api/axios/dataReport/marketSegment.js'

export default {
    props: {
        displayPlaceholderConfigButton:{type: Boolean, default: true},
        hideOnNoConfig:{type: Boolean, default: false}
    },
    data() {
        return {
            marketSegmentSegmentSize:null,
        }
    },

    components: {
        YoodaChart
    },
    watch:{
        getChartSelectedSeriesName(){
            if(this.marketSegmentSegmentSize.data.series){
                this.marketSegmentSegmentSize.data.series[0].data.forEach((data)=>{
                    if(data.name === this.getChartSelectedSeriesName){
                        data.itemStyle.borderWidth = 2
                    }
                    else{
                        data.itemStyle.borderWidth = 0
                    }
                })
            }
        }
    },
    methods:{
        setSelectedSegment(pie){
            this.setSegmentSizeSelected(pie.name)
        },
        formatData(jsonData){
            if(jsonData.content.length === 0){return {}}
            let legend = []
            let serieData = []
            let colorIndex = 1
            let tooltipLabel = this.$t('component_marketSegmentSegmentSize_label')

            jsonData.content.sort((a,b)=>{
                return (a.keywordGroupName < b.keywordGroupName) ? -1 : (a.keywordGroupName > b.keywordGroupName) ? 1 : 0
            })


            jsonData.content.forEach(data => {
                legend.push((data.keywordGroupName === '#other#'?this.$t('component_marketSegmentSegmentSize_other'):data.keywordGroupName))
                serieData.push({
                    value: data.cumulatedSearchCount,
                    name: (data.keywordGroupName === '#other#'?this.$t('component_marketSegmentSegmentSize_other'):data.keywordGroupName),
                    itemStyle: { color: this.getThemeColorByName('marketSegmentColor'+colorIndex), borderWidth:0, borderColor:'#a70202' }
                })
                colorIndex++
            })

            let tooltipFormatter = params => {
                return `
                    <p>
                        <span class="toolTipColorSpan" style="background-color:${params.color}"></span>
                        ${params.name} : ${this.$options.filters.formatNumber(params.percent)}%
                        <br>
                        ${this.$options.filters.formatNumber(params.value)} ${tooltipLabel}
                    </p>`
            }
            let labelFormatter = params => { return  params.name+': '+this.$options.filters.formatNumber(params.percent)+'%\r\n ('+this.$options.filters.formatNumber(params.value)+' '+tooltipLabel+')' }

            return {
                grid: { left: 50, top: 25, right: 30, bottom: 40 },
                tooltip: this.genericTooltipConfiguration('item', tooltipFormatter),
                legend: { orient: 'horizontal', icon:'circle', data: legend },
                series: [
                    {
                        type: 'pie',
                        selectMode:'multiple',
                        center: ['50%', '60%'],
                        data: serieData,
                        avoidLabelOverlap: true,
                        label: { formatter:labelFormatter, show: true, fontWeight: 400, fontSize:14 },
                        emphasis: {
                            label: { show: true },
                            itemStyle: { shadowBlur: 10, shadowOffsetX: 0, shadowColor: 'rgba(0, 0, 0, 0.5)', }
                        }
                    }
                ]
            }
        },
        initComponentData(){
            this.getComponentData({
                componentName:'marketSegmentSegmentSize',
                promise: (!this.projectCreationWaiting && this.$router.currentRoute.params.projectId  && this.keywordGroupsConfiguration.length>0) ? apiCalls.readSegmentSize(this.$router.currentRoute.params.projectId) : false,
                dataFormatter: this.formatData
            })
        }
    },
    beforeMount() {
        this.initComponentData()
    },
}
</script>
