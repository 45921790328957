<template>

    <v-dialog v-model="show" content-class="popin-dialog-container" overlay-opacity="0.85">

        <v-icon
            class="close-popin-dialog"
            @click="show=false"
        >
            mdi-close
        </v-icon>

        <component-container
            :card-title="`${$t('component_advertisingAdwordsAdvertiserAds_title')} ${elementName}`"
            :card-subtitle="$t('component_advertisingAdwordsAdvertiserAds_subtitle')"



            :table-menu="{csv:true}"

            :component-data="{response:advertisingAdwordsAdvertiserAds, hasData:advertisingAdwordsAdvertiserAds.data}"

            @setTableMenuExportCsv=" $refs.agGridTable.exportCsvTable()"
        >
            <template v-slot:content>
                <ag-grid-table v-if="advertisingAdwordsAdvertiserAds.data"
                   :with-fullscreen="false"
                   :show-pagination="false"
                   :show-floating-filter="false"
                   :table-data="{columnDefs:columnDefs,rowData:advertisingAdwordsAdvertiserAds.data}"
                   :ag-grid-options="{ rowHeight: 160 }"
                   class="mt-10"
                   ref="agGridTable"
                />
                <p class="text-right mt-6">{{ $t('component_advertisingAdwordsAdvertiserAds_footer') }} {{ totalPrint }}</p>
            </template>
        </component-container>

    </v-dialog>
</template>


<script>

import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable'


export default {
    data() {
        return {
            show:false,
            advertisingAdwordsAdvertiserAds:{  isLoaded:false, error:null,data: null },
            elementName:null,
            popinStateName:'advertisingAdwordsAdvertiserAds',
            columnDefs:[
                {headerName: this.$t('component_advertisingAdwordsAdvertiserAds_table_header_ad'), field: 'ad',   sortable: false, width:600, cellRendererFramework: 'CellRendererAdwordAd'},
                {headerName: this.$t('component_advertisingAdwordsAdvertiserAds_table_header_keywordCount'), field: 'keywordCount', width:200, cellRendererFramework: 'CellRendererFormatedNumber', cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},
                {headerName: this.$t('component_advertisingAdwordsAdvertiserAds_table_header_printCount'), field: 'printCount',  sort:'desc', width:200, cellRendererFramework: 'CellRendererFormatedNumber', cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right'},
            ],
            totalPrint:0
        }
    },
    components: {
        AgGridTable
    },
    watch:{
        getShowPopin(){
            if(!this.show && this.getShowPopin){
                if(this.getShowPopin.componentName === this.popinStateName){
                    this.show = true
                    this.elementName = this.getShowPopin.elementName
                    this.getComponentData({
                        componentName:'advertisingAdwordsAdvertiserAds',
                        promise: this.getShowPopin.promise(),
                        dataFormatter: this.formatData
                    })
                }
            }
        },
        show(){
            if(!this.show){
                this.showAgGridPopin(null)
            }
        }

    },
    methods:{
        formatData(jsonData){
            let rowData = []
            let total = 0
            jsonData.content.forEach(data => {
                rowData.push({
                    ad: {...data.info, ...{url:data.url, title:data.title, isAdwordsRenderCell:true}},
                    keywordCount: data.rankedKeywordCount,
                    printCount: data.cumulatedSearchCount
                })
                total += data.cumulatedSearchCount

            })
            this.totalPrint = this.$options.filters.formatNumber(total)
            return rowData
        }
    }
}
</script>
