<template>
    <div class="kpiValueEvolution v-application">
        <h3 class="text-h3 font-weight-bold mt-6 mb-6">{{ value }}</h3>
        <div>
            <div>
                <yooda-icon :name="getEvoIconName" :color="getEvoIconColor" :size="18"></yooda-icon>
                <span v-if="evolutionValue" :style="{color:getThemeColorByName(getEvoIconColor)}">{{ evolutionValue }}</span>
            </div>
        </div>
    </div>

</template>

<script>

import YoodaIcon from "@/components/yooda-components/YoodaIcon";
export default {
    props: {
        value:{
            type:String,
            default: ''
        },
        evolutionSign:{
            type: String,
            default: 'increase'
        },
        evolutionValue:{
            type:Number,
            default: 0
        },
    },
    computed: {
        getEvoIconName(){
            let iconName = 'arrow-down-c'
            if(this.evolutionSign === '+'){
                iconName = 'arrow-up-c'
            }
            if(this.evolutionSign === '='){
                iconName = 'equal'
            }

            return iconName
        },
        getEvoIconColor(){
            let iconColor = 'negativeVariation'
            if(this.evolutionSign === '+'){
                iconColor = 'positiveVariation'
            }

            if(this.evolutionSign === '='){
                iconColor = ''
            }

            return iconColor
        }
    },
    components:{
        YoodaIcon
    }
}
</script>

<style lang="scss">

.kpiValueEvolution{
    position: relative;
    h3{
        display: inline-block;
    }
    >div{
        display: inline-block;
        >div{
            position: absolute;
            top: 40px;
            margin-left: 10px;
        }
    }

}

</style>
